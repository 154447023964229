import React from "react";
import style from "./../OpportunityDetails.module.css";
import defaultImg from "../../../../assets/defaultimg/Rectangle 5337@2x.png";

function AboutRecruiter(props) {
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <img
            src={
              props.profileData && props.profileData.recruiterImgUrl !== null
                ? props.profileData.recruiterImgUrl
                : defaultImg
            }
            className={style.orgimgRecr}
            alt="img"
          />
          <div>
            <p className={style.playerName}>
              {props.profileData ? props.profileData.recruiterName : " "}
            </p>
            <p className={style.gameName}>
              {props.profileData ? props.profileData.recruiterDesignation : ""}
            </p>
          </div>
        </div>
        <div>
          <div>
            <h1 className={style.aboutRecr}> About </h1>
          </div>
          <div>
            {props.profileData && (props.profileData.recruiterAbout !== null && props.profileData.recruiterAbout !== "") ? (
              <p className={style.paraaboutRecr}>
                {props.profileData.recruiterAbout}
              </p>
            ) : (
              <p className={style.paraaboutRecr} style={{ opacity: "0.5" }}>
                No Information Provided.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default AboutRecruiter;
