import {
  postRequest,
  getRequest,
  // putRequest,
  // postRequestFile,
  // postRequestVerify,
  loginRequest,
  postGenerateToken,
  postResetPass,
  // getRequestFileDownload
} from "../../Helper/Helper";

import * as actionTypes from "./Actiontypes";
import jwt_decode from "jwt-decode";

export const getSignIn = (formData) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOGIN_INITIAL });
    loginRequest("/signIn", {
      email: formData.email,
      password: formData.password,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            var decoded = jwt_decode(
              res.headers.authorization,
              res.headers.opportunitycount
            );
            localStorage.setItem("userToken", res.headers.authorization);
            localStorage.setItem("userRole", decoded.AUTHORITIES_KEY);
            // var decodedr=jwt_decode(res.headers.opportunityCount);
            localStorage.setItem(
              "opportunityCount",
              res.data.responseData.opportunityCount
            );

            return dispatch({
              type: actionTypes.LOGIN_SUCCESS,
              payload: res.data.responseData.profileUpdated,
              role: decoded.AUTHORITIES_KEY,
              // opportunityCount:decodedr.AUTHORITIES_KEY
            });
          } else {
            return dispatch({
              type: actionTypes.LOGIN_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.LOGIN_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: actionTypes.LOGIN_FAIL,
          payload: "Server Error,Try Again",
        });
      });
  };
};
//--------------------Student Sign Up API-------------------------------------------------------------
export const getUserDetails = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_DETAILS_INITIAL });
    getRequest(`/GET_COMMON_DETAILS`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            if (res.data.responseData.name !== null) {
              localStorage.setItem("userName", res.data.responseData.name);
            }
            localStorage.setItem("userEmail", res.data.responseData.email);
            localStorage.setItem("accountId", res.data.responseData.accountId);
            return dispatch({
              type: actionTypes.GET_DETAILS_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.GET_DETAILS_FAIL,
              payload: res.data.responseData.message,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GET_DETAILS_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: actionTypes.GET_DETAILS_FAIL,
          payload: "Server Error",
        });
      });
  };
};
//--------------------Student Sign Up API-------------------------------------------------------------
export const getStudentSignUp = (formData) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.STUDENT_SIGNUP_INITIAL });
    postRequest("/studentSignUp", formData)
      .then((res) => {
        if (res.status === 201) {
          return dispatch({
            type: actionTypes.STUDENT_SIGNUP_SUCCESS,
            payload: true,
            emailid: formData.email,
          });
        } else {
          return dispatch({
            type: actionTypes.STUDENT_SIGNUP_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.STUDENT_SIGNUP_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//--------------------Organization Sign Up API-------------------------------------------------------------
export const getOrgSignUp = (formData) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ORG_SIGNUP_INITIAL });
    postRequest("/organisationSignUp", formData)
      .then((res) => {
        if (res.status === 201) {
          return dispatch({
            type: actionTypes.ORG_SIGNUP_SUCCESS,
            payload: true,
            emailid: formData.email,
          });
        } else {
          return dispatch({
            type: actionTypes.ORG_SIGNUP_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.ORG_SIGNUP_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

// ******************************************************************************

//**************************************************************************** */
export const hideAuthModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_AUTH_HIDE_MODAL,
      payload: data,
    });
  };
};
//**************************************************************************** */
export const handleSignInModal = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_AUTH_SIGNIN_MODAL,
      // payload:data,
    });
  };
};
//**************************************************************************** */
export const handleSignUpModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_AUTH_SIGNUP_MODAL,
      payload: data,
    });
  };
};
//**************************************************************************** */
export const handleForgotPasswordModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_AUTH_FORGOT_MODAL,
      payload: data,
    });
  };
};
//****************************************** */
export const handleOTPModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_AUTH_OTP_MODAL,
      payload: data,
    });
  };
};

export const handleSignupOTPModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_AUTH_SIGNUP_OTP_MODAL,
      payload: data,
    });
  };
};
//**************************************************************************** */

//*********************************************************************** */
export const ResetPasswordModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_AUTH_RESET_PASSWORD_MODAL,
      payload: data,
    });
  };
};

//**************************************************************************** */
export const handleConfirmModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_AUTH_CONFIRM_MODAL,
      payload: data,
    });
  };
};

export const closeResponseAlert = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLOSE_RESPONSE_ALERT,
      // payload:data,
    });
  };
};

//**************************************************************************** */

//************************************************************************** */

export const getRecruiterList = (filter) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RECRUITER_LIST_INITIAL });
    getRequest(`/recruiter/getRecruiterList?filterValue=${filter}`)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: actionTypes.RECRUITER_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          return dispatch({
            type: actionTypes.RECRUITER_LIST_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.RECRUITER_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//**************************************************************************** */
// export const getLogout=()=>{
//   return (dispatch) => {
//     dispatch({ type: actionTypes.LOGOUT_ALERT_INITIAL });
//       localStorage.removeItem("userToken");
//       localStorage.removeItem("userRole");
//       localStorage.removeItem("userName");
//       localStorage.removeItem("userEmail");
//       localStorage.removeItem("accountId");
// return dispatch({type: actionTypes.LOGOUT_ALERT_SUCCESS, });

//   }
// }
export const logout = (Id) => {
  return (dispatch) => {
    postRequest(`/logout?deviceId=${Id}`, Id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: actionTypes.LOGOUT_INITIAL });
          localStorage.removeItem("userToken");
          localStorage.removeItem("userRole");
          localStorage.removeItem("userName");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("accountId");
          localStorage.removeItem("fcmtoken");
          localStorage.removeItem("opportunityCount");
          return dispatch({
            type: actionTypes.LOGOUT_SUCCESS,
            payload: res.data.responseData.message,
          });
        } else {
          return dispatch({
            type: actionTypes.LOGOUT_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.LOGOUT_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//**************************************************************************** */
export const updateLoader = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_LOADER,
    });
  };
};

//**************************************************************************** */
export const verifyEmail = (token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.VERIFY_EMAIL_INITIAL });
    getRequest(`/verifyEmail?token=${token}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.VERIFY_EMAIL_SUCCESS,
              payload: true,
            });
          } else {
            return dispatch({
              type: actionTypes.VERIFY_EMAIL_FAIL,
              payload: res.data.responseData.message,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.VERIFY_EMAIL_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.VERIFY_EMAIL_NETWORK_ERROR,
          payload: err.message,
        });
      });
  };
};

// ************************************ForgotPassword*************

export const generatePasswordResetToken = (formData) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GENERATE_PASSWORD_RESET_TOKEN_INITIAL });
    postGenerateToken("/generatePasswordResetToken", {
      email: formData.email,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.GENERATE_PASSWORD_RESET_TOKEN_SUCCESS,
              payload: true,
            });
          } else {
            return dispatch({
              type: actionTypes.GENERATE_PASSWORD_RESET_TOKEN_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GENERATE_PASSWORD_RESET_TOKEN_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: actionTypes.GENERATE_PASSWORD_RESET_TOKEN_FAIL,
          payload: "Server Error,Try Again",
        });
      });
  };
};

//***************resetPassword*****************************/

// export const resetPassword = (pass,token) => {
//   return dispatch => {
//     dispatch({ type: actionTypes.RESET_PASSWORD_INITIAL });
//     postResetPass("/resetPassword",
//     {
//       "password": pass
//     },
//       token
//     )
//       .then(res => {
//         if (res.status === 200) {
//           if (res.data.statusCode === 0) {
//             return dispatch({
//               type: actionTypes.RESET_PASSWORD_SUCCESS,
//               payload: true,
//             });
//           } else {
//             return dispatch({
//               type: actionTypes.RESET_PASSWORD_FAIL,
//               payload: res.data.responseData.message,
//               ErrorCode: res.data.responseData.errorCode
//             });
//           }
//         } else {
//           return dispatch({
//             type: actionTypes.RESET_PASSWORD_FAIL,
//             payload: res.data.responseData.message,
//             ErrorCode: res.data.responseData.errorCode
//           });
//         }
//       })
//       .catch(error => {

//         return dispatch({
//           type: actionTypes.RESET_PASSWORD_EMAIL_NETWORK_ERROR,
//           payload: "Server Error,Try Again"
//         });
//       });
//   };
// };
//******************fotgotPassword *********************************/
export const forgotPassword = (email) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FORGOT_PASSWORD_INITIAL });
    getRequest(`/forgotPassword/${email.email}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.FORGOT_PASSWORD_SUCCESS,
              payload: true,
            });
          } else {
            return dispatch({
              type: actionTypes.FORGOT_PASSWORD_FAIL,
              payload: res.data.responseData.message,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.FORGOT_PASSWORD_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.FORGOT_PASSWORD_FAIL,
          payload: "Server Error,Try Again",
        });
      });
  };
};
//**************************OTP Validate****************************
export const ValidateOtpResetPassword = (otp, email) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.VALIDATE_OTP_RESET_PASSWORD_INITIAL });

    postRequest(`/ValidateOtpResetPassword?OTP=${otp}&email=${email}`)
      // {
      //   "otp":formData.otp,
      //  "email":formData.email
      // })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.VALIDATE_OTP_RESET_PASSWORD_SUCCESS,
              payload: true,
            });
          } else {
            return dispatch({
              type: actionTypes.VALIDATE_OTP_RESET_PASSWORD_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.VALIDATE_OTP_RESET_PASSWORD_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: actionTypes.VALIDATE_OTP_RESET_PASSWORD_NETWORK_ERROR,
          payload: "Server Error,Try Again",
        });
      });
  };
};

export const ResetPasswordEmailOtp = (otp, email, password) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_PASSWORD_EMAIL_OTP_INITIAL });

    postRequest(`/ResetPasswordEmailOtp?OTP=${otp}&email=${email}`, {
      password: password,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.RESET_PASSWORD_EMAIL_OTP_SUCCESS,
              payload: true,
            });
          } else {
            return dispatch({
              type: actionTypes.RESET_PASSWORD_EMAIL_OTP_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.RESET_PASSWORD_EMAIL_OTP_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: actionTypes.RESET_PASSWORD_EMAIL_OTP_NETWORK_ERROR,
          payload: "Server Error,Try Again",
        });
      });
  };
};

export const ValidateOtpEmailVerification = (otp, email, password) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_INITIAL });

    postRequest(
      `/ValidateOtpEmailVerification?OTP=${otp}&email=${email}&password=${password}`
    )
      // {
      //   "otp":formData.otp,
      //  "email":formData.email
      // })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_SUCCESS,
              payload: true,
            });
          } else {
            return dispatch({
              type: actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_NETWORK_ERROR,
          payload: "Server Error,Try Again",
        });
      });
  };
};

export const ResendOtpForEmailVerification = (email) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESEND_OTP_FOR_EMAIL_VERIFICATION_INITIAL });

    postRequest(`/ResendOtpForEmailVerification?email=${email}`)
      // {
      //   "otp":formData.otp,
      //  "email":formData.email
      // })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.RESEND_OTP_FOR_EMAIL_VERIFICATION_SUCCESS,
              payload: true,
            });
          } else {
            return dispatch({
              type: actionTypes.RESEND_OTP_FOR_EMAIL_VERIFICATION_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.RESEND_OTP_FOR_EMAIL_VERIFICATION_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: actionTypes.RESEND_OTP_FOR_EMAIL_VERIFICATION_NETWORK_ERROR,
          payload: "Server Error,Try Again",
        });
      });
  };
};

// **************************RegisterFCMToken****************************
export const registerFcmToken = (formData) => {
  return async (dispatch) => {
    await dispatch({ type: actionTypes.REGISTER_FCMTOKEN_INITIAL });
    postRequest("/registerFcmToken", formData)
      .then((res) => {
        if (res.status === 201) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.REGISTER_FCMTOKEN_SUCCESS,
              payload: true,
            });
          } else {
            return dispatch({
              type: actionTypes.REGISTER_FCMTOKEN_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.REGISTER_FCMTOKEN_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: actionTypes.REGISTER_FCMTOKEN_FAIL,
          payload: "Server Error,Try Again",
        });
      });
  };
};

//********************************************** */
export const getUserList = (filter) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_LIST_INITIAL });
    getRequest(`/api/v1/userList`)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: actionTypes.USER_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          return dispatch({
            type: actionTypes.USER_LIST_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.USER_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

export const getNotificationCount = () => {
  return (dispatch) => {
    getRequest(`/notificationCount`)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: actionTypes.GET_NOTIFICATION_COUNT_SUCCESS,
            payload: res.data.responseData.totalNewNotification,
          });
        } else {
          return dispatch({
            type: actionTypes.GET_NOTIFICATION_COUNT_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.GET_NOTIFICATION_COUNT_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
