import * as actionTypes from "../Action/Actiontypes";

const initialState = {
  loader: false,
  errorMsg: "",
  errorCode: "",
  recErrorMsg: "",
  recSuccessMsg: "",
  recSuccessStatus: false,
  candidateProfileData: {},
  initial: true,
  recErrorCode: "",
  showAlert: false,
  showBlur: false,
  category_list: [],
  sub_category_list: [],
  recruiterProfileData: {},
  recruiterProfileErrMsg: "",
  recruiterProfileStatus: false,
  recruiterUpdateErrMsg: "",
  recruiterUpdateStatus: false,
  updateRecruiterErrMsg: "",
  updateRecruiterStatus: false,
  showDeleteModal: false,
  showRejectModal: false,
  showsentRequestModal: false,
  showRecAdvanceFilter: false,
  opp_list: [],
  country_list: [],
  state_list: [],
};

export const recruiterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_BLUR:
      return {
        showBlur: false,
      };
    case actionTypes.SHOW_ALERT:
      return {
        showAlert: true,
      };
    case actionTypes.UPDATE_REC_ADVANCE_FILTER_MODAL:
      return {
        ...state,
        showBlur: action.payload,
        showRecAdvanceFilter: action.payload,
      };
    case actionTypes.RECRUITER_PROFILE_INITIAL:
      return {
        ...state,
        recruiterProfileErrMsg: "",
        recruiterProfileStatus: false,
        recErrorMsg: "",
        recErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.RECRUITER_PROFILE_SUCCESS:
      return {
        ...state,
        recruiterProfileErrMsg: "",
        recruiterProfileStatus: true,
        recErrorMsg: "",
        recErrorCode: "",
        loader: false,
        initial: true,
        recruiterProfileData: action.payload,
        showAlert: false,
      };
    case actionTypes.RECRUITER_PROFILE_FAIL:
      return {
        ...state,
        recruiterProfileErrMsg: "",
        recruiterProfileStatus: false,
        recErrorMsg: action.payload,
        recErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.UPDATE_AUTH_REJECT_MODAL:
      return {
        ...state,
        showRejectModal: action.payload,
        showBlur: action.payload,
      };
    case actionTypes.UPDATE_AUTH_SENT_REQUEST_MODAL:
      return {
        ...state,
        showsentRequestModal: action.payload,
        showBlur: action.payload,
      };

    case actionTypes.UPDATE_RECRUITER_PROFILE_INITIAL:
      return {
        ...state,
        updateRecruiterErrMsg: "",
        updateRecruiterStatus: false,
        recErrorMsg: "",
        recErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.UPDATE_RECRUITER_PROFILE_SUCCESS:
      return {
        ...state,
        updateRecruiterErrMsg: "",
        updateRecruiterStatus: true,
        recErrorMsg: "",
        recErrorCode: "",
        loader: false,
        initial: true,
        recSuccessMsg: "Status updated successfully!",
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.UPDATE_RECRUITER_PROFILE_FAIL:
      return {
        ...state,
        updateRecruiterErrMsg: "",
        updateRecruiterStatus: false,
        recErrorMsg: action.payload,
        recErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.CREATE_RECRUITER_OPPO_INITIAL:
      return {
        ...state,
        recErrorStatus: false,
        recErrorMsg: "",
        recSuccessStatus: false,
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.CREATE_RECRUITER_OPPO_SUCCESS:
      return {
        ...state,
        recErrorStatus: false,
        recErrorMsg: "",
        recSuccessStatus: true,
        loader: false,
        initial: true,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.CREATE_RECRUITER_OPPO_FAIL:
      return {
        ...state,
        recErrorStatus: true,
        recErrorMsg: action.payload,
        recSuccessStatus: false,
        loader: false,
        initial: false,
        showAlert: false,
        showBlur: true,
      };
    case actionTypes.CATEGORY_LIST_INITIAL:
      return {
        ...state,
        categoryListErrMsg: "",
        categoryListStatus: false,
        categoryErrorMsg: "",
        categoryErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
        category_list: [],
        sub_category_list: [],
      };
    case actionTypes.CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryListErrMsg: "",
        categoryListStatus: true,
        categoryErrorMsg: "",
        categoryErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        category_list: action.payload,
      };
    case actionTypes.CATEGORY_LIST_FAIL:
      return {
        ...state,
        categoryListErrMsg: "",
        categoryListStatus: true,
        categoryErrorMsg: action.payload,
        categoryErrorCode: action.ErrorCode,
        loader: false,
        initial: true,
        showAlert: false,
      };

    case actionTypes.SUB_CATEGORY_LIST_INITIAL:
      return {
        ...state,
        categoryListErrMsg: "",
        categoryListStatus: false,
        categoryErrorMsg: "",
        categoryErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.SUB_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryListErrMsg: "",
        categoryListStatus: true,
        categoryErrorMsg: "",
        categoryErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        sub_category_list: action.payload,
      };
    case actionTypes.SUB_CATEGORY_LIST_FAIL:
      return {
        ...state,
        categoryListErrMsg: "",
        categoryListStatus: true,
        categoryErrorMsg: action.payload,
        categoryErrorCode: action.ErrorCode,
        loader: false,
        showAlert: false,
        initial: true,
      };
    case actionTypes.OPPO_RECRUITER_LIST_INITIAL:
      return {
        ...state,
        opprecruiterListErrMsg: "",
        opprecruiterlistStatus: false,
        oppErrorMsg: "",
        oppErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.OPPO_RECRUITER_LIST_SUCCESS:
      return {
        ...state,
        opprecruiterListErrMsg: "",
        opprecruiterlistStatus: true,
        oppErrorMsg: "",
        oppErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        // showBlur:false,
        opp_rec_list: action.payload.createdOpportinity,
        page_no_rec: action.payload.noOfPagesAsPerPageLimit,
      };
    case actionTypes.OPPO_RECRUITER_LIST_FAIL:
      return {
        ...state,
        opprecruiterListErrMsg: "",
        opprecruiterlistStatus: true,
        oppErrorMsg: action.payload,
        oppErrorCode: action.ErrorCode,
        loader: false,
        showBlur: false,
        initial: false,
        showAlert: false,
      };

    case actionTypes.CLOSE_RECRUITER_RESPONSE_ALERT:
      return {
        ...state,
        loader: false,
        recErrorCode: "",
        recErrorMsg: "",
        showBlur: false,
        showAlert: false,
      };
    case actionTypes.GET_DETAILS_FOR_EDIT_INITIAL:
      return {
        ...state,
        getDetailsForEditErrMsg: "",
        getDetailsForEditStatus: false,
        getDetailsForEditErrorMsg: "",
        getDetailsForEditErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.GET_DETAILS_FOR_EDIT_SUCCESS:
      return {
        ...state,
        getDetailsForEditErrMsg: "",
        getDetailsForEditStatus: true,
        getDetailsForEditErrorMsg: "",
        getDetailsForEditErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        getDetailsForEdit: action.payload,
      };
    case actionTypes.GET_DETAILS_FOR_EDIT_FAIL:
      return {
        ...state,
        getDetailsForEditErrMsg: "",
        getDetailsForEditStatus: true,
        getDetailsForEditErrorMsg: action.payload,
        getDetailsForEditErrorCode: action.ErrorCode,
        loader: false,
        initial: true,
        showAlert: true,
      };
    case actionTypes.UPDATE_RECRUITER_DELETE_MODAL:
      return {
        ...state,
        showBlur: action.payload,
        showDeleteModal: action.payload,
      };
    case actionTypes.GET_APPLIED_STUDENT_LIST_INITIAL:
      return {
        ...state,
        appliedstudentListErrMsg: "",
        appliedstudentlistStatus: false,
        applidstudentErrorMsg: "",
        oppErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.GET_APPLIED_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        appliedstudentListErrMsg: "",
        appliedstudentlistStatus: true,
        applidstudentErrorMsg: "",
        oppErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        app_stu_list: action.payload.appliedOpportinity,
        page_no_stu: action.payload.noOfPagesAsPerPageLimit,
      };
    case actionTypes.GET_APPLIED_STUDENT_LIST_FAIL:
      return {
        ...state,
        appliedstudentListErrMsg: "",
        appliedstudentlistStatus: true,
        applidstudentErrorMsg: action.payload,
        oppErrorCode: action.ErrorCode,
        loader: false,
        showBlur: false,
        initial: false,

        showAlert: true,
      };
    case actionTypes.OPPORTUNITIES_LIST_INITIAL:
      return {
        ...state,
        errorMsg: "",
        errorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.OPPORTUNITIES_LIST_SUCCESS:
      return {
        ...state,
        errorMsg: "",
        errorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        accepted_opp_list: action.payload.acceptStudent,
        page_no: action.payload.noOfPagesAsPerPageLimit,
      };
    case actionTypes.OPPORTUNITIES_LIST_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
        errorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };

    case actionTypes.ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_INITIAL:
      return {
        ...state,
        acceptedrejectedappliedErrMsg: "",
        acceptedrejectedappliedErrMsgStatus: false,
        acceptedrejectedappliedErrMsgErrorMsg: "",
        acceptedrejectedappliedErrMsgErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_SUCCESS:
      return {
        ...state,
        acceptedrejectedappliedErrMsg: "",
        acceptedrejectedappliedStatus: true,
        acceptedrejectedappliedErrorMsg: "",
        acceptedrejectedappliedErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        acceptedrejectedapplied: action.payload,
      };
    case actionTypes.ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_FAIL:
      return {
        ...state,
        acceptedrejectedappliedErrMsgErrMsg: "",
        acceptedrejectedappliedErrMsgStatus: true,
        acceptedrejectedappliedErrMsgErrorMsg: action.payload,
        acceptedrejectedappliedErrMsgErrorCode: action.ErrorCode,
        loader: false,
        initial: true,
        showAlert: true,
      };
    case actionTypes.GET_STUDENT_PROFILE_BY_PROFILEID_INITIAL:
      return {
        ...state,
        studentProfilebyProfileIdErrMsg: "",
        studentProfilebyProfileIdStatus: false,
        studentProfilebyProfileIdErrorMsg: "",
        studentProfilebyProfileIdErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.GET_STUDENT_PROFILE_BY_PROFILEID_SUCCESS:
      return {
        ...state,
        studentProfilebyProfileIdErrMsg: "",
        studentProfilebyProfileIdStatus: true,
        studentProfilebyProfileIdErrorMsg: "",
        studentProfilebyProfileIdErrorCode: "",
        loader: false,
        initial: true,
        candidateProfileData: action.payload,
        skillDatas: action.payload.skillList,
        //  showBlur:false,
        showAlert: false,
      };
    case actionTypes.GET_STUDENT_PROFILE_BY_PROFILEID_FAIL:
      return {
        ...state,
        studentProfilebyProfileIdErrMsg: "",
        studentProfilebyProfileIdStatus: false,
        studentProfilebyProfileIdErrorMsg: action.payload,
        studentProfilebyProfileIdErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        // showBlur: true,
      };
    case actionTypes.SENT_REQUEST_INITIAL:
      return {
        ...state,
        sentRequestErrMsg: "",
        sentRequestStatus: false,
        sentRequestErrorMsg: "",
        sentRequestErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
        showBlur: true,
      };
    case actionTypes.SENT_REQUEST_SUCCESS:
      return {
        ...state,
        sentRequestErrMsg: "",
        sentRequestStatus: true,
        sentRequestErrorMsg: "",
        sentRequestErrorCode: "",
        loader: false,
        initial: true,
        // candidateProfileData: action.payload,
        // skillDatas: action.payload.createdOpportinity,
        showAlert: true,
        showBlur: true,
        showsentRequestModal: false,
      };
    case actionTypes.SENT_REQUEST_FAIL:
      return {
        ...state,
        sentRequestErrMsg: "",
        sentRequestStatus: false,
        sentRequestErrorMsg: action.payload,
        sentRequestErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };

    case actionTypes.SEARCH_STUDENT_LIST_BY_RERUITER_INITIAL:
      return {
        ...state,
        searchstudentListErrMsg: "",
        searchstudentlistStatus: false,
        searchstudentErrorMsg: "",
        oppErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
        showsentRequestModal: false,
      };
    case actionTypes.SEARCH_STUDENT_LIST_BY_RERUITER_SUCCESS:
      return {
        ...state,
        searchstudentListErrMsg: "",
        searchstudentlistStatus: true,
        searchstudentErrorMsg: "",
        oppErrorCode: "",
        loader: false,
        initial: true,

        search_stu_list: action.payload.studentList,
        page_no_stu: action.payload.noOfPagesAsPerPageLimit,
        showsentRequestModal: false,
      };
    case actionTypes.SEARCH_STUDENT_LIST_BY_RERUITER_FAIL:
      return {
        ...state,
        searchstudentListErrMsg: "",
        searchstudentlistStatus: true,
        searchstudentErrorMsg: action.payload,
        oppErrorCode: action.ErrorCode,
        loader: false,
        // showBlur: true,
        initial: false,
        showAlert: false,
      };

    case actionTypes.COUNTRY_LIST_INITIAL:
      return {
        ...state,
        countryListErrMsg: "",
        countryListStatus: false,
        countryErrorMsg: "",
        countryErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
        country_list: [],
        state_list: [],
      };
    case actionTypes.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryListErrMsg: "",
        countryListStatus: true,
        countryErrorMsg: "",
        countryErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        country_list: action.payload,
      };
    case actionTypes.COUNTRY_LIST_FAIL:
      return {
        ...state,
        countryListErrMsg: "",
        countryListStatus: true,
        countryErrorMsg: action.payload,
        countryErrorCode: action.ErrorCode,
        loader: false,
        initial: true,
        showAlert: true,
      };
    case actionTypes.STATE_LIST_INITIAL:
      return {
        ...state,
        stateListErrMsg: "",
        stateListStatus: false,
        stateErrorMsg: "",
        stateErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
        state_list: [],
        city_list: [],
      };
    case actionTypes.STATE_LIST_SUCCESS:
      return {
        ...state,
        stateListErrMsg: "",
        stateListStatus: true,
        stateErrorMsg: "",
        stateErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        state_list: action.payload,
      };
    case actionTypes.STATE_LIST_FAIL:
      return {
        ...state,
        stateListErrMsg: "",
        stateListStatus: true,
        stateErrorMsg: action.payload,
        stateErrorCode: action.ErrorCode,
        loader: false,
        initial: true,
        showAlert: true,
      };

    case actionTypes.CITY_LIST_INITIAL:
      return {
        ...state,
        cityListErrMsg: "",
        cityListStatus: false,
        cityErrorMsg: "",
        cityErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
        city_list: [],
      };
    case actionTypes.CITY_LIST_SUCCESS:
      return {
        ...state,
        cityListErrMsg: "",
        cityListStatus: true,
        cityErrorMsg: "",
        cityErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        city_list: action.payload,
      };
    case actionTypes.CITY_LIST_FAIL:
      return {
        ...state,
        cityListErrMsg: "",
        cityListStatus: true,
        cityErrorMsg: action.payload,
        cityErrorCode: action.ErrorCode,
        loader: false,
        initial: true,
        showAlert: true,
      };

    case actionTypes.GET_ACCEPT_OPP_CATEGORY_INITIAL:
      return {
        ...state,
        acceptedopportunityErrMsg: "",
        acceptedopportunityStatus: false,
        acceptedopportunityErrorMsg: "",
        acceptedopportunityErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.GET_ACCEPT_OPP_CATEGORY_SUCCESS:
      return {
        ...state,
        acceptedopportunityErrMsg: "",
        acceptedopportunityStatus: true,
        acceptedopportunityErrorMsg: "",
        acceptedopportunityErrorCode: "",
        loader: false,
        initial: true,
        acceptedopportunityData: action.payload,
        skillDatas: action.payload.acceptOpportunityCategory,
        page_no_accepted: action.payload.noOfPagesAsPerPageLimit,
        showAlert: false,
      };
    case actionTypes.GET_ACCEPT_OPP_CATEGORY_FAIL:
      return {
        ...state,
        acceptedopportunityErrMsg: "",
        acceptedopportunityStatus: false,
        acceptedopportunityErrorMsg: action.payload,
        acceptedopportunityErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        // showBlur: true,
      };
    case actionTypes.GET_STUDENT_PROFILE_BY_PROFILE_INITIAL:
      return {
        ...state,
        studentProfilebyProfileIdErrMsg: "",
        studentProfilebyProfileIdStatus: false,
        studentProfilebyProfileIdErrorMsg: "",
        studentProfilebyProfileIdErrorCode: "",
        studentId: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.GET_STUDENT_PROFILE_BY_PROFILE_SUCCESS:
      return {
        ...state,
        studentProfilebyProfileIdErrMsg: "",
        studentProfilebyProfileIdStatus: true,
        studentProfilebyProfileIdErrorMsg: "",
        studentProfilebyProfileIdErrorCode: "",
        loader: false,
        initial: true,
        candidateProfileData: action.payload,
        skillDatas: action.payload.skillList,
        //  showBlur:false,
        // showAlert: true,
      };
    case actionTypes.GET_STUDENT_PROFILE_BY_PROFILE_FAIL:
      return {
        ...state,
        studentProfilebyProfileIdErrMsg: "",
        studentProfilebyProfileIdStatus: false,
        studentProfilebyProfileIdErrorMsg: action.payload,
        studentProfilebyProfileIdErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: false,
        // showBlur: true,
      };

    default:
      return state;
  }
};
