import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import style from "./appliedfilterc.module.css";
import { BlueButton, WhiteButton } from "../../GenericComponent/Buttons/button";
import Cross from "../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import { useHistory } from "react-router-dom";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

function AdvanceFilterc(props) {
  const history = useHistory();

  const catRef = useRef();
  const skillRef = useRef();
  const [categoryID, setCategoryID] = useState(
    JSON.parse(localStorage.getItem("filter"))?.categoryID || ""
  );
  const [errorMsg, setErrorMsg] = useState(null);

  const [showCalStart, setShowcalStart] = useState(false);
  const [showCalEnd, setShowcalEnd] = useState(false);
  const [skillID, setSkillID] = useState(
    JSON.parse(localStorage.getItem("filter"))?.skillID || ""
  );
  const [location, setLocation] = useState(
    JSON.parse(localStorage.getItem("filter"))?.location || ""
  );
  const [from, setFrom] = useState(
    JSON.parse(localStorage.getItem("filter"))?.from || null
  );
  const [to, setTo] = useState(
    JSON.parse(localStorage.getItem("filter"))?.to || null
  );
  const [skill1, setskill1] = useState([]);
  const handleCategory = (value) => {
    setErrorMsg(null);
    setCategoryID(value);
    setSkillID("");
    props.getSubCategoryList(value);
  };

  const handleSubCategory = (value) => {
    setErrorMsg(null);
    setSkillID(value);
  };

  const handlelocation = (value) => {
    setErrorMsg(null);
    setLocation(value);
  };

  const changeDateFrom = (e) => {
    e.map((item) => setFrom(item));
    setShowcalStart(false);
    setTo(null);
  };
  const changeDateTo = (e1) => {
    e1.map((item) => setTo(item));
    setShowcalEnd(false);
  };

  const ApplyFilter = () => {
    props.handlefilter(categoryID, skillID, location, from, to);
    props.handleFilterModal(false);
    localStorage.setItem(
      "filter",
      JSON.stringify({
        categoryID,
        skillID,
        location,
        from,
        to,
        skill1,
      })
    );
  };

  function showCategoryErr() {
    if (errorMsg !== null && errorMsg.categoryErr) {
      return errorMsg.categoryErr;
    } else {
      return "";
    }
  }
  function showSkillErr() {
    if (errorMsg !== null && errorMsg.skillErr) {
      return errorMsg.skillErr;
    } else {
      return "";
    }
  }

  const resetFilter = () => {
    setSkillID(null);
    setLocation("");
    setCategoryID(null);
    setskill1([]);
    setFrom(null);
    setTo(null);
    // props.handlefilter(null, [], null, null, null);
    // props.handleFilterModal(false);
  };
  useEffect(() => {
    setskill1(props.sub_category_list);
  }, [props.sub_category_list]);

  return (
    <div className={style.container}>
      <img
        src={Cross}
        alt="img"
        className={style.closeModal}
        onClick={() => props.handleFilterModal(false)}
      />
      <h1 className={style.para}> Advance Filter</h1>
      <div>
        <div className={style.div}>
          <form className={style.form1}>
            <div className={style.first}>
              <label className={style.label}> Category</label>
              <select
                ref={catRef}
                className={style.input}
                name="categoryId"
                onChange={(e) => handleCategory(e.target.value)}
                value={categoryID}
              >
                <option value="">Select a category</option>
                {props.category_list && props.category_list.length > 0
                  ? props.category_list.map((item, index) => (
                      <option value={item.categoryId}>
                        {item.categoryName}
                      </option>
                    ))
                  : null}
              </select>
              <span className={style.errorMsgbelow}>{showCategoryErr()}</span>
            </div>
            <div className={style.second}>
              <label className={style.label}> Skill</label>
              <select
                className={style.input}
                ref={skillRef}
                onChange={(e) => handleSubCategory(e.target.value)}
                value={skillID}
              >
                <option value="">Select a skill</option>

                {skill1 && skill1.length > 0
                  ? skill1.map((item, index) => (
                      <option
                        value={item.subCategoryId}
                        style={{ cursor: "pointer" }}
                      >
                        {item.subCategoryName}
                      </option>
                    ))
                  : null}
              </select>
              <span className={style.errorMsgbelow}>{showSkillErr()}</span>
            </div>
            <div className={style.fourth}>
              <label className={style.label}> Location</label>

              <input
                className={style.input}
                onChange={(e) => handlelocation(e.target.value)}
                value={location}
              />
            </div>
            <div className={style.third}>
              <label className={style.subheading}> Select Date Range </label>
            </div>
            <div className={style.fourth3}>
              <div className={style.fourth1}>
                <label className={style.label}> From</label>
                <Flatpickr
                  className={style.input1}
                  data-enable-time
                  value={from}
                  options={{
                    maxDate: "today",
                    dateFormat: "m/d/Y",
                    enableTime: false,
                    disableMobile: "true",
                  }}
                  onChange={(date) => {
                    changeDateFrom(date);
                  }}
                />
              </div>
              <div className={style.fourth2}>
                <label className={style.label}> To</label>

                <Flatpickr
                  className={style.input1}
                  data-enable-time
                  value={to}
                  options={{
                    maxDate: "today",
                    dateFormat: "m/d/Y",
                    enableTime: false,
                    minDate: from,
                    disableMobile: "true",
                  }}
                  disabled={from === null}
                  onChange={(date) => {
                    changeDateTo(date);
                  }}
                />
              </div>
            </div>
            <div className={style.btn_css}>
              <button
                type="reset"
                className={style.whiteButton}
                onClick={() => resetFilter()}
              >
                Reset
              </button>
              <BlueButton children="Apply" onClick={() => ApplyFilter()} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.candidateReducer.loader,
    skillDatas: state.candidateReducer.skillDatas,
    candidateErrorMsg: state.candidateReducer.candidateErrorMsg,
    candidateErrorCode: state.candidateReducer.candidateErrorCode,
    sub_category_list: state.recruiterReducer.sub_category_list,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
    category_list: state.recruiterReducer.category_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubCategoryList: (id) => dispatch(actionTypes.getSubCategoryList(id)),
    searchStudentListByRecruiter: (pageNo, payload, searchQuery) =>
      dispatch(
        actionTypes.searchStudentListByRecruiter(pageNo, payload, searchQuery)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceFilterc);
