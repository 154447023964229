import React from "react";
import style from "./cards.module.css";

import defaultImg from "../../../../../assets/defaultimg/Rectangle 5337@2x.png";

function Cards(props) {
  function showSkillData(condition, val1, val2) {
    if (condition) {
      return val1.join(" , ");
    } else {
      return val1;
    }
  }

  return (
    <>
      {props.data && props.data.length > 0
        ? props.data.map((item, index) => (
            <div className={style.container}>
              <div className={style.card}>
                <div className={style.left}>
                  <img
                    src={
                      item && item.studentImgUrl !== null
                        ? item.studentImgUrl
                        : defaultImg
                    }
                    alt="img"
                  />
                </div>
                <div className={style.middle}>
                  <p>
                    {item.firstName} {item.lastName}
                  </p>
                  <table className={style.table1}>
                    <tbody>
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.category}>Age</div>

                        <div className={style.value}>
                          {item && item.age !== null
                            ? item.age
                            : "No Information Provided"}
                        </div>
                      </div>
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.category}>Category</div>
                        <div className={style.value}>
                          {showSkillData(
                            Object.entries(item).length > 0 &&
                              item.category !== null,
                            item.category,
                            ""
                          ) || "No Information Provided"}
                        </div>
                      </div>

                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.category}>Location</div>

                        <div className={style.value}>
                          {/* {item && item.country !== null ? item.country : "-"}
                          &nbsp;,
                          {item && item.state !== null ? item.state : "-"}
                          &nbsp;,
                          {item && item.city !== null ? item.city : "-"} */}
                          {item.city === null && item.state !== null
                            ? item.state + "," + item.country
                            : item.city === null && item.state === null
                            ? item.country
                            : item.state === null && item.city !== null
                            ? item.city + "," + item.country
                            : item.city + "," + item.state + "," + item.country}
                        </div>
                      </div>
                    </tbody>
                  </table>
                </div>
                <div className={style.right}>
                  <table className={style.right_table1}>
                    <tbody>
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.category}>Gender</div>

                        <div className={style.value}>
                          {item && item.gender !== null
                            ? item.gender
                            : "No Information Provided"}
                        </div>
                      </div>
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.category}>Skills</div>

                        <div className={style.value}>
                          {showSkillData(
                            Object.entries(item).length > 0 &&
                              item.skills !== null,
                            item.skills,
                            ""
                          ) || "No Information Provided"}
                        </div>
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))
        : null}
    </>
  );
}

export default Cards;
