import React from "react";
import style from "./ResponseAlert.module.css";

import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import { useHistory } from "react-router-dom";

function ResponseAlert(props) {
  const history = useHistory();

  const handleSubmit = (redirect) => {
    if (props.fetchUpdate !== undefined && props.fetchUpdate !== null) {
      props.fetchUpdate();
    }
    if (props.type === "ORGANISATION") {
      props.closeOrgResponseAlert();
    } else if (props.type === "RECRUITER") {
      props.closeRecResponseAlert();
    } else if (props.type === "CANDIDATE") {
      props.closeCandidateResponseAlert();
    } else if (props.ErrorCode == 107) {
      props.closeResponseAlert();
      props.SignupOTPModal();
    } else if (props.ErrorCode == 105) {
      if (props.newType !== 2) {
        props.closeResponseAlert();
        props.SignupOTPModal();
      }
    }
    if (props.newType === 2 && props.ErrorCode == 105) {
      props.closeResponseAlert();
      props.OTPModal();
    } else {
      props.closeResponseAlert();
    }
    if (props.redirect === "/") {
      localStorage.clear();
    }
    if (props.redirect !== null) {
      history.push(redirect);
    }
    props.sentRequestErrorCode == "175"
      ? props.handleModal(true)
      : history.push(props.redirect);
  };

  return (
    <div className={style.container}>
      <div className={style.result}>
        <p>{props.message}</p>
        <button
          className={style.ok_btn}
          type="button"
          onClick={() => handleSubmit(props.redirect)}
        >
          OK
        </button>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    ErrorCode: state.authReducer.ErrorCode,
    newType: state.authReducer.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => dispatch(actionTypes.hideAuthModal()),
    OTPModal: () => dispatch(actionTypes.handleOTPModal()),
    ValidateOtpResetPassword: (otp, email) =>
      dispatch(actionTypes.ValidateOtpResetPassword(otp, email)),
    SignupOTPModal: () => dispatch(actionTypes.handleSignupOTPModal()),
    getSignIn: (formData) => dispatch(actionTypes.getSignIn(formData)),
    closeResponseAlert: () => dispatch(actionTypes.closeResponseAlert()),
    closeOrgResponseAlert: () => dispatch(actionTypes.closeOrgResponseAlert()),
    closeRecResponseAlert: () =>
      dispatch(actionTypes.closeRecruiterResponseAlert()),
    closeCandidateResponseAlert: () =>
      dispatch(actionTypes.closeCandidateResponseAlert()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResponseAlert);
