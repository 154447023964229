import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as actionTypes from "../../Store/Action/index";
import { connect } from "react-redux";
import style from "./chatlist.module.css";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import BackButton from "../../assets/eye/Icon ionic-ios-arrow-round-back.png";
import Eyeicon from "../../assets/ellipse/Ellipse -1.png";
import defaultImg from "../../assets/defaultimg/Rectangle 5337@2x.png";
import Cards from "./Cards/cards";
import NoData from "../../assets/globe.jpg";
import searchImg from "../../assets/search/Icon ionic-ios-search.png";
import loader from "../../assets/Loader/spinner.gif";
import firebase from "firebase/app";
import "firebase/messaging";

function Notification(props) {
  const [sidebar, setSidebar] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState("");
  const [noDataVal, setNoDataVal] = useState(false);

  const toShowCards = () => {
    if (data && data.length > 0) {
      return <Cards data={data} userId={props.userDetails.accountId} />;
    } else {
      if (props.loader) {
        return (
          <div className={style.loader}>
            <img className={style.nodata} src={loader} alt="loader" />
          </div>
        );
      } else {
        if (noDataVal) {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <p style={{ textAlign: "center", padding: "0 10px" }}>
                  <b>
                    {firebase.messaging.isSupported()
                      ? "No Data Found"
                      : "The message feature is not supported on this browser. To access the message feature please switch on to a non iPad device."}
                  </b>
                </p>
                <img
                  className={style.nodata}
                  style={{ width: "50%", height: "60%" }}
                  src={NoData}
                  alt="nodata"
                />
              </div>
            </div>
          );
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNoDataVal(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  // filter data result by search query
  const filterData = (seachQuery) => {
    if (seachQuery !== "") {
      let filteredData = props?.userList?.filter((item) => {
        return item.userName
          ? item.userName.toLowerCase().indexOf(seachQuery.toLowerCase()) !== -1
          : null;
      });
      setData(filteredData);
    } else {
      setData(props.userList);
    }
  };

  useEffect(() => {
    props.getUserList();
    props.getUserDetails();
  }, []);

  useEffect(() => {
    filterData(searchQuery);
  }, [props.userList, searchQuery]);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.topHeading}>
          <label className={style.label}>ChatList</label>
        </div>
        <div className={style.keyword}>
          <img src={searchImg} alt="img" />
          <input
            placeholder=" &nbsp;&nbsp;Keywords"
            className={style.input}
            id="seachQuery"
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          ></input>
        </div>

        <div className={style.centered}>{toShowCards()}</div>
      </section>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.authReducer.loader,
    errorMsg: state.authReducer.errorMsg,
    verifyUserListStatus: state.authReducer.verifyUserListStatus,
    userList: state.authReducer.userList,
    conversations: state.fReducer.conversations,
    userDetails: state.authReducer.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: () => dispatch(actionTypes.getUserDetails()),
    getUserList: () => dispatch(actionTypes.getUserList()),
    getRealtimeConversations: (user) =>
      dispatch(actionTypes.getRealtimeConversations(user)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
