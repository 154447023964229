import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import style from "./RViewProfile.module.css";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import defaultImg from "../../assets/defaultimg/Rectangle 5337.png";
import Loader from "../../assets/Loader/spinner.gif";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import { BlueButton } from "../../GenericComponent/Buttons/button";

function RProfile(props) {
  const [sidebar, setSidebar] = useState(false);
  const history = useHistory();

  function handleClick() {
    history.push({
      pathname: "/Recruiter/EditProfile",
      state: {
        recprofileData: props.profileData,
      },
    });
  }
  useEffect(() => {
    props.getRecruiterProfile();
  }, []);

  function showImgData(data) {
    if (props.profileData && props.profileData !== null && data !== null) {
      return data;
    } else {
      return defaultImg;
    }
  }

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.topHeading}>
          <div>
            <p className={style.para}>Profile</p>
          </div>
          <div
            style={{
              marginTop: "1.8%",
            }}
          >
            <div>
              <BlueButton children="Edit" onClick={handleClick} />
            </div>
          </div>
        </div>
        {props.loader ? (
          <div className={style.loader_styles}>
            <img src={Loader} alt="loader" />
          </div>
        ) : (
          <div>
            <div className={style.centered}>
              <div>
                <div className={style.left}>
                  <div className={style.leftinner1}>
                    <label className={style.label1}>Organization Name</label>
                    <span className={style.span1}>
                      {props.profileData
                        ? props.profileData.organisationName
                        : ""}
                    </span>
                    <label className={style.label1}>Email Id</label>
                    <span className={style.span1}>
                      {props.profileData
                        ? props.profileData.organisationEmail
                        : ""}
                    </span>
                  </div>
                  <div className={style.leftinner2}>
                    <label className={style.label1}>Phone</label>

                    <table>
                      <tr>
                        <span
                          className={
                            props.profileData.countrycode
                              ? style.span4
                              : style.span2
                          }
                        >
                          <td>
                            <span>
                              {props.profileData
                                ? props.profileData.countrycode &&
                                  props.profileData.countrycode + "-"
                                : ""}
                            </span>
                          </td>
                          <td>
                            {props.profileData
                              ? props.profileData.organisationContactNo
                              : ""}
                          </td>
                        </span>
                      </tr>{" "}
                    </table>
                  </div>
                </div>
                <hr style={{ opacity: "0.5" }} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className={style.div}>
                    <div className={style.divimg}>
                      <img
                        src={showImgData(props.profileData.imgURL)}
                        className={style.img}
                      />
                    </div>
                    <div className={style.middle}>
                      <label className={style.label3}>Full Name</label>
                      {props.profileData &&
                      props.profileData.firstName !== "" &&
                      props.profileData.firstName !== null ? (
                        <span className={style.span3}>
                          {props.profileData ? props.profileData.firstName : ""}{" "}
                          {props.profileData ? props.profileData.lastName : ""}
                        </span>
                      ) : (
                        <span
                          className={style.span3}
                          style={{ opacity: "0.5" }}
                        >
                          No Information Provided.
                        </span>
                      )}

                      <label className={style.label3}>Designation</label>
                      {props.profileData &&
                      props.profileData.designation !== "" &&
                      props.profileData.designation !== null ? (
                        <span className={style.span3}>
                          {props.profileData.designation}
                        </span>
                      ) : (
                        <span
                          className={style.span3}
                          style={{ opacity: "0.5" }}
                        >
                          No Information Provided.
                        </span>
                      )}
                    </div>

                    <div className={style.right}>
                      <label className={style.label3}>Country</label>
                      {props.profileData &&
                      props.profileData.country !== "" &&
                      props.profileData.country !== null ? (
                        <span className={style.span3}>
                          {props.profileData.country}
                        </span>
                      ) : (
                        <span
                          className={style.span3}
                          style={{ opacity: "0.5" }}
                        >
                          No Information Provided.
                        </span>
                      )}

                      <label className={style.label3}>State</label>
                      {props.profileData &&
                      props.profileData.state !== "" &&
                      props.profileData.state !== null ? (
                        <span className={style.span3}>
                          {props.profileData.state}
                        </span>
                      ) : (
                        <span
                          className={style.span3}
                          style={{ opacity: "0.5" }}
                        >
                          No Information Provided.
                        </span>
                      )}
                      <label className={style.label3}>City</label>
                      {props.profileData &&
                      props.profileData.city !== "" &&
                      props.profileData.city !== null ? (
                        <span className={style.span3}>
                          {props.profileData.city}
                        </span>
                      ) : (
                        <span
                          className={style.span3}
                          style={{ opacity: "0.5" }}
                        >
                          No Information Provided.
                        </span>
                      )}
                      <label className={style.label3}>Postal Code</label>
                      {props.profileData &&
                      props.profileData.pinCode !== "" &&
                      props.profileData.pinCode !== null ? (
                        <span className={style.span3}>
                          {props.profileData.pinCode}
                        </span>
                      ) : (
                        <span
                          className={style.span3}
                          style={{ opacity: "0.5" }}
                        >
                          No Information Provided.
                        </span>
                      )}
                    </div>
                  </div>
                  <div style={{ marginLeft: "4%", marginTop: "0.5rem" }}>
                    {/* about  */}
                    <label className={style.label}>About Yourself</label>
                    <div>
                      {props.profileData &&
                      props.profileData.about !== "" &&
                      props.profileData.about !== null ? (
                        <p className={style.about}>{props.profileData.about}</p>
                      ) : (
                        <p style={{ opacity: "0.5" }} className={style.about}>
                          No Information Provided
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.showAlert && (
          <div className={style.changeDiv}>
            <ResponseAlert message={props.recErrorMsg} type="RECRUITER" />
          </div>
        )}
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.recruiterReducer.loader,
    profileData: state.recruiterReducer.recruiterProfileData,
    recErrorMsg: state.recruiterReducer.recErrorMsg,
    recErrorCode: state.recruiterReducer.recErrorCode,
    recruiterProfileErrMsg: state.recruiterReducer.recruiterProfileErrMsg,
    recruiterProfileStatus: state.recruiterReducer.recruiterProfileStatus,
    showAlert: state.recruiterReducer.showAlert,
    showBlur: state.recruiterReducer.showBlur,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecruiterProfile: () => dispatch(actionTypes.getRecruiterProfile()),
    // clearLoginState:()=>dispatch(actionTypes.clearLoginState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RProfile);
