import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import Cards from "./Cards/managementCard";
import Delete from "../../GenericComponent/Modal/Delete/Delete";
import Backdroplog from "../../GenericComponent/Backdrop/Backdrop";
import style from "./management.module.css";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import Pagination from "../../GenericComponent/Pagination/Pagination";
import Modal from "../../GenericComponent/Modal/Creation/creation";
import Loader from "../../assets/Loader/spinner.gif";
import Filter from "../../assets/filter.png";
import NoData from "../../assets/globe.jpg";

function Recruiter(props) {
  const [sidebar, setSidebar] = useState(false);
  const [filter, setFilter] = useState("ALL");
  const [recId, setRecId] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [f_active, setfilter] = useState(false);

  const showmenu = () => {
    setfilter(true);
  };

  const hidemenu = () => {
    setfilter(false);
  };

  const handlefilter = (value) => {
    setPageNo(1);
    setFilter(value);
  };

  const onLeftChange = () => {
    if (pageNo !== 1) {
      setPageNo(pageNo - 1);
    }
  };

  const onRightChange = () => {
    if (pageNo !== props.page_no_rec) {
      setPageNo(pageNo + 1);
    }
  };

  const toShowPagination = () => {
    if (props.page_no_rec > 1) {
      return (
        <Pagination
          activePage={pageNo}
          pageRangeDisplayed={props.page_no_rec}
          onLeftChange={() => onLeftChange()}
          onRightChange={() => onRightChange()}
        />
      );
    }
  };

  const handlepage = (data) => {
    setPageNo(data);
  };

  const handleDelete = (id, val) => {
    setRecId(id);
    props.handleDeleteModal(val);
  };

  const submitDelete = async () => {
    let formData = new FormData();
    formData.append("recruiterAccountId", recId);
    await props.handleDeleteModal(false);
    await props.deleteRecruiterProfile(formData, filter, pageNo);
  };

  const handleStatus = (data) => {
    // setPageNo(1)
    props.updateRecruiterStatus(data, filter, pageNo);
  };

  const createRecruitersubmit = async (formData) => {
    handleModal(false);
    await props.createRecruiter(formData, filter);
  };

  const handleModal = (val) => {
    props.handleCreationModal(val);
  };

  const toShowCards = () => {
    if (props.rec_list !== undefined && props.rec_list.length > 0) {
      return (
        <>
          <div className={style.subcentered}>
            <Cards
              data={props.rec_list}
              showdropdown={props.showDropDownModal}
              showDeleteConfirm={props.showDeleteModal}
              handleDelete={handleDelete}
              handleStatus={handleStatus}
              handlepage={handlepage}
            />
            {props.showDeleteModal && (
              <Delete
                cancelDelete={handleDelete}
                confirmDelete={submitDelete}
                recId={recId}
              />
            )}
          </div>
        </>
      );
    } else {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <p>
              <b>No Data Found – Create new recruiters</b>
            </p>
            <img
              className={style.nodata}
              style={{ width: "50%", height: "60%" }}
              src={NoData}
              alt="nodata"
            />
          </div>
        </div>
      );
    }
  };

  const getDetails = () => {
    props.getRecruiterList(filter, pageNo);
    setfilter(false);
  };

  useEffect(() => {
    getDetails();
  }, [filter, pageNo]);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <Backdroplog show={props.showBlur} />
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>

        {props.loader ? (
          <div className={style.loader_style}>
            <img src={Loader} alt="loader/" />
          </div>
        ) : (
          <div>
            <div className={style.topHeading}>
              <div>
                <label className={style.label}>
                  Recruiter Profile Management
                </label>
              </div>
              <div className={style.btn_css}>
                <div>
                  <img
                    src={Filter}
                    alt="/filter"
                    height="60px"
                    width="60px"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (f_active == false) {
                        showmenu();
                      }
                    }}
                    onMouseOver={() => {
                      if (f_active == true) {
                        hidemenu();
                      }
                    }}
                  />

                  {f_active ? (
                    <div className={style.filter1}>
                      <p onClick={() => handlefilter("ALL")}>All</p>
                      <p onClick={() => handlefilter("ACTIVE")}>Active</p>
                      <p onClick={() => handlefilter("INACTIVE")}>In-Active</p>
                      <p onClick={() => handlefilter("ALL")}>Cancel</p>
                    </div>
                  ) : null}
                </div>
                <button
                  className={style.button}
                  children="+ Create new"
                  onClick={() => handleModal(true)}
                />
              </div>
            </div>
            <div className={style.centered}>{toShowCards()}</div>
          </div>
        )}
        <div className={style.footer}>{toShowPagination()}</div>
      </section>
      {props.showCreationModal && (
        <div className={style.changeDiv1}>
          <Modal
            handleModal={handleModal}
            createRecruitersubmit={createRecruitersubmit}
            filter={filter}
          />
        </div>
      )}
      {props.showAlert && (
        <div className={style.changeDiv}>
          <ResponseAlert
            redirect={
              // props.recSuccessMsg ?
              "/Management"
              // : null
            }
            // redirect={"/"}
            message={
              props.orgErrorMsg === "" ? props.recSuccessMsg : props.orgErrorMsg
            }
            type="ORGANISATION"
          />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.orgReducer.loader,
    rec_list: state.orgReducer.rec_list,
    page_no_rec: state.orgReducer.page_no_rec,
    showAlert: state.orgReducer.showAlert,
    showBlur: state.orgReducer.showBlur,
    orgErrorMsg: state.orgReducer.orgErrorMsg,
    orgErrorCode: state.orgReducer.orgErrorCode,
    recSuccessMsg: state.orgReducer.recSuccessMsg,
    initial: state.orgReducer.initial,
    filter: state.orgReducer.rec_filter,
    showDropDownModal: state.orgReducer.showDropDownModal,
    showCreationModal: state.orgReducer.showCreationModal,
    recsignUpStatus: state.orgReducer.recsignUpStatus,
    showDeleteModal: state.orgReducer.showDeleteModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecruiterList: (filter, pageNo) =>
      dispatch(actionTypes.getRecruiterList(filter, pageNo)),
    deleteRecruiterProfile: (id, filter, pageNo) =>
      dispatch(actionTypes.deleteRecruiterProfile(id, filter, pageNo)),
    updateRecruiterStatus: (data, filter, pageNo) =>
      dispatch(actionTypes.updateRecruiterStatus(data, filter, pageNo)),
    handleCreationModal: (val) =>
      dispatch(actionTypes.handleCreationModal(val)),
    createRecruiter: (formData, filter) =>
      dispatch(actionTypes.createRecruiter(formData, filter)),
    handleDeleteModal: (val) => dispatch(actionTypes.handleDeleteModal(val)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Recruiter);
