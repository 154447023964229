import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import style from "./Dashboard.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import { LongButton } from "../../../GenericComponent/Buttons/button";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import recruiter from "../../../assets/globe.jpg";
import Loader from "../../../assets/Loader/spinner.gif";
import axios from "axios";
// import Sidebar from "../../../GenericComponent/Sidebar/sidebar";

import constant from "../../../constant/constant";
const url = constant.apiIpPort + constant.apiBaseUrl;

function RDashboard(props) {
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);

  const [responseData, setResponseData] = useState(null);

  return (
    <>
      {responseData !== null ? (
        <div className="loader_styles">
          <img src={Loader} alt="loader/" />
        </div>
      ) : (
        <div className={style.container}>
          <header className={style.headerStyle}>
            <Header sidebar={setSidebar} side={sidebar} />
          </header>
          <section className={style.sectionStyle}>
            <div>
              <Sidebar side={sidebar} />
            </div>
            {props.loader ? (
              <div className="loader_styles">
                <img src={Loader} alt="loader/" />
              </div>
            ) : (
              <>
                <div className={style.button}>
                  <LongButton
                    children="Create an Opportunity"
                    onClick={() => history.push("/Recruiter/CreateOpportunity")}
                  />
                </div>
                <div className={style.centered}>
                  <div className={style.content}>
                    <div className={style.title}>
                      <p>
                        <b>No Data Found – Create opportunities/scholarships</b>
                      </p>
                    </div>
                    <div className={style.image}>
                      <img src={recruiter} alt="img" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    opprecruiterListErrMsg: state.recruiterReducer.opprecruiterListErrMsg,
    opprecruiterlistStatus: state.recruiterReducer.opprecruiterlistStatus,
    oppErrorMsg: state.recruiterReducer.oppErrorMsg,
    oppErrorCode: state.recruiterReducer.oppErrorCode,
    loader: state.recruiterReducer.loader,
    initial: state.recruiterReducer.initial,
    opp_rec_list: state.recruiterReducer.opp_rec_list,
    showAlert: state.recruiterReducer.showAlert,
    showBlur: state.recruiterReducer.showBlur,
    showDropDownModal: state.recruiterReducer.showDropDownModal,
    recsignUpStatus: state.recruiterReducer.recsignUpStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListByRecruiter: (filter) =>
      dispatch(actionTypes.getListByRecruiter(filter)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RDashboard);
