import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { getToken, onMessageListener } from "../../firebaseInit";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import Backdroplog from "../../GenericComponent/Backdrop/Backdrop";
import SignUpModal from "../Homepage/components/SignUp/signup";
import LoginModal from "../Homepage/components/Login/login";
import OTPModal from "../Homepage/components/OTPScreen/OTPScreen";
import SignUpOTPModal from "../Homepage/components/SignUpOTPScreen/SignupOTPScreen";
import ResetPasswordModal from "../Homepage/components/ResetPassword/ResetPassword";
import ForgotPassModal from "../Homepage/components/ForgotPassword/forgotpassword";
import ConfirmModal from "../Homepage/components/confirm";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import style from "./homepage.module.css";
import Header from "../../GenericComponent/header/header";
import { BlueButton, WhiteButton } from "../../GenericComponent/Buttons/button";
import backgroundImg from "../../assets/globe.jpg";

import Loader from "../../assets/Loader/spinner.gif";

function Homepage(props) {
  const history = useHistory();
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const [SsignupEmail, setSSignupEmail] = useState("");
  const [SsignupPassword, setSSignupPassword] = useState("");
  const [OsignupEmail, setOSignupEmail] = useState("");
  const [OsignupPassword, setOSignupPassword] = useState("");
  const [forgotOtp, setForgotOtp] = useState("");
  const [ip, setIP] = useState("");
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, tokenFound] = useState("");
  getToken(setTokenFound).then((message) => tokenFound(message));
  localStorage.setItem("token", 9000);
  localStorage.setItem("fcmtoken", token);

  function showButton() {
    if (props.showSignUpModal) {
      return (
        <BlueButton
          children="Sign Up Now!"
          onClick={props.SignUp}
          className={style.centered_button}
        />
      );
    } else {
      return (
        <WhiteButton
          children="Sign Up Now!"
          onClick={props.SignUp}
          className={style.centered_button}
        />
      );
    }
  }

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header
          showSignUpModal={props.showSignUpModal}
          showSignInModal={props.showSignInModal}
          sidebar={setSidebar}
          side={sidebar}
        />
      </header>
      {props.loader ? (
        <div className={style.loader_style}>
          <img alt="loader" src={Loader} />
        </div>
      ) : (
        <div>
          <Backdroplog show={props.showBlur} />
          <div className={style.hero}>
            <div className={style.left}>
              <p>
                We are the ultimate place where students, parents, and talent
                can meet colleges, universities, and professional recruiters for
                athletics, academics, and performing arts.
              </p>

              {/* <p>
          The moment you are escorted to the interview room, you start panicking and thinking about all the possible questions and answers you could frame in that short period of time. However, we often ignore the importance of the very first question about our introduction in the interview. Just sit back and note down the following pointers on how to ace self-introduction.
          </p> */}
              {/* <p>Get connected with candidates who are committed to your process</p>
          <button onClick={() => props.history.push("/login")}>
            Request Demo
          </button> */}
            </div>
            <div className={style.right}>
              <img src={backgroundImg} alt="hero-img" />
              {/* <img src={playBtn} alt="play-btn" /> */}
            </div>
          </div>
        </div>
      )}
      {props.showSignInModal && (
        <div className={style.changeDiv}>
          <LoginModal
            handleForgotPasswordModal={props.handleForgotPasswordModal}
            ResetPasswordModal={props.showResetPasswordModal}
            // getData={getData}
          />
        </div>
      )}

      {props.showSignUpModal && (
        <div className={style.changeDiv}>
          <SignUpModal
            setSSignupEmail={setSSignupEmail}
            setSSignupPassword={setSSignupPassword}
            setOSignupPassword={setOSignupPassword}
            setOSignupEmail={setOSignupEmail}
          />
        </div>
      )}

      {props.showForgotPassModal && (
        <div className={style.changeDiv}>
          <ForgotPassModal
            showResetPasswordModal={props.showResetPasswordModal}
            ResetPasswordModal={props.ResetPasswordModal}
            hideModal={props.hideModal}
            setForgetEmail={setForgetEmail}
          />
        </div>
      )}

      {props.showOTPModal && (
        <div className={style.changeDiv}>
          <OTPModal
            OTPModal={props.showOTPModal}
            forgetEmail={forgetEmail}
            forgotPassword={props.forgotPassword}
            hideModal={props.hideModal}
            handleForgotPasswordModal={props.handleForgotPasswordModal}
            showResetPasswordModal={props.showResetPasswordModal}
            setForgotOtp={setForgotOtp}
            ErrorCode={props.ErrorCode}
          />
        </div>
      )}

      {props.showSignupOTPModal && (
        <div className={style.changeDiv}>
          <SignUpOTPModal
            OTPModal={props.showSignupOTPModal}
            SsignupEmail={SsignupEmail}
            forgotPassword={props.forgotPassword}
            hideModal={props.hideModal}
            SsignupPassword={SsignupPassword}
            OsignupEmail={OsignupEmail}
            OsignupPassword={OsignupPassword}
            setForgotOtp={setForgotOtp}
            ErrorCode={props.ErrorCode}
          />
        </div>
      )}

      {props.showResetPasswordModal && (
        <div className={style.changeDiv}>
          <ResetPasswordModal
            ResetPasswordModal={props.showResetPasswordModal}
            OTPModal={props.showOTPModal}
            forgetEmail={forgetEmail}
            hideModal={props.hideModal}
            forgotOtp={forgotOtp}
          />
        </div>
      )}

      {props.showConfirmModal && (
        <div className={style.changeDiv1}>
          <ConfirmModal
            hideModal={props.hideModal}
            confirm={props.confirm}
            OTPModal={props.OTPModal}
          />
        </div>
      )}

      {props.showAlert && (
        <div className={style.changeDiv2}>
          <ResponseAlert message={props.errorMsg} />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    signUpStatus: state.authReducer.signUpStatus,
    errorMsg: state.authReducer.errorMsg,
    loader: state.authReducer.loader,
    initial: state.authReducer.initial,
    ErrorCode: state.authReducer.ErrorCode,
    showAlert: state.authReducer.showAlert,
    resetPasswordSuccMsg: state.authReducer.resetPasswordSuccMsg,
    emailverifySuccMsg: state.authReducer.emailverifySuccMsg,
    showConfirmModal: state.authReducer.showConfirmModal,

    showForgotPassModal: state.authReducer.showForgotPassModal,
    showSignUpModal: state.authReducer.showSignUpModal,
    showSignInModal: state.authReducer.showSignInModal,
    confirm: state.authReducer.confirm,
    showBlur: state.authReducer.showBlur,
    showOTPModal: state.authReducer.showOTPModal,
    showSignupOTPModal: state.authReducer.showSignupOTPModal,
    showResetPasswordModal: state.authReducer.showResetPasswordModal,
    newuserEmailid: state.authReducer.newuserEmailid,
    FCMStatus: state.authReducer.FCMStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SignIn: () => dispatch(actionTypes.handleSignInModal()),
    SignUp: () => dispatch(actionTypes.handleSignUpModal()),
    OTPModal: () => dispatch(actionTypes.handleOTPModal()),
    handleSignupOTPModal: () => dispatch(actionTypes.handleSignupOTPModal()),
    handleForgotPasswordModal: () =>
      dispatch(actionTypes.handleForgotPasswordModal()),

    ResetPasswordModal: () => dispatch(actionTypes.ResetPasswordModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
