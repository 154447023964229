import axios from "axios";
import api from "../constant/constant";
import sha1 from "sha1";

var date = new Date();
var iso = date.getTime();

const generateSecurityToken = (url) => {
  var secret = "#a2@innovationm.com$";
  return sha1(`${url}${iso}1${secret}`);
};

export function loginRequest(apiUrl, data) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    AppAuthenticationToken: sha,
    timestamp: iso,
    "Access-Control-Allow-Origin": "*",
  };

  return axios({
    method: "post",
    url: url,
    data: data,
    headers: header,
  });
}

export function postRequest(apiUrl, data) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    Authorization: localStorage.getItem("userToken"),
    "Access-Control-Allow-Origin": "*",
    AppAuthenticationToken: sha,
    timestamp: iso,
  };
  return axios({
    method: "post",
    url: url,
    data: data,
    headers: header,
  });
}

export function postRequestFile(apiUrl, data) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    Authorization: localStorage.getItem("userToken"),
    "Access-Control-Allow-Origin": "*",
    AppAuthenticationToken: sha,
    timestamp: iso,
  };
  return axios({
    method: "post",
    url: url,
    data: data,
    headers: header,
  });
}

export function putRequestFile(apiUrl, data) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    Authorization: localStorage.getItem("userToken"),
    "Access-Control-Allow-Origin": "*",
    AppAuthenticationToken: sha,
    timestamp: iso,
    "Content-Type": "application/json",
  };
  return axios({
    method: "put",
    url: url,
    data: data,
    headers: header,
  });
}

export function postResetPass(apiUrl, data, token) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    resetPasswordToken: token,
    "content-Type": "application/json",
    AppAuthenticationToken: sha,
    timestamp: iso,
    "Access-Control-Allow-Origin": "*",
  };
  return axios({
    method: "post",
    url: url,
    data: data,
    headers: header,
  });
}

export function postGenerateToken(apiUrl, data, token) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    "content-Type": "application/json",
    AppAuthenticationToken: sha,
    timestamp: iso,
    "Access-Control-Allow-Origin": "*",
  };
  return axios({
    method: "post",
    url: url,
    data: data,
    headers: header,
  });
}

export function getRequest(apiUrl) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    Authorization: localStorage.getItem("userToken"),
    AppAuthenticationToken: sha,
    timestamp: iso,
    "Access-Control-Allow-Origin": "*",
  };

  return axios({
    method: "get",
    url: url,
    headers: header,
  });
}

export function putRequest(apiUrl, data) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    Authorization: localStorage.getItem("userToken"),
    AppAuthenticationToken: sha,
    timestamp: iso,
    "Access-Control-Allow-Origin": "*",
  };
  return axios({
    method: "put",
    data: data,
    url: url,
    headers: header,
  });
}

export function deleteRequest(apiUrl, data) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    Authorization: localStorage.getItem("userToken"),
    AppAuthenticationToken: sha,
    timestamp: iso,
    "Access-Control-Allow-Origin": "*",
  };
  return axios({
    method: "delete",
    data: data,
    url: url,
    headers: header,
  });
}

export function postRequestVerify(apiUrl, data) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    Authorization: localStorage.getItem("userToken"),
    AppAuthenticationToken: sha,
    timestamp: iso,
    "Access-Control-Allow-Origin": "*",
  };
  return axios({
    method: "post",
    url: url,
    data: data,
    headers: header,
  });
}

export function getRequestFileDownload(apiUrl) {
  const url = api.apiIpPort + api.apiBaseUrl + apiUrl;
  const sha = generateSecurityToken(api.apiBaseUrl + apiUrl);
  const header = {
    Authorization: localStorage.getItem("userToken"),
    AppAuthenticationToken: sha,
    timestamp: iso,
    "Access-Control-Allow-Origin": "*",
  };
  return axios({
    responseType: "arraybuffer",
    method: "get",
    url: url,
    headers: header,
  });
}

export function getRequestLocation(apiUrl) {
  const url = api.apiIpPort1 + apiUrl;
  const sha = generateSecurityToken(apiUrl);
  const header = {
    Authorization: localStorage.getItem("userToken"),
    AppAuthenticationToken: sha,
    timestamp: iso,
    "Access-Control-Allow-Origin": "*",
  };

  return axios({
    method: "get",
    url: url,
    headers: header,
  });
}
