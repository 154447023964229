import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import style from "./profile.module.css";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import { BlueButton } from "../../GenericComponent/Buttons/button";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";

import Loader from "../../assets/Loader/spinner.gif";
import defaultImg from "../../assets/defaultimg/Rectangle.png";
import * as actionTypes from "../../Store/Action/index";

function Profile(props) {
  const [sidebar, setSidebar] = useState(false);

  const history = useHistory();
  const handleClick = () => {
    history.push({
      pathname: "/EditProfile",
      state: {
        profileData: props.profileData,
      },
    });
  };

  function showData(condition) {
    if (condition !== "" && condition !== null) {
      return <p className={style.input}>{condition}</p>;
    } else {
      return (
        <p className={style.input} style={{ opacity: "0.5" }}>
          No Information Provided
        </p>
      );
    }
  }

  function showContactData(condition1, condition2) {
    if (condition2 !== "" && condition2 !== null) {
      if (condition1 == "" || condition1 == null) {
        return <p className={style.input}>{condition2}</p>;
      } else {
        return (
          <p className={style.input}>
            {condition1}
            {"-"}
            {condition2}
          </p>
        );
      }
    } else {
      return (
        <p className={style.input} style={{ opacity: "0.5" }}>
          No Information Provided
        </p>
      );
    }
  }

  function showImage() {
    if (props.profileData && props.profileData.profileUrl !== null) {
      return props.profileData.profileUrl;
    } else {
      return defaultImg;
    }
  }

  useEffect(() => {
    props.getOrgProfile();
  }, []);
  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        {props.loader ? (
          <div className="loader_styles">
            <img src={Loader} alt="loader" />
          </div>
        ) : (
          <div>
            <div className={style.topsomepagewrapper}>
              <div className={style.toprow}>
                <div className={style.topcolumn}>
                  <div>
                    <p className={style.para}>Profile</p>
                  </div>
                </div>
                <div className={style.topcolumn}>
                  <div className={style.editbtn}>
                    <BlueButton children="Edit" onClick={handleClick} />
                  </div>
                </div>
              </div>
              <div className={style.toprow}>
                <div className={style.centered}>
                  <div className={style.top}>
                    <p>Upload Organization Image</p>
                    <img
                      src={showImage()}
                      className={style.orgimg}
                      alt="profile"
                    />
                  </div>
                  <div className={style.somepagewrapper}>
                    <hr className={style.line} />
                    <div className={style.row}>
                      <div className={style.column}>
                        <label className={style.label1}>
                          Organization Name
                        </label>
                        {props.profileData &&
                          showData(props.profileData.organisationName)}
                        <label className={style.label1}>Email ID</label>
                        {props.profileData &&
                          showData(props.profileData.emailId)}

                        <label className={style.label1}>Website</label>
                        {props.profileData &&
                          showData(props.profileData.website)}

                        <label className={style.label1}>Phone No.</label>
                        {props.profileData &&
                          showContactData(
                            props.profileData.countrycode,
                            props.profileData.contactNo
                          )}
                      </div>
                      <div className={style.column}>
                        <label className={style.label1}>Country</label>
                        {props.profileData &&
                          showData(props.profileData.country)}

                        <label className={style.label1}>State</label>
                        {props.profileData && showData(props.profileData.state)}

                        <label className={style.label1}>City</label>
                        {props.profileData && showData(props.profileData.city)}

                        <label className={style.label1}>Postal Code</label>
                        {props.profileData &&
                          showData(props.profileData.pinCode)}
                      </div>

                      <div className={style.row}>
                        <div className={style.row}>
                          <label className={style.label3}>
                            Organization Highlights
                          </label>
                        </div>
                        <div className={style.rows}>
                          {props.profileData &&
                            showData(props.profileData.about)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.showAlert && (
          <div className={style.changeDiv}>
            <ResponseAlert message={props.recErrorMsg} type="ORGANISATION" />
          </div>
        )}
      </section>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.orgReducer.loader,
    profileData: state.orgReducer.orgProfileData,
    orgErrorMsg: state.orgReducer.orgErrorMsg,
    orgErrorCode: state.orgReducer.orgErrorCode,
    showAlert: state.orgReducer.showAlert,
    showBlur: state.orgReducer.showBlur,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrgProfile: () => dispatch(actionTypes.getOrgProfile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
