import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Loader from "../../../assets/Loader/spinner.gif";
import * as actionTypes from "../../../Store/Action/index";

function Logout(props) {
  

 
 

  useEffect(() => {
    props.logout(props.location.state.ip);
  }, []);

  return (
    <>
      {props.logout ? (
        <div className="loader_styles">
          <img src={Loader} alt="loader" style={{marginLeft:"50%"}}/>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (Id) => dispatch(actionTypes.logout(Id)),
  };
};

export default connect(null, mapDispatchToProps)(Logout);
