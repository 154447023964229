import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cards from "./Cards/cards";
import { connect } from "react-redux";
import * as actionTypes from "../../../../Store/Action/index";
import style from "./student.module.css";
import Header from "../../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../../GenericComponent/Sidebar/sidebar";
import Pagination from "../../../../GenericComponent/Pagination/Pagination";
import Modal from "../../../../GenericComponent/Appliedfilterc/appliedfilterc";
import loader from "../../../../assets/Loader/spinner.gif";

import NoData from "../../../../assets/globe.jpg";

function Accepted(props) {
  const [sidebar, setSidebar] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showBlur, setShowBlur] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [noDataVal, setNoDataVal] = useState(false);
  const toShowCards = () => {
    if (props.skillDatas && props.skillDatas.length > 0) {
      return <Cards data={props.skillDatas} />;
    } else {
      if (props.loader) {
        return (
          <div className={style.loader}>
            <img className={style.nodata} src={loader} alt="loader" />
          </div>
        );
      } else {
        if (noDataVal) {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <p>
                  <b>
                    No Data Found – Accepted opportunities/scholarships
                    forthcoming
                  </b>
                </p>
                <img
                  className={style.nodata}
                  style={{ width: "50%", height: "60%" }}
                  src={NoData}
                  alt="nodata"
                />
              </div>
            </div>
          );
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNoDataVal(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const toShowPagination = () => {
    if (props.page_no_accepted > 1) {
      return (
        <Pagination
          activePage={pageno}
          pageRangeDisplayed={props.page_no_accepted}
          onLeftChange={() => onLeftChange()}
          onRightChange={() => onRightChange()}
        />
      );
    }
  };

  const onLeftChange = () => {
    if (pageno !== 1) {
      setPageno(pageno - 1);
    }
  };

  const onRightChange = () => {
    if (pageno !== props.page_no_accepted) {
      setPageno(pageno + 1);
    }
  };

  const getDetails = () => {
    props.acceptedOpportunityCategoryListByOrganisation(pageno);
  };

  useEffect(() => {
    getDetails();
  }, [pageno]);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section
        className={
          showBlur === true
            ? style.sectionStyle + " " + style.addBlurr
            : style.sectionStyle
        }
      >
        <div style={{ position: "relative" }}>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.heading}>
          <div className={style.filter}></div>
        </div>
        <div className={style.centered}>
          {toShowCards()}
          <br />
        </div>
        <div className={style.footer}>{toShowPagination()}</div>
      </section>

      {showModal && (
        <div className={style.changeDiv}>
          <Modal />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.orgReducer.loader,
    initial: state.orgReducer.initial,

    showAlert: state.orgReducer.showAlert,
    page_no_accepted: state.orgReducer.page_no_accepted,
    acceptedopportunityErrMsg: state.orgReducer.acceptedopportunityErrMsg,
    acceptedopportunityStatus: state.orgReducer.acceptedopportunityStatus,
    acceptedopportunityErrorMsg: state.orgReducer.acceptedopportunityErrorMsg,
    acceptedopportunityErrorCode: state.orgReducer.acceptedopportunityErrorCode,
    acceptedopportunityData: state.orgReducer.acceptedopportunityData,
    skillDatas: state.orgReducer.skillDatas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acceptedOpportunityCategoryListByOrganisation: (pageno) =>
      dispatch(
        actionTypes.acceptedOpportunityCategoryListByOrganisation(pageno)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Accepted);
