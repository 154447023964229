import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import style from "./login.module.css";
import * as actionTypes from "../../../../Store/Action/index";
import SignupImg from "../../../../assets/globe.jpg";
import logo from "../../../../assets/logo/rev13-03.png";
import mailIcon from "../../../../assets/email/Icon material-email.png";
import openEye from "../../../../assets/eyeIcon/Icon awesome eye.png";
import closeEye from "../../../../assets/eyeIcon/Icon ionic-ios-eye-off.png";
import closeIcon from "../../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";

function SignIn(props) {
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passErrorMsg, setPassErrorMsg] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const handleSignIn2 = (e) => {
    e.preventDefault();
    if (e.target.emailid.value === "") {
      setEmailErrorMsg("Enter your Email ID");
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        e.target.emailid.value.trim()
      )
    ) {
      setEmailErrorMsg("Please enter a valid Email ID");
    } else if (e.target.password.value === "") {
      setPassErrorMsg("Enter your Password");
    } else if (!/^.{8,16}$/.test(e.target.password.value)) {
      setPassErrorMsg("Password length 8-15");
    } else {
      const form2Data = {
        email: e.target.emailid.value,
        password: e.target.password.value,
      };
      props.getSignIn(form2Data);
      props.hideModal();
      // props.setSSignupEmail(e.target.emailid.value);
      // props.setSSignupPassword(e.target.password.value);
      // props.getData();
    }
  };

  const passwordFormatChangedHandler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  useEffect(() => {}, [passwordType]);

  return (
    <div className={style.container}>
      <div className={style.leftContainer}>
        <div className={style.leftLogo}>
          <img className={style.logo} src={logo} alt="logo" />
        </div>
        <div className={style.leftPara}>
          {" "}
          <p>
            I Got Game 2 is the #1 portal for connecting students, athletes and
            talent to available scholarship opportunities with universities and
            colleges worldwide.
          </p>
        </div>
        <div className={style.leftImg}>
          <img src={SignupImg} alt="img" />
        </div>
      </div>
      <div className={style.rightContainer}>
        <img
          src={closeIcon}
          className={style.closeModal}
          alt="img"
          onClick={() => props.hideModal()}
        />

        <p className={style.title}>Sign In</p>
        <p className={style.subTitle}>Don't have an account? </p>
        <label onClick={() => props.SignUp()} className={style.anchorStyle}>
          Sign Up
        </label>
        <div className={style.formStyle}>
          <form
            className={style.formStyle}
            onSubmit={handleSignIn2}
            autoComplete="off"
          >
            <div className={style.emailName2}>
              <label className={style.mail2}>Email Id</label>
              <input
                type="text"
                id="emailid"
                name="emailid"
                autoComplete="off"
                className="inputform"
              />
              <img className={style.icon2} src={mailIcon} alt="email" />
              <span className={style.errorMsg}>{emailErrorMsg}</span>
            </div>
            <div className={style.pass2}>
              <label className={style.mail2}>Password</label>
              <input
                type={passwordType}
                id="password"
                name="password"
                autoComplete="off"
                className="inputform"
              />
              <img
                className={
                  passwordType === "password" ? style.icon3 : style.icon33
                }
                src={passwordType === "password" ? closeEye : openEye}
                alt="eye"
                onClick={() => passwordFormatChangedHandler()}
              />
              <span className={style.errorMsg}>{passErrorMsg}</span>
            </div>
            <div className={style.allBtn2}>
              <button
                className={style.forgot}
                type="button"
                onClick={() => props.handleForgotPasswordModal()}
              >
                <b>Forgot Password?</b>
              </button>
              <button className={style.signin2} type="submit">
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    errorMsg: state.authReducer.loginErrorMsg,
    loader: state.authReducer.loader,
    initial: state.authReducer.initial,
    ErrorCode: state.authReducer.ErrorCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSignIn: (formData) => dispatch(actionTypes.getSignIn(formData)),
    hideModal: () => dispatch(actionTypes.hideAuthModal()),
    SignUp: () => dispatch(actionTypes.handleSignUpModal()),
    handleForgotPasswordModal: () =>
      dispatch(actionTypes.handleForgotPasswordModal()),
    ResetPasswordModal: () => dispatch(actionTypes.ResetPasswordModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
