import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import thunk from "redux-thunk";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase";
import "./index.css";
import App from "./App";
import { createStore, applyMiddleware, compose } from "redux";
import reportWebVitals from "./reportWebVitals";
import { rootReducer } from "./Store/Reducer/CombineReducer";
import createBrowserHistory from "history/createBrowserHistory";
import ProfileUpdate from "./GenericComponent/Modal/profileUpdate/ProfileUpdate";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory({
  basename: "/",
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// const firebaseConfig = {
//   apiKey: "AIzaSyAqBZjokr7cMF9unUTGiw2GUTDtb1gkbWM",
//   authDomain: "igotgame2.firebaseapp.com",
//   projectId: "igotgame2",
//   storageBucket: "igotgame2.appspot.com",
//   messagingSenderId: "889066049031",
//   appId: "1:889066049031:web:9d9c53bb2e469f9fa324a2"
// };

// firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={"/"} history={history}>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
