import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import { useHistory } from "react-router-dom";
import Cards from "./Cards/cards";
import style from "./s_opportunities.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import Modal from "../../../GenericComponent/Modal/Appliedfilterc/appliedfilterc";

import backarrowImg from "../../../assets/eye/Icon ionic-ios-arrow-round-back.png";
import Pagination from "../../../GenericComponent/Pagination/Pagination";
import Filter from "../../../assets/filter/Group 18142.png";
import Backdroplog from "../../../GenericComponent/Backdrop/Backdrop";

function Opportunities(props) {
  const [sidebar, setSidebar] = useState(false);
  const [showdropdown, setShowDropdown] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryId, setCategoryID] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [location, setLocation] = useState(null);
  const limit = 5;
  const history = useHistory();

  const toShowPagination = () => {
    if (
      props.page_no_rec !== undefined &&
      props.page_no_rec > 1 &&
      props.opp_can_list
    ) {
      return (
        <Pagination
          activePage={pageno}
          pageRangeDisplayed={props.page_no_rec}
          onLeftChange={() => onLeftChange()}
          onRightChange={() => onRightChange()}
        />
      );
    }
  };

  const onLeftChange = () => {
    if (pageno !== 1) {
      setPageno(pageno - 1);
    }
  };

  const onRightChange = () => {
    if (pageno !== props.page_no_rec) {
      setPageno(pageno + 1);
    }
  };

  const handleDropdownModal = () => {
    setShowDropdown((prev) => !prev);
  };
  const handleDelete = () => {
    setShowDeleteConfirm((prev) => !prev);
  };

  const getDetails = () => {
    let filters = {
      categoryId: categoryId,
      location: location,
      subCategoryId: subCategoryId,
    };
    props.getListByCandidate(pageno, limit, filters, searchQuery);
  };

  useEffect(() => {
    getDetails();
  }, [pageno, categoryId, location, subCategoryId, searchQuery]);

  useEffect(() => {
    props.getMainCategoryList();
  }, []);

  const handleModal = (val1, val2) => {
    props.handleAdvanceFilter(val1);
  };

  const handlesubmit = (val1, val2, val3) => {
    setCategoryID(val1);
    setSubCategoryId(val2);
    setLocation(val3);
    getDetails();
    setPageno(1);
  };

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <Backdroplog show={props.showBlur} />
      <section className={style.sectionStyle}>
        <div className={style.sidemenu}>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.topHeading}>
          <div>
            <img
              className={style.labelImg}
              src={backarrowImg}
              alt="pro"
              // onClick={() => handleRedirect("/Student/Dashboard")}
              onClick={() => history.goBack()}
            />
            <label className={style.label}>
              Created Opportunities/Scholarship
            </label>
          </div>
          <div className={style.options}>
            <img
              src={Filter}
              alt="img"
              style={{ cursor: "pointer", width: "3.3em" }}
              onClick={() => handleModal(true, true)}
            />
          </div>
        </div>
        <div className={style.centered}>
          {props.opp_can_list && props.opp_can_list.length > 0 ? (
            <Cards
              data={props.opp_can_list}
              handleDropdownModal={() => handleDropdownModal()}
              showdropdown={showdropdown}
              showDeleteConfirm={showDeleteConfirm}
              handleDelete={handleDelete}
            />
          ) : null}
        </div>

        <div className={style.footer}>{toShowPagination()}</div>
      </section>
      {props.showAdvanceFilter && (
        <div className={style.changeDiv1}>
          <Modal
            handleModal={handleModal}
            category_list={props.category_list}
            handlesubmit={handlesubmit}
          />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    oppcandidateListErrMsg: state.candidateReducer.oppcandidateListErrMsg,
    oppcandidateListStatus: state.candidateReducer.oppcandidateListStatus,
    oppErrorMsg: state.candidateReducer.oppErrorMsg,
    oppErrorCode: state.candidateReducer.oppErrorCode,
    loader: state.candidateReducer.loader,
    initial: state.candidateReducer.initial,
    opp_rec_filter: state.candidateReducer.opp_rec_filter,
    opp_can_list: state.candidateReducer.opp_can_list,
    page_no_rec: state.candidateReducer.page_no_rec,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
    showDropDownModal: state.candidateReducer.showDropDownModal,
    recsignUpStatus: state.candidateReducer.recsignUpStatus,
    showAdvanceFilter: state.candidateReducer.showAdvanceFilter,
    sub_category_list: state.recruiterReducer.sub_category_list,
    category_list: state.recruiterReducer.category_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListByCandidate: (pageno, limit, filters, searchQuery) =>
      dispatch(
        actionTypes.getListByCandidate(pageno, limit, filters, searchQuery)
      ),
    handleAdvanceFilter: (val) =>
      dispatch(actionTypes.handleAdvanceFilter(val)),
    getMainCategoryList: () => dispatch(actionTypes.getMainCategoryList()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Opportunities);
