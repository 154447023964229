import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import { useHistory } from "react-router-dom";
import Cards from "./Cards/cards";
import style from "./s_opportunities.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import Modal from "../../../GenericComponent/Modal/Appliedfilterc/appliedfilterc";
import optionsImg from "../../../assets/options/Group 18213.png";
import searchImg from "../../../assets/search/Icon ionic-ios-search.png";
import backarrowImg from "../../../assets/eye/Icon ionic-ios-arrow-round-back.png";
import Pagination from "../../../GenericComponent/Pagination/Pagination";
import NoData from "../../../assets/globe.jpg";
import Backdroplog from "../../../GenericComponent/Backdrop/Backdrop";
function Opportunities(props) {
  const [sidebar, setSidebar] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [showdropdown, setShowDropdown] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [locationId, setLocationId] = useState();
  const limit = 5;
  const history = useHistory();
  const handleModal = (val1, val2) => {
    props.handleAdvanceFilter(val1, val2);

    //  props.handleCanshowblur(true)
  };

  const handlesubmit = (val1, val2, val3) => {
    setCategoryId(val1);
    setSubCategoryId(val2);
    setLocationId(val3);
    setPageno(1);
    getDetails();
  };

  const handleRedirect = (path) => {
    history.push(path);
  };

  const toShowPagination = () => {
    if (
      props.page_no_rec !== undefined &&
      props.page_no_rec > 1 &&
      props.opp_can_list
    ) {
      return (
        <Pagination
          activePage={pageno}
          pageRangeDisplayed={props.page_no_rec}
          onLeftChange={() => onLeftChange()}
          onRightChange={() => onRightChange()}
        />
      );
    }
  };

  const onLeftChange = () => {
    if (pageno !== 1) {
      setPageno(pageno - 1);
    }
  };

  const onRightChange = () => {
    if (pageno !== props.page_no_rec) {
      setPageno(pageno + 1);
    }
  };

  const handleDropdownModal = () => {
    setShowDropdown((prev) => !prev);
  };
  const handleDelete = () => {
    setShowDeleteConfirm((prev) => !prev);
  };

  const getDetails = (e) => {
    let filters = {
      categoryId: categoryId,
      location: locationId,
      subCategoryId: subCategoryId,
    };

    props.getListByCandidate(pageno, limit, filters, searchQuery);
  };

  useEffect(
    (e) => {
      getDetails(e);
    },

    [pageno, searchQuery, categoryId, subCategoryId, locationId]
  );

  useEffect((e) => {
    props.getMainCategoryList();
  }, []);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <Backdroplog show={props.showBlur} />
      <section className={style.sectionStyle}>
        <div className={style.sidemenu}>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.topHeading}>
          <div className={style.header}>
            <div className={style.keyword}>
              <img src={searchImg} alt="img" />
              <input
                placeholder="Keywords"
                className={style.input}
                onChange={(e) => {
                  setPageno(1);
                  setSearchQuery(e.target.value);
                }}
              ></input>
            </div>
            <div className={style.options}>
              <img
                src={optionsImg}
                style={{ cursor: "pointer" }}
                alt="img"
                onClick={() => handleModal(true, true)}
              />
            </div>
          </div>
        </div>
        <div className={style.centered}>
          {props.opp_can_list && props.opp_can_list.length > 0 ? (
            <Cards
              data={props.opp_can_list}
              handleDropdownModal={() => handleDropdownModal()}
              showdropdown={showdropdown}
              showDeleteConfirm={showDeleteConfirm}
              handleDelete={handleDelete}
            />
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <p>
                  <b>No Data Found – Create opportunities/scholarships</b>
                </p>
                <img
                  className={style.nodata}
                  style={{ width: "50%", height: "60%" }}
                  src={NoData}
                  alt="nodata"
                />
              </div>
            </div>
          )}
        </div>

        <div className={style.footer}>{toShowPagination()}</div>
      </section>
      {props.showAdvanceFilter && props.showBlur && (
        <div className={style.changeDiv1}>
          <Modal
            handleModal={handleModal}
            category_list={props.category_list}
            handlesubmit={handlesubmit}
          />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    oppcandidateListErrMsg: state.candidateReducer.oppcandidateListErrMsg,
    oppcandidateListStatus: state.candidateReducer.oppcandidateListStatus,
    oppErrorMsg: state.candidateReducer.oppErrorMsg,
    oppErrorCode: state.candidateReducer.oppErrorCode,
    loader: state.candidateReducer.loader,
    initial: state.candidateReducer.initial,
    opp_rec_filter: state.candidateReducer.opp_rec_filter,
    opp_can_list: state.candidateReducer.opp_can_list,
    page_no_rec: state.candidateReducer.page_no_rec,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
    showDropDownModal: state.candidateReducer.showDropDownModal,
    recsignUpStatus: state.candidateReducer.recsignUpStatus,
    showAdvanceFilter: state.candidateReducer.showAdvanceFilter,
    sub_category_list: state.recruiterReducer.sub_category_list,
    category_list: state.recruiterReducer.category_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListByCandidate: (pageno, limit, filters, searchQuery) =>
      dispatch(
        actionTypes.getListByCandidate(pageno, limit, filters, searchQuery)
      ),

    handleCanshowblur: (val) => dispatch(actionTypes.handleCanshowblur(val)),
    handleAdvanceFilter: (val) =>
      dispatch(actionTypes.handleAdvanceFilter(val)),
    getMainCategoryList: () => dispatch(actionTypes.getMainCategoryList()),
    getSubCategoryList: (id) => dispatch(actionTypes.getSubCategoryList(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Opportunities);
