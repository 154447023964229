import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cards from "./Cards/cards";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import style from "./student.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import NoData from "../../../assets/globe.jpg";
import Modal from "../../../GenericComponent/Appliedfilterc/appliedfilterc";
import { BlueButton } from "../../../GenericComponent/Buttons/button";
import backarrowImg from "../../../assets/back arrow/Icon ionic-ios-arrow-round-back.png";

function Applied(props) {
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showBlur, setShowBlur] = useState(false);
  const [pageNo, setpageNo] = useState(1);
  const [studentProfileId, setstudentProfileId] = useState();
  const [opportunityId, setOpportunityId] = useState(
    props.history.location.state.OpportunityId
  );

  const getDetails = () => {
    props.getAppliedStudentList(
      props?.history?.location?.state?.OpportunityId,
      pageNo
    );
  };

  useEffect(() => {
    getDetails();
  }, [pageNo]);

  const toShowCards = () => {
    if (props.app_stu_list && props.app_stu_list.length > 0) {
      return (
        <Cards data={props.app_stu_list} setOpportunityId={opportunityId} />
      );
    } else {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <p>
              <b>
                {" "}
                No Data Found – Accepted opportunities/scholarships forthcoming
              </b>
            </p>
            <img
              className={style.nodata}
              style={{ width: "50%", height: "60%" }}
              src={NoData}
              alt="nodata"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section
        className={
          showBlur === true
            ? style.sectionStyle + " " + style.addBlurr
            : style.sectionStyle
        }
      >
        <div>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.heading}>
          <div className={style.backarrow}>
            {localStorage.getItem("userRole") === "RECRUITER" ? (
              <img
                src={backarrowImg}
                alt="img"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/Recruiter/Opportunities")}
              />
            ) : null}
            {localStorage.getItem("userRole") === "ORGANISATION" ? (
              <img
                src={backarrowImg}
                alt="img"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/CreatedOpportunities")}
              />
            ) : null}
          </div>

          <div className={style.filter}></div>
        </div>
        <div className={style.centered}>{toShowCards()}</div>
      </section>

      {showModal && (
        <div className={style.changeDiv}>
          <Modal />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    appliedstudentListErrMsg: state.recruiterReducer.appliedstudentListErrMsg,
    appliedstudentlistStatus: state.recruiterReducer.appliedstudentlistStatus,
    applidstudentErrorMsg: state.recruiterReducer.applidstudentErrorMsg,
    oppErrorCode: state.recruiterReducer.oppErrorCode,
    loader: state.recruiterReducer.loader,
    initial: state.recruiterReducer.initial,
    app_stu_list: state.recruiterReducer.app_stu_list,
    page_no_stu: state.recruiterReducer.page_no_stu,
    showAlert: state.recruiterReducer.showAlert,
    showBlur: state.recruiterReducer.showBlur,
    showDropDownModal: state.recruiterReducer.showDropDownModal,
    showDeleteModal: state.orgReducer.showDeleteModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAppliedStudentList: (opportunityId, pageNo) =>
      dispatch(actionTypes.getAppliedStudentList(opportunityId, pageNo)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Applied);
