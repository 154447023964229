import React from "react";
import style from "./ResponseAlert.module.css";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import { useHistory } from "react-router-dom";

function ResponseAlert(props) {
  const history = useHistory();

  const handleCancle = () => {
    // localStorage.removeItem("userToken");
    //       localStorage.removeItem("userRole");
    //       localStorage.removeItem("userName");
    //       localStorage.removeItem("userEmail");
    //       localStorage.removeItem("accountId");
    localStorage.clear();
    history.push("/CreatedOpportunities");
  };

  return (
    <div className={style.container}>
      <div className={style.result}>
        <p className={style.error}>Error:</p>
        <p className={style.message}> Please login again</p>
        <button className={style.ok_btn} type="button" onClick={handleCancle}>
          Ok
        </button>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    ErrorCode: state.authReducer.ErrorCode,
    newType: state.authReducer.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getLogout: () => dispatch(actionTypes.getLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResponseAlert);
