import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { getToken, onMessageListener } from "../../firebaseInit";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import Backdroplog from "../../GenericComponent/Backdrop/Backdrop";
import SignUpModal from "../Homepage/components/SignUp/signup";
import LoginModal from "../Homepage/components/Login/login";
import OTPModal from "../Homepage/components/OTPScreen/OTPScreen";
import SignUpOTPModal from "../Homepage/components/SignUpOTPScreen/SignupOTPScreen";
import ResetPasswordModal from "../Homepage/components/ResetPassword/ResetPassword";
import ForgotPassModal from "../Homepage/components/ForgotPassword/forgotpassword";
import ConfirmModal from "../Homepage/components/confirm";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import style from "./termsandcondition.module.css";
import Header from "../../GenericComponent/header/header";
import { BlueButton, WhiteButton } from "../../GenericComponent/Buttons/button";
import backgroundImg from "../../assets/globe.jpg";

import Loader from "../../assets/Loader/spinner.gif";

function Termsandcondition(props) {
  const history = useHistory();
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const [SsignupEmail, setSSignupEmail] = useState("");
  const [SsignupPassword, setSSignupPassword] = useState("");
  const [OsignupEmail, setOSignupEmail] = useState("");
  const [OsignupPassword, setOSignupPassword] = useState("");
  const [forgotOtp, setForgotOtp] = useState("");
  const [ip, setIP] = useState("");
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, tokenFound] = useState("");
  getToken(setTokenFound).then((message) => tokenFound(message));
  localStorage.setItem("token", 9000);
  localStorage.setItem("fcmtoken", token);

  function showButton() {
    if (props.showSignUpModal) {
      return (
        <BlueButton
          children="Sign Up Now!"
          onClick={props.SignUp}
          className={style.centered_button}
        />
      );
    } else {
      return (
        <WhiteButton
          children="Sign Up Now!"
          onClick={props.SignUp}
          className={style.centered_button}
        />
      );
    }
  }

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header
          showSignUpModal={props.showSignUpModal}
          showSignInModal={props.showSignInModal}
          sidebar={setSidebar}
          side={sidebar}
        />
      </header>
      {props.loader ? (
        <div className={style.loader_style}>
          <img alt="loader" src={Loader} />
        </div>
      ) : (
        <div>
          <Backdroplog show={props.showBlur} />
          <div className={style.hero}>
            <div
              className={style.left}
              style={{ overflow: "scroll", height: "83vh" }}
            >
              <p>
                {" "}
                Welcome to https://igotgame2.com/. This Site is owned and
                operated by I Got Games 2. By accessing or using this Site, you
                agree to be bound by the following terms and conditions of use
                ("Terms and Conditions"). If you do not agree to these Terms and
                Conditions, please do not use this Site.
              </p>
              <h4>Use of Site</h4>
              <p>
                The content on this Site is for your general information and use
                only. We reserve the right to modify or withdraw, temporarily or
                permanently, this Site with or without notice to you. You
                acknowledge and agree that we shall not be liable to you or any
                third party for any modification or withdrawal of the Site.
              </p>

              <h4>Intellectual Property</h4>
              <p>
                This Site and its original content, features, and functionality
                are owned by I Got Games 2 and are protected by international
                copyright, trademark, patent, trade secret, and other
                intellectual property or proprietary rights laws. You may not
                copy, reproduce, distribute, modify, create derivative works of,
                publicly display, publicly perform, republish, download, store,
                or transmit any of the material on our Site, except as follows:
              </p>

              <div className={style.row}>
                <ul>
                  <li>
                    Your computer may temporarily store copies of such materials
                    in RAM incidental to your accessing and viewing those
                    materials.
                  </li>
                  <li>
                    You may store files that are automatically cached by your
                    Web browser for display enhancement purposes.
                  </li>
                </ul>
              </div>
              <h4>Prohibited Uses</h4>
              <div className={style.row}>
                <ul>
                  <li>
                    In any way that violates any applicable federal, state,
                    local, or international law or regulation (including,
                    without limitation, any laws regarding the export of data or
                    software to and from the US or other countries).
                  </li>
                  <li>
                    For the purpose of exploiting, harming, or attempting to
                    exploit or harm minors in any way by exposing them to
                    inappropriate content, asking for personally identifiable
                    information, or otherwise.
                  </li>
                  <li>
                    To send, knowingly receive, upload, download, use, or re-use
                    any material that does not comply with these Terms and
                    Conditions.
                  </li>
                  <li>
                    To transmit, or procure the sending of, any advertising or
                    promotional material, including any "junk mail," "chain
                    letter," "spam," or any other similar solicitation.
                  </li>
                  <li>
                    To impersonate or attempt to impersonate I Got Games 2, a I
                    Got Games 2 employee, another user, or any other person or
                    entity (including, without limitation, by using e-mail
                    addresses or screen names associated with any of the
                    foregoing).
                  </li>
                  <li>
                    To engage in any other conduct that restricts or inhibits
                    anyone's use or enjoyment of the Site, or which, as
                    determined by us, may harm I Got Games 2 or users of the
                    Site or expose them to liability.
                  </li>
                </ul>
              </div>

              <h4>Disclaimer of Warranties</h4>
              <p>
                This Site is provided on an "as is" and "as available" basis. I
                Got Games 2 makes no representations or warranties of any kind,
                express or implied, as to the operation of this Site or the
                information, content, materials, or products included on this
                Site. You expressly agree that your use of this Site is at your
                sole risk. To the fullest extent permissible by applicable law,
                I Got Games 2 disclaims all warranties, express or implied,
                including, but not limited to, implied warranties of
                merchantability and fitness for a particular purpose. I Got
                Games 2 does not warrant that this Site, its servers, or e-mail
                sent from I Got Games 2 are free of viruses or other harmful
                components. I Got Games 2 will not be liable for any damages of
                any kind arising from the use of this Site, including, but not
                limited to, direct.
              </p>
            </div>
            <div className={style.right}>
              <img src={backgroundImg} alt="hero-img" />
            </div>
          </div>
        </div>
      )}
      {props.showSignInModal && (
        <div className={style.changeDiv}>
          <LoginModal
            handleForgotPasswordModal={props.handleForgotPasswordModal}
            ResetPasswordModal={props.showResetPasswordModal}
            // getData={getData}
          />
        </div>
      )}

      {props.showSignUpModal && (
        <div className={style.changeDiv}>
          <SignUpModal
            setSSignupEmail={setSSignupEmail}
            setSSignupPassword={setSSignupPassword}
            setOSignupPassword={setOSignupPassword}
            setOSignupEmail={setOSignupEmail}
          />
        </div>
      )}

      {props.showForgotPassModal && (
        <div className={style.changeDiv}>
          <ForgotPassModal
            showResetPasswordModal={props.showResetPasswordModal}
            ResetPasswordModal={props.ResetPasswordModal}
            hideModal={props.hideModal}
            setForgetEmail={setForgetEmail}
          />
        </div>
      )}

      {props.showOTPModal && (
        <div className={style.changeDiv}>
          <OTPModal
            OTPModal={props.showOTPModal}
            forgetEmail={forgetEmail}
            forgotPassword={props.forgotPassword}
            hideModal={props.hideModal}
            handleForgotPasswordModal={props.handleForgotPasswordModal}
            showResetPasswordModal={props.showResetPasswordModal}
            setForgotOtp={setForgotOtp}
            ErrorCode={props.ErrorCode}
          />
        </div>
      )}

      {props.showSignupOTPModal && (
        <div className={style.changeDiv}>
          <SignUpOTPModal
            OTPModal={props.showSignupOTPModal}
            SsignupEmail={SsignupEmail}
            forgotPassword={props.forgotPassword}
            hideModal={props.hideModal}
            SsignupPassword={SsignupPassword}
            OsignupEmail={OsignupEmail}
            OsignupPassword={OsignupPassword}
            setForgotOtp={setForgotOtp}
            ErrorCode={props.ErrorCode}
          />
        </div>
      )}

      {props.showResetPasswordModal && (
        <div className={style.changeDiv}>
          <ResetPasswordModal
            ResetPasswordModal={props.showResetPasswordModal}
            OTPModal={props.showOTPModal}
            forgetEmail={forgetEmail}
            hideModal={props.hideModal}
            forgotOtp={forgotOtp}
          />
        </div>
      )}

      {props.showConfirmModal && (
        <div className={style.changeDiv1}>
          <ConfirmModal
            hideModal={props.hideModal}
            confirm={props.confirm}
            OTPModal={props.OTPModal}
          />
        </div>
      )}

      {props.showAlert && (
        <div className={style.changeDiv2}>
          <ResponseAlert message={props.errorMsg} />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    signUpStatus: state.authReducer.signUpStatus,
    errorMsg: state.authReducer.errorMsg,
    loader: state.authReducer.loader,
    initial: state.authReducer.initial,
    ErrorCode: state.authReducer.ErrorCode,
    showAlert: state.authReducer.showAlert,
    resetPasswordSuccMsg: state.authReducer.resetPasswordSuccMsg,
    emailverifySuccMsg: state.authReducer.emailverifySuccMsg,
    showConfirmModal: state.authReducer.showConfirmModal,

    showForgotPassModal: state.authReducer.showForgotPassModal,
    showSignUpModal: state.authReducer.showSignUpModal,
    showSignInModal: state.authReducer.showSignInModal,
    confirm: state.authReducer.confirm,
    showBlur: state.authReducer.showBlur,
    showOTPModal: state.authReducer.showOTPModal,
    showSignupOTPModal: state.authReducer.showSignupOTPModal,
    showResetPasswordModal: state.authReducer.showResetPasswordModal,
    newuserEmailid: state.authReducer.newuserEmailid,
    FCMStatus: state.authReducer.FCMStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SignIn: () => dispatch(actionTypes.handleSignInModal()),
    SignUp: () => dispatch(actionTypes.handleSignUpModal()),
    OTPModal: () => dispatch(actionTypes.handleOTPModal()),
    handleSignupOTPModal: () => dispatch(actionTypes.handleSignupOTPModal()),
    handleForgotPasswordModal: () =>
      dispatch(actionTypes.handleForgotPasswordModal()),

    ResetPasswordModal: () => dispatch(actionTypes.ResetPasswordModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Termsandcondition);
