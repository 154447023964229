import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as actionTypes from "../../Store/Action/index";
import { connect } from "react-redux";
import style from "./StudentDashboard.module.css";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import { BlueButton } from "../../GenericComponent/Buttons/button";
import Cards from "./Cards/cards";
import defaultImg from "../../assets/defaultimg/Rectangle 5337@2x.png";
import image from "../../assets/Group 18374/Group 18374.png";
import Loader from "../../assets/Loader/spinner.gif";
import profile_success from "../../assets/svg files/profile_success.svg";
function SDashboard(props) {
  const [sidebar, setSidebar] = useState(false);
  const history = useHistory();
  const [pageno] = useState(1);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showdropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const limit = 3;
  const Opportunities = [
    {
      title: "Cricket",
      Category: "Sports",
      Skills: "Cricket",
      Location: "London,Paris",
      Applied: "22",
      Seen: "112",
      Created: "Created by: Kajal Aggarwal | 3 Days ago",
      Color: "#36B453",
    },
  ];
  const handleDropdownModal = () => {
    setShowDropdown((prev) => !prev);
  };
  const handleDelete = () => {
    setShowDeleteConfirm((prev) => !prev);
  };

  const showData = (condition, val1, val2) => {
    if (condition) {
      return val1;
    } else {
      return val2;
    }
  };

  function showSkillData(condition, val1, val2) {
    if (condition) {
      return val1.join(" , ");
    } else {
      return val1;
    }
  }

  useEffect(() => {
    const filters = {
      categoryId: null,
      location: null,
      subCategoryId: null,
    };
    props.getCandidateDashboard();
    props.getListByCandidate(pageno, limit, filters, searchQuery);
  }, []);
  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.button}>
          <BlueButton
            style={{ margin: ".5% 0px 2% -25px" }}
            children="Post"
            onClick={() => history.push("/Student/SkillDetails")}
          />
        </div>
        {props.loader ? (
          <div className="loader_styles">
            <img src={Loader} alt="loader" />
          </div>
        ) : (
          <div>
            <div className={style.centered}>
              <div className={style.maindiv}>
                <div className={style.box1}>
                  <img
                    src={showData(
                      props?.dashboardData &&
                        props?.dashboardData?.profileUrl !== null,
                      props?.dashboardData?.profileUrl,
                      defaultImg
                    )}
                    alt="img"
                  />
                  <div className={style.midbox1}>
                    <p1>
                      {showData(
                        props?.dashboardData &&
                          props?.dashboardData?.name !== null,
                        props?.dashboardData?.name,
                        ""
                      )}
                    </p1>
                    <table>
                      <div className={style.skillls}>
                        <div style={{ width: "5%", paddingRight: "1.5rem" }}>
                          Skills:
                        </div>
                        <div>
                          {" "}
                          &nbsp;{props?.dashboardData?.constructor}
                          {showSkillData(
                            Object.entries(props.dashboardData).length > 0 &&
                              props?.dashboardData?.skills !== null,
                            props?.dashboardData?.skills,
                            ""
                          )}
                        </div>
                      </div>
                    </table>
                    <div className={style.profile}>
                      <h1
                        style={{
                          color:
                            props?.dashboardData?.profilePercentage === 100
                              ? "#35B357"
                              : "#333333",
                        }}
                      >
                        {showData(
                          props?.dashboardData,
                          props?.dashboardData?.profilePercentage,
                          0
                        )}
                      </h1>
                      <h2
                        style={{
                          color:
                            props?.dashboardData?.profilePercentage === 100
                              ? "#35B357"
                              : "#333333",
                        }}
                      >
                        %
                      </h2>
                      <span className={style.profilesuccesscss}>Profile</span>{" "}
                      {props?.dashboardData?.profilePercentage === 100 && (
                        <span>
                          <img
                            src={profile_success}
                            className={style.profilesuccessimg}
                            alt="profile_success"
                          />
                        </span>
                      )}
                    </div>
                    <div className={style.greydiv}>
                      <img
                        src={image}
                        style={{ cursor: "pointer" }}
                        alt="img"
                        onClick={() =>
                          history.push({
                            pathname: "/Student/S_Profilepage",
                            state: {
                              profileData: props.dashboardData,
                            },
                          })
                        }
                      />
                      <div
                        style={{
                          height: "100%",
                          width: props.dashboardData.profilePercentage + "%",
                          borderRadius: "8px",
                          backgroundColor:
                            props?.dashboardData?.profilePercentage === 100
                              ? "#35B357"
                              : "#1E118D",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div className={style.heading3}>
                  <p className={style.box3heading}>Opportunity/Scholarship</p>
                  <span className={style.link}>
                    <a onClick={() => history.push("/Student/Opportunities")}>
                      View All
                    </a>
                  </span>
                </div>
                <div className={style.box3}>
                  {Opportunities.length > 0 &&
                    Opportunities.map((val, index) => {
                      return (
                        <div>
                          {props.opp_can_list &&
                          props.opp_can_list.length > 0 ? (
                            <Cards
                              data={props.opp_can_list}
                              handleDropdownModal={() => handleDropdownModal()}
                              showdropdown={showdropdown}
                              showDeleteConfirm={showDeleteConfirm}
                              handleDelete={handleDelete}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
        {props.showAlert && (
          <div className={style.changeDiv}>
            <ResponseAlert message={props.recErrorMsg} type="ORGANISATION" />
          </div>
        )}
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.candidateReducer.loader,
    dashboardData: state.candidateReducer.candidateDashboardData,
    candidateErrorMsg: state.candidateReducer.candidateErrorMsg,
    candidateErrorCode: state.candidateReducer.candidateErrorCode,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
    oppcandidateListErrMsg: state.candidateReducer.oppcandidateListErrMsg,
    oppcandidateListStatus: state.candidateReducer.oppcandidateListStatus,
    oppErrorMsg: state.candidateReducer.oppErrorMsg,
    oppErrorCode: state.candidateReducer.oppErrorCode,
    opp_rec_filter: state.candidateReducer.opp_rec_filter,
    opp_can_list: state.candidateReducer.opp_can_list,
    page_no_rec: state.candidateReducer.page_no_rec,
    showDropDownModal: state.candidateReducer.showDropDownModal,
    recsignUpStatus: state.candidateReducer.recsignUpStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateDashboard: () => dispatch(actionTypes.getCandidateDashboard()),
    getListByCandidate: (pageno, limit, filters, searchQuery) =>
      dispatch(
        actionTypes.getListByCandidate(pageno, limit, filters, searchQuery)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SDashboard);
