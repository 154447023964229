import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import style from "./OpportunityDetails.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import * as actionTypes from "../../../Store/Action/index";
import moment from "moment";
import backarrowImg from "../../../assets/back arrow/Icon ionic-ios-arrow-round-back@3x.png";
import Loader from "../../../assets/Loader/spinner.gif";
import { useHistory } from "react-router";
import AboutRecruiter from "./Component/AboutRecruiter";
import AboutOpportunity from "./Component/AboutOpportunity";
import AboutOrganisation from "./Component/AboutOrganisation";
import MainDetails from "./Component/MainDetails";
import CandidateOpportunity from "../../../Pages/OpportunitiesList/StudentOpportunities/s_opportunities";

function OpportunityDetails(props) {
  const [backPath, setBackPath] = useState(
    props?.history?.location?.state?.backPath
  );
  const history = useHistory();
  const [sidebar, setSidebar] = useState();
  const [showLeft, setShowLeft] = useState(true);
  const [requestDate, setRequestDate] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [showRight, setShowRight] = useState(false);
  const [child, setchild] = useState("Apply");
  const [showMiddle, setShowMiddle] = useState(false);

  const handleClick = () => {
    history.push({
      pathname: "/Recruiter/EditOpportunity",
      state: {
        profileData: props.profileData,
        opp_id: props.history.location.state.opp_id,
      },
    });
  };

  const handleNavigation = () => {
    if (backPath == "/Student/Opportunities") {
      history.push("/Student/Opportunities");
    } else if (backPath == "/Student/Dashboard") {
      history.push("/Student/Dashboard");
    } else if (backPath == "/student/SearchOpportunity") {
      history.push("/student/SearchOpportunity");
    } else if (backPath == "/AppliedOpportunities") {
      history.push("/AppliedOpportunities");
    }
  };

  const openCity = (cityName) => {
    if (cityName === "Left") {
      setShowLeft(true);
      setShowRight(false);
      setShowMiddle(false);
    } else if (cityName === "Middle") {
      setShowLeft(false);
      setShowMiddle(true);
      setShowRight(false);
    } else if (cityName === "Right") {
      setShowLeft(false);
      setShowMiddle(false);
      setShowRight(true);
    }
  };

  const applyButton = () => {
    if (props?.history?.location?.state?.applied) {
      return <button className={style.applied}>Applied</button>;
    } else {
      return (
        <button
          className={child == "Apply" ? style.apply : style.applied}
          onClick={() => {
            props.applyOnOpportunity(
              requestDate,
              props?.history?.location?.state?.opp_id
            );
            setchild("Applied");
          }}
        >
          {child}
        </button>
      );
    }
  };

  const getDetails = () => {
    props.getOpportunityDetails(props?.history?.location?.state?.opp_id);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        {props.loader ? (
          <div className={style.loader}>
            <img alt="loader" src={Loader} />
          </div>
        ) : (
          <>
            <div>
              <Sidebar side={sidebar} />
            </div>
            <div className={style.topHeading}>
              <div>
                {localStorage.getItem("userRole") === "CANDIDATE" ? (
                  <img
                    src={backarrowImg}
                    className={style.arrow}
                    alt="img"
                    onClick={handleNavigation}
                  />
                ) : localStorage.getItem("userRole") === "ORGANISATION" ? (
                  <img
                    src={backarrowImg}
                    className={style.arrow}
                    alt="img"
                    onClick={() => history.push("/CreatedOpportunities")}
                  />
                ) : (
                  <img
                    src={backarrowImg}
                    className={style.arrow}
                    alt="img"
                    onClick={() => history.push("/Recruiter/Opportunities")}
                  />
                )}
              </div>
              <div>
                <div className={style.para}>
                  Opportunity/Scholarship Details
                </div>

                <div>
                  <div style={{}}>
                    {localStorage.getItem("userRole") === "RECRUITER" ? (
                      <button
                        type="button"
                        className={style.button}
                        onClick={handleClick}
                      >
                        Edit
                      </button>
                    ) : null}
                  </div>

                  <div
                    style={{
                      marginTop: "-2%",
                      marginLeft: "-3%",
                      pointerEvents: "none",
                    }}
                  ></div>
                  {localStorage.getItem("userRole") === "CANDIDATE"
                    ? applyButton()
                    : null}
                </div>
              </div>
            </div>
            <div className={style.centered}>
              <MainDetails profileData={props.profileData} />
              <div style={{ marginTop: "1rem" }}>
                <label
                  className={showLeft ? style.activeTab : style.inactiveTab}
                  onClick={() => openCity("Left")}
                >
                  Opportunity Details
                </label>
              </div>
              <div className={style.aboutcss}>
                <label
                  className={showMiddle ? style.aactiveTab : style.inaactiveTab}
                  onClick={() => openCity("Middle")}
                >
                  About Organization
                </label>
              </div>
              <div className={style.about_rec}>
                <label
                  className={showRight ? style.aactiveTab : style.inaactiveTab}
                  onClick={() => openCity("Right")}
                >
                  About Recruiter
                </label>
              </div>
              <hr className={style.line} />
              {showLeft && <AboutOpportunity profileData={props.profileData} />}
              {showMiddle && (
                <AboutOrganisation profileData={props.profileData} />
              )}
              {showRight && <AboutRecruiter profileData={props.profileData} />}
            </div>
          </>
        )}
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.orgReducer.loader,
    profileData: state.orgReducer.orgOpportunityData,
    orgErrorMsg: state.orgReducer.orgErrorMsg,
    orgErrorCode: state.orgReducer.orgErrorCode,
    showAlert: state.orgReducer.showAlert,
    showBlur: state.orgReducer.showBlur,
    applyStatus: state.candidateReducer.applyStatus,
    initial: state.candidateReducer.initial,
    ErrorCode: state.candidateReducer.ErrorCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOpportunityDetails: (id) =>
      dispatch(actionTypes.getOpportunityDetails(id)),
    applyOnOpportunity: (appliedDate, opportunityId) =>
      dispatch(actionTypes.applyOnOpportunity(appliedDate, opportunityId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetails);
