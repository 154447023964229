import React from "react";
import style from "./cards.module.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import defaultImg from "../../../assets/defaultimg/Rectangle 5337@2x.png";

function Cards(props) {
  const history = useHistory();

  return (
    <>
      {props.data && props.data.length > 0
        ? props.data.map((item, index) => (
            <div className={style.container}>
              <div className={style.card}>
                <div className={style.left}>
                  {/* <p className={style.oppdes}>{item.message}</p> */}
                  <img
                    src={
                      item.profileUrl && item.profileUrl !== null
                        ? item.profileUrl
                        : defaultImg
                    }
                    alt="img"
                  />
                  <p className={style.orgname}>{item.message}</p>
                </div>

                <div className={style.right}>
                  <p className={style.time}>
                    {moment(item.createdTime).fromNow()}
                  </p>
                </div>
              </div>
            </div>
          ))
        : null}
    </>
  );
}

export default Cards;
