import React from "react";
import style from "./Delete.module.css";

import {
  BlueButton,
  WhiteButton,
} from "../../../GenericComponent/Buttons/button";

import closeCircle from "../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import DeleteImg from "../../../assets/delete/Group 18302.png";

export default function Modal2(props) {
  return (
    <div className={style.centered}>
      <div className={style.header}>
        <div className={style.upper}>
          <img
            src={closeCircle}
            alt="img"
            onClick={() => props.cancelDelete()}
          />
        </div>
        <div className={style.delete}>
          <img src={DeleteImg} alt="img" className={style.alignImg} />
          <p>Are you sure you want to delete this?</p>
        </div>
        <div className={style.btn}>
          <div>
            <WhiteButton
              children="Cancel"
              onClick={() => props.cancelDelete()}
            />
          </div>

          <div>
            <BlueButton
              children="Delete"
              onClick={() => props.confirmDelete()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
