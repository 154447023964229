import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import { useHistory } from "react-router-dom";
import style from "./Chat.module.css";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import defaultImg from "../../assets/defaultimg/Rectangle 5337@2x.png";
import backarrowImg from "../../assets/back arrow/Icon ionic-ios-arrow-round-back.png";
import moreImg from "../../assets/eye/More.png";
import onlineImg from "../../assets/online/Group 18363.png";
import galleryImg from "../../assets/gallery/Icon metro-attachment.png";
import sentImg from "../../assets/sent/Group 17913.png";
import recruiter from "../../assets/recruiter/Group 18174.png";
import { firestore } from "firebase";
import deleteButton from "../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import Modal1 from "./ChatDrop/ChatDropdown";
import Backdroplog from "../../GenericComponent/Backdrop/Backdrop";
import Modal2 from "../../GenericComponent/Modal/DeleteChat/Delete";

function Chat(props) {
  const [sidebar, setSidebar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showBlur, setShowBlur] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [message, setMessage] = useState("");
  const [chatStarted] = useState(true);
  const [newline, setNewline] = useState(false);
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const myRef = useRef();
  const messagesEndRef = useRef(null);

  const showData = () => {
    if (isShow) {
      setIsShow(false);
    } else {
      setIsShow(true);
    }
  };
  const hideData = () => {
    setIsShow(false);
  };

  const handleDelete = (val) => {
    setIsShow(false);
    props.handleCandidateDeleteModal(val);
  };

  const handleCancelDelete = () => {
    props.handleCandidateDeleteModal(false);
  };

  const submitMessage = (e) => {
    const msgObj = {
      user_uid_1: props.history.location.state.userid,
      user_uid_2: props.history.location.state.senderid,
      message,
    };

    if (message.trim() !== "") {
      props.updateMessage(msgObj).then(() => {
        setMessage("");
      });
    }
  };

  const handleRedirect = () => {
    history.push({
      pathname: "/Student/Chatlist",
      state: {},
    });
  };

  const submitDelete = () => {
    props.handleCandidateDeleteModal(false);
    const uid1 = props.history.location.state.userid;
    const uid2 = props.history.location.state.senderid;
    const db = firestore();
    let conversations = db.collection("conversations");
    conversations.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        const n = [uid1, uid2];
        if (
          n.includes(doc.data().user_uid_1) &&
          n.includes(doc.data().user_uid_2)
        ) {
          doc.ref.delete();
        }
      });
    });
  };

  function handleKeyPress(e) {
    if ((e.code == "Enter" && e.ctrlKey) || (e.key == "Enter" && e.shiftKey)) {
      e.preventDefault();
      {
        message.trim() !== "" && setMessage(message + "\n");
      }
      return <br />;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      submitMessage();
    }
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "end",
      inline: "nearest",
    });
  };
  useEffect(() => {
    scrollToBottom();
  }, [props?.conversations]);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <Backdroplog show={props.showBlur} />
        <div className={style.heading}>
          <div className={style.backarrow}>
            <img
              src={backarrowImg}
              onClick={handleRedirect}
              alt="img"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className={style.text}>
            <p>Chat</p>
          </div>
        </div>
        <div className={style.centered}>
          <div className={style.header}>
            <div className={style.left}>
              <img
                src={
                  props.history.location.state.uimg === null
                    ? defaultImg
                    : props.history.location.state.uimg
                }
                alt="img"
              />
            </div>
            <div className={style.mid}>
              <h4>{props.history.location.state.chatUser}</h4>
              <div className={style.more}>
                <img
                  src={moreImg}
                  alt="img"
                  style={{ position: "relative" }}
                  onClick={() => showData()}
                />
                {isShow ? (
                  <div
                    className={style.modal_container}
                    ref={myRef}
                    onMouseLeave={() => hideData()}
                  >
                    <button
                      className={style.modal_label}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(true)}
                    >
                      Delete
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {chatStarted
            ? props?.conversations?.map((con) => (
                <div
                  className={
                    con.user_uid_1 == props?.history?.location?.state?.userid
                      ? style.uReceiver
                      : style.rReceiver
                  }
                >
                  <p style={{ marginLeft: "5px" }}>
                    {con.message.split("\n").map(function (item) {
                      return <span style={{ display: "block" }}>{item}</span>;
                    })}
                  </p>
                  <div ref={messagesEndRef} />
                </div>
              ))
            : null}

          {chatStarted ? (
            <div className={style.downcontainer}>
              <div className={style.downleft}></div>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Write Message"
                onKeyPress={(e) => handleKeyPress(e)}
              />

              <div className={style.downright}>
                <img
                  src={sentImg}
                  alt="img"
                  onClick={submitMessage}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </section>
      {props.showDeleteModal && (
        <div className={style.changeDeleteDiv}>
          <Modal2
            cancelDelete={handleCancelDelete}
            confirmDelete={submitDelete}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.authReducer.loader,
    errorMsg: state.authReducer.errorMsg,
    verifyUserListStatus: state.authReducer.verifyUserListStatus,
    userList: state.authReducer.userList,
    conversations: state.fReducer.conversations,
    userDetails: state.authReducer.userDetails,
    showDeleteModal: state.candidateReducer.showDeleteModal,
    showBlur: state.candidateReducer.showBlur,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMessage: (msgObj) => dispatch(actionTypes.updateMessage(msgObj)),
    handleCandidateDeleteModal: (val) =>
      dispatch(actionTypes.handleCandidateDeleteModal(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
