import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import * as actionTypes from "../../Store/Action/index";
import style from "./dashboard.module.css";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../..//GenericComponent/Sidebar/sidebar";
import Icon from "../../assets/Group 18139/Group 18139.png";
import Loader from "../../assets/Loader/spinner.gif";
import defaultImg from "../../assets/defaultimg/Rectangle.png";

function Dashboard(props) {
  const [sidebar, setSidebar] = useState(false);
  const history = useHistory();

  function toShowData(condition, value1, value2) {
    if (condition) {
      return value1;
    } else {
      return value2;
    }
  }

  const toshowLocation = () => {
    if (
      props.profileData.city === null &&
      props.profileData.state !== null &&
      props.profileData.country !== null
    ) {
      return props.profileData.state + "," + props.profileData.country;
    }
    if (
      props.profileData.city !== null &&
      props.profileData.state === null &&
      props.profileData.country !== null
    ) {
      return props.profileData.city + "," + props.profileData.country;
    }
    if (
      props.profileData.city !== null &&
      props.profileData.state !== null &&
      props.profileData.country === null
    ) {
      return props.profileData.city + "," + props.profileData.state;
    } else if (
      props.profileData.city === null &&
      props.profileData.state === null &&
      props.profileData.country !== null
    ) {
      return props.profileData.country;
    } else if (
      props.profileData.city === "" &&
      props.profileData.state === "" &&
      props.profileData.country === ""
    ) {
      return "No Information provided";
    }
    if (
      props.profileData.city === null &&
      props.profileData.state === null &&
      props.profileData.country === null
    ) {
      return "No Information provided";
    } else {
      return (
        props.profileData.city +
        "," +
        props.profileData.state +
        "," +
        props.profileData.country
      );
    }
  };

  useEffect(() => {
    props.getOrgDashboard();
  }, []);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        {props.loader ? (
          <div className={style.loader_style}>
            <img src={Loader} alt="loader" />
          </div>
        ) : (
          <div>
            <div className={style.centered}>
              <div className={style.grid}>
                <div className={style.div2}>
                  <img
                    alt="img"
                    src={toShowData(
                      props.profileData.profileUrl !== null,
                      props.profileData.profileUrl,
                      defaultImg
                    )}
                  />

                  <div className={style.whiteBackground}>
                    <h1>
                      {props.profileData && props.profileData.organisationName}
                    </h1>

                    <p>
                      Location :{toshowLocation()}
                      {/* {props.profileData.city},{props.profileData.state},{props.profileData.country} */}
                    </p>
                  </div>
                </div>

                <div className={style.rows}>
                  <div className={style.div3}>
                    <h1>
                      {toShowData(
                        props.profileData.recruiterCount !== undefined,
                        props.profileData.recruiterCount,
                        "0"
                      )}
                    </h1>
                    <p>Recruiter</p>
                  </div>

                  <div className={style.div4}>
                    <h1>
                      {toShowData(
                        props.profileData.candidateCount !== undefined,
                        props.profileData.candidateCount,
                        "0"
                      )}
                    </h1>
                    <p>Student/ Talent</p>
                  </div>
                  <div className={style.div5}>
                    <h1>
                      {toShowData(
                        props.profileData.activeOpportunityCount !== undefined,
                        props.profileData.activeOpportunityCount,
                        "0"
                      )}
                    </h1>
                    <p>Created Opportunities</p>
                    <div className={style.imgIcon}>
                      <img
                        src={Icon}
                        style={{ width: "63px", height: "61px" }}
                        alt="img"
                        onClick={() => {
                          history.push("/CreatedOpportunities");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.showAlert && (
          <div className={style.changeDiv}>
            <ResponseAlert message={props.recErrorMsg} type="ORGANISATION" />
          </div>
        )}
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.orgReducer.loader,
    profileData: state.orgReducer.orgDashboardData,
    orgErrorMsg: state.orgReducer.orgErrorMsg,
    orgErrorCode: state.orgReducer.orgErrorCode,
    showAlert: state.orgReducer.showAlert,
    showBlur: state.orgReducer.showBlur,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrgDashboard: () => dispatch(actionTypes.getOrgDashboard()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
