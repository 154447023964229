import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cards from "./Cards/cards";
import { connect } from "react-redux";
import * as actionTypes from "../../../../Store/Action/index";
import style from "./student.module.css";
import Header from "../../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../../GenericComponent/Sidebar/sidebar";
import Pagination from "../../../../GenericComponent/Pagination/Pagination";
import Modal from "../../../../GenericComponent/Appliedfilterc/appliedfilterc";
import backarrowImg from "../../../../assets/back arrow/Icon ionic-ios-arrow-round-back.png";
import loader from "../../../../assets/Loader/spinner.gif";

import { BlueButton } from "../../../../GenericComponent/Buttons/button";
import NoData from "../../../../assets/globe.jpg";

function Accepted(props) {
  const [showModal, setshowModal] = useState(false);
  const [showBlur, setShowBlur] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [sidebar, setSidebar] = useState();
  const [noDataVal, setNoDataVal] = useState(false);
  const toShowCards = () => {
    if (props.opp_list && props.opp_list.length > 0) {
      return <Cards data={props.opp_list} />;
    } else {
      if (props.loader) {
        return (
          <div className={style.loader}>
            <img src={loader} alt="loader" />
          </div>
        );
      } else {
        if (noDataVal) {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <p>
                  <b>
                    {" "}
                    No Data Found – Accepted opportunities/scholarships
                    forthcoming
                  </b>
                </p>
                <img
                  className={style.nodata}
                  style={{ width: "50%", height: "60%" }}
                  src={NoData}
                  alt="nodata"
                />
              </div>
            </div>
          );
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNoDataVal(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const history = useHistory();
  const handleRedirect = (path) => {
    history.push(path);
  };

  const toShowPagination = () => {
    if (props.page_no_rec > 1) {
      return (
        <Pagination
          activePage={pageno}
          pageRangeDisplayed={props.page_no_rec}
          onLeftChange={() => onLeftChange()}
          onRightChange={() => onRightChange()}
        />
      );
    }
  };

  const onLeftChange = () => {
    if (pageno !== 1) {
      setPageno(pageno - 1);
    }
  };

  const onRightChange = () => {
    if (pageno !== props.page_no_rec) {
      setPageno(pageno + 1);
    }
  };

  const getDetails = () => {
    props.getAcceptedStudentListByOpportunityId(
      props.location.state.opp_id,
      pageno
    );
  };

  useEffect(() => {
    getDetails();
  }, [pageno]);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section
        className={
          showBlur === true
            ? style.sectionStyle + " " + style.addBlurr
            : style.sectionStyle
        }
      >
        <div>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.heading}>
          <div className={style.backarrow}>
            <img
              src={backarrowImg}
              alt="img"
              onClick={() => handleRedirect("/R_AcceptedStudent")}
              style={{ cursor: "pointer" }}
            ></img>
          </div>

          <div className={style.filter}></div>
        </div>
        <div className={style.centered}>
          {toShowCards()}
          <br />
        </div>
        <div className={style.footer}>{toShowPagination()}</div>
      </section>

      {showModal && (
        <div className={style.changeDiv}>
          <Modal />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    acceptedStudentListErrMsg: state.recruiterReducer.acceptedStudentListErrMsg,
    acceptedStudentlistStatus: state.recruiterReducer.acceptedStudentlistStatus,
    oppErrorMsg: state.recruiterReducer.errorMsg,
    oppErrorCode: state.recruiterReducer.errorCode,
    loader: state.recruiterReducer.loader,
    initial: state.recruiterReducer.initial,

    showAlert: state.recruiterReducer.showAlert,
    showBlur: state.recruiterReducer.showBlur,
    showDropDownModal: state.recruiterReducer.showDropDownModal,
    opp_list: state.recruiterReducer.accepted_opp_list,
    page_no_rec: state.recruiterReducer.page_no,
    showDeleteModal: state.orgReducer.showDeleteModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAcceptedStudentListByOpportunityId: (opportunityId, pageno) =>
      dispatch(
        actionTypes.getAcceptedStudentListByOpportunityId(opportunityId, pageno)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Accepted);
