//Auth Modals
export const UPDATE_AUTH_HIDE_MODAL = "UPDATE_AUTH_HIDE_MODAL";
export const UPDATE_AUTH_SIGNIN_MODAL = "UPDATE_AUTH_SIGNIN_MODAL";
export const UPDATE_AUTH_SIGNUP_MODAL = "UPDATE_AUTH_SIGNUP_MODAL";
export const UPDATE_AUTH_FORGOT_MODAL = "UPDATE_AUTH_FORGOT_MODAL";
export const UPDATE_AUTH_RESET_MODAL = "UPDATE_AUTH_RESET_MODAL";
export const UPDATE_AUTH_OTP_MODAL = "UPDATE_AUTH_OTP_MODAL";
export const UPDATE_AUTH_SIGNUP_OTP_MODAL = "UPDATE_AUTH_SIGNUP_OTP_MODAL";
export const UPDATE_AUTH_CONFIRM_MODAL = "UPDATE_AUTH_CONFIRM_MODAL";
export const UPDATE_AUTH_REJECT_MODAL = "UPDATE_AUTH_REJECT_MODAL";
export const UPDATE_AUTH_RESET_PASSWORD_MODAL =
  "UPDATE_AUTH_RESET_PASSWORD_MODAL";
export const UPDATE_AUTH_SENT_REQUEST_MODAL = "UPDATE_AUTH_SENT_REQUEST_MODAL";
export const UPDATE_ADVANCE_FILTER_MODAL = "UPDATE_ADVANCE_FILTER_MODAL ";
export const UPDATE_REC_ADVANCE_FILTER_MODAL =
  "UPDATE_REC_ADVANCE_FILTER_MODAL";
export const SHOW_BLUR = "SHOW_BLUR";
export const SHOW_ALERT = "SHOW_ALERT";
export const LOGOUT_INITIAL = "LOGOUT_INITIAL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const UPDATE_LOADER = "UPDATE_LOADER";

export const UPDATE_REC_CREATION_MODAL = "UPDATE_REC_CREATION_MODAL";
export const UPDATE_DELETE_MODAL = "UPDATE_DELETE_MODAL";

export const CLOSE_RESPONSE_ALERT = "CLOSE_RESPONSE_ALERT";
export const CLOSE_CANDIDATE_RESPONSE_ALERT = "CLOSE_CANDIDATE_RESPONSE_ALERT";
export const CLOSE_RECRUITER_RESPONSE_ALERT = "CLOSE_RECRUITER_RESPONSE_ALERT";
export const CLOSE_ORG_RESPONSE_ALERT = "CLOSE_ORG_RESPONSE_ALERT";

export const GET_DETAILS_INITIAL = "GET_DETAILS_INITIAL";
export const GET_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
export const GET_DETAILS_FAIL = "GET_DETAILS_FAIL";
//**************** */
export const LOGOUT_ALERT_INITIAL = "LOGOUT_ALERT_INITIAL";
export const LOGOUT_ALERT_SUCCESS = "LOGOUT_ALERT_SUCCESS";

//Login Actions
export const LOGIN_INITIAL = "LOGIN_INITIAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CLEAR_LOGIN_STATE = "CLEAR_LOGIN_STATE";
//RegisterFCMToken
export const REGISTER_FCMTOKEN_INITIAL = "REGISTER_FCMTOKEN_INITIAL";
export const REGISTER_FCMTOKEN_SUCCESS = "REGISTER_FCMTOKEN_SUCCESS";
export const REGISTER_FCMTOKEN_FAIL = "REGISTER_FCMTOKEN_FAIL";
//Login Actions
export const VERIFY_EMAIL_INITIAL = "VERIFY_EMAIL_INITIAL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAIL = "VERIFY_EMAIL_FAIL";
export const VERIFY_EMAIL_NETWORK_ERROR = "VERIFY_EMAIL_NETWORK_ERROR";

//Student Sign Up Actions
export const STUDENT_SIGNUP_INITIAL = "STUDENT_SIGNUP_INITIAL";
export const STUDENT_SIGNUP_SUCCESS = "STUDENT_SIGNUP_SUCCESS";
export const STUDENT_SIGNUP_FAIL = "STUDENT_SIGNUP_FAIL";

//Org Sign Up Actions
export const ORG_SIGNUP_INITIAL = "ORG_SIGNUP_INITIAL";
export const ORG_SIGNUP_SUCCESS = "ORG_SIGNUP_SUCCESS";
export const ORG_SIGNUP_FAIL = "ORG_SIGNUP_FAIL";

//Recruiter Sign Up Actions
export const RECRUITER_SIGNUP_INITIAL = "RECRUITER_SIGNUP_INITIAL";
export const RECRUITER_SIGNUP_SUCCESS = "RECRUITER_SIGNUP_SUCCESS";
export const RECRUITER_SIGNUP_FAIL = "RECRUITER_SIGNUP_FAIL";

//Resend SignUp Email Actions
export const RESEND_SIGNUP_EMAIL_INITIAL = "RESEND_SIGNUP_EMAIL_INITIAL";
export const RESEND_SIGNUP_EMAIL_SUCCESS = "RESEND_SIGNUP_EMAIL_SUCCESS";
export const RESEND_SIGNUP_EMAIL_FAIL = "RESEND_SIGNUP_EMAIL_FAIL";

//
export const HANDLE_DROPDOWN_MODAL = "HANDLE_DROPDOWN_MODAL";
export const CLOSE_DROPDOWN_MODAL = "CLOSE_DROPDOWN_MODAL";

//Recruiter List Actions
export const RECRUITER_LIST_INITIAL = "RECRUITER_LIST_INITIAL";
export const RECRUITER_LIST_SUCCESS = "RECRUITER_LIST_SUCCESS";
export const RECRUITER_LIST_FAIL = "RECRUITER_LIST_FAIL";

//Recruiter Profile Actions
export const RECRUITER_PROFILE_INITIAL = "RECRUITER_PROFILE_INITIAL";
export const RECRUITER_PROFILE_SUCCESS = "RECRUITER_PROFILE_SUCCESS";
export const RECRUITER_PROFILE_FAIL = "RECRUITER_PROFILE_FAIL";

//Recruiter Delete Actions
export const DELETE_RECRUITER_PROFILE_INITIAL =
  "DELETE_RECRUITER_PROFILE_INITIAL";
export const DELETE_RECRUITER_PROFILE_SUCCESS =
  "DELETE_RECRUITER_PROFILE_SUCCESS";
export const DELETE_RECRUITER_PROFILE_FAIL = "DELETE_RECRUITER_PROFILE_FAIL";

//Recruiter Update Actions
export const UPDATE_RECRUITER_PROFILE_INITIAL =
  "UPDATE_RECRUITER_PROFILE_INITIAL";
export const UPDATE_RECRUITER_PROFILE_SUCCESS =
  "UPDATE_RECRUITER_PROFILE_SUCCESS";
export const UPDATE_RECRUITER_PROFILE_FAIL = "UPDATE_RECRUITER_PROFILE_FAIL";

//Recruiter Update Status Actions
export const UPDATE_RECRUITER_STATUS_INITIAL =
  "UPDATE_RECRUITER_STATUS_INITIAL";
export const UPDATE_RECRUITER_STATUS_SUCCESS =
  "UPDATE_RECRUITER_STATUS_SUCCESS";
export const UPDATE_RECRUITER_STATUS_FAIL = "UPDATE_RECRUITER_STATUS_FAIL";

//Recruiter  Create Opportunity
export const CREATE_RECRUITER_OPPO_INITIAL = "CREATE_RECRUITER_OPPO_INITIAL";
export const CREATE_RECRUITER_OPPO_SUCCESS = "CREATE_RECRUITER_OPPO_SUCCESS";
export const CREATE_RECRUITER_OPPO_FAIL = "CREATE_RECRUITER_OPPO_FAIL";

//Recruiter  Delete Opportunity
export const DUPLICATE_RECRUITER_OPPO_INITIAL =
  "DUPLICATE_RECRUITER_OPPO_INITIAL";
export const DUPLICATE_RECRUITER_OPPO_SUCCESS =
  "DUPLICATE_RECRUITER_OPPO_SUCCESS";
export const DUPLICATE_RECRUITER_OPPO_FAIL = "DUPLICATE_RECRUITER_OPPO_FAIL";

//Category List Actions
export const CATEGORY_LIST_INITIAL = "CATEGORY_LIST_INITIAL";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

//RECRUITER ADD SKILLS
export const SUB_CATEGORY_LIST_INITIAL = "SUB_CATEGORY_LIST_INITIAL";
export const SUB_CATEGORY_LIST_SUCCESS = "SUB_CATEGORY_LIST_SUCCESS";
export const SUB_CATEGORY_LIST_FAIL = "SUB_CATEGORY_LIST_FAIL";

//***********************************************ORG ACTION********************************************* */
//Org Dashboard Actions
export const ORG_DASHBOARD_INITIAL = "ORG_DASHBOARD_INITIAL";
export const ORG_DASHBOARD_SUCCESS = "ORG_DASHBOARD_SUCCESS";
export const ORG_DASHBOARD_FAIL = "ORG_DASHBOARD_FAIL";
//Org profile Actions
export const ORG_PROFILE_INITIAL = "ORG_PROFILE_INITIAL";
export const ORG_PROFILE_SUCCESS = "ORG_PROFILE_SUCCESS";
export const ORG_PROFILE_FAIL = "ORG_PROFILE_FAIL";

//Update Org profile Actions
export const UPDATE_ORG_PROFILE_INITIAL = "UPDATE_ORG_PROFILE_INITIAL";
export const UPDATE_ORG_PROFILE_SUCCESS = "UPDATE_ORG_PROFILE_SUCCESS";
export const UPDATE_ORG_PROFILE_FAIL = "UPDATE_ORG_PROFILE_FAIL";

//********************************************CANDIDATE ACTIONS************************************ */
//Candidate Profile Actions
export const CANDIDATE_DASHBOARD_INITIAL = "CANDIDATE_DASHBOARD_INITIAL";
export const CANDIDATE_DASHBOARD_SUCCESS = "CANDIDATE_DASHBOARD_SUCCESS";
export const CANDIDATE_DASHBOARD_FAIL = "CANDIDATE_DASHBOARD_FAIL";

//Candidate Profile Actions
export const CANDIDATE_PROFILE_INITIAL = "CANDIDATE_PROFILE_INITIAL";
export const CANDIDATE_PROFILE_SUCCESS = "CANDIDATE_PROFILE_SUCCESS";
export const CANDIDATE_PROFILE_FAIL = "CANDIDATE_PROFILE_FAIL";

//Update Candidate profile Actions
export const UPDATE_CANDIDATE_PROFILE_INITIAL =
  "UPDATE_CANDIDATE_PROFILE_INITIAL";
export const UPDATE_CANDIDATE_PROFILE_SUCCESS =
  "UPDATE_CANDIDATE_PROFILE_SUCCESS";
export const UPDATE_CANDIDATE_PROFILE_FAIL = "UPDATE_CANDIDATE_PROFILE_FAIL";

//Get Candidate Skills
export const GET_CANDIDATE_SKILL_INITIAL = "GET_CANDIDATE_SKILL_INITIAL";
export const GET_CANDIDATE_SKILL_SUCCESS = "GET_CANDIDATE_SKILL_SUCCESS";
export const GET_CANDIDATE_SKILL_FAIL = "GET_CANDIDATE_SKILL_FAIL";

//Add Candidate Skills
export const ADD_CANDIDATE_SKILL_INITIAL = "ADD_CANDIDATE_SKILL_INITIAL";
export const ADD_CANDIDATE_SKILL_SUCCESS = "ADD_CANDIDATE_SKILL_SUCCESS";
export const ADD_CANDIDATE_SKILL_FAIL = "ADD_CANDIDATE_SKILL_FAIL";

// ForgotPassword
export const GENERATE_PASSWORD_RESET_TOKEN_INITIAL =
  "GENERATE_PASSWORD_RESET_TOKEN_INITIAL";
export const GENERATE_PASSWORD_RESET_TOKEN_SUCCESS =
  "GENERATE_PASSWORD_RESET_TOKEN_SUCCESS";
export const GENERATE_PASSWORD_RESET_TOKEN_FAIL =
  "GENERATE_PASSWORD_RESET_TOKEN_FAIL";
export const CLEAR_GENERATE_PASSWORD_RESET_TOKEN_STATE =
  "CLEAR_GENERATE_PASSWORD_RESET_TOKEN_STATE";
// OtpValidate
export const VALIDATE_OTP_RESET_PASSWORD_INITIAL =
  "VALIDATE_OTP_RESET_PASSWORD_INITIAL";
export const VALIDATE_OTP_RESET_PASSWORD_SUCCESS =
  "VALIDATE_OTP_RESET_PASSWORD_SUCCESS";
export const VALIDATE_OTP_RESET_PASSWORD_FAIL =
  "VALIDATE_OTP_RESET_PASSWORD_FAIL";
export const VALIDATE_OTP_RESET_PASSWORD_NETWORK_ERROR =
  "VALIDATE_OTP_RESET_PASSWORD_NETWORK_ERROR";
//ResetPasswordEmailOtp
export const RESET_PASSWORD_EMAIL_OTP_INITIAL =
  "RESET_PASSWORD_EMAIL_OTP_INITIAL";
export const RESET_PASSWORD_EMAIL_OTP_SUCCESS =
  "RESET_PASSWORD_EMAIL_OTP_SUCCESS";
export const RESET_PASSWORD_EMAIL_OTP_FAIL = "RESET_PASSWORD_EMAIL_OTP_FAIL";
export const RESET_PASSWORD_EMAIL_OTP_NETWORK_ERROR =
  "RESET_PASSWORD_EMAIL_OTP_NETWORK_ERROR";

// velidateOtp for Email Verification
export const VALIDATE_OTP_EMAIL_VERIFICATION_INITIAL =
  "VALIDATE_OTP_EMAIL_VERIFICATION_INITIAL";
export const VALIDATE_OTP_EMAIL_VERIFICATION_SUCCESS =
  "VALIDATE_OTP_EMAIL_VERIFICATION_SUCCESS";
export const VALIDATE_OTP_EMAIL_VERIFICATION_FAIL =
  "VALIDATE_OTP_EMAIL_VERIFICATION_FAIL";
export const VALIDATE_OTP_EMAIL_VERIFICATION_NETWORK_ERROR =
  "VALIDATE_OTP_EMAIL_VERIFICATION_NETWORK_ERROR";

// Resnd Otp
export const RESEND_OTP_FOR_EMAIL_VERIFICATION_INITIAL =
  "RESEND_OTP_FOR_EMAIL_VERIFICATION_INITIAL";
export const RESEND_OTP_FOR_EMAIL_VERIFICATION_SUCCESS =
  "RESEND_OTP_FOR_EMAIL_VERIFICATION_SUCCESS";
export const RESEND_OTP_FOR_EMAIL_VERIFICATION_FAIL =
  "RESEND_OTP_FOR_EMAIL_VERIFICATION_FAIL";
export const RESEND_OTP_FOR_EMAIL_VERIFICATION_NETWORK_ERROR =
  "RESEND_OTP_FOR_EMAIL_VERIFICATION_NETWORK_ERROR";

//OPPORTUNITY CANDIDATE LIST
export const OPPO_CANDIDATE_LIST_INITIAL = "OPPO_CANDIDATE_LIST_INITIAL";
export const OPPO_CANDIDATE_LIST_SUCCESS = "OPPO_CANDIDATE_LIST_SUCCESS";
export const OPPO_CANDIDATE_LIST_FAIL = "OPPO_CANDIDATE_LIST_FAIL";

//OPPORTUNITY RECRUITER LIST
export const OPPO_RECRUITER_LIST_INITIAL = "OPPO_RECRUITER_LIST_INITIAL";
export const OPPO_RECRUITER_LIST_SUCCESS = "OPPO_RECRUITER_LIST_SUCCESS";
export const OPPO_RECRUITER_LIST_FAIL = "OPPO_RECRUITER_LIST_FAIL";

//Get Opportunity_Details
export const GET_OPPORTUNITY_DETAILS_INITIAL =
  "GET_OPPORTUNITY_DETAILS_INITIAL";
export const GET_OPPORTUNITY_DETAILS_SUCCESS =
  "GET_OPPORTUNITY_DETAILS_SUCCESS";
export const GET_OPPORTUNITY_DETAILS_FAIL = "GET_OPPORTUNITY_DETAILS_FAIL";

export const OPEN_SKILL_MODAL = "OPEN_SKILL_MODAL";
export const CLOSE_SKILL_MODAL = "CLOSE_SKILL_MODAL";

//for EditOpportunity  **********
export const GET_DETAILS_FOR_EDIT_INITIAL = "GET_DETAILS_FOR_EDIT_INITIAL";
export const GET_DETAILS_FOR_EDIT_SUCCESS = "GET_DETAILS_FOR_EDIT_SUCCESS";
export const GET_DETAILS_FOR_EDIT_FAIL = "GET_DETAILS_FOR_EDIT_FAIL";

// deleteRecruiterOpportunity
export const DELETE_OPPORTUNITY_INITIAL = "DELETE_OPPORTUNITY_INITIAL";
export const DELETE_OPPORTUNITY_SUCCESS = "DELETE_OPPORTUNITY_SUCCESS";
export const DELETE_OPPORTUNITY_FAIL = "DELETE_OPPORTUNITY_FAIL";

// updateStatus for opportunity
export const UPDATE_OPPORTUNITY_STATUS_INITIAL =
  "UPDATE_OPPORTUNITY_STATUS_INITIAL";
export const UPDATE_OPPORTUNITY_STATUS_SUCCESS =
  "UPDATE_OPPORTUNITY_STATUS_SUCCESS";
export const UPDATE_OPPORTUNITY_STATUS_FAIL = "UPDATE_OPPORTUNITY_STATUS_FAIL";

// updateStatus for organisation opportunity
export const UPDATE_ORG_OPPORTUNITY_STATUS_INITIAL =
  "UPDATE_ORG_OPPORTUNITY_STATUS_INITIAL";
export const UPDATE_ORG_OPPORTUNITY_STATUS_FAIL =
  "UPDATE_ORG_OPPORTUNITY_STATUS_FAIL";

//Add Update Candidate Skills
export const ADD_UPDATE_CANDIDATE_SKILL_INITIAL =
  "ADD_UPDATE_CANDIDATE_SKILL_INITIAL";
export const ADD_UPDATE_CANDIDATE_SKILL_SUCCESS =
  "ADD_UPDATE_CANDIDATE_SKILL_SUCCESS";
export const ADD_UPDATE_CANDIDATE_SKILL_FAIL =
  "ADD_UPDATE_CANDIDATE_SKILL_FAIL";

//Delete Candidate Skills
export const DELETE_CANDIDATE_SKILL_INITIAL = "DELETE_CANDIDATE_SKILL_INITIAL";
export const DELETE_CANDIDATE_SKILL_SUCCESS = "DELETE_CANDIDATE_SKILL_SUCCESS";
export const DELETE_CANDIDATE_SKILL_FAIL = "DELETE_CANDIDATE_SKILL_FAIL";

export const UPDATE_CANDIDATE_DELETE_MODAL = "UPDATE_CANDIDATE_DELETE_MODAL";
export const UPDATE_RECRUITER_DELETE_MODAL = "UPDATE_RECRUITER_DELETE_MODAL";

//OPPORTUNITY ORGANISATION LIST
export const OPPO_ORGANISATION_LIST_INITIAL = "OPPO_ORGANISATION_LIST_INITIAL";
export const OPPO_ORGANISATION_LIST_SUCCESS = "OPPO_ORGANISATION_LIST_SUCCESS";
export const OPPO_ORGANISATION_LIST_FAIL = "OPPO_ORGANISATION_LIST_FAIL";

export const DELETE_SKILL_MEDIA_INITIAL = "DELETE_SKILL_MEDIA_INITIAL";
export const DELETE_SKILL_MEDIA_FAIL = "DELETE_SKILL_MEDIA_FAIL";

// getAppliedStudentList
export const GET_APPLIED_STUDENT_LIST_INITIAL =
  "GET_APPLIED_STUDENT_LIST_INITIAL";
export const GET_APPLIED_STUDENT_LIST_SUCCESS =
  "GET_APPLIED_STUDENT_LIST_SUCCESS";
export const GET_APPLIED_STUDENT_LIST_FAIL = "GET_APPLIED_STUDENT_LIST_FAIL";
// forgotPAssword
export const FORGOT_PASSWORD_INITIAL = "FORGOT_PASSWORD_INITIAL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const CLEAR_FORGOT_PASSWORD_STATE = "CLEAR_FORGOT_PASSWORD_STATE";

// =======APPLYONOPPORTUNITY API====================

export const APPLY_ON_OPPORTUNITY_INITIAL = "APPLY_ON_OPPORTUNITY_INITIAL";
export const APPLY_ON_OPPORTUNITY_SUCCESS = "APPLY_ON_OPPORTUNITY_SUCCESS";
export const APPLY_ON_OPPORTUNITY_FAIL = "APPLY_ON_OPPORTUNITY_FAIL";
export const APPLY_ON_OPPORTUNITY_NETWORK_ERROR = "APPLY_ON_OPPORTUNITY_ERROR";

// -------------------------GetAcceptedOpportunitiesList-----------------------
export const OPPORTUNITIES_LIST_INITIAL = "OPPORTUNITIES_LIST_INITIAL";
export const OPPORTUNITIES_LIST_SUCCESS = "OPPORTUNITIES_LIST_SUCCESS";
export const OPPORTUNITIES_LIST_FAIL = "OPPORTUNITIES_LIST_FAIL";
//acceptedRejectedAppliedOpportunity
export const ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_INITIAL =
  "ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_INITIAL";
export const ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_SUCCESS =
  "ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_SUCCESS";
export const ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_FAIL =
  "ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_FAIL";
//getStudentProfileByProfileId
export const GET_STUDENT_PROFILE_BY_PROFILEID_INITIAL =
  "GET_STUDENT_PROFILE_BY_PROFILEID_INITIAL";
export const GET_STUDENT_PROFILE_BY_PROFILEID_SUCCESS =
  "GET_STUDENT_PROFILE_BY_PROFILEID_SUCCESS";
export const GET_STUDENT_PROFILE_BY_PROFILEID_FAIL =
  "GET_STUDENT_PROFILE_BY_PROFILEID_FAIL";
// -------------------------getStudentAppliedOpportunityList----------------------
export const GET_STUDENT_APPLIED_OPP_INITIAL =
  "GET_STUDENT_APPLIED_OPP_INITIAL";
export const GET_STUDENT_APPLIED_OPP_SUCCESS =
  "GET_STUDENT_APPLIED_OPP_SUCCESS";
export const GET_STUDENT_APPLIED_OPP_FAIL = "GET_STUDENT_APPLIED_OPP_FAIL";
// sentRequest
export const SENT_REQUEST_INITIAL = "SENT_REQUEST_INITIAL";
export const SENT_REQUEST_SUCCESS = "SENT_REQUEST_SUCCESS";
export const SENT_REQUEST_FAIL = "SENT_REQUEST_FAIL";

//serachStudnetListbyRecruiter
export const SEARCH_STUDENT_LIST_BY_RERUITER_INITIAL =
  "SEARCH_STUDENT_LIST_BY_RERUITER_INITIAL";
export const SEARCH_STUDENT_LIST_BY_RERUITER_SUCCESS =
  "SEARCH_STUDENT_LIST_BY_RERUITER_SUCCESS";
export const SEARCH_STUDENT_LIST_BY_RERUITER_FAIL =
  "SEARCH_STUDENT_LIST_BY_RERUITER_FAIL";

// ///////////////////////////////////////////

export const COUNTRY_LIST_INITIAL = "COUNTRY_LIST_INITIAL";
export const COUNTRY_LIST_SUCCESS = "COUNTRY_LIST_SUCCESS";
export const COUNTRY_LIST_FAIL = "COUNTRY_LIST_FAIL";
// //////////////////////////////////////////////////////////////////////////
export const CANDIDATE_NOTIFICATION_INITIAL = "CANDIDATE_NOTIFICATION_INITIAL";
export const CANDIDATE_NOTIFICATION_SUCCESS = "CANDIDATE_NOTIFICATION_SUCCESS";
export const CANDIDATE_NOTIFICATION_FAIL = "CANDIDATE_NOTIFICATION_FAIL";
// ///////////////////////////////////////////////////////////////////////////
export const STATE_LIST_INITIAL = "STATE_LIST_INITIAL";
export const STATE_LIST_SUCCESS = "STATE_LIST_SUCCESS";
export const STATE_LIST_FAIL = "STATE_LIST_FAIL";

// ///////////////////////////////////////////////////////////////////////////////
export const CITY_LIST_INITIAL = "CITY_LIST_INITIAL";
export const CITY_LIST_SUCCESS = "CITY_LIST_SUCCESS";
export const CITY_LIST_FAIL = "CITY_LIST_FAIL";

export const GET_ACCEPT_OPP_CATEGORY_INITIAL =
  "GET_ACCEPT_OPP_CATEGORY_INITIAL";
export const GET_ACCEPT_OPP_CATEGORY_SUCCESS =
  "GET_ACCEPT_OPP_CATEGORY_SUCCESS";
export const GET_ACCEPT_OPP_CATEGORY_FAIL = "GET_ACCEPT_OPP_CATEGORY_FAIL";

export const GET_ACCEPT_OPP_CATEGORY_ORG_INITIAL =
  "GET_ACCEPT_OPP_CATEGORY_ORG_INITIAL";
export const GET_ACCEPT_OPP_CATEGORY_ORG_SUCCESS =
  "GET_ACCEPT_OPP_CATEGORY_ORG_SUCCESS";
export const GET_ACCEPT_OPP_CATEGORY_ORG_FAIL =
  "GET_ACCEPT_OPP_CATEGORY_ORG_FAIL";

export const USER_LIST_INITIAL = "USER_LIST_INITIAL";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
///////////////////////////////////
export const GET_STUDENT_PROFILE_BY_PROFILE_INITIAL =
  "GET_STUDENT_PROFILE_BY_PROFILE_INITIAL";
export const GET_STUDENT_PROFILE_BY_PROFILE_SUCCESS =
  "GET_STUDENT_PROFILE_BY_PROFILE_SUCCESS";
export const GET_STUDENT_PROFILE_BY_PROFILE_FAIL =
  "GET_STUDENT_PROFILE_BY_PROFILE_FAIL";

//////////////////////////////////////
export const GET_NOTIFICATION_COUNT_SUCCESS = "GET_NOTIFICATION_COUNT_SUCCESS";
export const GET_NOTIFICATION_COUNT_FAIL = "GET_NOTIFICATION_COUNT_FAIL";
