import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as actionTypes from "../../../Store/Action/index";
import { connect } from "react-redux";

import style from "./RejectModal.module.css";

import closeIcon from "../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import {
  BlueButton,
  WhiteButton,
} from "../../../GenericComponent/Buttons/button";
import Loader from "../../../assets/Loader/spinner.gif";

export default function Confirm(props) {
  const history = useHistory();
  const [status, setStatus] = useState(
    `We are honored that you showed interest in us.  Unfortunately, you have not been selected for the ${props?.profileData?.opportunityTitle} at ${props?.profileData?.orgName}. Thank You.`
  );
  const [showErr, setShowErr] = useState(false);

  const cancleModal = () => {
    props.handleModal(false);
  };

  const submit = () => {
    props.handleModal(false);
    props.submitReject(status);
    history.push({
      state: {
        OpportunityId: props.OpportunityId,
      },
    });
  };

  return (
    <div className={style.container}>
      {props.loader ? (
        <div className="loader_styles">
          <img src={Loader} alt="loader/" />
        </div>
      ) : (
        <>
          <img
            src={closeIcon}
            className={style.closeModal}
            onClick={cancleModal}
            alt="img"
          />
          <div style={{ marginTop: "-5%" }}>
            <p> Student/Talent Rejection </p>
          </div>
          <div>
            <div className={style.msg2}>
              <p style={{ opacity: "1", marginTop: "-4%" }}>Feedback</p>
              <textarea
                className={style.input}
                value={status}
                maxLength="250"
                onChange={(e) => setStatus(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "5%", marginLeft: "20%" }}>
              <WhiteButton children="Cancel" onClick={cancleModal} />
              <BlueButton children="Reject" onClick={submit} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
