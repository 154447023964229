import React from "react";
import style from "./cards.module.css";
import { useHistory } from "react-router-dom";
import defaultImg from "../../../../../assets/defaultimg/Rectangle 5337@2x.png";

function Cards(props) {
  const history = useHistory();
  function showSkillData(condition, val1, val2) {
    if (condition) {
      return val1.join(" , ");
    } else {
      return val1;
    }
  }
  const handleRedirect = (path, oppid) => {
    history.push({
      pathname: path,
      state: {
        opp_id: oppid,
      },
    });
  };

  return (
    <>
      {props.data && props.data.length > 0
        ? props.data.map((item, index) => (
            <div
              className={style.container}
              onClick={() =>
                handleRedirect("/Org_AcceptedList", item.opportunityId)
              }
            >
              <div className={style.card}>
                <div className={style.middle}>
                  <table className={style.table1}>
                    <tbody>
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.value}>
                          {item.opportunityTitle}
                        </div>
                      </div>
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.value1}>
                          {item.totalAcceptedStudents}&nbsp; Students/Talents{" "}
                        </div>
                      </div>
                    </tbody>
                  </table>
                </div>
                <div className={style.right}>
                  <table className={style.right_table1}>
                    <tbody>
                      <div className={style.left}>
                        <img
                          src={
                            item && item.oppImageUrl !== null
                              ? item.oppImageUrl
                              : defaultImg
                          }
                          alt="img"
                        />
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))
        : null}
    </>
  );
}

export default Cards;
