import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { getToken, onMessageListener } from "../../firebaseInit";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import Backdroplog from "../../GenericComponent/Backdrop/Backdrop";
import SignUpModal from "../Homepage/components/SignUp/signup";
import LoginModal from "../Homepage/components/Login/login";
import OTPModal from "../Homepage/components/OTPScreen/OTPScreen";
import SignUpOTPModal from "../Homepage/components/SignUpOTPScreen/SignupOTPScreen";
import ResetPasswordModal from "../Homepage/components/ResetPassword/ResetPassword";
import ForgotPassModal from "../Homepage/components/ForgotPassword/forgotpassword";
import ConfirmModal from "../Homepage/components/confirm";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import style from "./Privacypolicy.module.css";
import Header from "../../GenericComponent/header/header";
import { BlueButton, WhiteButton } from "../../GenericComponent/Buttons/button";
import backgroundImg from "../../assets/globe.jpg";

import Loader from "../../assets/Loader/spinner.gif";

function Privacypolicy(props) {
  const history = useHistory();
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);
  const [forgetEmail, setForgetEmail] = useState("");
  const [SsignupEmail, setSSignupEmail] = useState("");
  const [SsignupPassword, setSSignupPassword] = useState("");
  const [OsignupEmail, setOSignupEmail] = useState("");
  const [OsignupPassword, setOSignupPassword] = useState("");
  const [forgotOtp, setForgotOtp] = useState("");
  const [ip, setIP] = useState("");
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, tokenFound] = useState("");
  getToken(setTokenFound).then((message) => tokenFound(message));
  localStorage.setItem("token", 9000);
  localStorage.setItem("fcmtoken", token);

  function showButton() {
    if (props.showSignUpModal) {
      return (
        <BlueButton
          children="Sign Up Now!"
          onClick={props.SignUp}
          className={style.centered_button}
        />
      );
    } else {
      return (
        <WhiteButton
          children="Sign Up Now!"
          onClick={props.SignUp}
          className={style.centered_button}
        />
      );
    }
  }

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header
          showSignUpModal={props.showSignUpModal}
          showSignInModal={props.showSignInModal}
          sidebar={setSidebar}
          side={sidebar}
        />
      </header>
      {props.loader ? (
        <div className={style.loader_style}>
          <img alt="loader" src={Loader} />
        </div>
      ) : (
        <div>
          <Backdroplog show={props.showBlur} />
          <div className={style.hero}>
            <div
              className={style.left}
              style={{ overflow: "scroll", height: "83vh" }}
            >
              <p>
                {" "}
                This Privacy Policy governs the manner in which I got game 2
                collects, uses, maintains, and discloses information collected
                from users (each, a "User") of the https://igotgame2.com/
                website and/or mobile application. This privacy policy applies
                to the Site and all products and services offered by I got game
                2.
              </p>
              <h4>Information We Collect</h4>
              <p>
                We may collect personal identification information from Users in
                a variety of ways, including, but not limited to, when Users
                visit our site, register on the site, fill out a form, and in
                connection with other activities, services, features, or
                resources we make available on our Site. Users may be asked for,
                as appropriate, name, email address, mailing address, phone
                number, and other personal information. Users may, however,
                visit our Site anonymously. We will collect personal
                identification information from Users only if they voluntarily
                submit such information to us. Users can always refuse to supply
                personally identification information, except that it may
                prevent them from engaging in certain Site-related activities.
              </p>
              <p>
                We may also collect non-personal identification information
                about Users whenever they interact with our Site. Non-personal
                identification information may include the browser name, the
                type of computer or mobile device, and technical information
                about Users' means of connection to our Site, such as the
                operating system and the Internet service providers utilized and
                other similar information.
              </p>
              <h4>Web Browser Cookies</h4>
              <p>
                Our Site may use "cookies" to enhance User experience. A User's
                web browser places cookies on their hard drive for
                record-keeping purposes and sometimes to track information about
                them. Users may choose to set their web browser to refuse
                cookies or to alert them when cookies are being sent. If they do
                so, note that some parts of the Site may not function properly.
              </p>
              <h4>How We Use Collected Information</h4>
              <p>
                We may collect and use Users' personal information for the
                following purposes:
              </p>
              <div className={style.row}>
                <ul>
                  <li>To personalize user experience</li>
                  <li>To improve our Site</li>
                  <li>To send periodic emails</li>
                  <li>
                    To facilitate communication between Users and recruiters
                  </li>
                  <li>To match candidates with job opportunities</li>
                </ul>
              </div>
              <h4>How We Protect Your Information</h4>
              <p>
                We adopt appropriate data collection, storage, and processing
                practices and security measures to protect against unauthorized
                access, alteration, disclosure, or destruction of your personal
                information, username, password, transaction information, and
                data stored on our Site.
              </p>
              <h4>Sharing Your Personal Information</h4>
              <p>
                We do not sell, trade, or rent Users' personal identification
                information to others. We may share generic aggregated
                demographic information not linked to any personal
                identification information regarding visitors and users with our
                business partners, trusted affiliates, and advertisers for the
                purposes outlined above.
              </p>
              <h4>Changes to This Privacy Policy</h4>
              <p>
                We have the discretion to update this privacy policy at any
                time. When we do, we will revise the updated date at the bottom
                of this page. We encourage Users to frequently check this page
                for any changes to stay informed about how we are helping to
                protect the personal information we collect.
              </p>
              <h4>Your Acceptance of These Terms</h4>
              <p>
                By using this Site, you signify your acceptance of this policy.
                If you do not agree to this policy, please do not use our Site.
                Your continued use of the Site following the posting of changes
                to this policy will be deemed your acceptance of those changes.
              </p>
              <h4>Contacting Us</h4>
              <p>
                If you have any questions about this Privacy Policy, the
                practices of this Site, or your dealings with this Site, please
                contact us at Letsgo@igotgame2.com.
              </p>
            </div>
            <div className={style.right}>
              <img src={backgroundImg} alt="hero-img" />
            </div>
          </div>
        </div>
      )}
      {props.showSignInModal && (
        <div className={style.changeDiv}>
          <LoginModal
            handleForgotPasswordModal={props.handleForgotPasswordModal}
            ResetPasswordModal={props.showResetPasswordModal}
            // getData={getData}
          />
        </div>
      )}

      {props.showSignUpModal && (
        <div className={style.changeDiv}>
          <SignUpModal
            setSSignupEmail={setSSignupEmail}
            setSSignupPassword={setSSignupPassword}
            setOSignupPassword={setOSignupPassword}
            setOSignupEmail={setOSignupEmail}
          />
        </div>
      )}

      {props.showForgotPassModal && (
        <div className={style.changeDiv}>
          <ForgotPassModal
            showResetPasswordModal={props.showResetPasswordModal}
            ResetPasswordModal={props.ResetPasswordModal}
            hideModal={props.hideModal}
            setForgetEmail={setForgetEmail}
          />
        </div>
      )}

      {props.showOTPModal && (
        <div className={style.changeDiv}>
          <OTPModal
            OTPModal={props.showOTPModal}
            forgetEmail={forgetEmail}
            forgotPassword={props.forgotPassword}
            hideModal={props.hideModal}
            handleForgotPasswordModal={props.handleForgotPasswordModal}
            showResetPasswordModal={props.showResetPasswordModal}
            setForgotOtp={setForgotOtp}
            ErrorCode={props.ErrorCode}
          />
        </div>
      )}

      {props.showSignupOTPModal && (
        <div className={style.changeDiv}>
          <SignUpOTPModal
            OTPModal={props.showSignupOTPModal}
            SsignupEmail={SsignupEmail}
            forgotPassword={props.forgotPassword}
            hideModal={props.hideModal}
            SsignupPassword={SsignupPassword}
            OsignupEmail={OsignupEmail}
            OsignupPassword={OsignupPassword}
            setForgotOtp={setForgotOtp}
            ErrorCode={props.ErrorCode}
          />
        </div>
      )}

      {props.showResetPasswordModal && (
        <div className={style.changeDiv}>
          <ResetPasswordModal
            ResetPasswordModal={props.showResetPasswordModal}
            OTPModal={props.showOTPModal}
            forgetEmail={forgetEmail}
            hideModal={props.hideModal}
            forgotOtp={forgotOtp}
          />
        </div>
      )}

      {props.showConfirmModal && (
        <div className={style.changeDiv1}>
          <ConfirmModal
            hideModal={props.hideModal}
            confirm={props.confirm}
            OTPModal={props.OTPModal}
          />
        </div>
      )}

      {props.showAlert && (
        <div className={style.changeDiv2}>
          <ResponseAlert message={props.errorMsg} />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    signUpStatus: state.authReducer.signUpStatus,
    errorMsg: state.authReducer.errorMsg,
    loader: state.authReducer.loader,
    initial: state.authReducer.initial,
    ErrorCode: state.authReducer.ErrorCode,
    showAlert: state.authReducer.showAlert,
    resetPasswordSuccMsg: state.authReducer.resetPasswordSuccMsg,
    emailverifySuccMsg: state.authReducer.emailverifySuccMsg,
    showConfirmModal: state.authReducer.showConfirmModal,

    showForgotPassModal: state.authReducer.showForgotPassModal,
    showSignUpModal: state.authReducer.showSignUpModal,
    showSignInModal: state.authReducer.showSignInModal,
    confirm: state.authReducer.confirm,
    showBlur: state.authReducer.showBlur,
    showOTPModal: state.authReducer.showOTPModal,
    showSignupOTPModal: state.authReducer.showSignupOTPModal,
    showResetPasswordModal: state.authReducer.showResetPasswordModal,
    newuserEmailid: state.authReducer.newuserEmailid,
    FCMStatus: state.authReducer.FCMStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SignIn: () => dispatch(actionTypes.handleSignInModal()),
    SignUp: () => dispatch(actionTypes.handleSignUpModal()),
    OTPModal: () => dispatch(actionTypes.handleOTPModal()),
    handleSignupOTPModal: () => dispatch(actionTypes.handleSignupOTPModal()),
    handleForgotPasswordModal: () =>
      dispatch(actionTypes.handleForgotPasswordModal()),

    ResetPasswordModal: () => dispatch(actionTypes.ResetPasswordModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Privacypolicy);
