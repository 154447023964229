import React from "react";
import style from "./../OpportunityDetails.module.css";
import defaultImg from "../../../../assets/defaultimg/Rectangle 5337.png";


const toshowLocation = (data1, data2, data3) => {
  if (data1 === null && data2 !== null && data3 !== null) {
    return data2 + "," + data3;
  } else if (data1 === null && data2 === null & data3 !== null) {
    return data3;
  }
  if (data1 !== null && data2 === null && data3 !== null) {
    return data1 + "," + data3;
  }
  if (data1 !== null && data2 !== null && data3 === null) {
    return data1+ "," + data2;
  }
     else if (data1 === null && data2 !== null & data3 === null) {
    return data2;
  }
  else if (data1 !== null && data2 === null & data3 === null) {
    return data1;
  }
  else if(data1 === null && data2 === null && data3 === null)
  {
    return "No Information Provided"
  }
   else {
    return data1 + "," + data2 + "," + data3;
  }
  
};

function AboutOrganisation(props) {
  return (
    <div>
      <div>
        <img
          src={props?.profileData && props?.profileData?.orgImgUrl!==null ? props?.profileData?.orgImgUrl : defaultImg}
          className={style.orgimg}
          alt="img"
        />
      </div>
      <div>
        <h1 className={style.about}> Organization Highlights </h1>
      </div>
      <div>
        {props.profileData && props?.profileData?.orgAbout !== null && props?.profileData?.orgAbout !== "" ? (
          <p className={style.paraabout}>
            {props?.profileData?.orgAbout}
          </p>
        ) : (
          <p className={style.paraabout} style={{ opacity: "0.5" }}>
            No Information Provided.
          </p>
        )}
      </div>
      <div>
        <p className={style.name}>
          {props.profileData ? props?.profileData?.orgName : ""}
        </p>
      </div>
      <div>
        <p className={style.name1}>Contact Details </p>
      </div>
      <div>
        <table className={style.table}>
          <tbody>
            <div className={style.display}>
              <div className={style.category}>Phone No.</div>
              <div className={style.value}>
                {props?.profileData ? props?.profileData?.orgContactNo : ""}
              </div>
            </div>
            <div className={style.display}> 
              <div className={style.category}>Email ID</div>
              <div className={style.value}>
                {props?.profileData ? props?.profileData?.orgEmail : ""}
              </div>
            </div>
            <div className={style.display}>
              <div className={style.category}>Website</div>
              <div className={style.value}>
                {props?.profileData && props?.profileData?.orgWebsite!==null && props?.profileData?.orgWebsite!=="" ? props?.profileData?.orgWebsite : "-"}
              </div>
            </div>
            <div className={style.display}>
              <div className={style.category}> Location</div>
              <div className={style.value}>
              {toshowLocation(
                                  props?.profileData?.orgCity,
                                  props?.profileData?.orgState,
                                  props?.profileData?.orgCountry
                                )}
               
              </div>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default AboutOrganisation;
