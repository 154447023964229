import React, { useState, useRef } from "react";
import style from "./managementCard.module.css";
import Tippy from "@tippyjs/react";
import displayImg from "../../../assets/defaultimg/placeholderImg.png";
import moreImg from "../../../assets/eye/More.png";
import activityImg from "../../../assets/activity/Group 18035.png";

function Cards(props) {
  const [isShow, setIsShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const node = useRef();
  const myRef = useRef();

  const showData = (index) => {
    if (isShow && currentIndex !== null) {
      setIsShow(false);
      setCurrentIndex(null);
    } else {
      setIsShow(true);
      setCurrentIndex(index);
    }
  };

  const hideData = (index) => {
    if (index === currentIndex) {
      setIsShow(false);
    }
  };

  const toHandleStatus = (id, status1) => {
    let rid = id;
    let status = status1 === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    let formData = new FormData();
    formData.append("recruiterAccountId", rid);
    formData.append("status", status);
    props.handleStatus(formData);
  };

  const toShowStatus = (item) => {
    if (item.status !== "ACTIVE") {
      return (
        <p
          className={style.modal_label}
          onClick={() =>{ toHandleStatus(item.recruiterAccountId, item.status);
          // props.handlepage(1)
          }}
        >
          Active
        </p>
      );
    } else {
      return (
        <p
          className={style.modal_label}
          onClick={() =>{ toHandleStatus(item.recruiterAccountId, item.status);
          // props.handlepage(1)
          }}
        >
          In-active
        </p>
      );
    }
  };

  return (
    <>
      {props.data.length > 0
        ? props.data.map((item, index) => (
            <div key={index} onMouseLeave={() => hideData(index)}>
              <div className={style.maincard}>
                <div className={style.card}>
                  <div
                    className={
                      item.status === "ACTIVE"
                        ? style.activeSquare
                        : style.inactiveSquare
                    }
                  ></div>
                  <div className={style.upperdiv}>
                    <div className={style.headleft}>
                      <img
                        src={
                          item.recuriterProfileUrl
                            ? item.recuriterProfileUrl
                            : displayImg
                        }
                        alt="img"
                      />
                    </div>
                    <div className={style.headright}>
                      <div className={style.img1}>
                        <img
                          src={moreImg}
                          ref={node}
                          alt="img"
                          style={{ position: "relative", cursor: "pointer" }}
                          onClick={() => showData(index)}
                        />
                        {isShow && index === currentIndex ? (
                          <div
                            className={style.modal_container}
                            ref={myRef}
                            onMouseLeave={() => hideData(index)}
                          >
                            {toShowStatus(item)}
                            <p
                              className={style.modal_label}
                              onClick={() =>
                               { props.handleDelete(
                                  item.recruiterAccountId,
                                  true
                                )
                                // props.handlepage(1)
                                }
                              }
                            >
                              Delete
                            </p>
                          </div>
                        ) : null}
                      </div>
                    
                    </div>
                  </div>

                  <div className={style.lowerdiv}>
                    <p>{item.name ? item.name : " "}</p>
                    <div>
                      <p className={style.category}>
                        Login ID:
                        <span
                          style={{ marginLeft: "5%" }}
                          className={style.value}
                        >
                          <Tippy
                            placement={"bottom"}
                            content={
                              <div
                                style={{
                                  fontSize: "8pt",
                                  backgroundColor: "#393939",
                                  color: "#FFFFFF",
                                  // border: "1px solid #393939",
                                  // borderRadius: "20%",
                                  padding: "3% 3% 3% 3%",
                                }}
                              >
                                &nbsp;{item.email}&nbsp;
                              </div>
                            }
                          >
                            <button
                              className={style.value}
                              style={{ border: "none", background: "white" }}
                            >
                              {item.email.length > 15
                                ? item.email.slice(0, 15) + "..."
                                : item.email}
                            </button>
                          </Tippy>
                        </span>
                      </p>

                      <p className={style.category1}>
                        <span className={style.value}>{}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : null}
    </>
  );
}
export default Cards;
