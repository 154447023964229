import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { getToken, onMessageListener } from "../../firebaseInit";
import * as actionTypes from "../../Store/Action/index";
import style from "./orgHeader.module.css";
import Backdroplog from "../Backdrop/Backdrop";
import sidebar from "../../assets/Menu/menu A.png";
import Modal from "./component/ProfileDropdown/profileDropdown";
import ResponseAlert from "../Modal/ResAlert/ResponseAlert";
import logo from "../../assets/logo/rev13-03.png";
import bellIcon_I from "../../assets/Component16_1/bellIcon1.png";
import bellIcon from "../../assets/Component16_1/bellIcon2.png";
import dropdownIcon_I from "../../assets/Component17_2/dropdown.png";
import ProfileUpdate from "../Modal/profileUpdate/ProfileUpdate";

function OrgHeader(props) {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [isTokenFound, setTokenFound] = useState(localStorage.getItem("token"));
  const [token, tokenFound] = useState();
  const [update, setUpdate] = useState(false);

  getToken(setTokenFound).then((message) => tokenFound(message));

  const node = useRef();

  useEffect(() => {
    // if url not contain CreatedOpportunities then localstorage.clearItem('filter')
    if (
      history.location.pathname !== "/CreatedOpportunities" ||
      history.location.pathname !== "/Recruiter/Opportunities"
    ) {
      localStorage.removeItem("filter");
    }
  }, [history.location.pathname]);

  const handleClick = () => {
    setShowModal(false);
  };

  const handleSidebar = () => {
    props.sidebar(!props.side);
  };

  const getData = async () => {
    const formData = {
      deviceId: props?.userDetails?.accountId,
      deviceToken: localStorage.getItem("fcmtoken"),
      deviceType: "NA",
    };

    let tokenValue = localStorage.getItem("userToken");

    if (isTokenFound && tokenValue && props?.userDetails?.accountId) {
      props.registerFcmToken(formData);
      localStorage.removeItem("token");
    }
  };
  function toShowLogo() {
    if (localStorage.getItem("userRole") === "ORGANISATION") {
      return (
        <div>
          <a onClick={handleSidebar}>
            <img className={style.menu} src={sidebar} alt="sidebar" />
          </a>
          <a href="/Dashboard">
            <img className={style.logo} src={logo} alt="logo" />
          </a>
        </div>
      );
    } else if (localStorage.getItem("userRole") === "CANDIDATE") {
      return (
        <div>
          <a onClick={handleSidebar}>
            <img className={style.menu} src={sidebar} alt="sidebar" />
          </a>
          <a href="/Student/Dashboard">
            <img className={style.logo} src={logo} alt="logo" />
          </a>
        </div>
      );
    } else if (localStorage.getItem("userRole") === "RECRUITER") {
      return (
        <div>
          <a onClick={handleSidebar}>
            <img className={style.menu} src={sidebar} alt="sidebar" />
          </a>
          <a href="/Recruiter/Dashboard">
            <img className={style.logo} src={logo} alt="logo" />
          </a>
        </div>
      );
    }
  }

  function toShownotification() {
    if (
      localStorage.getItem("userRole") === "RECRUITER" ||
      localStorage.getItem("userRole") === "CANDIDATE"
    ) {
      return (
        <div style={{ position: "relative" }}>
          <p className={style.noOfNotification}>
            {props.notificationCount ? props.notificationCount : 0}
          </p>
          <img
            src={bellIcon_I}
            alt=" img"
            className={
              location.pathname === "/Notification"
                ? style.bellIconStyleActive
                : style.bellIconStyle
            }
            onClick={() => history.push("/Notification")}
          />
        </div>
      );
    }
  }

  useEffect(() => {
    getData();
    props.getUserDetails();
  }, [props?.userDetails?.accountId]);

  useEffect(() => {
    const NotId = setInterval(() => {
      props.getNotificationCount();
    }, 15000);

    return () => clearInterval(NotId);
  }, []);

  function checkUpdate() {
    if (window.location.pathname === "/Recruiter/Profile") {
      // null;
    } else if (window.location.pathname === "/Recruiter/EditProfile") {
      // null;
    } else if (props.userDetails?.name) {
      // null;
    } else {
      return (
        <div className={style.profileUpdate}>
          <ProfileUpdate />
        </div>
      );
    }
  }

  useEffect(() => {
    setTimeout(() => setUpdate(true), 1000);
  }, []);

  return (
    <div>
      {update && checkUpdate()}
      <div className={style.header}>
        {toShowLogo()}
        <div className={style.profileSection}>
          <div>{toShownotification()}</div>
          <label className={style.orgNameStyle}>
            {localStorage.getItem("userName")
              ? localStorage.getItem("userName")
              : ""}
          </label>
          <label className={style.dropStyle} ref={node}>
            <img
              src={dropdownIcon_I}
              className={style.dropdownIconStyle}
              alt=" img"
              onClick={() => setShowModal(!showModal)}
            />
            {showModal && (
              <div
                className={style.modalStyle}
                onMouseLeave={() => handleClick()}
              >
                <Modal
                  data={props.userDetails}
                  showAlert1={props.showAlert1}
                ></Modal>
              </div>
            )}
          </label>
        </div>
        <Backdroplog show={props.showBlur1} />
      </div>
      <div>
        {props.showAlert1 && (
          <div className={style.changeDiv}>{<ResponseAlert />}</div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    updateStatus: state.authReducer.updateStatus,
    errorMsg: state.authReducer.loginErrorMsg,
    loader: state.authReducer.loader,
    initial: state.authReducer.initial,
    userDetails: state.authReducer.userDetails,
    ErrorCode: state.authReducer.ErrorCode,
    userDetails: state.authReducer.userDetails,
    FCMStatus: state.authReducer.FCMStatus,
    showBlur1: state.authReducer.showBlur1,
    showAlert1: state.authReducer.showAlert1,
    notificationCount: state.authReducer.notificationCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actionTypes.logout()),
    hideModal: () => dispatch(actionTypes.hideAuthModal()),
    getUserDetails: () => dispatch(actionTypes.getUserDetails()),
    registerFcmToken: (formData) =>
      dispatch(actionTypes.registerFcmToken(formData)),
    getNotificationCount: () => dispatch(actionTypes.getNotificationCount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrgHeader);
