import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import Calendar from "react-calendar";
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from "./SEditProfile.module.css";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import Backdroplog from "../../GenericComponent/Backdrop/Backdrop";
import CalendarImg from "../../assets/svg files/Icon feather_calendar.svg";
import "react-calendar/dist/Calendar.css";
import defaultImg from "../../assets/defaultimg/Rectangle 5337@2x.png";

import "flatpickr/dist/themes/material_green.css";
import Flatpickr, { flatpickr } from "react-flatpickr";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import Loader from "../../assets/Loader/spinner.gif";
import Uploadimg from "../../assets/regicons/upload.svg";
import { WhiteButton, BlueButton } from "../../GenericComponent/Buttons/button";
import { useDropzone } from "react-dropzone";

function SEditProfile(props) {
  const inputFile = useRef(null);
  const [sidebar, setSidebar] = useState();

  const [showCal, setShowcal] = useState(false);
  const [profileData, setprofileData] = useState(
    props.history.location.state.profileData
      ? props.history.location.state.profileData
      : null
  );
  const [country, setCountry] = useState(profileData.country);
  const [state, setState] = useState(profileData.state);
  const [city, setCity] = useState(profileData.city);
  const [operation, setOperation] = useState("NONE");
  const [isRemoveIcon, setIsRemoveIcon] = useState(
    props.history.location.state.profileData.profileUrl ? true : false
  );
  // const [profileImage, setProfileImage] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(
    profileData && profileData.dateOfBirth !== null
      ? Moment(profileData.dateOfBirth).format("MM/DD/YYYY")
      : null
  );
  const [sendDOB, setSendDOB] = useState(
    profileData && profileData.dateOfBirth !== null
      ? Moment(profileData.dateOfBirth).format("YYYY-MM-DD")
      : null
  );

  const [previewImage, setPreviewImage] = useState(
    profileData && profileData.profileUrl ? profileData.profileUrl : defaultImg
  );
  const [errorMsg, setErrorMsg] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  const history = useHistory();

  function removeImage() {
    setPreviewImage(defaultImg);
    // setProfileImage(null);
    setprofileData({ ...profileData, profileUrl: null });
    setIsRemoveIcon(false);
    setOperation(profileData.profileUrl === null ? "NONE" : "DELETE");
  }

  function handleSubmitData(e) {
    e.preventDefault();
    if (profileData.firstName === null || profileData.firstName === "") {
      setErrorMsg({ firstNameErr: "*First name is required." });
    } else if (profileData.lastName === null || profileData.lastName === "") {
      setErrorMsg({ lastNameErr: "*Last name is required." });
    } else if (profileData.contactNo === null || profileData.contactNo === "") {
      setErrorMsg({ contactNoErr: "*Contact no. is required." });
    } else if (profileData.gender === null || profileData.gender === "") {
      setErrorMsg({ genderErr: "*Gender field is required." });
    } else if (profileData.email === null || profileData.email === "") {
      setErrorMsg({ emailErr: "*Email field is required." });
    } else {
      if (errorMsg === null) {
        let data = {
          firstName: profileData.firstName,
          lastName: profileData.lastName,
          gender: profileData.gender,
          contactNo: profileData.contactNo,
          dateOfBirth: sendDOB,
          addressLine1: profileData.addressLine1,
          country: country,
          countrycode: profileData.countrycode,
          state: state,
          city: city,
          pinCode: profileData.pinCode,
          achievement: profileData.achievement,
          about: profileData.about,
          contactVisible: profileData.contactVisible,
          operation: operation,
        };
        let formData = new FormData();
        formData.append(
          "profileImage",
          previewImage[0] !== null ? previewImage[0] : ""
        );
        formData.append("jsonRequest", JSON.stringify(data));
        props.updateCandidateProfile(formData);
      }
    }
  }

  const showData = (condition, val1, val2) => {
    if (condition) {
      return val1;
    } else {
      return val2;
    }
  };

  function updateProfileData(e) {
    let name = e.target.name;
    let value = e.target.value;
    setprofileData({ ...profileData, [name]: value });
    setErrorMsg(null);
  }

  const handleClick = () => {
    history.push("Student/Profile");
  };

  const changeContactVisible = () => {
    if (profileData.contactVisible) {
      setprofileData({ ...profileData, contactVisible: false });
    } else {
      setprofileData({ ...profileData, contactVisible: true });
    }
  };

  function showContatctErr() {
    if (errorMsg !== null && errorMsg.contactNoErr) {
      return errorMsg.contactNoErr;
    } else {
      return "";
    }
  }

  function showFirstNAmeErr() {
    if (errorMsg !== null && errorMsg.firstNameErr) {
      return errorMsg.firstNameErr;
    } else {
      return "";
    }
  }

  function showLastNameErr() {
    if (errorMsg !== null && errorMsg.lastNameErr) {
      return errorMsg.lastNameErr;
    } else {
      return "";
    }
  }

  function showProfileImageErr() {
    if (errorMsg !== null && errorMsg.ProfileImageErr) {
      return errorMsg.ProfileImageErr;
    } else {
      return "";
    }
  }

  function showGenderErr() {
    if (errorMsg !== null && errorMsg.genderErr) {
      return errorMsg.genderErr;
    } else {
      return "";
    }
  }

  const showLoader = () => {
    return (
      <div className="loader_styles">
        <img src={Loader} alt="loader" />
      </div>
    );
  };

  function handleNumberdata(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.keyCode === 190 || e.keyCode === 187) {
        e.preventDefault();
      } else {
        setprofileData({ ...profileData, [e.target.name]: e.target.value });
      }
    }
  }

  const handleCountry = (value) => {
    const index = value.target.selectedIndex;
    const el = value.target.childNodes[index];
    const option = el.getAttribute("id");
    setCountry(option);
    props.getStateListId(option);
    setCountry(value.target.value);
    setState(null);
    setCity(null);
  };

  const handleState = (value) => {
    const index = value.target.selectedIndex;
    const el = value.target.childNodes[index];
    const option = el.getAttribute("id");
    setState(option);
    props.getCityListId(option);
    setState(value.target.value);
    setCity(null);
  };

  const handleCity = (value) => {
    setCity(value.target.value);
  };

  useEffect(() => {
    props.getCountryList();
    props.getStateListId(profileData.countryId);
    props.getCityListId(profileData.stateId);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      setPreviewImage(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setOperation("UPDATE");
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    if (previewImage == []) {
      previewImage.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  }, [previewImage]);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <Backdroplog show={props.showBlur} />
        <div className={style.firstHeading}>Personal Details</div>
        {props.loader ? (
          showLoader()
        ) : (
          <div>
            <div className={style.mainbox}>
              <div className={style.div}>
                <div>
                  <div className={style.left}>
                    <div className={style.uploadImg}>
                      <p style={{ position: "sticky" }}>Profile Photo</p>
                      <img
                        className={style.img1}
                        src={showData(
                          previewImage[0]?.preview,
                          previewImage[0]?.preview,
                          profileData.profileUrl == null
                            ? defaultImg
                            : profileData.profileUrl
                        )}
                      />

                      {isRemoveIcon && (
                        <i
                          style={{
                            color: "green",
                            top: "-60",
                            position: "absolute",
                            left: "4",
                            cursor: "pointer",
                          }}
                          className={style.cross}
                          class="fa fa-times-circle"
                          aria-hidden="true"
                          onClick={removeImage}
                        ></i>
                      )}

                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <img
                          src={Uploadimg}
                          className={style.img2}
                          style={{ cursor: "pointer" }}
                          id="img"
                          // onClick={onButtonClick}
                        />
                      </div>
                    </div>
                    <br />
                    <span className={style.errorMsg}>
                      {showProfileImageErr()}
                    </span>
                    <p className={style.heading}>I Am</p>
                    <textarea
                      className={style.yourinput}
                      placeholder="Provide information on who you are and what you aspire to become."
                      defaultValue={showData(
                        profileData,
                        profileData.about,
                        null
                      )}
                      name="about"
                      onChange={(e) => updateProfileData(e)}
                    ></textarea>

                    <p className={style.heading}>Achievement</p>
                    <textarea
                      className={style.yourinput}
                      placeholder="Share your achievements and proudest moments."
                      defaultValue={showData(
                        profileData,
                        profileData.achievement,
                        null
                      )}
                      name="achievement"
                      onChange={(e) => updateProfileData(e)}
                    ></textarea>

                    <br />
                  </div>
                </div>
                <div className={style.right}>
                  <label className={style.label}> First Name</label>
                  <input
                    className={style.input1}
                    type="text"
                    defaultValue={showData(
                      profileData,
                      profileData.firstName,
                      ""
                    )}
                    name="firstName"
                    onChange={(e) => updateProfileData(e)}
                    autoComplete="off"
                  />
                  <span className={style.errorMsg}>{showFirstNAmeErr()}</span>
                  <label className={style.label}> Last Name</label>
                  <input
                    className={style.input1}
                    type="text"
                    defaultValue={showData(
                      profileData,
                      profileData.lastName,
                      ""
                    )}
                    name="lastName"
                    onChange={(e) => updateProfileData(e)}
                    autoComplete="off"
                  />
                  <span className={style.errorMsg}>{showLastNameErr()}</span>
                  <label className={style.label}> Date of Birth</label>
                  <div className={style.calendarBox}>
                    <Flatpickr
                      className={style.input1}
                      // data-enable-time
                      value={dateOfBirth}
                      options={{
                        maxDate: "today",
                        dateFormat: "m/d/Y",
                        enableTime: false,
                        disableMobile: "true",
                      }}
                      onChange={(date) => {
                        date.map((item) => {
                          Moment(item).format("MM/DD/YYYY");
                          setDateOfBirth(Moment(item).format("MM/DD/YYYY"));
                          setSendDOB(Moment(item).format("YYYY-MM-DD"));
                        });
                      }}
                    />
                  </div>

                  <label className={style.label}>Gender</label>
                  <select
                    className={style.input1}
                    type="text"
                    defaultValue={showData(profileData, profileData.gender, "")}
                    name="gender"
                    onChange={(e) => updateProfileData(e)}
                    autoComplete="off"
                  >
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHERS">Others</option>
                  </select>
                  <span className={style.errorMsg}>{showGenderErr()}</span>
                  <label className={style.label}> Email</label>
                  <input
                    className={style.input1}
                    type="email"
                    defaultValue={showData(profileData, profileData.email, "")}
                    name="email"
                    onChange={(e) => updateProfileData(e)}
                    autoComplete="off"
                  />
                  <label className={style.label}>Phone No. </label>
                  <div className={style.phonediv}>
                    <div className={style.phonedivleft}>
                      <input
                        className={style.inputp}
                        type="number"
                        onKeyDown={(e) => handleNumberdata(e)}
                        defaultValue={profileData && profileData.countrycode}
                        onChange={(e) => updateProfileData(e)}
                        name="countrycode"
                        autoComplete="off"
                      />
                    </div>
                    <div className={style.phonedivright}>
                      <input
                        className={style.inputp}
                        type="number"
                        onKeyDown={(e) => handleNumberdata(e)}
                        defaultValue={profileData && profileData.contactNo}
                        onChange={(e) => updateProfileData(e)}
                        name="contactNo"
                        autoComplete="off"
                        min={0}
                      />
                    </div>
                  </div>
                  <span className={style.errorMsg}>{showContatctErr()}</span>
                  <div style={{ float: "right" }} className={style.onspan}>
                    <p>Phone No. Visibility</p>
                    <label className={style.switch}>
                      <input
                        className={style.switch_input}
                        defaultChecked={showData(
                          profileData,
                          profileData.contactVisible,
                          "false"
                        )}
                        onChange={() => changeContactVisible()}
                        type="checkbox"
                        name="contactVisible"
                      />
                      <span className={style.slider}></span>
                    </label>
                  </div>
                  <label className={style.label}>Country</label>
                  <select
                    className={style.input1}
                    onChange={(e) => {
                      handleCountry(e);
                      updateProfileData(e);
                    }}
                    name="country"
                  >
                    <option value="">{country ? country : "--Select--"}</option>
                    {props.country_list && props.country_list.length > 0
                      ? props.country_list.map((item, index) => (
                          <option value={item.name} id={item.id}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>

                  <label className={style.label}>State</label>
                  <select
                    className={style.input1}
                    onChange={(e) => {
                      handleState(e);
                      updateProfileData(e);
                    }}
                    name="state"
                  >
                    <option value="">{state ? state : "--Select--"}</option>
                    {props.state_list && props.state_list.length > 0
                      ? props.state_list.map((item, index) => (
                          <option value={item.name} id={item.id}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>

                  <label className={style.label}>City</label>
                  <select
                    className={style.input1}
                    onChange={(e) => {
                      handleCity(e);
                      updateProfileData(e);
                    }}
                    name="city"
                  >
                    <option value="">{city ? city : "--Select--"}</option>
                    {props.city_list && props.city_list.length > 0
                      ? props.city_list.map((item, index) => (
                          <option value={item.name} id={item.id}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <label className={style.label}>Postal Code</label>
                  <input
                    className={style.input1}
                    type="number"
                    defaultValue={showData(
                      profileData,
                      profileData.pinCode,
                      ""
                    )}
                    name="pinCode"
                    onChange={(e) => updateProfileData(e)}
                    autoComplete="off"
                  />

                  <br />
                </div>
                <div className={style.button}>
                  <div>
                    <WhiteButton children="Cancel" onClick={handleClick} />
                  </div>
                  <div>
                    <BlueButton
                      children="Save &amp; Next"
                      onClick={(e) => handleSubmitData(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.showAlert && (
          <div className={style.changeDiv}>
            <ResponseAlert
              redirect={
                props.updateCandidateSuccessStatus
                  ? "/Student/SkillDetails"
                  : null
              }
              message={
                props.updateCandidateSuccessStatus
                  ? "Personal details updated successfully!"
                  : props.candidateErrorMsg
              }
              type="CANDIDATE"
            />
          </div>
        )}
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.candidateReducer.loader,
    profileData: state.candidateReducer.candidateProfileData,
    candidateErrorMsg: state.candidateReducer.candidateErrorMsg,
    candidateErrorCode: state.candidateReducer.candidateErrorCode,
    candidateProfileErrMsg: state.candidateReducer.candidateProfileErrMsg,
    candidateProfileStatus: state.candidateReducer.candidateProfileStatus,
    updateCandidateSuccessStatus:
      state.candidateReducer.updateCandidateSuccessStatus,
    updateCandidateProfileErrMsg:
      state.candidateReducer.updateCandidateProfileErrMsg,
    updateCandidateProfileStatus:
      state.candidateReducer.updateCandidateProfileStatus,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
    country_list: state.recruiterReducer.country_list,
    state_list: state.recruiterReducer.state_list,
    city_list: state.recruiterReducer.city_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCandidateProfile: (data) =>
      dispatch(actionTypes.updateCandidateProfile(data)),
    getCountryList: () => dispatch(actionTypes.getCountryList()),
    getStateListId: (id) => dispatch(actionTypes.getStateListId(id)),
    getCityListId: (id) => dispatch(actionTypes.getCityListId(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SEditProfile);
