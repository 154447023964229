import React from "react";
import * as actionTypes from "../../Store/Action/index";
import { connect } from "react-redux";
import style from "./header.module.css";
import sidebar from "../../assets/Menu/menu A.png";
import logo from "../../assets/logo/rev13-03.png";
import { useLocation } from "react-router-dom";

function Header(props) {
  let location = useLocation();
  const isDesktop = window.innerWidth > 850;

  const handleSidebar = () => {
    props.sidebar(!props.side);
  };

  return (
    <div className={style.header}>
      <a href="/">
        <img className={style.logo} src={logo} alt="logo" />
      </a>

      <div
        className={
          isDesktop
            ? style.headerRight
            : props.side
            ? style.sidebar
            : style.headerRight
        }
      >
        <a
          href="/AboutUs"
          onClick={handleSidebar}
          className={
            location.pathname == "/AboutUs" ? style.activeSide : style.side
          }
        >
          About Us
        </a>
        <a
          href="/WhyIgg2"
          onClick={handleSidebar}
          className={
            location.pathname == "/WhyIgg2" ? style.activeSide : style.side
          }
        >
          Why igotgame2
        </a>
        <a
          href="/Resources"
          id="Resources"
          onClick={handleSidebar}
          className={
            location.pathname == "/Resources" ? style.activeSide : style.side
          }
        >
          Resources
        </a>
        <a
          href="/ContactUs"
          onClick={handleSidebar}
          className={
            location.pathname == "/ContactUs" ? style.activeSide : style.side
          }
        >
          Contact Us
        </a>
        <a
          href="/privacy_policy"
          onClick={handleSidebar}
          className={
            location.pathname == "/privacy_policy"
              ? style.activeSide
              : style.side
          }
        >
          Privacy Policy
        </a>
        <a
          href="/termsandcondition"
          onClick={handleSidebar}
          className={
            location.pathname == "/termsandcondition"
              ? style.activeSide
              : style.side
          }
        >
          Terms & Conditions
        </a>

        <div className={style.btn_css}>
          {props.showSignUpModal ? (
            <button
              children="Sign Up"
              className={style.blueButton}
              onClick={() => props.SignUp()}
            />
          ) : (
            <button
              children="Sign Up"
              className={style.whiteButton}
              onClick={() => {
                props.SignUp();
              }}
            />
          )}
          {props.showSignInModal ? (
            <button
              children="Sign In"
              className={style.blueButton}
              onClick={() => props.SignIn()}
            />
          ) : (
            <button
              children="Sign In"
              className={style.whiteButton}
              onClick={() => {
                props.SignIn();
              }}
            />
          )}
        </div>
      </div>
      <a onClick={handleSidebar}>
        <img className={style.menu} src={sidebar} alt="sidebar" />
      </a>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    errorMsg: state.authReducer.loginErrorMsg,
    loader: state.authReducer.loader,
    initial: state.authReducer.initial,
    ErrorCode: state.authReducer.ErrorCode,
    showConfirmModal: state.authReducer.showConfirmModal,
    signUpSuccess: state.authReducer.signUpSuccess,
    loginErrorMsg: state.authReducer.loginErrorMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SignIn: () => dispatch(actionTypes.handleSignInModal()),
    SignUp: () => dispatch(actionTypes.handleSignUpModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
