import React, { Component, useState, useEffect, useCallback } from "react";
import OtpInput from "react-otp-input";

import { connect } from "react-redux";
import style from "./OTPScreen.module.css";
import * as actionTypes from "../../../../Store/Action/index";
import closeCircle from "../../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import SignupImg from "../../../../assets/Basketball.jpg";
import logo from "../../../../assets/logo/rev13-03.png";

function OTPScreen(props) {
  const [otp, setOtp] = useState();
  const [otpErrorMsg, setOtpErrorMsg] = useState(" ");
  const [resentConfirm, setResentConfirm] = useState(false);

  const [minute, setMinute] = useState(3);
  const [second, setSecond] = useState(0);
  const [intev, setSetintev] = useState("");
  const start = () => {
    clock();
    setSetintev(setInterval(clock, 1000));
  };
  var updatedS = second,
    updatedM = minute;
  const clock = () => {
    if (updatedM > 0 && updatedS === 0) {
      updatedS = 60;
      updatedM--;
      setMinute(updatedM);
    }
    if (updatedS > 0) {
      updatedS--;
      setSecond(updatedS);
    }
    if (updatedM === 0 && updatedS === 0) {
      setResentConfirm(false);
    }
  };
  const resendOtp = () => {
    const email = {
      email: props.forgetEmail,
    };
    setMinute(3);
    setResentConfirm(true);
    props.forgotPassword(email);
  };

  useEffect(() => {
    start();
  }, [resentConfirm]);

  const handleChange = (otp) => {
    setOtp(otp);
  };
  props.setForgotOtp(otp);

  const verifyOtp = () => {
    if (otp.length === 6) {
      props.setForgotOtp(otp);
      props.ValidateOtpResetPassword(otp, props.forgetEmail);
      props.hideModal();
    } else {
      setOtpErrorMsg("All fields are required.");
    }
  };

  return (
    <div className={style.container}>
      <div className={style.leftContainer}>
        <div className={style.leftLogo}>
          <img className={style.logo} src={logo} alt="logo" />
        </div>
        <div className={style.leftPara}>
          <p>
            I Got Game 2 is the #1 portal for connecting students, athletes and
            talent to available scholarship opportunities with universities and
            colleges worldwide.
          </p>
        </div>
        <div className={style.leftImg}>
          <img src={SignupImg} alt="img" />
        </div>
      </div>
      <div className={style.rightContainer}>
        <img
          src={closeCircle}
          className={style.closeModal}
          alt="img"
          onClick={() => props.hideModal()}
        />

        <div className={style.flex}>
          <div>
            <h1 className={style.heading}> One Time Password Verify</h1>
          </div>
          <div>
            <p className={style.para}> Enter the 6 digit code below.</p>
          </div>
          <div className={style.text}>
            <OtpInput
              value={otp}
              isInputNum={true}
              onChange={(otp) => handleChange(otp)}
              numInputs={6}
              separator={<span>-</span>}
            />
          </div>
          <span className={style.errorMsg}>{otpErrorMsg}</span>
          <div>
            <button className={style.button1} onClick={verifyOtp}>
              verify
            </button>
          </div>
          <div className={style.para}>
            Resend One Time Password in{" "}
            <span>{minute >= 10 ? minute : "0" + minute}</span>:
            <span>{second >= 10 ? second : "0" + second} </span>
          </div>
          <div>
            <button
              className={style.button2}
              disabled={minute !== 0 || second !== 0}
              onClick={resendOtp}
            >
              Resend One Time Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    otpValidationStatus: state.authReducer.otpValidationStatus,
    otpErrorMsg: state.authReducer.otpErrorMsg,
    loader: state.authReducer.loader,
    initial: state.authReducer.initial,
    ErrorCode: state.authReducer.ErrorCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => dispatch(actionTypes.hideAuthModal()),
    OTPModal: () => dispatch(actionTypes.handleOTPModal()),
    forgotPassword: (email) => dispatch(actionTypes.forgotPassword(email)),
    ValidateOtpResetPassword: (otp, email) =>
      dispatch(actionTypes.ValidateOtpResetPassword(otp, email)),
    ResetPasswordModal: () => dispatch(actionTypes.ResetPasswordModal()),
    ResendOtpForEmailVerification: (email) =>
      dispatch(actionTypes.ResendOtpForEmailVerification(email)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OTPScreen);
