import React, { useState, useEffect, useRef } from "react";
import * as actionTypes from "../../../../Store/Action/index";
import { connect } from "react-redux";

import closeIcon from "../../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import ConfirmModal from "../confirm";
import style from "./forgotpassword.module.css";
import OTPModal from "../OTPScreen/OTPScreen";
import SignupImg from "../../../../assets/Basketball.jpg";
import logo from "../../../../assets/logo/rev13-03.png";
import mailIcon from "../../../../assets/email/Icon material-email.png";

function ForgotPassword(props) {
  const [emailErrorMsg, setEmailErrorMsg] = useState("");

  const inputRef = useRef();

  const generatePassword = (e) => {
    e.preventDefault();

    if (e.target.emailid.value === "") {
      setEmailErrorMsg("Enter your Email ID");
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        e.target.emailid.value.trim()
      )
    ) {
      setEmailErrorMsg("Please enter a valid Email ID");
    } else {
      const id = {
        email: e.target.emailid.value.trim(),
      };

      props.setForgetEmail(e.target.emailid.value.trim());
      props.forgotPassword(id);
      props.hideModal();
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  });

  return (
    <div className={style.container}>
      <div className={style.leftContainer}>
        <div className={style.leftLogo}>
          <a href="/">
            <img className={style.logo} src={logo} alt="logo" />
          </a>
        </div>
        <div className={style.leftPara}>
          <p>
            I Got Game 2 is the #1 portal for connecting students, athletes and
            talent to available scholarship opportunities with universities and
            colleges worldwide.
          </p>
        </div>
        <div className={style.leftImg}>
          <img src={SignupImg} alt="img" />
        </div>
        <br />
      </div>
      <div className={style.rightContainer}>
        <img
          src={closeIcon}
          className={style.closeModal}
          onClick={() => props.hideModal()}
          alt="img"
        />

        <p className={style.title}>Forgot Password</p>
        <p className={style.subTitle}></p>

        <div className={style.formStyle}>
          <form className={style.formStyle} onSubmit={generatePassword}>
            <div className={style.emailName}>
              <label className={style.mail}>Email ID</label>
              <input
                type="text"
                id="emailid"
                name="emailid"
                autoComplete="off"
                className="inputform"
                ref={inputRef}
                onKeyDown={() => setEmailErrorMsg("")}
              />
              <img className={style.icon1} src={mailIcon} alt="email" />
              <span className={style.errorMsg}>{emailErrorMsg}</span>
            </div>

            <button className={style.signup}>Forgot</button>
          </form>
          <p className={style.lastPara}>
            This action will send a One Time Password to your Email ID. Check
            your email to obtain the code and continue your request.
          </p>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    showForgotPassModal: state.authReducer.showForgotPassModal,
    showResetPasswordModal: state.authReducer.showResetPasswordModal,
    signUpSuccess: state.authReducer.signUpSuccess,
    GeneratePasswordResetTokenStatus:
      state.authReducer.GeneratePasswordResetTokenStatus,
    errorMsg: state.authReducer.errorMsg,
    GenerateOTP: state.authReducer.GenerateOTP,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => dispatch(actionTypes.hideAuthModal()),
    ResetPasswordModal: () => dispatch(actionTypes.ResetPasswordModal()),

    forgotPassword: (email) => dispatch(actionTypes.forgotPassword(email)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
