import React, { useState, useEffect, useRef } from "react";
import style from "./creation.module.css";

import { connect } from "react-redux";

import closeIcon from "../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";

function Modal(props) {
  const inputRef = useRef();

  const [emailErrorMsg, setEmailErrorMsg] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(
      e.target.emailid.value.trim()
    );
    if (e.target.emailid.value === "") {
      setEmailErrorMsg("Enter Email ID");
    }
    // !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    else if (!email) {
      setEmailErrorMsg("Enter a valid Email ID");
    } else {
      const formData = {
        recruiterEmail: e.target.emailid.value.trim(),
      };

      props.createRecruitersubmit(formData);
    }
  };
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <div className={style.container}>
      <img
        src={closeIcon}
        className={style.closeModal}
        alt="img"
        onClick={() => props.handleModal(false)}
      />
      <h3 className={style.para}> New User Creation </h3>
      <div className={style.formStyle}>
        <form
          className={style.formStyle}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className={style.emailName2}>
            <label className={style.mail2}>Email Id</label>
            <input
              type="text"
              id="emailid"
              name="emailid"
              ref={inputRef}
              autoComplete="off"
              className="inputform"
            />
            {/* <img className ={style.icon2} src={mailIcon} alt="email"/> */}
            <span className={style.errorMsg}>{emailErrorMsg}</span>
          </div>
          <div className={style.allBtn2}>
            {/* <BlueButton children="Create" /> */}
            <button className={style.signin2} type="submit">
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.recruiterReducer.loader,
    initial: state.recruiterReducer.initial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // createRecruiter: (formData) => dispatch(actionTypes.createRecruiter(formData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
