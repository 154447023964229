import React from "react";

import style from "./button.module.css";


function WhiteButton(props) {
    
    return (
        <button
        className={style.whiteButton}
        type="button"
        onClick={props.onClick}
        >
        {props.children}
        </button>
    );
}

function BlueButton(props) {
   
    return (
        <button
        className={style.blueButton}
        type="button"
        onClick={props.onClick}
        >
        {props.children}
        </button>
    );
}

function LongButton(props) {
    
    return (
        <button
        className={style.LongButton}
        type="button"
        onClick={props.onClick}
        >
        {props.children}
        </button>
    );
}
export {
  BlueButton,
  WhiteButton,
  LongButton
};