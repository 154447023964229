import React from "react";
import style from "./../OpportunityDetails.module.css";

function AboutOpportunity(props) {
  return (
    <div>
      <div>
        <p className={style.high}>Highlights</p>
      </div>
      <div>
        <table className={style.table}>
          <tbody>
            <tr>
              <td className={style.category}>Category</td>
              <td className={style.value}>
                {props.profileData &&
                props.profileData.opportunityMainSkill !== null &&
                props.profileData.opportunityMainSkill !== ""
                  ? props.profileData.opportunityMainSkill
                  : "-"}
              </td>
            </tr>
            <tr>
              <td className={style.category}>Skills</td>
              <td className={style.value}>
                {props.profileData &&
                props.profileData.opportunitySubSkill !== null &&
                props.profileData.opportunitySubSkill !== ""
                  ? props.profileData.opportunitySubSkill
                  : "-"}
              </td>
            </tr>
            <tr>
              <td className={style.category}>Location</td>
              <td className={style.value}>
                {props.profileData &&
                props.profileData.opportunityAddressLine1 !== null &&
                props.profileData.opportunityAddressLine1 !== ""
                  ? props.profileData.opportunityAddressLine1
                  : "-"}
                {/* {
                              (props.profileData.opportunityCity === null && props.profileData.opportunityState !== null ) ?  props.profileData.opportunityState+","+props.profileData.opportunityCountry :  
                              (props.profileData.opportunityCity === null && props.profileData.opportunityState === null) ? props.profileData.opportunityCountry:
                              (props.profileData.opportunityState === null && props.profileData.opportunityCity !== null) ? props.profileData.opportunityCity+","+props.profileData.opportunityCountry:
                              props.profileData.opportunityCity +","+props.profileData.opportunityState+","+props.profileData.opportunityCountry
                              
                            } */}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <h1 className={style.head3}>About Opportunity & Scholarship</h1>
        </div>
        <div>
          {props.profileData && props.profileData.opportunityAbout !== "" ? (
            <p className={style.paraOpp}>
              {props.profileData.opportunityAbout}
            </p>
          ) : (
            <p className={style.paraOpp} style={{ opacity: "0.5" }}>
              No Information Provided.
            </p>
          )}
        </div>
        <div>
          <h1 className={style.head4}>Our Wins</h1>
        </div>
        <div>
          {props.profileData && props.profileData.opportunityOurWins !== "" ? (
            <p className={style.paraOpp}>
              {props.profileData.opportunityOurWins}
            </p>
          ) : (
            <p className={style.paraOpp} style={{ opacity: "0.5" }}>
              No Information Provided.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
export default AboutOpportunity;
