import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import style from "./EditAddSkill.module.css";

import * as actionTypes from "../../../Store/Action/index";
import deletebin from "../../../assets/svg files/deletebin.svg";
import closeIcon from "../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import Uploadimg from "../../../assets/regicons/upload.svg";
import deletebin12 from "../../../assets/svg files/greencross.png";

import {
  BlueButton,
  WhiteButton,
} from "../../../GenericComponent/Buttons/button";
import { useDropzone } from "react-dropzone";

function EditSkill(props) {
  const [allMedia] = useState(
    props.editSkillData ? props.editSkillData.mediaList : []
  );
  const [removeMedia, setRemoveMedia] = useState([]);
  const [newMedia, setNewMedia] = useState([]);
  const [editData, setEditData] = useState(
    props.editSkillData !== null ? props.editSkillData : null
  );
  const catRef = useRef();
  const skillRef = useRef();
  const [description, setDescription] = useState(editData?.description);
  const [categoryID, setCategoryID] = useState(
    props.type == "Add" ? null : editData?.mainCategoryId
  );
  const [skillId, setSkillId] = useState(
    props.type == "Add" ? null : editData?.subCategoryId
  );
  const [warning, setWarning] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const inputFile = useRef(null);
  const [isEditCategory, setisEditCategory] = useState(false);

  function onCategoryChange(e) {
    setCategoryID(e.target.value);
    setSkillId("");
    setErrorMsg(null);
    props.getSubCategoryList(e.target.value);
    setisEditCategory(true);
  }
  function onsubCategoryChange(e) {
    setSkillId(e.target.value);
    setErrorMsg(null);
    setisEditCategory(true);
  }

  const save1 = (value) => {
    var arr = Object.assign([], removeMedia);
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(value);
    }
    setRemoveMedia(arr);
  };

  const removenewmedia = (val) => {
    var newarray = Object.assign([], newMedia);
    newarray.splice(val, 1);

    setNewMedia(newarray);
  };

  const deleteMedia = () => {
    props.handleDeleteSkillMedia(removeMedia, true);
  };

  const saveDetails = () => {
    if (categoryID === null || categoryID === "") {
      setErrorMsg({ categoryErr: "*Category is required." });
      catRef.current.focus();
    } else if (skillId === null || skillId === "") {
      setErrorMsg({ skillErr: "*Skill is required." });
      skillRef.current.focus();
    } else {
      if (errorMsg === null) {
        let payload = {
          candidateSkillId:
            props.type === "Edit" && editData != null
              ? editData?.skillId
              : null,
          mainCategoryid: parseInt(isEditCategory ? categoryID : null),
          subCategoryId: parseInt(isEditCategory ? skillId : null),
          description: description,
        };

        let formData = new FormData();
        if (newMedia.length > 0) {
          for (let newm of newMedia) {
            formData.append("media", newm);
          }
        } else {
          formData.append("media", null);
        }
        formData.append("jsonRequest", JSON.stringify(payload));
        for (var pair of formData.entries()) {
        }
        props.toSubmit(props.type, formData, newMedia);
      }
    }
  };

  function updateProfileData(e) {
    setErrorMsg({});
    let name = e.target.name;
    let value = e.target.value;
    setEditData({ ...editData, [name]: value });
    // setisEditCategory(true)
  }

  function onButtonClick() {
    inputFile.current.click();
    setWarning("");
  }

  function uploadMultipleFiles(e) {
    var myFiles = Array.from(e.target.files);

    if (myFiles.length > 0) {
      myFiles.map((item, index1) => {
        var FileSize = item.size / 1024 / 1024;
        if (FileSize > 10) {
          myFiles.splice(index1, 1);
          setWarning("Media must be less then 10 mb.");
        }
      });
    }

    setNewMedia([...newMedia, ...myFiles]);
    e.target.value = null;
  }
  function showCategoryErr() {
    if (errorMsg !== null && errorMsg?.categoryErr) {
      return errorMsg?.categoryErr;
    } else {
      return "";
    }
  }
  function showSkillErr() {
    if (errorMsg !== null && errorMsg?.skillErr) {
      return errorMsg?.skillErr;
    } else {
      return "";
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      image: "image/*",
      video: "video/*",
    },
    onDrop: (acceptedFiles) => {
      setNewMedia(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    if (newMedia == []) {
      newMedia.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  }, [newMedia]);

  return (
    <div className={style.container}>
      <div className={style.rowone}>
        <div className={style.colone}>
          <label className={style.mainheading}>{props.type} Skill.</label>
        </div>
        <div className={style.colone}>
          <img
            src={closeIcon}
            className={style.closeModal}
            onClick={() => props.toClose()}
          />
        </div>
      </div>

      <form className={style.form_style}>
        <div className={style.row}>
          <div className={style.col}>
            <label className={style.heading}>Category</label>
            <select
              ref={catRef}
              className={style.category_select}
              name="categoryId"
              onChange={(e) => {
                onCategoryChange(e);
              }}
              value={categoryID}
            >
              <option value="">Select a category</option>
              {props.category_list &&
                props.category_list.map((item, index1) => (
                  <option value={item.categoryId} key={index1}>
                    {item.categoryName}
                  </option>
                ))}
            </select>
            <span className={style.errorMsgbelow}>{showCategoryErr()}</span>
          </div>
          <div className={style.col}>
            <label className={style.heading}>Skill</label>
            <select
              className={style.category_select}
              name="subCategoryId"
              ref={skillRef}
              onChange={(e) => {
                onsubCategoryChange(e);
              }}
              value={skillId}
            >
              <option value="">Select a skill</option>
              {props.sub_category_list &&
                props.sub_category_list.map((subitem, index2) => (
                  <option value={subitem.subCategoryId} key={index2}>
                    {subitem.subCategoryName}
                  </option>
                ))}
            </select>
            <span className={style.errorMsgbelow}>{showSkillErr()}</span>
          </div>
        </div>
        <div className={style.row2}>
          <div className={style.col2}>
            <label className={style.heading}>Description</label>
            <textarea
              className={style.textarea_style}
              placeholder="Describe your talent/skills in this section."
              name="description"
              maxLength="500"
              onChange={(e) => setDescription(e.target.value)}
              defaultValue={description ? description : ""}
            />
          </div>
        </div>
        {props.type === "Edit" && (
          <div className={style.row}>
            <div className={style.col2}>
              {/* <label className={style.heading}>Media</label> */}

              {removeMedia.length > 0 ? (
                <img
                  src={deletebin}
                  className={style.imgDelete}
                  style={{ cursor: "pointer" }}
                  id="img"
                  onClick={() => deleteMedia()}
                />
              ) : null}
            </div>
          </div>
        )}
        <div className={style.row}>
          <div className={style.col2}>
            <label className={style.heading}>Upload Media</label>
            <div className={style.mediadiv}>
              <div className={style.gridContainer}>
                {newMedia.length > 0 &&
                  newMedia.map((nmedia, index4) => (
                    <div className={style.item} key={index4}>
                      {nmedia.type.includes("image") && (
                        <div>
                          <img
                            src={URL.createObjectURL(nmedia)}
                            className={style.img1}
                            alt="medias"
                          />
                          <span>
                            <img
                              className={style.cross}
                              src={deletebin12}
                              onClick={() => removenewmedia(index4)}
                            />
                          </span>
                        </div>
                      )}
                      {nmedia.type.includes("video") && (
                        <div>
                          <video
                            className={style.showvideos}
                            controls
                            preload="none"
                          >
                            <source src={URL.createObjectURL(nmedia)} />
                          </video>
                          <span>
                            <img src={deletebin12} />
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <span className={style.warning}>{warning}</span>
            {/* <input
              type="file"
              name="profile"
              onChange={uploadMultipleFiles}
              ref={inputFile}
              accept="image/png, image/jpg, image/jpeg,image/svg,video/*"
              style={{ display: "none", visibility: "none" }}
              multiple
            />
            <img
              src={Uploadimg}
              className={style.imgUpload}
              style={{ marginTop: warning !== "" ? "-7%" : "-4%" }}
              id="img"
              onClick={onButtonClick}
            /> */}
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <img
                src={Uploadimg}
                className={style.img2}
                style={{ cursor: "pointer" }}
                id="img"
                // onClick={onButtonClick}
              />
              <p> </p>
            </div>
          </div>
        </div>
        <div className={style.button_row}>
          <WhiteButton children="Close" onClick={props.toClose} />
          <BlueButton children={"Save"} onClick={() => saveDetails()} />
        </div>
      </form>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.candidateReducer.loader,
    skillDatas: state.candidateReducer.skillDatas,
    candidateErrorMsg: state.candidateReducer.candidateErrorMsg,
    candidateErrorCode: state.candidateReducer.candidateErrorCode,
    sub_category_list: state.recruiterReducer.sub_category_list,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubCategoryList: (id) => dispatch(actionTypes.getSubCategoryList(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditSkill);
