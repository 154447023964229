import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cards from "./Cards/cards";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import style from "./student.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import NoData from "../../../assets/globe.jpg";
import Modal from "../../../GenericComponent/Appliedfilterc/appliedfilterc";
import backarrowImg from "../../../assets/back arrow/Icon ionic-ios-arrow-round-back.png";
import loader from "../../../assets/Loader/spinner.gif";
function Accepted(props) {
  const [sidebar, setSidebar] = useState(false);

  const history = useHistory();
  const [noDataVal, setNoDataVal] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showBlur, setShowBlur] = useState(false);

  const [oppid] = useState([]);

  const handleRedirect = () => {
    history.push({
      pathname: "/",
      state: {},
    });
  };

  const handleModal = () => {
    if (showModal === true) {
      setshowModal(false);
      setShowBlur(false);
    } else {
      setshowModal(true);
      setShowBlur(true);
    }
  };

  const getDetails = () => {
    props.getStudentAppliedOpportunityList();
  };

  useEffect(() => {
    getDetails();
  }, []);

  const toShowCards = () => {
    if (props.list && props.list.length > 0) {
      return <Cards data={props.list} />;
    } else {
      if (props.loader) {
        return (
          <div className={style.loader}>
            <img className={style.nodata} src={loader} alt="loader" />
          </div>
        );
      } else {
        if (noDataVal) {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <p>
                  <b>No Data Found – Create opportunities/scholarships</b>
                </p>
                <img
                  className={style.nodata}
                  style={{ width: "50%", height: "60%" }}
                  src={NoData}
                  alt="nodata"
                />
              </div>
            </div>
          );
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNoDataVal(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section
        className={
          showBlur === true
            ? style.sectionStyle + " " + style.addBlurr
            : style.sectionStyle
        }
      >
        <div>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.heading}>
          <div className={style.backarrow}>
            <img src={backarrowImg} alt="img" onClick={handleRedirect} />
          </div>

          <div className={style.filter}></div>
        </div>
        <div className={style.centered}>{toShowCards()}</div>
      </section>

      {showModal && (
        <div className={style.changeDiv}>
          <Modal />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    applyStatus: state.candidateReducer.applyStatus,

    errorMsg: state.candidateReducer.errorMsg,
    ErrorCode: state.candidateReducer.ErrorCode,
    loader: state.candidateReducer.loader,
    initial: state.candidateReducer.initial,
    list: state.candidateReducer.list,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentAppliedOpportunityList: (opportunityId, pageNo) =>
      dispatch(
        actionTypes.getStudentAppliedOpportunityList(opportunityId, pageNo)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Accepted);
