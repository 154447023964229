import * as actionTypes from "../Action/Actiontypes";

const initialState = {
  loader: false,
  initial: true,
  showBlur: false,
  showAlert: false,
  candidateDashboardData: {},
  candidateProfileData: {},
  addCandidateSkillMsg: "",
  addCandidateSkillStatus: false,
  candidateDashboardErrMsg: "",
  candidateDashboardStatus: false,
  candidateProfileErrMsg: "",
  candidateProfileStatus: false,
  updateCandidateProfileStatus: false,
  updateCandidateErrorMsg: "",
  candidateErrorMsg: "",
  candidateErrorCode: "",
  showSkillModal: false,
  showAdvanceFilter: false,
  skill_list: [],
  skillDatas: [],
  getSkillListrStatus: false,
  addSkillListStatus: false,
  showDeleteModal: false,
  applyStatus: false,
};

export const candidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ADVANCE_FILTER_MODAL:
      return {
        ...state,
        showBlur: action.payload,
        showAdvanceFilter: action.payload,
      };
    case actionTypes.SHOW_BLUR:
      return {
        showBlur: true,
      };
    case actionTypes.CANDIDATE_PROFILE_INITIAL:
      return {
        ...state,
        candidateProfileErrMsg: "",
        candidateProfileStatus: false,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: true,
        initial: true,
      };
    case actionTypes.CANDIDATE_PROFILE_SUCCESS:
      return {
        ...state,
        candidateProfileErrMsg: "",
        candidateProfileStatus: true,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: false,
        initial: true,
        candidateProfileData: action.payload,
      };
    case actionTypes.CANDIDATE_PROFILE_FAIL:
      return {
        ...state,
        candidateProfileErrMsg: "",
        candidateProfileStatus: false,
        candidateErrorMsg: action.payload,
        candidateErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.UPDATE_CANDIDATE_PROFILE_INITIAL:
      return {
        ...state,
        updateCandidateProfileErrMsg: "",
        updateCandidateProfileStatus: false,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: true,
        initial: true,
        updateCandidateSuccessStatus: false,
        showAlert: false,
        showBlur: false,
      };
    case actionTypes.UPDATE_CANDIDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateCandidateProfileErrMsg: "",
        updateCandidateProfileStatus: true,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: false,
        initial: true,
        updateCandidateSuccessStatus: true,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.UPDATE_CANDIDATE_PROFILE_FAIL:
      return {
        ...state,
        updateCandidateProfileErrMsg: "",
        updateCandidateProfileStatus: false,
        candidateErrorMsg: action.payload,
        candidateErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.CANDIDATE_DASHBOARD_INITIAL:
      return {
        ...state,
        candidateDashboardErrMsg: "",
        candidateDashboardStatus: false,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.CANDIDATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        candidateDashboardErrMsg: "",
        candidateDashboardStatus: true,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: false,
        initial: true,
        candidateDashboardData: action.payload,
        showAlert: false,
      };
    case actionTypes.CANDIDATE_DASHBOARD_FAIL:
      return {
        ...state,
        candidateDashboardErrMsg: "",
        candidateDashboardStatus: false,
        candidateErrorMsg: action.payload,
        candidateErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: false,
        showBlur: false,
      };

    case actionTypes.CLOSE_CANDIDATE_RESPONSE_ALERT:
      return {
        ...state,
        loader: false,
        candidateErrorCode: "",
        candidateErrorMsg: "",
        showBlur: false,
        showAlert: false,
      };
    case actionTypes.OPPO_CANDIDATE_LIST_INITIAL:
      return {
        ...state,
        oppcandidateListErrMsg: "",
        oppcandidatelistStatus: false,
        oppErrorMsg: "",
        oppErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.OPPO_CANDIDATE_LIST_SUCCESS:
      return {
        ...state,
        oppcandidateListErrMsg: "",
        oppcandidatelistStatus: true,
        oppErrorMsg: "",
        oppErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
        showBlur: false,
        opp_can_list: action.payload.allOpportinity,
        page_no_rec: action.payload.noOfPagesAsPerPageLimit,
      };
    case actionTypes.OPPO_CANDIDATE_LIST_FAIL:
      return {
        ...state,
        oppcandidateListErrMsg: "",
        oppcandidatelistStatus: true,
        oppErrorMsg: action.payload,
        oppErrorCode: action.ErrorCode,
        loader: false,
        showBlur: false,
        initial: false,
        showAlert: false,
      };
    case actionTypes.GET_CANDIDATE_SKILL_INITIAL:
      return {
        ...state,
        loader: true,
        initial: true,
        showAlert: false,
        showBlur: false,
        candidateErrorCode: "",
        candidateErrorMsg: "",
      };
    case actionTypes.GET_CANDIDATE_SKILL_SUCCESS:
      return {
        ...state,
        loader: false,
        initial: false,
        showAlert: false,
        showBlur: false,
        candidateErrorCode: "",
        candidateErrorMsg: "",
        skillDatas: action.payload.createdOpportinity,
        page_no_rec: action.payload.noOfPagesAsPerPageLimit,
      };
    case actionTypes.GET_CANDIDATE_SKILL_FAIL:
      return {
        ...state,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
        candidateErrorCode: action.ErrorCode,
        candidateErrorMsg: action.payload,
      };
    case actionTypes.OPEN_SKILL_MODAL:
      return {
        ...state,
        showBlur: true,
        showSkillModal: true,
      };
    case actionTypes.CLOSE_SKILL_MODAL:
      return {
        ...state,
        showBlur: false,
        showSkillModal: false,
      };
    case actionTypes.ADD_UPDATE_CANDIDATE_SKILL_INITIAL:
      return {
        ...state,
        addCandidateSkillErrMsg: "",
        addCandidateSkillStatus: false,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: true,
        initial: true,
        addCandidateSuccessStatus: false,
        showAlert: false,
        showBlur: false,
      };
    case actionTypes.ADD_UPDATE_CANDIDATE_SKILL_SUCCESS:
      return {
        ...state,
        addCandidateSkillErrMsg: "",
        addCandidateSkillStatus: true,
        addCandidateSkillMsg: action.payload,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: false,
        initial: true,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.ADD_UPDATE_CANDIDATE_SKILL_FAIL:
      return {
        ...state,
        addCandidateSkillErrMsg: "",
        addCandidateSkillStatus: false,
        candidateErrorMsg: action.payload,
        candidateErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.UPDATE_CANDIDATE_DELETE_MODAL:
      return {
        ...state,
        showBlur: action.payload,
        showDeleteModal: action.payload,
      };
    case actionTypes.DELETE_SKILL_MEDIA_INITIAL:
      return {
        ...state,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
        showBlur: false,
      };
    case actionTypes.DELETE_SKILL_MEDIA_FAIL:
      return {
        ...state,
        candidateErrorMsg: action.payload,
        candidateErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.APPLY_ON_OPPORTUNITY_INITIAL:
      return {
        ...state,
        errorMsg: "",
        loader: true,
        applyStatus: false,
        initial: true,
        ErrorCode: "",
        showAlert: false,
      };
    case actionTypes.APPLY_ON_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        applyStatus: action.payload,
        errorMsg: "",
        loader: false,
        initial: true,
        // role: action.role,
        ErrorCode: "",
        showAlert: false,
      };
    case actionTypes.APPLY_ON_OPPORTUNITY_FAIL:
      return {
        ...state,
        applyStatus: false,
        errorMsg: action.payload,
        loader: false,
        initial: false,
        showBlur: true,
        ErrorCode: action.ErrorCode,
        showAlert: true,
      };
    case actionTypes.GET_STUDENT_APPLIED_OPP_INITIAL:
      return {
        ...state,
        errorMsg: "",
        loader: true,
        applyStatus: false,
        initial: true,
        ErrorCode: "",
        showAlert: false,
      };
    case actionTypes.GET_STUDENT_APPLIED_OPP_SUCCESS:
      return {
        ...state,
        applyStatus: true,
        errorMsg: "",
        loader: false,
        initial: true,
        list: action.payload,
        // role: action.role,
        ErrorCode: "",
        showAlert: false,
      };
    case actionTypes.GET_STUDENT_APPLIED_OPP_FAIL:
      return {
        ...state,
        applyStatus: false,
        errorMsg: action.payload,
        loader: false,
        initial: false,
        showBlur: true,
        ErrorCode: action.ErrorCode,
        showAlert: true,
      };

    case actionTypes.CANDIDATE_NOTIFICATION_INITIAL:
      return {
        ...state,
        candidateNotificationErrMsg: "",
        candidateNotificationStatus: false,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: true,
        initial: true,
      };
    case actionTypes.CANDIDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        candidateNotificationErrMsg: "",
        candidateNotificationStatus: true,
        candidateErrorMsg: "",
        candidateErrorCode: "",
        loader: false,
        initial: true,
        candidateNotificationData: action.payload,
      };
    case actionTypes.CANDIDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        candidateNotificationErrMsg: "",
        candidateNotificationStatus: false,
        candidateErrorMsg: action.payload,
        candidateErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };

    default:
      return state;
  }
};
