import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import style from "./EditOpportunity.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import Uploadimg from "../../../assets/regicons/upload.svg";
import ResponseAlert from "../../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import Backdroplog from "../../../GenericComponent/Backdrop/Backdrop";
import defaultImg from "../../../assets/defaultimg/Rectangle 5337@2x.png";
import {
  WhiteButton,
  BlueButton,
} from "../../../GenericComponent/Buttons/button";

function EditOpportunity(props) {
  const [sidebar, setSidebar] = useState(false);

  const inputFile = useRef(null);
  const catRef = useRef();
  const skillRef = useRef();
  const titleRef = useRef();
  const aboutRef = useRef();
  const history = useHistory();

  const [operation, setOperation] = useState("NONE");
  const [profileData, setprofileData] = useState(
    props.history.location.state.profileData
      ? props.history.location.state.profileData
      : null
  );

  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(
    profileData && profileData.opportunityImgUrl
      ? profileData.opportunityImgUrl
      : defaultImg
  );

  const [categoryID, setCategoryID] = useState(
    props.history.location.state.profileData.opportunityMainSkillid
  );
  const [skillID, setSkillID] = useState(
    props.history.location.state.profileData.opportunitySubSkillid
  );
  const [isRemoveIcon, setIsRemoveIcon] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const opportunityId = props.history.location.state.opp_id;

  function updateProfileData(e) {
    let name = e.target.name;
    let value = e.target.value;
    setprofileData({ ...profileData, [name]: value });
    setErrorMsg(null);
  }

  const handleSubmitData = (e) => {
    e.preventDefault();
    if (categoryID === null || categoryID === "") {
      setErrorMsg({ categoryErr: "*Category is required." });
      catRef.current.focus();
    } else if (skillID === null || skillID === "") {
      setErrorMsg({ skillErr: "*Skill is required." });
      skillRef.current.focus();
    } else if (
      profileData.opportunityAbout === null ||
      profileData.opportunityAbout === ""
    ) {
      setErrorMsg({ aboutErr: "*About is required." });
      aboutRef.current.focus();
    } else if (
      profileData.opportunityTitle === null ||
      profileData.opportunityTitle === ""
    ) {
      setErrorMsg({ titleErr: "*Title is required." });
      titleRef.current.focus();
    } else {
      if (errorMsg === null) {
        let payload = {
          about: profileData.opportunityAbout,
          addressLine1: profileData.opportunityAddressLine1,
          city: profileData.opportunityCity,
          country: profileData.opportunityCountry,
          mainSkillId: categoryID,
          ourWins: profileData.opportunityOurWins,
          pinCode: profileData.orgPinCode,
          state: profileData.orgState,
          subSkillId: skillID,
          title: profileData.opportunityTitle,
          opportunityId: opportunityId,
        };
        let formData = new FormData();
        formData.append(
          "opportunityImage",
          profileImage !== null ? profileImage : ""
        );
        formData.append("jsonRequest", JSON.stringify(payload));
        props.createRecruiterOpportunity(formData, profileImage);
      }
    }
  };

  const imageHandler = (e) => {
    if (e.target.files.length > 0) {
      setProfileImage(e.target.files[0]);
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
      e.target.value = null;
      setIsRemoveIcon(true);
      setOperation("UPDATE");
    }
  };
  function removeImage() {
    setPreviewImage(defaultImg);
    setProfileImage("");
    setIsRemoveIcon(false);
    setOperation(profileData.profileUrl === null ? "NONE" : "DELETE");
  }
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleCategory = (value) => {
    setErrorMsg(null);
    setCategoryID(value.target.value);
    setSkillID("");
    props.getSubCategoryList(value.target.value);
  };
  const handleSubCategory = (value) => {
    setErrorMsg(null);
    setSkillID(value.target.value);
  };
  function showCategoryErr() {
    if (errorMsg !== null && errorMsg.categoryErr) {
      return errorMsg.categoryErr;
    } else {
      return "";
    }
  }
  function showSkillErr() {
    if (errorMsg !== null && errorMsg.skillErr) {
      return errorMsg.skillErr;
    } else {
      return "";
    }
  }
  function showAboutErr() {
    if (errorMsg !== null && errorMsg.aboutErr) {
      return errorMsg.aboutErr;
    } else {
      return "";
    }
  }
  function showTitleErr() {
    if (errorMsg !== null && errorMsg.titleErr) {
      return errorMsg.titleErr;
    } else {
      return "";
    }
  }
  const handleClick = () => {
    history.push("/Recruiter/Dashboard");
  };
  useEffect(() => {
    props.getMainCategoryList();
    props.getSubCategoryList(categoryID);
  }, []);

  const showData = (condition, val1, val2) => {
    if (condition) {
      return val1;
    } else {
      return val2;
    }
  };
  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <Backdroplog show={props.showBlur} />
        <div>
          <h1 className={style.para}>Edit an Opportunity</h1>
        </div>
      </section>

      <div className={style.centered}>
        <p className={style.heading}>Thumbnail of Opportunity</p>
        {/* {/ <img src={defaultImg}className={style.img} /> /} */}
        <img src={previewImage} className={style.img} alt="img" />
        {isRemoveIcon && (
          <i
            style={{
              position: "absolute",
              color: "green",

              cursor: "pointer",
            }}
            className="fa fa-times-circle"
            aria-hidden="true"
            onClick={() => removeImage()}
          ></i>
        )}
        <input
          type="file"
          name="profile"
          onChange={imageHandler}
          accept="image/png, image/jpg, image/jpeg,image/svg"
          ref={inputFile}
          style={{ display: "none", visibility: "none" }}
          for="img"
        />
        <img
          src={Uploadimg}
          className={style.img1}
          style={{ cursor: "pointer" }}
          id="img"
          alt="img"
          onClick={onButtonClick}
        />

        <div className={style.divFirst}>
          <label className={style.label1}> Opportunity Title</label>
          <input
            className={style.input1}
            defaultValue={showData(
              profileData,
              profileData.opportunityTitle,
              null
            )}
            ref={titleRef}
            name="opportunityTitle"
            onChange={(e) => updateProfileData(e)}
            autoComplete="off"
          ></input>
          <span className={style.errorMsg}>{showTitleErr()}</span>
        </div>
        <hr className={style.line} />
        <form className={style.div2}>
          <div className={style.left}>
            <div className={style.form1}>
              <label className={style.label2}> Category</label>
              <select
                autoComplete="off"
                name="opportunityMainSkillid"
                className={style.input2}
                onChange={(e) => {
                  handleCategory(e);
                  updateProfileData(e);
                }}
                ref={catRef}
                value={categoryID}
              >
                <option value="">--Select--</option>
                {props.category_list && props.category_list.length > 0
                  ? props.category_list.map((item, index) => (
                      <option value={item.categoryId} key={index}>
                        {item.categoryName}
                      </option>
                    ))
                  : null}
              </select>
              <span className={style.errorMsgbelow}>{showCategoryErr()}</span>
              <label className={style.label2} style={{ marginTop: "7%" }}>
                Location
              </label>
              <input
                className={style.location}
                defaultValue={showData(
                  profileData,
                  profileData.opportunityAddressLine1,
                  "-"
                )}
                name="opportunityAddressLine1"
                onChange={(e) => updateProfileData(e)}
                autoComplete="off"
              ></input>
              <label
                className={style.label2}
                style={{
                  marginTop: "8%",
                  color: "#24242E",
                  fontSize: "14px",
                }}
              >
                About Opportunity&nbsp;Scholarship
              </label>
              <textarea
                className={style.textarea1}
                ref={aboutRef}
                style={{
                  width: "152%",
                  height: "150%",
                  opacity: "100%",
                  padding: "10px",
                }}
                name="opportunityAbout"
                defaultValue={showData(
                  profileData,
                  profileData.opportunityAbout,
                  null
                )}
                onChange={(e) => updateProfileData(e)}
              ></textarea>
              <span className={style.errorMsgta}>{showAboutErr()}</span>
              <label
                className={style.label2}
                style={{
                  marginTop: "13%",
                  color: "#24242E",
                  fontSize: "14px",
                }}
              >
                Wins
              </label>
              <textarea
                className={style.textarea1}
                style={{
                  width: "152%",
                  height: "150%",
                  opacity: "100%",
                  padding: "10px",
                }}
                name="opportunityOurWins"
                defaultValue={showData(
                  profileData,
                  profileData.opportunityOurWins,
                  null
                )}
                onChange={(e) => updateProfileData(e)}
              ></textarea>
              <br />
            </div>
          </div>
          <div className={style.right}>
            <div className={style.form2}>
              <label className={style.label3}> Skill</label>
              <select
                className={style.input3}
                onChange={(e) => {
                  handleSubCategory(e);
                  updateProfileData(e);
                }}
                name="opportunitySubSkillid"
                ref={skillRef}
                value={skillID}
              >
                <option value="">--Select--</option>

                {props.sub_category_list && props.sub_category_list.length > 0
                  ? props.sub_category_list.map((item, index) => (
                      <option value={item.subCategoryId} key={index}>
                        {item.subCategoryName}
                      </option>
                    ))
                  : null}
              </select>
              <span className={style.errorMsgbelow}>{showSkillErr()}</span>
            </div>
          </div>
        </form>

        <div className={style.buttonDiv}>
          <div>
            <WhiteButton children="Cancel" onClick={() => handleClick()} />
          </div>
          <div>
            <BlueButton children="Update" onClick={handleSubmitData} />
          </div>
        </div>
        <br />
      </div>
      {props.showAlert && (
        <div className={style.changeDiv}>
          <ResponseAlert
            redirect={
              props.recSuccessStatus ? "/Recruiter/Opportunities" : null
            }
            message={
              props.recSuccessStatus
                ? "Opportunity Updated successfully!"
                : props.recErrorMsg
            }
            type="RECRUITER"
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profileData: state.orgReducer.orgOpportunityData,
    loader: state.recruiterReducer.loader,
    createErrorMsg: state.recruiterReducer.createErrorMsg,
    createErrorCode: state.recruiterReducer.createErrorCode,
    createRecruiterStatus: state.recruiterReducer.createrecruiterStatus,
    loginStatus: state.authReducer.loginStatus,
    category_list: state.recruiterReducer.category_list,
    sub_category_list: state.recruiterReducer.sub_category_list,
    categoryErrorMsg: state.recruiterReducer.categoryErrorMsg,
    categoryErrorCode: state.recruiterReducer.categoryErrorCode,
    categoryListErrMsg: state.recruiterReducer.categoryListErrMsg,
    categoryListStatus: state.recruiterReducer.categoryListStatus,
    showBlur: state.recruiterReducer.showBlur,
    showAlert: state.recruiterReducer.showAlert,
    recErrorStatus: state.recruiterReducer.recErrorStatus,
    // recErrorMsg: state.recruiterReducer.recErrorMsg,
    recSuccessStatus: state.recruiterReducer.recSuccessStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRecruiterOpportunity: (formData, imageData) =>
      dispatch(actionTypes.createRecruiterOpportunity(formData, imageData)),
    getMainCategoryList: () => dispatch(actionTypes.getMainCategoryList()),
    getSubCategoryList: (id) => dispatch(actionTypes.getSubCategoryList(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditOpportunity);
