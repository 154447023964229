import React, { useState, useRef, useEffect } from "react";
import style from "./appliedfilterc.module.css";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import { BlueButton, WhiteButton } from "../../Buttons/button";

import Cross from "../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";

function AdvanceFilterc(props) {
  const catRef = useRef();
  const skillRef = useRef();
  const [categoryID, setCategoryID] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [skillID, setSkillID] = useState("");
  const [locationId, setLocationId] = useState(null);
  const [skill1, setskill1] = useState([]);
  const handleCategory = (value) => {
    setErrorMsg(null);
    setSkillID("");
    setCategoryID(value);
    props.getSubCategoryList(value);
  };

  const handleSubCategory = (value) => {
    setErrorMsg(null);
    setSkillID(value);
  };

  const handleLocation = (value) => {
    setErrorMsg(null);
    setLocationId(value);
  };

  const handlesubmit = () => {
    props.handleModal(false);
    props.handlesubmit(categoryID, skillID, locationId);
  };

  const resetFilter = () => {
    setSkillID(null);
    setLocationId(null);
    setCategoryID(null);
    setskill1([]);
  };

  useEffect(() => {
    setskill1(props.sub_category_list);
  }, [props.sub_category_list]);

  return (
    <div className={style.container}>
      <img
        src={Cross}
        alt="img"
        className={style.closeModal}
        onClick={() => props.handleModal()}
      />
      <h1 className={style.para}> Advance Filter</h1>
      <div>
        <div className={style.div}>
          <form className={style.form1}>
            <div className={style.first}>
              <label className={style.label}> Category</label>
              <select
                ref={catRef}
                className={style.input}
                name="categoryId"
                onChange={(e) => handleCategory(e.target.value)}
              >
                <option value="">Select a category</option>
                {props.category_list && props.category_list.length > 0
                  ? props.category_list.map((item, index) => (
                      <option value={item.categoryId}>
                        {item.categoryName}
                      </option>
                    ))
                  : null}
              </select>

              <div className={style.second}>
                <label className={style.label}> Skill</label>

                <select
                  className={style.input}
                  ref={skillRef}
                  onChange={(e) => handleSubCategory(e.target.value)}
                  value={skillID}
                >
                  <option value="">Select a skill</option>

                  {skill1 && skill1.length > 0
                    ? skill1.map((item, index) => (
                        <option
                          value={item.subCategoryId}
                          style={{ cursor: "pointer" }}
                        >
                          {item.subCategoryName}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </div>

            <div className={style.fourth}>
              <label className={style.label}> Location</label>
              <input
                className={style.input}
                onChange={(e) => handleLocation(e.target.value)}
              />
            </div>

            <div className={style.btn_css}>
              <button
                type="reset"
                className={style.whiteButton}
                onClick={() => resetFilter()}
              >
                Reset
              </button>
              <BlueButton children="Apply" onClick={handlesubmit} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.candidateReducer.loader,
    skillDatas: state.candidateReducer.skillDatas,
    candidateErrorMsg: state.candidateReducer.candidateErrorMsg,
    candidateErrorCode: state.candidateReducer.candidateErrorCode,
    sub_category_list: state.recruiterReducer.sub_category_list,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubCategoryList: (id) => dispatch(actionTypes.getSubCategoryList(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceFilterc);
