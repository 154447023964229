import React from "react";
import { useHistory } from "react-router-dom";
import * as actionTypes from "../../../Store/Action/index";
import { connect } from "react-redux";

import style from "./confirm.module.css";
import OTPModal from "./OTPScreen/OTPScreen";
import Cycle from "../../../assets/cycling.jpg";
import Cricket from "../../../assets/Batsman.png";
import closeIcon from "../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import { BlueButton } from "../../../GenericComponent/Buttons/button";
import Loader from "../../../assets/Loader/spinner.gif";

function Confirm(props) {
  const email = props.sendemail;

  const history = useHistory();
  const handleClick = () => {
    history.push("/");
  };
  return (
    <div className={style.container}>
      {props.loader ? (
        <div className="loader_styles">
          <img src={Loader} alt="loader/" />
        </div>
      ) : (
        <>
          <img
            src={closeIcon}
            className={style.closeModal}
            onClick={() => props.hideModal()}
            alt="img"
          />
          {props.type !== 2 && (
            <div className={style.leftImg}>
              <img src={Cycle} alt="img" />
            </div>
          )}
          {props.type === 2 && (
            <div className={style.leftImg}>
              <img src={Cricket} alt="img" />
            </div>
          )}
          <div>
            <div className={style.msg2}>
              {props.type !== 2 && (
                <p>
                  Thanks for signing up. Confirm your email address &nbsp;
                  <b>{props.newUserEmailId}</b> &nbsp;to activate your account.
                </p>
              )}
              {props.type === 2 && (
                <p>
                  Please check your email. A One Time Password has been sent to
                  reset your password.
                </p>
              )}
            </div>
            <div style={{ margin: "auto 20%" }}>
              {props.type !== 2 && (
                <BlueButton
                  children="confirm"
                  onClick={() => props.SignupOTPModal()}
                />
              )}
              {props.type === 2 && (
                <BlueButton
                  children="Confirm"
                  onClick={() => props.OTPModal()}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.authReducer.loader,
    showConfirmModal: state.authReducer.showConfirmModal,
    signUpSuccess: state.authReducer.signUpSuccess,
    newUserEmailId: state.authReducer.newuserEmailid,
    type: state.authReducer.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SignIn: () => dispatch(actionTypes.handleSignInModal()),
    SignUp: () => dispatch(actionTypes.handleSignUpModal()),
    hideModal: () => dispatch(actionTypes.hideAuthModal()),
    OTPModal: () => dispatch(actionTypes.handleOTPModal()),
    SignupOTPModal: () => dispatch(actionTypes.handleSignupOTPModal()),
    handleConfirmModal: (data) =>
      dispatch(actionTypes.handleConfirmModal(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
