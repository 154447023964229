import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cards from "./Cards/cards";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import style from "./r_opportunities.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import Backdroplog from "../../../GenericComponent/Backdrop/Backdrop";
import Pagination from "../../../GenericComponent/Pagination/Pagination";
import Delete from "../../../GenericComponent/Modal/Delete/Delete";
import Modal from "../../../GenericComponent/Appliedfilterc/appliedfilterc";
import backarrowImg from "../../../assets/back arrow/Icon ionic-ios-arrow-round-back@3x.png";
import Filter from "../../../assets/filter/Group 18142.png";
import Loader from "../../../assets/Loader/spinner.gif";
import NoData from "../../../assets/globe.jpg";

function ROpportunities(props) {
  const history = useHistory();

  const [showModal, setshowModal] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [oppId, setOppId] = useState(null);
  const [categoryId, setCategoryID] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [fromId, setfromId] = useState(null);
  const [toId, setToId] = useState(null);
  const [noDataVal, setNoDataVal] = useState(false);

  const handleModal = () => {
    if (showModal) {
      setshowModal(false);
    } else {
      setshowModal(true);
    }
  };

  const handleFilterModal = (val1, val2) => {
    props.handleRecAdvanceFilter(val1);
  };
  const handlefilter = (val1, val2, val3, val4, val5) => {
    setCategoryID(val1);
    setSubCategoryId(val2);
    setLocationId(val3);
    setfromId(val4);
    setToId(val5);
    setPageno(1);
  };

  const toShowCards = () => {
    if (props.opp_rec_list && props.opp_rec_list.length > 0) {
      return (
        <Cards
          data={props.opp_rec_list}
          handleDelete={handleDelete}
          handleStatus={handleStatus}
          handleDuplicate={handleDuplicate}
        />
      );
    } else {
      if (props.loader) {
        return (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <p>
                <b>No Data Found – Create opportunities/scholarships</b>
              </p>
              <img
                className={style.nodata}
                style={{ width: "50%", height: "60%" }}
                src={NoData}
                alt="nodata"
              />
            </div>
          </div>
        );
      } else {
        if (noDataVal) {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <p>
                  <b>No Data Found – Create opportunities/scholarships</b>
                </p>
                <img
                  className={style.nodata}
                  style={{ width: "50%", height: "60%" }}
                  src={NoData}
                  alt="nodata"
                />
              </div>
            </div>
          );
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNoDataVal(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleDelete = (id, val) => {
    setOppId(id);
    props.handleRecruiterDeleteModal(val);
  };

  const handleStatus = (id, status) => {
    const payload = {
      categoryId: null,
      createdFrom: null,
      createdTo: null,
      subCategoryId: null,
      location: null,
    };
    props.updateOpportuntiyStatus(id, status, pageno, payload);
  };
  const handleDuplicate = (formData) => {
    const payload = {
      categoryId: null,
      createdFrom: null,
      createdTo: null,
      subCategoryId: null,
      location: null,
    };
    props.createDuplicateOpportunity(formData, pageno, payload);
  };

  const toShowPagination = () => {
    if (props.page_no_rec > 1) {
      return (
        <Pagination
          activePage={pageno}
          pageRangeDisplayed={props.page_no_rec}
          onLeftChange={() => onLeftChange()}
          onRightChange={() => onRightChange()}
        />
      );
    }
  };

  const onLeftChange = () => {
    if (pageno !== 1) {
      setPageno(pageno - 1);
    }
  };

  const onRightChange = () => {
    if (pageno !== props.page_no_rec) {
      setPageno(pageno + 1);
    }
  };

  const submitDelete = async () => {
    const payload = {
      categoryId: null,
      createdFrom: null,
      createdTo: null,
      subCategoryId: null,
      location: null,
    };
    await props.deleteOpportunity(oppId, pageno, payload);
    await props.handleRecruiterDeleteModal(false);
  };

  const getDetails = () => {
    const payload = {
      categoryId: categoryId,
      createdFrom: fromId,
      createdTo: toId,
      subCategoryId: subCategoryId,
      location: locationId,
    };
    props.getListByRecruiter("ALL", pageno, payload);
  };

  useEffect(() => {
    getDetails();
  }, [pageno, categoryId, subCategoryId, locationId, fromId, toId]);

  useEffect(() => {
    props.getMainCategoryList();
  }, []);

  return (
    <div className={style.container}>
      <header className={style.headerSection}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <Backdroplog show={props.showBlur} />
      <section className={style.sectionStyle}>
        <div className={style.sidemenu}>
          <Sidebar side={sidebar} />
        </div>
        {props.loader ? (
          <div className={style.loader}>
            <img
              src={Loader}
              alt="loader/"
              style={{ marginLeft: "50%", marginTop: "50%" }}
            />
          </div>
        ) : (
          <>
            <div className={style.topHeading}>
              <div className={style.leftHead}>
                <label className={style.tagline}>
                  Created Opportunities/Scholarship
                </label>
              </div>
              <div className={style.options}>
                <img
                  src={Filter}
                  alt="img"
                  style={{
                    cursor: "pointer",
                    marginTop: "1em",
                    width: "3.3em",
                  }}
                  onClick={() => handleFilterModal(true, true)}
                />
              </div>
              <div className={style.rightHead}>
                <div>
                  <button
                    className={style.createOpp}
                    type="button"
                    onClick={() => history.push("/Recruiter/CreateOpportunity")}
                  >
                    Create an Opportunity
                  </button>
                </div>
              </div>
            </div>
            <div className={style.centered}>
              {toShowCards()}
              <br></br>
            </div>
            {props.showDeleteModal && (
              <>
                <div className={style.newdiv}>
                  <Delete
                    cancelDelete={handleDelete}
                    confirmDelete={submitDelete}
                    oppId={props.opp_rec_list.opportunityId}
                  />
                </div>
              </>
            )}
          </>
        )}
      </section>
      {props.showRecAdvanceFilter && (
        <div className={style.changeDiv3}>
          <Modal
            handleFilterModal={handleFilterModal}
            handlefilter={handlefilter}
            category_list={props.category_list}
          />
        </div>
      )}
      <div className={style.footer}>{toShowPagination()}</div>
      {showModal && (
        <div className={style.changeDiv}>
          <Modal handleModal={handleModal} />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    opprecruiterListErrMsg: state.recruiterReducer.opprecruiterListErrMsg,
    opprecruiterlistStatus: state.recruiterReducer.opprecruiterlistStatus,
    oppErrorMsg: state.recruiterReducer.oppErrorMsg,
    oppErrorCode: state.recruiterReducer.oppErrorCode,
    loader: state.recruiterReducer.loader,
    initial: state.recruiterReducer.initial,
    opp_rec_list: state.recruiterReducer.opp_rec_list,
    page_no_rec: state.recruiterReducer.page_no_rec,
    showAlert: state.recruiterReducer.showAlert,
    showBlur: state.recruiterReducer.showBlur,
    showRecAdvanceFilter: state.recruiterReducer.showRecAdvanceFilter,
    showDeleteModal: state.recruiterReducer.showDeleteModal,
    sub_category_list: state.recruiterReducer.sub_category_list,
    category_list: state.recruiterReducer.category_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListByRecruiter: (filter, pageno, payload) =>
      dispatch(actionTypes.getListByRecruiter(filter, pageno, payload)),
    deleteOpportunity: (id, pageno, payload) =>
      dispatch(actionTypes.deleteOpportunity(id, pageno, payload)),
    handleRecruiterDeleteModal: (val) =>
      dispatch(actionTypes.handleRecruiterDeleteModal(val)),
    updateOpportuntiyStatus: (id, status, pageno, payload) =>
      dispatch(
        actionTypes.updateOpportuntiyStatus(id, status, pageno, payload)
      ),
    createDuplicateOpportunity: (formData, pageno, payload) =>
      dispatch(
        actionTypes.createDuplicateOpportunity(formData, pageno, payload)
      ),
    getMainCategoryList: () => dispatch(actionTypes.getMainCategoryList()),
    handleRecAdvanceFilter: (val) =>
      dispatch(actionTypes.handleRecAdvanceFilter(val)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ROpportunities);
