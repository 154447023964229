import React, { useState, useRef } from "react";
import style from "./cards.module.css";
import { useHistory } from "react-router-dom";
import defaultimg from "../../../../assets/defaultimg/Rectangle 5337@2x.png";
import appliedImg from "../../../../assets/applied/APPLIED people@3x.png";
import eyeIcon from "../../../../assets/eye/Watched@3x.png";
import moreImg from "../../../../assets/eye/More.png";
import detailImg from "../../../../assets/details/Details@2x.png";

function Cards(props) {
  let history = useHistory();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isShow, setIsShow] = useState(true);
  const myRef = useRef();
  const showData = (index) => {
    if (isShow && currentIndex !== null) {
      setIsShow(false);
      setCurrentIndex(null);
    } else {
      setIsShow(true);
      setCurrentIndex(index);
    }
  };
  const hideData = (index) => {
    if (index === currentIndex) {
      setIsShow(false);
    }
  };

  const toShowStatus = (item) => {
    if (item.status === "OPEN") {
      return (
        <button
          className={style.modal_label}
          style={{ cursor: "pointer" }}
          onClick={() => toHandleStatus(item.opportunityId, item.status)}
        >
          Close
        </button>
      );
    } else {
      return (
        <button
          className={style.modal_label}
          style={{ cursor: "pointer" }}
          onClick={() => toHandleStatus(item.opportunityId, item.status)}
        >
          Open
        </button>
      );
    }
  };

  function handleClick(id) {
    history.push("/");
    history.push({
      pathname: "/Opportunity_Details",
      state: {
        backPath: "/Recruiter/Opportunities",
        opp_id: id,
      },
    });
  }

  const toHandleStatus = (id, status1) => {
    let oppId = id;
    let status = status1 === "OPEN" ? "CLOSED" : "OPEN";
    let formData = new FormData();
    formData.append("OpportunityId", oppId);
    formData.append("status", status);
    props.handleStatus(oppId, status);
  };

  const toHandleDuplicate = (id, status1) => {
    let status = status1 === "DUPLICATE" ? null : true;
    let data = {
      opportunityId: id,
      duplicate: status,
    };
    let formData = new FormData();
    formData.append("jsonRequest", JSON.stringify(data));
    props.handleDuplicate(formData);
  };

  const toHandleDelete = (id) => {
    props.handleDelete(id, true);
  };
  function toappliedlist(id) {
    history.push("/");
    history.push({
      pathname: "/AppliedStudents",
      state: {
        OpportunityId: id,
      },
    });
  }
  return (
    <>
      {props.data && props.data.length > 0
        ? props.data.map((item, index) => (
            <div className={style.maincontainer}>
              <div className={style.maincard}>
                <div
                  className={
                    item.status === "OPEN"
                      ? style.openSquare
                      : style.closeSquare
                  }
                ></div>
                <div className={style.card}>
                  <div className={style.box1}>
                    <div className={style.heading}>
                      <div className={style.lefthead}>
                        <p className={style.otitle}>
                          {item.title} <span>{item.organizationName}</span>
                        </p>
                      </div>
                      <div className={style.detail}>
                        <img
                          src={detailImg}
                          alt="img"
                          onClick={() => handleClick(item.opportunityId)}
                        />
                      </div>
                      <div className={style.more}>
                        <img
                          src={moreImg}
                          alt="img"
                          style={{ position: "relative" }}
                          onClick={() => showData(index)}
                        />
                        {isShow && index === currentIndex ? (
                          <div
                            className={style.modal_container}
                            ref={myRef}
                            onMouseLeave={() => hideData(index)}
                          >
                            {item.status !== "DUPLICATE" && (
                              <button
                                className={style.modal_label}
                                style={{ cursor: !item.duplicate && "pointer" }}
                                disabled={item.duplicate}
                                onClick={() =>
                                  toHandleDuplicate(
                                    item.opportunityId,
                                    item.status
                                  )
                                }
                              >
                                Duplicate
                              </button>
                            )}
                            {toShowStatus(item)}
                            <button
                              className={style.modal_label}
                              style={{ cursor: "pointer" }}
                              onClick={() => toHandleDelete(item.opportunityId)}
                            >
                              Delete
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={style.shelf}>
                      <div className={style.labelbox}>Category</div>{" "}
                      <div className={style.valuebox}>{item.mainSkill}</div>
                    </div>

                    <div className={style.shelf}>
                      <div className={style.labelbox}>Skills</div>
                      <div className={style.valuebox}>{item.subSkill}</div>
                    </div>

                    <div className={style.shelf}>
                      <div className={style.labelbox}>Location</div>
                      <div className={style.valuebox}>
                        {item.addressLine1 && item.addressLine1 !== null
                          ? item.addressLine1
                          : "-"}
                      </div>
                      {/* props.profileData!==undefined && props.profileData!==null && data!==null */}
                    </div>
                    <hr></hr>
                    <div className={style.heading}>
                      <div className={style.lefthead1}>
                        <div className={style.applied}>
                          <div className={style.constantImg}>
                            <img
                              src={appliedImg}
                              alt="img"
                              style={{ cursor: "pointer" }}
                              onClick={() => toappliedlist(item.opportunityId)}
                            />
                            <p
                              onClick={() => toappliedlist(item.opportunityId)}
                              style={{
                                marginLeft: "18px",
                                fontSize: ".8rem",
                                color: "rgb(30, 17, 141)",

                                cursor: "pointer",
                              }}
                            >
                              {item.appliedCandidateCount} Applied
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={style.constantImg1}>
                        <div className={style.eye}>
                          <img src={eyeIcon} alt="img" />
                        </div>

                        <div
                          style={{
                            marginRight: "10px",
                            marginTop: "2.5%",
                            fontSize: ".8rem",
                          }}
                        >
                          {item.viewCount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={style.box2}>
                    <div className={style.card_image}>
                      <img
                        src={
                          item.opportunityImgUrl === null
                            ? defaultimg
                            : item.opportunityImgUrl
                        }
                        alt="img"
                        media="(width: 100%)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : null}
    </>
  );
}

export default Cards;
