import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cards from "./Cards/cards";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import style from "./opportunities.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import Modal from "../../../GenericComponent/Appliedfilterc/appliedfilterc";
import Pagination from "../../../GenericComponent/Pagination/Pagination";
import backarrowImg from "../../../assets/eye/Icon ionic-ios-arrow-round-back.png";
import Filter from "../../../assets/filter/Group 18142.png";
import NoData from "../../../assets/globe.jpg";
import Loader from "../../../assets/Loader/spinner.gif";
import Backdroplog from "../../../GenericComponent/Backdrop/Backdrop";

function OrgOpportunities(props) {
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [categoryId, setCategoryID] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [fromId, setfromId] = useState(null);
  const [toId, setToId] = useState(null);
  const toShowPagination = () => {
    if (props.page_no_rec !== undefined && props.page_no_rec > 1) {
      return (
        <Pagination
          activePage={pageNo}
          pageRangeDisplayed={props.page_no_rec}
          onLeftChange={() => onLeftChange()}
          onRightChange={() => onRightChange()}
        />
      );
    }
  };

  const onLeftChange = () => {
    if (pageNo !== 1) {
      setPageNo(pageNo - 1);
    }
  };

  const onRightChange = () => {
    if (pageNo !== props.page_no_rec) {
      setPageNo(pageNo + 1);
    }
  };

  const toShowCards = () => {
    if (props.opp_org_list !== undefined && props.opp_org_list.length > 0) {
      return (
        <>
          <div className={style.subcentered}>
            <Cards data={props.opp_org_list} handleStatus={handleStatus} />
          </div>
        </>
      );
      //
    } else {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <p>
              <b>No Data Found – Create opportunities/scholarships</b>
            </p>
            <img
              className={style.nodata}
              style={{ width: "50%", height: "60%" }}
              src={NoData}
              alt="nodata"
            />
          </div>
        </div>
      );
    }
  };

  const handleStatus = (id, status) => {
    let filters = {
      categoryId: null,
      createdFrom: null,
      createdTo: null,
      subCategoryId: null,
    };
    props.updateOrgOpportuntiyStatus(id, status, pageNo, filters);
  };

  const handleModal = () => {
    if (showModal) {
      setshowModal(false);
    } else {
      setshowModal(true);
    }
  };

  useEffect(() => {
    let filters = {
      categoryId: categoryId,
      createdFrom: fromId,
      createdTo: toId,
      subCategoryId: subCategoryId,
      location: locationId,
    };
    props.getListByOrganisation(pageNo, filters);
  }, [pageNo, categoryId, subCategoryId, locationId, fromId, toId]);

  const handleFilterModal = (val1, val2) => {
    props.handleRecAdvanceFilter(val1);
  };

  useEffect(() => {
    props.getMainCategoryList();
  }, []);

  const handlefilter = (val1, val2, val3, val4, val5) => {
    setCategoryID(val1);
    setSubCategoryId(val2);
    setLocationId(val3);
    setfromId(val4);
    setToId(val5);
    setPageNo(1);
  };

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <Backdroplog show={props.showBlur} />
      <section className={style.sectionStyle}>
        <div className={style.sidemenu}>
          <Sidebar side={sidebar} />
        </div>
        {props.loader ? (
          <div className="loader_styles">
            <img src={Loader} alt="loader/" />
          </div>
        ) : (
          <>
            <div className={style.topHeading} style={{ display: "flex" }}>
              <div className={style.Initial} style={{ width: "65vw" }}>
                <img
                  className={style.labelImg}
                  src={backarrowImg}
                  alt="pro"
                  onClick={() => history.push("/Dashboard")}
                />
                <label className={style.label}>
                  Created Opportunities/Scholarship
                </label>
              </div>
              <div className={style.options}>
                <img
                  src={Filter}
                  alt="img"
                  style={{
                    cursor: "pointer",
                    marginTop: "-12px",
                    width: "3.3em",
                  }}
                  onClick={() => handleFilterModal(true, true)}
                />
              </div>
            </div>
            <div className={style.centered}>{toShowCards()}</div>
            <div className={style.footer}>{toShowPagination()}</div>
          </>
        )}
      </section>
      {props.showRecAdvanceFilter && (
        <div className={style.backdrop}>
          <div className={style.changeDiv3}>
            <Modal
              handleFilterModal={handleFilterModal}
              handlefilter={handlefilter}
              category_list={props.category_list}
            />
          </div>
        </div>
      )}
      {showModal && (
        <div className={style.changeDiv}>
          <Modal handleModal={handleModal} />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    oppErrorMsg: state.orgReducer.oppErrorMsg,
    oppErrorCode: state.orgReducer.oppErrorCode,
    loader: state.orgReducer.loader,
    initial: state.orgReducer.initial,
    opp_org_filter: state.orgReducer.opp_org_filter,
    opp_org_list: state.orgReducer.opp_org_list,
    page_no_rec: state.orgReducer.page_no_rec,
    showAlert: state.orgReducer.showAlert,
    showBlur: state.orgReducer.showBlur,
    showDropDownModal: state.orgReducer.showDropDownModal,
    recsignUpStatus: state.orgReducer.recsignUpStatus,
    showRecAdvanceFilter: state.recruiterReducer.showRecAdvanceFilter,
    category_list: state.recruiterReducer.category_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListByOrganisation: (pageNo, filters) =>
      dispatch(actionTypes.getListByOrganisation(pageNo, filters)),
    updateOrgOpportuntiyStatus: (id, status, pageNo, filters) =>
      dispatch(
        actionTypes.updateOrgOpportuntiyStatus(id, status, pageNo, filters)
      ),
    handleRecAdvanceFilter: (val) =>
      dispatch(actionTypes.handleRecAdvanceFilter(val)),
    getMainCategoryList: () => dispatch(actionTypes.getMainCategoryList()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrgOpportunities);
