import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAqBZjokr7cMF9unUTGiw2GUTDtb1gkbWM",
  authDomain: "igotgame2.firebaseapp.com",
  projectId: "igotgame2",
  storageBucket: "igotgame2.appspot.com",
  messagingSenderId: "889066049031",
  appId: "1:889066049031:web:9d9c53bb2e469f9fa324a2",
};

firebase.initializeApp(firebaseConfig);

// const messaging = firebase.messaging();

const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : console.log("Browser Doesn't Support Messaging.");

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({
      vapidKey:
        "BGSAtcWezOLnz-2cF8BqqBi13ugNqu4gPaicWvPmrtWT3Hq9h0tXuUi3jHxgjcqa5A2bf3MS-sThWScRgA3m4_g",
    });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log(error.message);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
