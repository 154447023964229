import React from "react";
import style from "./../OpportunityDetails.module.css";
import defaultImg from "../../../../assets/defaultimg/Rectangle 5337.png";

function MainDetails(props) {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div>
        <img
          src={props.profileData && props.profileData.opportunityImgUrl!==null? props.profileData.opportunityImgUrl : defaultImg}
          className={style.cricket}
          alt="img"
        />
      </div>
      <div style={{ marginTop: "12px" }}>
        <p className={style.para1}>
          {props.profileData ? props.profileData.opportunityTitle : ""}
        </p>

        <p className={style.para2}>
          {props.profileData ? props.profileData.orgName : ""}
        </p>

        {/* <p className={style.para3}>
          {props.profileData
            ? props.profileData.opportunityAppliedCandidateCount
            : 0}
          days ago
        </p>
        <p className={style.para4}> 25 Applied</p> */}
      </div>
    </div>
  );
}
export default MainDetails;
