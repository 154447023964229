import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as actionTypes from "../../../Store/Action/index";
import { connect } from "react-redux";
import style from "./SViewProfile.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import defaultImage from "../../../assets/defaultimg/Rectangle 5337@2x.png";
import media from "../../../assets/ellipse/Rectangle -1.png";
import { BlueButton } from "../../../GenericComponent/Buttons/button";
import moment from "moment";
function SProfile(props) {
  const [sidebar, setSidebar] = useState(false);

  const history = useHistory();
  function showData(data) {
    if (
      props.profileData !== undefined &&
      props.profileData !== null &&
      data !== null &&
      data !== ""
    ) {
      return <p>{data}</p>;
    } else {
      return <p style={{ opacity: "0.5" }}>No Information Provided.</p>;
    }
  }

  function showTopData(data) {
    if (
      props.profileData !== undefined &&
      props.profileData !== null &&
      data !== null &&
      data !== ""
    ) {
      return data;
    } else {
      return "No Information Provided.";
    }
  }

  function showSkillData(condition, val1, val2) {
    if (condition) {
      return val1.join(" , ");
    } else {
      return val2;
    }
  }

  function showskilldatas() {
    if (
      props.skillDatas !== undefined &&
      props.skillDatas !== null &&
      props.skillDatas.length > 0
    ) {
      const newarray = props.skillDatas.slice(0, 3);
      return newarray.map((userSkill, index) => (
        <div className={style.row3} key={index}>
          <p>Skill:{showTopData(userSkill.subCategory)}</p>
          <div className={style.upper}>
            <div className={style.upperleft}>
              <p>Description:</p>
            </div>
            <div className={style.upperright}>
              <p>{showTopData(userSkill.description)}</p>
            </div>
          </div>

          <div className={style.lower}>
            <div className={style.lowerleft}>
              <p>Media:</p>
            </div>
            <div className={style.lowerright}>
              {userSkill.mediaList &&
                userSkill.mediaList.length > 0 &&
                userSkill.mediaList.map((item, index3) => (
                  <div className={style.item} key={index3}>
                    {item.fileType === "image" && (
                      <img
                        src={item.fileUri}
                        alt="medias"
                        style={{
                          width: "210px",
                          height: "200px",
                          margin: "3px",
                        }}
                      />
                    )}
                    {item.fileType === "video" && (
                      // <iframe
                      //   src={item.fileUri}
                      //   height="200px"
                      //   width="210px"
                      //   alt="medias"
                      // ></iframe>
                      <video
                        width="210px"
                        height="200px"
                        controls
                        preload="metadata"
                      >
                        <source
                          src={`${item.fileUri}#t=0.5`}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </div>
                ))}
              {/* {userSkill.mediaList.fileType==="image" &&  <img src={userSkill.mediaList.fileUri} alt="img" style={{height:"200px", width:"200px" }} />}
                    {userSkill.mediaList.fileType==="video" &&  <iframe src={userSkill.mediaList.fileUri} alt="img" style={{ marginLeft: "10px" }}></iframe>} */}
              {/* <img src={media} alt="img" />
                    <img src={media} alt="img" style={{ marginLeft: "10px" }} />
                    <img src={media} alt="img" style={{ marginLeft: "10px" }} />
                    <img src={media} alt="img" style={{ marginLeft: "10px" }} /> */}
            </div>
          </div>
        </div>
      ));
    }
  }

  function showImgData(data) {
    if (
      props.profileData !== undefined &&
      props.profileData !== null &&
      data !== null
    ) {
      return data;
    } else {
      return defaultImage;
    }
  }
  useEffect(() => {
    props.getCandidateDashboard();
    props.getCandidateSkills(4, 1);
  }, []);

  return (
    <div className={style.maincontainer}>
      <div className={style.container}>
        <header className={style.headerStyle}>
          <Header sidebar={setSidebar} side={sidebar} />
        </header>
        <section className={style.sectionStyle}>
          <div>
            <Sidebar side={sidebar} />
          </div>
          <div className={style.topsomepagewrapper}>
            <div className={style.toprow}>
              <div className={style.topcolumn}>
                <div>
                  <p className={style.para}>Profile</p>
                </div>
              </div>
              <div className={style.topcolumn}>
                <div className={style.editbtn}>
                  {localStorage.getItem("userRole") === "CANDIDATE" ? (
                    <BlueButton
                      children="Edit"
                      onClick={() =>
                        history.push({
                          pathname: "/Student/S_Profilepage",
                          state: {
                            profileData: props.profileData,
                          },
                        })
                      }
                    />
                  ) : null}
                  {localStorage.getItem("userRole") === "RECRUITER" ? (
                    <BlueButton children="Send request" />
                  ) : null}
                </div>
              </div>
            </div>
            <div className={style.toprow}>
              <div className={style.centered}>
                <div className={style.borderbox}>
                  <div className={style.row1}>
                    <div className={style.row1box}>
                      <div className={style.left}>
                        <img
                          src={showImgData(props.profileData?.profileUrl)}
                          alt="img"
                        />
                      </div>
                      <div className={style.middle}>
                        <span style={{ fontSize: "15pt" }}>
                          {showTopData(props.profileData.firstName)}{" "}
                          {showTopData(props.profileData.lastName)}
                        </span>
                        <table className={style.table1}>
                          <tbody>
                            <div
                              style={{ display: "flex" }}
                              className={style.profile}
                            >
                              <div
                                className={style.category}
                                style={{ width: "30%" }}
                              >
                                Date Of Birth
                              </div>
                              <div
                                className={style.value}
                                style={{ width: "70%" }}
                              >
                                {showTopData(
                                  moment(
                                    props?.profileData?.dateOfBirth,
                                    "YYYY/MM/DD"
                                  ).format("MM/DD/YYYY")
                                ) == "Invalid date"
                                  ? "No Information Provided."
                                  : showTopData(
                                      moment(
                                        props?.profileData?.dateOfBirth,
                                        "YYYY/MM/DD"
                                      ).format("MM/DD/YYYY")
                                    )}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex" }}
                              className={style.profile}
                            >
                              <div
                                className={style.category}
                                style={{ width: "30%" }}
                              >
                                Category
                              </div>
                              <div
                                className={style.value}
                                style={{ width: "70%" }}
                              >
                                {Object.entries(props.profileData?.categories)
                                  .length > 0 &&
                                props.profileData.categories !== null
                                  ? props.profileData.categories.join(" , ")
                                  : "No Information Provided."}
                              </div>
                            </div>

                            <div
                              style={{ display: "flex" }}
                              className={style.profile}
                            >
                              <div
                                className={style.category}
                                style={{ width: "30%" }}
                              >
                                Email ID
                              </div>
                              <div
                                className={style.value}
                                style={{ width: "70%" }}
                              >
                                {showTopData(props.profileData.email)}
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </div>
                      <div className={style.right}>
                        <table className={style.right_table1}>
                          <tbody>
                            <div
                              style={{ display: "flex" }}
                              className={style.profile}
                            >
                              <div
                                className={style.category}
                                style={{ width: "30%" }}
                              >
                                Gender
                              </div>
                              <div
                                className={style.value}
                                style={{ width: "70%" }}
                              >
                                {showTopData(props.profileData.gender)}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex" }}
                              className={style.profile}
                            >
                              <div
                                className={style.category}
                                style={{ width: "30%" }}
                              >
                                Skills
                              </div>
                              <div
                                className={style.value}
                                style={{ width: "70%" }}
                              >
                                {showSkillData(
                                  Object.entries(props.profileData?.skills)
                                    .length > 0 &&
                                    props.profileData.skills !== null,
                                  props.profileData.skills,
                                  "No Information Provided."
                                )}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex" }}
                              className={style.profile}
                            >
                              <div
                                className={style.category}
                                style={{ width: "30%" }}
                              >
                                Country
                              </div>
                              <div
                                className={style.value}
                                style={{ width: "70%" }}
                              >
                                {showTopData(props.profileData.country)}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex" }}
                              className={style.profile}
                            >
                              <div
                                className={style.category}
                                style={{ width: "30%" }}
                              >
                                State
                              </div>
                              <div
                                className={style.value}
                                style={{ width: "70%" }}
                              >
                                {showTopData(props.profileData.state)}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex" }}
                              className={style.profile}
                            >
                              <div
                                className={style.category}
                                style={{ width: "30%" }}
                              >
                                City
                              </div>
                              <div
                                className={style.value}
                                style={{ width: "70%" }}
                              >
                                {showTopData(props.profileData.city)}
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className={style.row2}>
                    <div className={style.row2left}>
                      <p>I Am</p>
                    </div>
                    <div className={style.row2right}>
                      {showData(props.profileData.about)}
                    </div>
                  </div>
                  <div className={style.row2}>
                    <div className={style.row2left}>
                      <p>Achievements</p>
                    </div>
                    <div className={style.row2right}>
                      {showData(props.profileData.achievement)}
                    </div>
                  </div>
                  {showskilldatas()}
                  {props.skillDatas.length > 3 && (
                    <div
                      className={style.lastview}
                      onClick={() => history.push("/Student/SkillDetails")}
                    >
                      <label>View All</label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.candidateReducer.loader,
    profileData: state.candidateReducer.candidateDashboardData,
    candidateErrorMsg: state.candidateReducer.candidateErrorMsg,
    candidateErrorCode: state.candidateReducer.candidateErrorCode,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
    skillDatas: state.candidateReducer.skillDatas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateDashboard: () => dispatch(actionTypes.getCandidateDashboard()),
    getCandidateSkills: (limit, pageNo) =>
      dispatch(actionTypes.getCandidateSkills(limit, pageNo)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SProfile);
