import {
  postRequest,
  getRequest,
  deleteRequest,
  // putRequest,
  putRequestFile,
  putRequest,
  getRequestLocation,
  // postRequestFile,
  // postRequestVerify,
  // postResetPass,
  // getRequestFileDownload
} from "../../Helper/Helper";

import * as actionTypes from "./Actiontypes";
//************************************************************************** */

//**************************************************************************** */

export const getRecruiterProfile = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RECRUITER_PROFILE_INITIAL });
    getRequest(`/recruiter/getRecProfileDetails`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.RECRUITER_PROFILE_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.RECRUITER_PROFILE_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.RECRUITER_PROFILE_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.RECRUITER_PROFILE_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//**************************************************************************** */
export const updateRecruiterProfile = (data, oldData) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_RECRUITER_PROFILE_INITIAL });
    putRequestFile(`/recruiter/recruiterUpdateProfile`, data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.UPDATE_RECRUITER_PROFILE_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.UPDATE_RECRUITER_PROFILE_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.UPDATE_RECRUITER_PROFILE_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.UPDATE_RECRUITER_PROFILE_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//*****************************CRETE REC OPPO********************************************************** */

export const createRecruiterOpportunity = (formData, imageData) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREATE_RECRUITER_OPPO_INITIAL });
    postRequest(`/opportunity/create`, formData, { profileImage: imageData })
      .then((res) => {
        if (res.status === 201) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.CREATE_RECRUITER_OPPO_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.CREATE_RECRUITER_OPPO_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.CREATE_RECRUITER_OPPO_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.CREATE_RECRUITER_OPPO_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//*****************************DUPLICATE REC OPPO********************************************************** */

export const createDuplicateOpportunity = (formData, pageno, payload) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.DUPLICATE_RECRUITER_OPPO_INITIAL });
    postRequest(`/opportunity/createDuplicateOpportunity`, formData)
      .then((res) => {
        if (res.status === 201) {
          if (res.data.statusCode === 0) {
            return dispatch(getListByRecruiter("ALL", pageno, payload));
          } else {
            return dispatch({
              type: actionTypes.DUPLICATE_RECRUITER_OPPO_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.DUPLICATE_RECRUITER_OPPO_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.DUPLICATE_RECRUITER_OPPO_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//********************************************************************************** */
export const getMainCategoryList = (filter) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CATEGORY_LIST_INITIAL });
    getRequest(`/getMainCategoryList`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.CATEGORY_LIST_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.CATEGORY_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.CATEGORY_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.CATEGORY_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//********************************************************************************** */
export const getSubCategoryList = (id) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SUB_CATEGORY_LIST_INITIAL });
    getRequest(`/getSubCategoryList/${id}`, { mainCategoryId: id })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.SUB_CATEGORY_LIST_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.SUB_CATEGORY_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.SUB_CATEGORY_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.SUB_CATEGORY_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//********************************************************************************** */
export const getListByRecruiter = (filter, pageNo, payload) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OPPO_RECRUITER_LIST_INITIAL });
    postRequest(
      `/opportunity/getListByRecruiter?filterValue=${filter}&pageLimit=6&pageNo=${pageNo}`,
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.OPPO_RECRUITER_LIST_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.OPPO_RECRUITER_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.OPPO_RECRUITER_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.OPPO_RECRUITER_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//********************************************************************************** */

export const closeRecruiterResponseAlert = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLOSE_RECRUITER_RESPONSE_ALERT,
      // payload:data,
    });
  };
};
//************************************************************************************ */

/************************************************************* */
export const getDetailsForEdit = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_DETAILS_FOR_EDIT_INITIAL });
    getRequest(`/opportunity/getDetailsForEdit/1`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.GET_DETAILS_FOR_EDIT_INITIAL,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.GET_DETAILS_FOR_EDIT_SUCCESS,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GET_DETAILS_FOR_EDIT_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.GET_DETAILS_FOR_EDIT_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

// ***************deleteRecruiterOppportunity***************
export const deleteOpportunity = (id, pageNo, filters) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.DELETE_OPPORTUNITY_INITIAL });
    deleteRequest(`/opportunity/delete/${id}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch(getListByRecruiter("ALL", pageNo, filters));
          } else {
            return dispatch({
              type: actionTypes.DELETE_OPPORTUNITY_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.DELETE_OPPORTUNITY_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.DELETE_OPPORTUNITY_FAIL,
          payload: err.message,
        });
      });
  };
};
//   ***********************************************************************
export const updateOpportuntiyStatus = (id, status, pageNo, filters) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_OPPORTUNITY_STATUS_INITIAL });
    putRequest(`/opportunity/updateStatus/${id}?status=${status}`)
      .then((res) => {
        if (res.status === 201) {
          if (res.data.statusCode === 0) {
            return dispatch(getListByRecruiter("ALL", pageNo, filters));
          } else {
            return dispatch({
              type: actionTypes.UPDATE_OPPORTUNITY_STATUS_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.UPDATE_OPPORTUNITY_STATUS_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.UPDATE_OPPORTUNITY_STATUS_FAIL,
          payload: err.message,
        });
      });
  };
};

//**************************************************************************** */
export const handleRecruiterDeleteModal = (val) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_RECRUITER_DELETE_MODAL,
      payload: val,
    });
  };
};

//*************************************************************** */
export const getAppliedStudentList = (opportunityId, pageNo) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_APPLIED_STUDENT_LIST_INITIAL });
    getRequest(
      `/opportunity/getAppliedStudentList/${opportunityId}?pageLimit=5&pageNo=${pageNo}`
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.GET_APPLIED_STUDENT_LIST_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.GET_APPLIED_STUDENT_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GET_APPLIED_STUDENT_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.GET_APPLIED_STUDENT_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
// -----------------------getAcceptedOpportunitiesList------------------------------------------------------------
export const getAcceptedStudentListByOpportunityId = (
  opportunityId,
  pageno
) => {
  //
  return (dispatch) => {
    dispatch({ type: actionTypes.OPPORTUNITIES_LIST_INITIAL });
    getRequest(
      `/opportunity/getAcceptedStudentListByOpportunityId?opportunityId=${opportunityId}&pageLimit=3&pageNo=${pageno}`
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.OPPORTUNITIES_LIST_SUCCESS,
              payload: res?.data?.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.OPPORTUNITIES_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.OPPORTUNITIES_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.OPPORTUNITIES_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//******************acceptedRejectedAppliedOpportunity******** */
export const acceptedRejectedAppliedOpportunity = (
  status,
  comment,
  opportunityId,
  requestDate,
  studentAccountId
) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_INITIAL,
    });
    postRequest(
      `/opportunity/acceptedRejectedAppliedOpportunity?accept=${status}&comment=${comment}&opportunityId=${opportunityId}&requestDate=${requestDate}&studentAccountId=${studentAccountId}`
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.ACCEPTED_REJECTED_APPLIED_OPPORTUNITY_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//**************************getStudentProfileByProfileId************************************************************ */
export const getStudentProfileByProfileId = (studentProfileId) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_STUDENT_PROFILE_BY_PROFILEID_INITIAL });
    getRequest(
      `/opportunity/getStudentProfileByProfileId?studentProfileId=${studentProfileId}`
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.GET_STUDENT_PROFILE_BY_PROFILEID_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.GET_STUDENT_PROFILE_BY_PROFILEID_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GET_STUDENT_PROFILE_BY_PROFILEID_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.GET_STUDENT_PROFILE_BY_PROFILEID_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
export const handleRejectModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_AUTH_REJECT_MODAL,
      payload: data,
    });
  };
};

//************************************************* */
export const searchStudentListByRecruiter = (pageNo, payload, searchQuery) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SEARCH_STUDENT_LIST_BY_RERUITER_INITIAL });
    postRequest(
      `/recruiter/searchStudentListByRecruiter?pageLimit=6&pageNo=${pageNo}&searchQuery=${searchQuery}`,
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.SEARCH_STUDENT_LIST_BY_RERUITER_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.SEARCH_STUDENT_LIST_BY_RERUITER_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.SEARCH_STUDENT_LIST_BY_RERUITER_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.SEARCH_STUDENT_LIST_BY_RERUITER_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

// sentRequest*****************************************************
export const sentRequest = (opportunityId, requestTime, studentProfileId) => {
  return async (dispatch) => {
    await dispatch({ type: actionTypes.SENT_REQUEST_INITIAL });
    postRequest(
      `/recruiter/sendRequest?opportunityId=${opportunityId}&requestTime=${requestTime}&studentProfileId=${studentProfileId}`
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.SENT_REQUEST_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.SENT_REQUEST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.SENT_REQUEST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.SENT_REQUEST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//******************************************************* */
export const handleSentRequestModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_AUTH_SENT_REQUEST_MODAL,
      payload: data,
    });
  };
};

// /////////////////////////////////////////////////////////////////////////////////////////////////
export const getCountryList = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.COUNTRY_LIST_INITIAL });
    getRequestLocation(`/getCountryList`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.COUNTRY_LIST_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.COUNTRY_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.COUNTRY_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.COUNTRY_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

// //////////////////////////////////////////////////////////////////////////////////////////
export const getStateListId = (id) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.STATE_LIST_INITIAL });
    getRequestLocation(`/getStateListId/${id}`, { id: id })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.STATE_LIST_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.STATE_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.STATE_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.STATE_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

// //////////////////////////////////////////////////////////////////////

export const getCityListId = (id) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CITY_LIST_INITIAL });
    getRequestLocation(`/getCityListId/${id}`, { id: id })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.CITY_LIST_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.CITY_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.CITY_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.CITY_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//
export const handleshowBlur = (val) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_BLUR,
      payload: val,
    });
  };
};

//****************************8 */
export const handleshowAlert = (val) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_ALERT,
      payload: val,
    });
  };
};

export const acceptedOpportunityCategoryListByRecruiter = (pageno) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_ACCEPT_OPP_CATEGORY_INITIAL });
    getRequest(
      `/opportunity/acceptedOpportunityCategoryListByRecruiter?pageLimit=5&pageNo=${pageno}`
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.GET_ACCEPT_OPP_CATEGORY_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.GET_ACCEPT_OPP_CATEGORY_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GET_ACCEPT_OPP_CATEGORY_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.GET_ACCEPT_OPP_CATEGORY_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

export const acceptedOpportunityCategoryListByOrganisation = (pageno) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_INITIAL });
    getRequest(
      `/opportunity/acceptedOpportunityCategoryListByOrganisation?pageLimit=5&pageNo=${pageno}`
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

export const handleRecAdvanceFilter = (val) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_REC_ADVANCE_FILTER_MODAL,
      payload: val,
    });
  };
};
//********************** */
export const getStudentProfileByProfile = (studentProfileId) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_STUDENT_PROFILE_BY_PROFILE_INITIAL });
    getRequest(
      `/opportunity/getStudentProfileByProfileId?studentProfileId=${studentProfileId}`
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.GET_STUDENT_PROFILE_BY_PROFILE_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.GET_STUDENT_PROFILE_BY_PROFILE_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GET_STUDENT_PROFILE_BY_PROFILE_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.GET_STUDENT_PROFILE_BY_PROFILE_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
