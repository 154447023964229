import React from "react";
import { useHistory } from "react-router-dom";
import style from "./cards.module.css";
import appliedImg from "../../../../assets/applied/APPLIED people@3x.png";
import eyeIcon from "../../../../assets/eye/Watched@3x.png";
import detailImg from "../../../../assets/details/Details@2x.png";
import defaultImg from "../../../../assets/defaultimg/Rectangle 5337@2x.png";

function Cards(props) {
  let history = useHistory();
  function handleClick(id, val) {
    history.push("/");
    history.push({
      pathname: "/Opportunity_Details",
      state: {
        backPath: "/student/SearchOpportunity",
        opp_id: id,
        applied: val,
        value: "2",
      },
    });
  }
  return (
    <>
      {props.data && props.data.length > 0 ? (
        props.data.map((item, index) => (
          <div className={style.maincontainer} key={index}>
            <div className={style.maincard}>
              <div className={style.square}></div>
              <div className={style.card}>
                <div className={style.box1}>
                  <div className={style.heading}>
                    <div className={style.lefthead}>
                      <p style={{ fontSize: "0.9rem" }}>{item.title}</p>
                    </div>
                    <div className={style.constantImg1}>
                      <div className={style.detail}>
                        <img
                          src={detailImg}
                          alt="img"
                          onClick={() =>
                            handleClick(item.opportunityId, item.applied)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={style.shelf}>
                    <div className={style.labelbox}>Category</div>{" "}
                    <div className={style.valuebox}>{item.mainSkill}</div>
                  </div>

                  <div className={style.shelf}>
                    <div className={style.labelbox}>Skills</div>
                    <div className={style.valuebox}>{item.subSkill}</div>
                  </div>

                  <div className={style.shelf}>
                    <div className={style.labelbox}>Location</div>
                    <div className={style.valuebox}>
                      {item.addressLine1 && item.addressLine1 !== null
                        ? item.addressLine1
                        : "-"}
                    </div>
                  </div>
                  <hr></hr>
                  <div className={style.heading}>
                    <div className={style.lefthead1}>
                      <div className={style.applied}>
                        <div className={style.constantImg}></div>
                      </div>
                    </div>
                    <div className={style.constantImg1}>
                      <div className={style.eye}>
                        <img src={eyeIcon} alt="img" />
                      </div>
                      <div
                        style={{
                          marginRight: "10px",
                          marginTop: "3%",
                          fontSize: ".8rem",
                        }}
                      >
                        {item.viewCount}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.box2}>
                  <div className={style.card_image}>
                    <img
                      src={
                        item.opportunityImgUrl === null
                          ? defaultImg
                          : item.opportunityImgUrl
                      }
                      alt="img"
                      media="(width: 100%)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <span>No data </span>
      )}
    </>
  );
}

export default Cards;
