import React from "react";
import style from "./cards.module.css";
import { useHistory } from "react-router";
import defaultImg from "../../../../assets/defaultimg/Rectangle 5337@2x.png";

function Cards(props) {
  const history = useHistory();

  const handleClick = (studentProfileId, OpportunityId, requestAccepted) => {
    history.push({
      pathname: "/Student/AcceptorReject",
      state: {
        studentProfileId: studentProfileId,
        OpportunityId: props.setOpportunityId,
        requestAccepted: requestAccepted,
      },
    });
  };

  function showSkillData(condition, val1, val2) {
    if (condition) {
      return val1.join(" , ");
    } else {
      return val1;
    }
  }

  return (
    <>
      {props.data && props.data.length > 0
        ? props.data.map((item, index) => (
            <div
              className={style.container}
              onClick={(studentProfileId, OpportunityId, requestAccepted) =>
                handleClick(
                  item.studentProfileId,
                  props.setOpportunityId,
                  item.requestAccepted
                )
              }
            >
              <div className={style.card}>
                <div className={style.left}>
                  <img
                    src={
                      item && item.studentImgUrl !== null
                        ? item.studentImgUrl
                        : defaultImg
                    }
                    alt="img"
                  />
                </div>
                <div className={style.middle}>
                  <p>
                    {item.firstName}&nbsp;&nbsp;{item.lastName}
                  </p>
                  <table className={style.table1}>
                    <tbody>
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.category}>Age</div>
                        <div className={style.value}>
                          {item && item.age !== null
                            ? item.age
                            : "No Information Provided"}
                        </div>
                      </div>
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.category}>Category</div>
                        <div className={style.value}>
                          {showSkillData(
                            Object.entries(item).length > 0 &&
                              item.category !== null,
                            item.category,
                            ""
                          )}
                        </div>
                      </div>

                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.category}>Location</div>
                        <div className={style.value}>
                          {item.city === null && item.state !== null
                            ? item.state + "," + item.country
                            : item.city === null && item.state === null
                            ? item.country
                            : item.state === null && item.city !== null
                            ? item.city + "," + item.country
                            : item.city + "," + item.state + "," + item.country}
                        </div>
                      </div>
                    </tbody>
                  </table>
                </div>
                <div className={style.right}>
                  <table className={style.right_table1}>
                    <tbody>
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.category}>Gender</div>
                        <div className={style.value}>
                          {item && item.gender !== null
                            ? item.gender
                            : "No Information Provided"}
                        </div>
                      </div>
                      <div style={{ display: "flex", margin: "5px" }}>
                        <div className={style.category}>Skills</div>
                        <div className={style.value}>
                          {showSkillData(
                            Object.entries(item).length > 0 &&
                              item.skills !== null,
                            item.skills,
                            ""
                          )}
                        </div>
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))
        : null}
    </>
  );
}

export default Cards;
