import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import style from "./REditProfile.module.css";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import Backdroplog from "../../GenericComponent/Backdrop/Backdrop";
import defaultImg from "../../assets/defaultimg/Rectangle 5337@2x.png";
import Uploadimg from "../../assets/regicons/upload.svg";
import { BlueButton, WhiteButton } from "../../GenericComponent/Buttons/button";
import Loader from "../../assets/Loader/spinner.gif";
import { useDropzone } from "react-dropzone";

function RProfileSave(props) {
  const history = useHistory();
  const inputFile = useRef(null);
  const lastNameRef = useRef(null);
  const firstNameRef = useRef(null);
  const [sidebar, setSidebar] = useState();
  const [recprofileData, setRecprofileData] = useState(
    props.history.location.state.recprofileData
  );

  const [isRemoveIcon, setIsRemoveIcon] = useState(
    props.history.location.state.recprofileData.imgURL ? true : false
  );
  const [operation, setOperation] = useState("NONE");
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(
    recprofileData && recprofileData.imgURL ? recprofileData.imgURL : defaultImg
  );
  const [country, setCountry] = useState(recprofileData.country);
  const [state, setState] = useState(recprofileData.state);
  const [city, setCity] = useState(recprofileData.city);

  const [fnameErrorMsg, setFnameErrorMsg] = useState("");
  const [lnameErrorMsg, setLnameErrorMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);

  const handleSubmitData = (e) => {
    e.preventDefault();
    if (
      recprofileData.firstName === null ||
      recprofileData.firstName?.trim() === ""
    ) {
      setFnameErrorMsg("*First name is required.");
      firstNameRef.current.focus();
    } else if (
      recprofileData.lastName === null ||
      recprofileData.lastName?.trim() === ""
    ) {
      setLnameErrorMsg("*Last name is required.");
      lastNameRef.current.focus();
    } else {
      if (errorMsg === null) {
        let payload = {
          about: recprofileData.about,
          addressLine1: recprofileData.addressLine1,
          city: city,
          country: country,
          firstName: recprofileData.firstName,
          lastName: recprofileData.lastName,
          pinCode: recprofileData.pinCode,
          designation: recprofileData.designation,
          state: state,

          operation: operation,
        };

        let formData = new FormData();
        formData.append(
          "profileImage",
          previewImage[0] !== null ? previewImage[0] : ""
        );
        formData.append("jsonRequest", JSON.stringify(payload));
        props.updateRecruiterProfile(formData);
      } else {
        inputFile.current.focus();
      }
    }
  };

  function updateProfileData(e) {
    let name = e.target.name;
    let value = e.target.value;
    setRecprofileData({ ...recprofileData, [name]: value });
    setErrorMsg(null);
  }

  function removeImage() {
    setRecprofileData({ ...recprofileData, imgURL: null });
    setPreviewImage(defaultImg);
    setProfileImage(defaultImg);
    setIsRemoveIcon(false);
    setOperation("DELETE");
  }

  const imageHandler = (e) => {
    setErrorMsg(null);
    if (e.target.files.length > 0) {
      const myfile = e.target.files[0].name;
      var ext = myfile.split(".").pop().toLowerCase();
      if (ext === "png" || ext === "jpg" || ext === "svg" || ext === "jpeg") {
        setProfileImage(e.target.files[0]);
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
        e.target.value = null;
        setIsRemoveIcon(true);
        setOperation("UPDATE");
      } else {
        setErrorMsg({
          ProfileImageErr:
            "*Invalid File!- Allowed Format: .png, .jpg, .jpeg, .svg.",
        });
        setProfileImage("");
        setPreviewImage(defaultImg);
        e.target.value = null;
        setIsRemoveIcon(false);
        setOperation("NONE");
      }
    }
  };
  const onButtonClick = () => {
    inputFile.current.click();
    setErrorMsg(null);
  };

  function showProfileImageErr() {
    if (errorMsg !== null && errorMsg.ProfileImageErr) {
      return errorMsg.ProfileImageErr;
    } else {
      return "";
    }
  }

  function handleTextarea(e) {
    if (recprofileData.about === "" && e.keyCode === 32) {
      e.preventDefault();
    } else {
      let name = e.target.name;
      let value = e.target.value;
      setRecprofileData({ ...recprofileData, [name]: value });
    }
  }
  const handleCountry = (value) => {
    const index = value.target.selectedIndex;
    const el = value.target.childNodes[index];
    const option = el.getAttribute("id");
    setCountry(option);
    props.getStateListId(option);
    setCountry(value.target.value);
    setState(null);
    setCity(null);
  };

  const handleState = (value) => {
    const index = value.target.selectedIndex;
    const el = value.target.childNodes[index];
    const option = el.getAttribute("id");
    setState(option);
    props.getCityListId(option);
    setState(value.target.value);
    setCity(null);
  };
  const handleCity = (value) => {
    setCity(value.target.value);
  };

  useEffect(() => {
    props.getCountryList();
    if (recprofileData?.countryId) {
      props.getStateListId(recprofileData?.countryId);
    }
    if (recprofileData?.stateId) {
      props.getCityListId(recprofileData?.stateId);
    }
  }, []);

  const showData = (condition, val1, val2) => {
    if (condition) {
      return val1;
    } else {
      return val2;
    }
  };

  const mediaMatch = window.matchMedia("(max-width: 500px)");
  const [matches, setMatches] = useState(mediaMatch.matches);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      setPreviewImage(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setOperation("UPDATE");
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    if (previewImage == []) {
      previewImage.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  }, [previewImage]);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <Backdroplog show={props.showBlur} />
        <div className={style.topHeading}>
          <div>
            <p className={style.para}>Profile</p>
          </div>
          <div className={style.button}>
            <div>
              <WhiteButton
                children="Cancel"
                onClick={() => history.push("/Recruiter/Profile")}
              />
            </div>
            <div>
              <BlueButton children="Save" onClick={handleSubmitData} />
            </div>
          </div>
        </div>

        {props.loader ? (
          <div className={style.loader_styles}>
            <img src={Loader} alt="loader" />
          </div>
        ) : (
          <div>
            <div className={style.centered}>
              <div className={style.left}>
                <div className={style.leftinner1}>
                  <label className={style.label1}>Organization Name</label>
                  <span className={style.span1}>
                    {recprofileData && recprofileData.organisationName}
                  </span>
                  <label className={style.label1}>Email Id</label>
                  <span className={style.span1}>
                    {recprofileData && recprofileData.organisationEmail}
                  </span>
                </div>
                <div className={style.leftinner2}>
                  <label className={style.label1}>Phone</label>
                  <span className={style.span1}>
                    {recprofileData && recprofileData.organisationContactNo}
                  </span>
                </div>
              </div>
              <hr style={{ opacity: "0.5" }} />
              <div className={style.div}>
                <div className={style.leftd}>
                  <div className={style.icon}>
                    <img
                      src={showData(
                        previewImage[0]?.preview,
                        previewImage[0]?.preview,
                        recprofileData.imgURL
                      )}
                      className={style.img1}
                    />
                    {isRemoveIcon && (
                      <i
                        style={{
                          position: "absolute",
                          color: "green",
                          cursor: "pointer",
                        }}
                        class="fa fa-times-circle"
                        aria-hidden="true"
                        onClick={() => removeImage()}
                      ></i>
                    )}
                    {/* <input
                      type="file"
                      name="profile"
                      onChange={imageHandler}
                      ref={inputFile}
                      accept="image/png, image/jpg, image/jpeg,image/svg"
                      style={{ display: "none", visibility: "none" }}
                      for="img"
                    />
                    <img
                      src={Uploadimg}
                      className={style.upimg}
                      style={{ cursor: "pointer" }}
                      id="img"
                      onClick={onButtonClick}
                    /> */}
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <img
                        src={Uploadimg}
                        className={style.img2}
                        style={{ cursor: "pointer" }}
                        id="img"
                        // onClick={onButtonClick}
                      />
                    </div>
                  </div>
                  <span className={style.errorMsgLeft}>
                    {showProfileImageErr()}
                  </span>
                  <div>
                    <p className={style.heading}> About Yourself</p>
                    <textarea
                      className={style.yourinput}
                      placeholder="Share information with the applicant on your personal background and experience."
                      name="about"
                      maxLength="500"
                      onKeyDown={(e) => handleTextarea(e)}
                      defaultValue={recprofileData && recprofileData.about}
                      onChange={(e) => updateProfileData(e)}
                    ></textarea>
                  </div>
                  <br />
                </div>
                <div className={style.right}>
                  <label className={style.label} style={{ marginTop: "3%" }}>
                    First Name
                  </label>
                  <input
                    className={style.input1}
                    type="text"
                    name="firstName"
                    ref={firstNameRef}
                    defaultValue={recprofileData && recprofileData.firstName}
                    onKeyDown={() => setFnameErrorMsg("")}
                    onChange={(e) => updateProfileData(e)}
                    autoComplete="off"
                  />
                  <span className={style.errorMsg}>{fnameErrorMsg}</span>
                  <label className={style.label}> Last Name</label>
                  <input
                    className={style.input1}
                    type="text"
                    ref={lastNameRef}
                    name="lastName"
                    defaultValue={recprofileData && recprofileData.lastName}
                    onKeyDown={() => setLnameErrorMsg("")}
                    onChange={(e) => updateProfileData(e)}
                    autoComplete="off"
                  />
                  <span className={style.errorMsg}>{lnameErrorMsg}</span>
                  <label className={style.label}> Designation</label>
                  <input
                    className={style.input1}
                    type="text"
                    name="designation"
                    defaultValue={recprofileData && recprofileData.designation}
                    onChange={(e) => updateProfileData(e)}
                    autoComplete="off"
                  />
                  <label className={style.label}>Country</label>
                  <select
                    className={style.input1}
                    onChange={(e) => {
                      handleCountry(e);
                    }}
                    name="country"
                  >
                    <option value="">{country ? country : "--Select--"}</option>
                    {props.country_list && props.country_list.length > 0
                      ? props.country_list.map((item, index) => (
                          <option value={item.name} id={item.id}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <label className={style.label}>State</label>

                  <select
                    className={style.input1}
                    onChange={(e) => {
                      handleState(e);
                    }}
                    name="state"
                  >
                    <option value="">{state ? state : "--Select--"}</option>
                    {props.state_list && props.state_list.length > 0
                      ? props.state_list.map((item, index) => (
                          <option value={item.name} id={item.id}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>

                  <label className={style.label}>City</label>
                  <select
                    className={style.input1}
                    onChange={(e) => handleCity(e)}
                    name="city"
                  >
                    <option value="">{city ? city : "--Select--"}</option>
                    {props.city_list && props.city_list.length > 0
                      ? props.city_list.map((item, index) => (
                          <option value={item.name} id={item.id}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>
                  <label className={style.label}>Postal Code</label>
                  <input
                    className={style.input1}
                    type="number"
                    defaultValue={recprofileData && recprofileData.pinCode}
                    name="pinCode"
                    onChange={(e) => updateProfileData(e)}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {props.showAlert && (
          <div className={style.changeDiv}>
            <ResponseAlert
              redirect={
                props.updateRecruiterStatus === true
                  ? "/Recruiter/Profile"
                  : null
              }
              message={
                props.updateRecruiterStatus === true
                  ? "Profile updated successfully!"
                  : props.recErrorMsg
              }
              type="RECRUITER"
            />
          </div>
        )}
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.recruiterReducer.loader,
    recErrorMsg: state.recruiterReducer.recErrorMsg,
    recErrorCode: state.recruiterReducer.recErrorCode,
    updateRecruiterStatus: state.recruiterReducer.updateRecruiterStatus,
    updateRecruiterErrMsg: state.recruiterReducer.updateRecruiterErrMsg,
    showAlert: state.recruiterReducer.showAlert,
    showBlur: state.recruiterReducer.showBlur,
    country_list: state.recruiterReducer.country_list,

    state_list: state.recruiterReducer.state_list,
    city_list: state.recruiterReducer.city_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRecruiterProfile: (data) =>
      dispatch(actionTypes.updateRecruiterProfile(data)),
    getCountryList: () => dispatch(actionTypes.getCountryList()),

    getStateListId: (id) => dispatch(actionTypes.getStateListId(id)),
    getCityListId: (id) => dispatch(actionTypes.getCityListId(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RProfileSave);
