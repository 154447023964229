import React, { useEffect, useState } from "react";
import * as actionTypes from "../../Store/Action/index";
import { connect } from "react-redux";
import style from "./notification.module.css";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import Cards from "./Cards/cards";
import NoData from "../../assets/globe.jpg";
import loader from "../../assets/Loader/spinner.gif";

function Notification(props) {
  const [sidebar, setSidebar] = useState(false);

  const toShowCards = () => {
    if (
      props.candidateNotificationData &&
      props.candidateNotificationData.length > 0
    ) {
      return <Cards data={props.candidateNotificationData} />;
    } else {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <p>
              <b>No Data Found</b>
            </p>
            <img
              className={style.nodata}
              style={{ width: "50%", height: "60%" }}
              src={NoData}
              alt="nodata"
            />
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    props.getcandidateNotification();
  }, []);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>

      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.topHeading}>
          <label className={style.label}>Notifications</label>
        </div>
        {props.loader ? (
          <div className={style.loader}>
            <img className={style.nodata} src={loader} alt="loader" />
          </div>
        ) : (
          <div className={style.centered}>{toShowCards()}</div>
        )}
      </section>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.candidateReducer.loader,
    candidateNotificationErrMsg:
      state.candidateReducer.candidateNotificationErrMsg,
    candidateErrorMsg: state.candidateReducer.candidateErrorMsg,
    candidateErrorCode: state.candidateReducer.candidateErrorCode,
    candidateNotificationStatus:
      state.candidateReducer.candidateNotificationStatus,
    candidateNotificationData: state.candidateReducer.candidateNotificationData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getcandidateNotification: () =>
      dispatch(actionTypes.getcandidateNotification()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
