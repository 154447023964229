import React from "react";
import { useLocation, Link } from "react-router-dom";
import style from "./sidebar.module.css";
import { ReactComponent as Home } from "../../assets/svg files/Home.svg";
import { ReactComponent as Search } from "../../assets/svg files/Search.svg";
import { ReactComponent as Message } from "../../assets/svg files/Message.svg";
import { ReactComponent as Accepted } from "../../assets/svg files/Accepted.svg";
import { ReactComponent as Applied } from "../../assets/svg files/AppliedIcon.svg";
import { ReactComponent as Dashboard } from "../../assets/svg files/dashboard.svg";
import { ReactComponent as Management } from "../../assets/svg files/management.svg";

const SideBar = (props) => {
  const location = useLocation();
  const opportunityCount = localStorage.getItem("opportunityCount");
  function getClassName(condition) {
    return location.pathname === condition ? style.active : style.inactive1;
  }

  function getFillValue(condition) {
    return location.pathname === condition ? "#110766" : "#ffffff";
  }

  return (
    <div className={props.side ? style.sidebar : style.leftSection}>
      <br />

      {localStorage.getItem("userRole") === "CANDIDATE" ? (
        <div className={style.menu}>
          <Link
            to="/Student/Dashboard"
            className={getClassName("/Student/Dashboard")}
          >
            <Home
              fill={getFillValue("/Student/Dashboard")}
              className={style.searchImg}
            />
            <span className={style.span}> Home </span>
          </Link>
          <Link
            to="/student/SearchOpportunity"
            className={getClassName("/student/SearchOpportunity")}
          >
            <Search
              fill={getFillValue("/student/SearchOpportunity")}
              className={style.searchImg}
            />
            <span className={style.span}> Search </span>
          </Link>
          <Link
            to="/Student/Chatlist"
            className={getClassName("/Student/Chatlist")}
          >
            <Message
              fill={getFillValue("/Student/Chatlist")}
              className={style.searchImg}
            />
            <span className={style.span} style={{ marginLeft: "17%" }}>
              Message
            </span>
          </Link>
          <Link
            to="/AppliedOpportunities"
            className={getClassName("/AppliedOpportunities")}
          >
            <Applied
              fill={getFillValue("/AppliedOpportunities")}
              className={style.searchImg}
            />
            <span className={style.span} style={{ marginLeft: "23%" }}>
              Applied
            </span>
            <span className={style.span} style={{ marginLeft: "12%" }}>
              Opportunities
            </span>
          </Link>
        </div>
      ) : localStorage.getItem("userRole") === "ORGANISATION" ? (
        <>
          <div className={style.menu}>
            <Link to="/Dashboard" className={getClassName("/Dashboard")}>
              <Dashboard
                fill={
                  location.pathname === "/Dashboard" ? "#110766" : "#ffffff"
                }
                className={style.searchImg}
              />
              <span className={style.span}>Dashboard</span>
            </Link>
            <Link
              to="/Management"
              className={
                location.pathname === "/Management"
                  ? style.active
                  : style.inactive1
              }
            >
              <Management
                fill={
                  location.pathname === "/Management" ? "#110766" : "#ffffff"
                }
                className={style.searchImg}
              />
              <span className={style.span}>Management</span>
            </Link>
            <Link
              to="/Org_AcceptedStudent"
              className={
                location.pathname === "/Org_AcceptedStudent"
                  ? style.active
                  : style.inactive1
              }
            >
              <Accepted
                fill={
                  location.pathname === "/Org_AcceptedStudent"
                    ? "#110766"
                    : "#ffffff"
                }
                className={style.searchImg}
              />
              <span className={style.span}>Accepted</span>
            </Link>
          </div>
        </>
      ) : (
        <div className={style.menu}>
          <Link
            to={
              opportunityCount == 0
                ? "/Recruiter/Opportunities"
                : "/Recruiter/Opportunities"
            }
            className={
              location.pathname === "/Recruiter/Opportunities" ||
              location.pathname === "/Recruiter/Opportunities"
                ? style.active
                : style.inactive1
            }
          >
            <Home
              fill={
                location.pathname === "/Recruiter/Opportunities" ||
                location.pathname === "/Recruiter/Opportunities"
                  ? "#110766"
                  : "#ffffff"
              }
              className={style.searchImg}
            />
            <span className={style.span}> Home </span>
          </Link>
          <Link
            to="/Recruiter/R_SearchList"
            className={
              location.pathname === "/Recruiter/R_SearchList"
                ? style.active
                : style.inactive1
            }
          >
            <Search
              fill={
                location.pathname === "/Recruiter/R_SearchList"
                  ? "#110766"
                  : "#ffffff"
              }
              className={style.searchImg}
            />
            <span className={style.span}> Search </span>
          </Link>
          <Link
            to="/R_AcceptedStudent"
            className={
              location.pathname === "/R_AcceptedStudent"
                ? style.active
                : style.inactive1
            }
          >
            <Accepted
              fill={
                location.pathname === "/R_AcceptedStudent"
                  ? "#110766"
                  : "#ffffff"
              }
              className={style.searchImg}
            />
            <span className={style.span}> Accepted </span>
          </Link>
          <Link
            to="/Recuiter/Chatlist"
            className={
              location.pathname === "/Recuiter/Chatlist"
                ? style.active
                : style.inactive1
            }
          >
            <Message
              fill={
                location.pathname === "/Recuiter/Chatlist"
                  ? "#110766"
                  : "#ffffff"
              }
              className={style.searchImg}
            />
            <span className={style.span}> Message </span>
          </Link>
        </div>
      )}

      <br />
    </div>
  );
};

export default SideBar;
