import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import ProtectedRoute from "./privateRoutes";
import { getToken, onMessageListener } from "./firebaseInit";
import "./App.css";
import HomePage from "./Pages/Homepage/homepage";
import AboutUs from "./Pages/AboutUs/homepage";
import ContactUs from "./Pages/ContactUS/homepage";
import Whyigg2 from "./Pages/WhyIgg2/homepage";
import Resources from "./Pages/Resources/homepage";
import Logout from "./Pages/Homepage/components/Logout";
import AppliedOpportunities from "./Pages/OpportunitiesList/S_AppliedOpportunities/student";
import OpportunityDetails from "./Pages/Opportunity/OpportunityDetails/OpportunityDetails";
import Dashboard from "./Pages/Dashboard/dashboard";
import Profile from "./Pages/Profile/profile";
import EditProfile from "./Pages/Profile/EditProfile";
import OrgOpportunities from "./Pages/OpportunitiesList/OrgOpportunities/opportunities";
import Management from "./Pages/Management/managnment";
import OTPModal from "./Pages/Homepage/components/OTPScreen/OTPScreen";
import StudentDashboard from "./Pages/StudentDashboard/StudentDashboard";
import StudentOpportunities from "./Pages/OpportunitiesList/StudentOpportunities/s_opportunities";
import SEditProfile from "./Pages/StudentProfile/SEditProfile";
import SViewProfile from "./Pages/StudentProfile/SViewProfile/SViewProfile";
import SkillDetails from "./Pages/StudentProfile/SkillDetails/SkillDetails";
import RDashboard from "./Pages/RecruiterDashboard/Dashboard/Dashboard";
import REditProfile from "./Pages/RecruiterProfile/REditProfile";
import RViewProfile from "./Pages/RecruiterProfile/RViewProfile";
import RecruiterOpportunities from "./Pages/OpportunitiesList/RecruiterOpportunities/r_opportunities";
import CreateOpportunity from "./Pages/Opportunity/CreateOpportunity/CreateOpportunity";
import EditOpportunity from "./Pages/Opportunity/EditOpportunity/EditOpportunity";
import AcceptorReject from "./Pages/AppliedAndAcceptReject/SAcceptRejectProfile/SViewProfile";
import AppliedStudents from "./Pages/AppliedAndAcceptReject/AppliedStudent/student";
import R_SearchList from "./Pages/RecruiterSearch/student";
import Notification from "./Pages/Notifications/notification";
import S_searchOpportunity from "./Pages/OpportunitiesList/S_searchOpportunity/s_opportunities";
import AcceptedList from "./Pages/AcceptedStudentList/ForRecruiter/AcceptedList/student";
import R_AcceptedStudent from "./Pages/AcceptedStudentList/ForRecruiter/CategoryList/student";
import Org_AcceptedStudent from "./Pages/AcceptedStudentList/ForOrganization/CategoryList/student";
import Org_AcceptedList from "./Pages/AcceptedStudentList/ForOrganization/AcceptedList/student";
import S_SearchProfile from "./Pages/RecruiterSentReqbyProfle/SViewProfile";
import ChatList from "./Pages/Chatlist/chatlist";
import Chat from "./Pages/Chatlist/Chat";
import RChatList from "./Pages/Rchatlist/chatlist";
import RChat from "./Pages/Rchatlist/Chat";
import Privacypolicy from "./Pages/privacypolicy/Privacypolicy";
import Termsandcondition from "./Pages/termsandcondition/Termsandcondition";

const App = (props) => {
  const [show, setShow] = useState(false);

  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);

  getToken(setTokenFound);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  const closeToast = () => {
    if (show === true) {
      setShow(false);
    }
  };

  const token = localStorage.getItem("userToken");
  const opportunityCount = localStorage.getItem("opportunityCount");
  const isAuthenticated = token !== null;

  let redirect;

  if (localStorage.getItem("userRole") === "ORGANISATION" && isAuthenticated) {
    redirect = <Redirect to="/Dashboard" />;
  } else if (
    localStorage.getItem("userRole") === "CANDIDATE" &&
    isAuthenticated
  ) {
    redirect = <Redirect to="/Student/Dashboard" />;
  } else if (
    localStorage.getItem("opportunityCount") == 0 &&
    localStorage.getItem("userRole") === "RECRUITER" &&
    isAuthenticated
  ) {
    redirect = <Redirect to="/Recruiter/Dashboard" />;
  } else if (
    localStorage.getItem("userRole") === "RECRUITER" &&
    isAuthenticated &&
    localStorage.getItem("opportunityCount") != 0
  ) {
    redirect = <Redirect to="/Recruiter/Opportunities" />;
  } else {
    redirect = <Redirect to="/" />;
  }

  let routes;
  if (isAuthenticated && localStorage.getItem("userRole")) {
    routes = (
      <Switch>
        <ProtectedRoute path="/Dashboard" exact component={Dashboard} />

        <Route path="/Dashboard" exact component={Dashboard} />
        <Route path="/Recruiter/Dashboard" exact component={RDashboard} />
        <Route path="/Student/Dashboard" exact component={StudentDashboard} />
        <Route path="/Management" exact component={Management} />
        <Route path="/Profile" exact component={Profile} />
        <Route path="/EditProfile" exact component={EditProfile} />
        <Route path="/Recruiter/Profile" exact component={RViewProfile} />
        <Route path="/Recruiter/EditProfile" exact component={REditProfile} />
        <Route path="/Student/Profile" exact component={SViewProfile} />
        <Route path="/AppliedStudents" exact component={AppliedStudents} />
        <Route path="/Student/S_Profilepage" exact component={SEditProfile} />
        <Route path="/Student/SkillDetails" exact component={SkillDetails} />
        <Route
          path="/Opportunity_Details"
          exact
          component={OpportunityDetails}
        />
        <Route path="/AcceptedList" exact component={AcceptedList} />
        <Route path="/Student/Chatlist" exact component={ChatList} />
        <Route path="/Student/Chat" exact component={Chat} />
        <Route path="/Recuiter/Chatlist" exact component={RChatList} />
        <Route path="/recruiter/Chat" exact component={RChat} />
        <Route
          path="/student/SearchOpportunity"
          exact
          component={S_searchOpportunity}
        />
        <Route
          path="/CreatedOpportunities"
          exact
          component={OrgOpportunities}
        />
        <Route path="/R_AcceptedStudent" exact component={R_AcceptedStudent} />

        <Route path="/OTPVerify" exact component={OTPModal} />
        <Route
          path="/AppliedOpportunities"
          exact
          component={AppliedOpportunities}
        />
        <Route path="/Notification" exact component={Notification} />
        <Route
          path="/Student/Opportunities"
          exact
          component={StudentOpportunities}
        />
        <Route
          path="/Recruiter/StudentProfile"
          exact
          component={S_SearchProfile}
        />

        <Route
          path="/Recruiter/CreateOpportunity"
          exact
          component={CreateOpportunity}
        />

        <Route
          path="/Student/AcceptorReject"
          exact
          component={AcceptorReject}
        />
        <Route
          path="/Org_AcceptedStudent"
          exact
          component={Org_AcceptedStudent}
        />
        <Route path="/Org_AcceptedList" exact component={Org_AcceptedList} />
        <Route path="/Recruiter/R_SearchList" exact component={R_SearchList} />
        <Route
          path="/Recruiter/EditOpportunity"
          exact
          component={EditOpportunity}
        />
        <Route path="/Studnet/Notification" exact component={Notification} />
        <Route
          path="/Recruiter/Opportunities"
          exact
          component={RecruiterOpportunities}
        />

        <Route path="/Logout" exact component={Logout} />
        {redirect}
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/AboutUs" exact component={AboutUs} />

        <Route path="/ContactUs" exact component={ContactUs} />
        <Route path="/WhyIgg2" excat component={Whyigg2} />
        <Route path="/Resources" excat component={Resources} />
        <Route path="/privacy_policy" excat component={Privacypolicy} />
        <Route path="/termsandcondition" excat component={Termsandcondition} />
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <div>
      <BrowserRouter>
        <div className="App">
          <Suspense>{routes}</Suspense>
        </div>
      </BrowserRouter>
      <div className={show ? "toast" : "toasthide"}>
        <div
          style={{ position: "absolute", right: 10, cursor: "pointer" }}
          onClick={closeToast}
        >
          x
        </div>
        <div className="toastheader">
          <p>Title:{notification.title}</p>
        </div>
        <div className="toastheader">
          <p>Message:{notification.body}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.token !== null,
    loader: state.authReducer.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onTryAutoAuth: () => dispatch(actionCreator.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
