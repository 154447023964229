import {
    getRequest,
    postRequest,
    putRequest,
    putRequestFile,
    deleteRequest,
  } from "../../Helper/Helper";
  
  import * as actionTypes from "./Actiontypes";
  
//***************************************************************************** */
  export const getOrgProfile = () => {
    return (dispatch) => {
      dispatch({ type: actionTypes.ORG_PROFILE_INITIAL });
      getRequest(`/organisation/getOrgProfileDetails`)
        .then((res) => {
          if (res.status === 200) {
            return dispatch({
              type: actionTypes.ORG_PROFILE_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.ORG_PROFILE_FAIL,
              payload: res.data.responseData.message,
            });
          }
        })
        .catch((err) => {
          return dispatch({
            type: actionTypes.ORG_PROFILE_FAIL,
            payload: "Server Error!",
          });
        });
    };
  };
//*************************************************************************** */

export const updateOrgProfile = (data, oldData) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_ORG_PROFILE_INITIAL });
    putRequestFile(`/organisation/orgUpdateProfile`,data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.UPDATE_ORG_PROFILE_SUCCESS,
              payload: res.data.responseData,
              oldData: oldData
            });
          } else {
            return dispatch({
              type: actionTypes.UPDATE_ORG_PROFILE_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.UPDATE_ORG_PROFILE_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.UPDATE_ORG_PROFILE_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//*************************************************************************************** */

export const closeOrgResponseAlert=()=>{
  return dispatch => {
    dispatch({
        type: actionTypes.CLOSE_ORG_RESPONSE_ALERT,
        // payload:data,
    })
  }
}

//*************************************************************************** */

export const getOrgDashboard = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ORG_DASHBOARD_INITIAL });
    getRequest(`/organisation/GetOrganisationDashboard`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
                type: actionTypes.ORG_DASHBOARD_SUCCESS,
                payload: res.data.responseData,
            });
          }else {
            return dispatch({
                type: actionTypes.ORG_DASHBOARD_FAIL,
                payload: res.data.responseData.message,
                ErrorCode: res.data.responseData.errorCode
            });
          }
        } else {
          return dispatch({
            type: actionTypes.ORG_DASHBOARD_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.ORG_DASHBOARD_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//*************************************************************************************** */
//--------------------Recruiter Sign Up API-------------------------------------------------------------
export const createRecruiter = (formData,filter) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RECRUITER_SIGNUP_INITIAL });
    postRequest("/recruiter/create", formData)
      .then((res) => {
        if (res.status === 201) {
          if(res.data.statusCode===0) {
            getRequest(`/recruiter/getRecruiterList?filterValue=${filter}&pageLimit=6&pageNo=1`)
      .then((res1) => {
        if (res1.status === 200) {
          if (res1.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.RECRUITER_LIST_SUCCESS,
              payload: res1.data.responseData,
              showAlert: true
            });
          } else {
            return dispatch({
              type: actionTypes.RECRUITER_LIST_FAIL,
              payload: res1.data.responseData.message,
              ErrorCode: res1.data.responseData.errorCode
            });
          }
        } else {
          return dispatch({
            type: actionTypes.RECRUITER_LIST_FAIL,
            payload: res1.data.responseData.message,
            ErrorCode: res1.data.responseData.errorCode
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.RECRUITER_LIST_FAIL,
          payload: "Server Error!",
        });
      });
            return dispatch({
              type: actionTypes.RECRUITER_SIGNUP_SUCCESS,
              payload: true,
            });
          }
          else {
            return dispatch({
              type: actionTypes.RECRUITER_SIGNUP_FAIL,
              payload: res.data.responseData.message,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.RECRUITER_SIGNUP_FAIL,
            payload: res.data.responseData.message,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.RECRUITER_SIGNUP_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//*****************************DELETE REC PROFILE********************************************************** */
export const deleteRecruiterProfile = (id,filter,pageNo) => {
  return (dispatch) => {
      dispatch({ type: actionTypes.DELETE_RECRUITER_PROFILE_INITIAL });
      deleteRequest(`/recruiter/deleteProfile`,id)
          .then((res) => {
              if (res.status === 200) {
                if (res.data.statusCode === 0) {
                  return dispatch(getRecruiterList(filter,pageNo));
                }else {
                  return dispatch({
                      type: actionTypes.DELETE_RECRUITER_PROFILE_FAIL,
                      payload: res.data.responseData.message,
                      ErrorCode: res.data.responseData.errorCode
                  });
                }
              } else {
                  return dispatch({
                      type: actionTypes.DELETE_RECRUITER_PROFILE_FAIL,
                      payload: res.data.responseData.message,
                      ErrorCode: res.data.responseData.errorCode
                  });
              }
          })
          .catch((err) => {
              return dispatch({
                  type: actionTypes.DELETE_RECRUITER_PROFILE_FAIL,
                  payload: "Server Error!",
              });
          });
  };
};

//*****************************UPDATE REC Status********************************************************** */
export const updateRecruiterStatus = (data, filter, pageNo) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_RECRUITER_STATUS_INITIAL });
    putRequest(`/recruiter/updateProfileStatus`, data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch(getRecruiterList(filter, pageNo));
            
          } else {
            return dispatch({
              type: actionTypes.UPDATE_RECRUITER_STATUS_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.UPDATE_RECRUITER_STATUS_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.UPDATE_RECRUITER_STATUS_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//************************************************************************************* */
  
export const getRecruiterList = (filter,pageNo) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RECRUITER_LIST_INITIAL });
    getRequest(`/recruiter/getRecruiterList?filterValue=${filter}&pageLimit=6&pageNo=${pageNo}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.RECRUITER_LIST_SUCCESS,
              payload: res.data.responseData,
              showAlert: false
            });
          } else {
            return dispatch({
              type: actionTypes.RECRUITER_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode
            });
          }
        } else {
          return dispatch({
            type: actionTypes.RECRUITER_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.RECRUITER_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//********************************************************************************** */

export const handleDropdownModal=()=>{
  return dispatch => {
    dispatch({
        type: actionTypes.HANDLE_DROPDOWN_MODAL,
        // payload:val,
    })
  }
}
//********************************************************************************** */

export const closeDropdownModal=()=>{
  return dispatch => {
    dispatch({
        type: actionTypes.CLOSE_DROPDOWN_MODAL,
        // payload:val,
    })
  }
}

//**************************************************************************** */
export const handleCreationModal=(val)=>{
  return dispatch => {
    dispatch({
        type: actionTypes.UPDATE_REC_CREATION_MODAL,
        payload: val
    })
  }
}

//**************************************************************************** */
export const handleDeleteModal=(val)=>{
  return dispatch => {
    dispatch({
        type: actionTypes.UPDATE_DELETE_MODAL,
        payload: val
    })
  }
}
//************************************************************************************* */
export const getOpportunityDetails = (id) => {
 return (dispatch) => {
   dispatch({ type: actionTypes.GET_OPPORTUNITY_DETAILS_INITIAL });
   getRequest(`/opportunity/getCompleteDetails/${id}`)
     .then((res) => {
       if (res.status === 200) {
         if (res.data.statusCode === 0) {
           return dispatch({
             type: actionTypes.GET_OPPORTUNITY_DETAILS_SUCCESS,
             payload: res.data.responseData,
             showAlert: false
           });
         } else {
           return dispatch({
             type: actionTypes.GET_OPPORTUNITY_DETAILS_FAIL,
             payload: res.data.responseData.message,
             ErrorCode: res.data.responseData.errorCode
           });
         }
       } else {
         return dispatch({
           type: actionTypes.GET_OPPORTUNITY_DETAILS_FAIL,
           payload: res.data.responseData.message,
           ErrorCode: res.data.responseData.errorCode
         });
       }
     })
     .catch((err) => {
       return dispatch({
         type: actionTypes.GET_OPPORTUNITY_DETAILS_FAIL,
         payload: "Server Error!",
       });
     });
 };
};  

//*****************************************************************************/

export const getListByOrganisation = (pageNo,filters) => {
  return (dispatch) => {
      dispatch({ type: actionTypes.OPPO_ORGANISATION_LIST_INITIAL });
      postRequest(`/opportunity/getListByOrganisation?filterValue=ALL&pageLimit=6&pageNo=${pageNo}`,filters
          )
          .then((res) => {
              if (res.status === 200) {
                  if (res.data.statusCode === 0) {
                      return dispatch({
                          type: actionTypes.OPPO_ORGANISATION_LIST_SUCCESS,
                          payload: res.data.responseData,
                      });
                  } else {
                      return dispatch({
                          type: actionTypes.OPPO_ORGANISATION_LIST_FAIL,
                          payload: res.data.responseData.message,
                          ErrorCode: res.data.responseData.errorCode
                      });
                  }
              } else {
                  return dispatch({
                      type: actionTypes.OPPO_ORGANISATION_LIST_FAIL,
                      payload: res.data.responseData.message,
                      ErrorCode: res.data.responseData.errorCode
                  });
              }
          })
          .catch((err) => {
              return dispatch({
                  type: actionTypes.OPPO_ORGANISATION_LIST_FAIL,
                  payload: "Server Error!",
              });
          });
  };
};

export const updateOrgOpportuntiyStatus = (id,status,pageNo,filters) => {
  return (dispatch) => {
      dispatch({ type: actionTypes.UPDATE_ORG_OPPORTUNITY_STATUS_INITIAL });
      putRequest(`/opportunity/updateStatus/${id}?status=${status}`)
          .then((res) => {
              if (res.status === 201) {
                if (res.data.statusCode === 0) {
                  return dispatch(getListByOrganisation(pageNo,filters));
                }else {
                  return dispatch({
                      type: actionTypes.UPDATE_ORG_OPPORTUNITY_STATUS_FAIL,
                      payload: res.data.responseData.message,
                      ErrorCode: res.data.responseData.errorCode
                  });
                }
              } else {
                  return dispatch({
                      type: actionTypes.UPDATE_ORG_OPPORTUNITY_STATUS_FAIL,
                      payload: res.data.responseData.message,
                      ErrorCode: res.data.responseData.errorCode
                  });
              }
          })
          .catch((err) => {
              return dispatch({
                  type: actionTypes.UPDATE_ORG_OPPORTUNITY_STATUS_FAIL,
                  payload: err.message,
              });
          });
  };
};


export const acceptedOpportunityCategoryListByOrganisation = (pageno) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_INITIAL });
    getRequest(
      `/opportunity/acceptedOpportunityCategoryListByOrganisation?pageLimit=5&pageNo=${pageno}`
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
