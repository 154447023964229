export default {
  apiIpPort: "https://service.igotgame2.com/igg",
  apiIpPort1: "https://service.igotgame2.com/igg/master",

  apiBaseUrl: "/api/v1",
};
export const userConstants = {
  GET_REALTIME_USERS: "GET_REALTIME_USERS",
  GET_REALTIME_MESSAGES: "GET_REALTIME_MESSAGES",
};
