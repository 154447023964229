import {
  postRequest,
  getRequest,
  deleteRequest,
  // putRequest,
  // postRequestFile,
  // postRequestVerify,
  putRequestFile,
  // postResetPass,
  // getRequestFileDownload
} from "../../Helper/Helper";

import * as actionTypes from "./Actiontypes";

//**********************************Candidate Profile**************************************** */
export const getCandidateDashboard = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CANDIDATE_DASHBOARD_INITIAL });
    getRequest(`/candidate/getHomePage`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.CANDIDATE_DASHBOARD_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.CANDIDATE_DASHBOARD_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.CANDIDATE_DASHBOARD_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.CANDIDATE_DASHBOARD_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//**********************************Candidate Profile**************************************** */
export const getCandidateProfile = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CANDIDATE_PROFILE_INITIAL });
    getRequest(`/candidate/getProfileDetails`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.CANDIDATE_PROFILE_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.CANDIDATE_PROFILE_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.CANDIDATE_PROFILE_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.CANDIDATE_PROFILE_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//**********************************Candidate Update Profile***************************************** */

export const updateCandidateProfile = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_CANDIDATE_PROFILE_INITIAL });
    putRequestFile(`/candidate/updateProfile`, data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.UPDATE_CANDIDATE_PROFILE_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.UPDATE_CANDIDATE_PROFILE_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.UPDATE_CANDIDATE_PROFILE_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.UPDATE_CANDIDATE_PROFILE_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//**************************************************************************** */
export const closeCandidateResponseAlert = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLOSE_CANDIDATE_RESPONSE_ALERT,
      // payload:data,
    });
  };
};

//**********************************Get Skills**************************************** */
export const getCandidateSkills = (limit, pageNo) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CANDIDATE_SKILL_INITIAL });
    getRequest(`/candidate/getSkills?pageLimit=${limit}&pageNo=${pageNo}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.GET_CANDIDATE_SKILL_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.GET_CANDIDATE_SKILL_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GET_CANDIDATE_SKILL_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.GET_CANDIDATE_SKILL_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//**********************************Get Skills**************************************** */
export const addUpdateSkill = (type, formData, imageData) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_UPDATE_CANDIDATE_SKILL_INITIAL });
    postRequest(`/candidate/addOrUpdateSkill`, formData, {
      newMedia: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.ADD_UPDATE_CANDIDATE_SKILL_SUCCESS,
              payload:
                type === "Add"
                  ? "Skill added successfully!"
                  : "Skill updated successfully!",
            });
          } else {
            return dispatch({
              type: actionTypes.ADD_UPDATE_CANDIDATE_SKILL_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.ADD_UPDATE_CANDIDATE_SKILL_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.ADD_UPDATE_CANDIDATE_SKILL_FAIL,
          payload: "Server Error!",
        });
      });
  };
};

//**************************************************************************** */

export const getListByCandidate = (pageno, limit, filters, searchQuery) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.OPPO_CANDIDATE_LIST_INITIAL });
    postRequest(
      `/opportunity/getListByCandidateId?filterValue=OPEN&pageLimit=${limit}&pageNo=${pageno}&searchQuery=${searchQuery}`,
      filters
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.OPPO_CANDIDATE_LIST_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.OPPO_CANDIDATE_LIST_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.OPPO_CANDIDATE_LIST_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.OPPO_CANDIDATE_LIST_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//*****************************DELETE REC PROFILE********************************************************** */
export const deleteSkill = (id, limit, pageNo) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.DELETE_CANDIDATE_SKILL_INITIAL });
    deleteRequest(`/candidate/deleteSkill?candidateSkillId=${id}`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.statusCode === 0) {
            return dispatch(getCandidateSkills(limit, pageNo));
          } else {
            return dispatch({
              type: actionTypes.DELETE_CANDIDATE_SKILL_FAIL,
              payload: response.data.responseData.message,
              ErrorCode: response.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.DELETE_CANDIDATE_SKILL_FAIL,
            payload: response.data.responseData.message,
            ErrorCode: response.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.DELETE_RECRUITER_PROFILE_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//**************************************************************************** */
export const openSkillModal = (val) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.OPEN_SKILL_MODAL,
    });
  };
};
//**************************************************************************** */
export const closeSkillModal = (val) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLOSE_SKILL_MODAL,
    });
  };
};
//**************************************************************************** */
export const handleCandidateDeleteModal = (val) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_CANDIDATE_DELETE_MODAL,
      payload: val,
    });
  };
};
//******************************************************************************* */
export const deleteSkillMedia = (arr, limit, pageNo) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.DELETE_SKILL_MEDIA_INITIAL });
    deleteRequest(`/candidate/deleteMedia`, arr)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.statusCode === 0) {
            return dispatch(getCandidateSkills(limit, pageNo));
          } else {
            return dispatch({
              type: actionTypes.DELETE_SKILL_MEDIA_FAIL,
              payload: response.data.responseData.message,
              ErrorCode: response.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.DELETE_SKILL_MEDIA_FAIL,
            payload: response.data.responseData.message,
            ErrorCode: response.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.DELETE_SKILL_MEDIA_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//--------------------------------ApplyOnOpportunity Api----------------------------------------

// ----------------------Applyonopportunity-------------------------------------------------------
export const applyOnOpportunity = (appliedDate, opportunityId) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.APPLY_ON_OPPORTUNITY_INITIAL });

    postRequest(
      `/candidate/applyOnOpportunity?appliedDate=${appliedDate}&opportunityId=${opportunityId}`
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.APPLY_ON_OPPORTUNITY_SUCCESS,
              payload: true,
            });
          } else {
            return dispatch({
              type: actionTypes.APPLY_ON_OPPORTUNITY_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.APPLY_ON_OPPORTUNITY_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: actionTypes.APPLY_ON_OPPORTUNITY_NETWORK_ERROR,
          payload: "Server Error,Try Again",
        });
      });
  };
};
//****************************getStudentAppliedOpportunityList********************8888888
// **************************StudentAppliedOpportunityList***********************************************
export const getStudentAppliedOpportunityList = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_STUDENT_APPLIED_OPP_INITIAL });
    getRequest(`/candidate/StudentAppliedOpportunityList`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.GET_STUDENT_APPLIED_OPP_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.GET_STUDENT_APPLIED_OPP_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.GET_STUDENT_APPLIED_OPP_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.GET_STUDENT_APPLIED_OPP_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//************************************************************************************* */
export const getcandidateNotification = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CANDIDATE_NOTIFICATION_INITIAL });
    getRequest(`/candidate/candidateNotification`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.statusCode === 0) {
            return dispatch({
              type: actionTypes.CANDIDATE_NOTIFICATION_SUCCESS,
              payload: res.data.responseData,
            });
          } else {
            return dispatch({
              type: actionTypes.CANDIDATE_NOTIFICATION_FAIL,
              payload: res.data.responseData.message,
              ErrorCode: res.data.responseData.errorCode,
            });
          }
        } else {
          return dispatch({
            type: actionTypes.CANDIDATE_NOTIFICATION_FAIL,
            payload: res.data.responseData.message,
            ErrorCode: res.data.responseData.errorCode,
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: actionTypes.CANDIDATE_NOTIFICATION_FAIL,
          payload: "Server Error!",
        });
      });
  };
};
//************************* */
export const handleAdvanceFilter = (val) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_ADVANCE_FILTER_MODAL,
      payload: val,
    });
  };
};

export const handleCanshowblur = (val) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_BLUR,
      payload: val,
    });
  };
};
