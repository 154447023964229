import React, { Component, useState, useEffect, useRef } from "react";

import queryString from "query-string";
import { connect } from "react-redux";
import style from "./ResetPassword.module.css";
import * as actionTypes from "../../../../Store/Action/index";
import closeCircle from "../../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import SignupImg from "../../../../assets/globe.jpg";
import logo from "../../../../assets/logo/rev13-03.png";
import ResponseAlert from "../../../../GenericComponent/Modal/ResponseAlert/ResponseAlert";

import openEye from "../../../../assets/eyeIcon/Icon awesome eye.png";
import closeEye from "../../../../assets/eyeIcon/Icon ionic-ios-eye-off.png";
import Loader from "../../../../assets/Loader/spinner.gif";
import Backdroplog from "../../../../GenericComponent/Backdrop/Backdrop";

function ResetPassword(props) {
  const [token, setToken] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [newpasswordType, setNewPasswordType] = useState("newpassword");
  const [passErrorMsg, setPassErrorMsg] = useState("");
  const [cpassErrorMsg, setCPassErrorMsg] = useState("");
  const inputRef = useRef();
  const cinputRef = useRef();
  const passwordFormatChangedHandler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  const passwordFormatChangedHandle = () => {
    if (newpasswordType === "newpassword") {
      setNewPasswordType("newtext");
    } else {
      setNewPasswordType("newpassword");
    }
  };
  // useEffect(() => {
  //   inputRef.current.focus();
  //   let url=props.location.search;
  //   if (localStorage.getItem('userToken')!==null && localStorage.getItem('userToken')!=="" && localStorage.getItem('userToken')!==undefined){
  //             this.props.history.push('/')
  //   }
  //   else {
  //             if(url!==''){
  //               let params = queryString.parse(url);
  //               setToken(params.token)
  //             }
  //   }

  // }, [passwordType]);

  const resetpassword = (e) => {
    e.preventDefault();
    if (e.target.password.value === "") {
      setPassErrorMsg("This is a required field.");
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        e.target.password.value
      )
    ) {
      setPassErrorMsg(
        "Invalid password, Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
      );
    } else if (e.target.cpassword.value === "") {
      setCPassErrorMsg("This is a required field");
    } else if (e.target.password.value !== e.target.cpassword.value) {
      setCPassErrorMsg("Password didn't match.");
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        e.target.cpassword.value
      )
    ) {
      setCPassErrorMsg(
        "Invalid password, Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
      );
    } else {
      setCPassErrorMsg("");
      setPassErrorMsg("");

      props.ResetPasswordEmailOtp(
        props.forgotOtp,
        props.forgetEmail,
        e.target.password.value
      );
      props.hideModal();
    }
  };

  return (
    <div className={style.container}>
      <div className={style.leftContainer}>
        <div className={style.leftLogo}>
          <img className={style.logo} src={logo} alt="logo" />
        </div>
        <div className={style.leftPara}>
          <p>
            I Got Game 2 is the #1 portal for connecting students, athletes and
            talent to available scholarship opportunities with universities and
            colleges worldwide.
          </p>
        </div>
        <div className={style.leftImg}>
          <img src={SignupImg} alt="img" />
        </div>
      </div>
      <div className={style.rightContainer}>
        <img
          src={closeCircle}
          className={style.closeModal}
          alt="img"
          onClick={() => props.hideModal()}
        />
        <p className={style.title}>Reset Password</p>

        <div className={style.formStyle}>
          <form
            className={style.formStyle}
            onSubmit={resetpassword}
            autoComplete="off"
          >
            <div className={style.pass}>
              <label className={style.mail}>New Password</label>
              <input
                type={newpasswordType === "newpassword" ? "password" : "text"}
                id="password"
                name="password"
                autoComplete="off"
                className="inputform"
                onChange={() => setPassErrorMsg("")}
                ref={inputRef}
              />
              <img
                className={
                  newpasswordType === "newpassword" ? style.icon3 : style.icon2
                }
                src={newpasswordType === "newpassword" ? closeEye : openEye}
                alt="eye"
                onClick={() => passwordFormatChangedHandle()}
              />
              <p className={style.errorMsg}>{passErrorMsg}</p>
            </div>
            <div className={style.pass}>
              <label className={style.mail}>Re-type Password</label>
              <input
                type={passwordType}
                id="cpassword"
                name="cpassword"
                autoComplete="off"
                className="inputform"
                onChange={() => setCPassErrorMsg("")}
                ref={cinputRef}
              />
              <img
                className={
                  passwordType === "password" ? style.icon3 : style.icon2
                }
                src={passwordType === "password" ? closeEye : openEye}
                alt="eye"
                onClick={() => passwordFormatChangedHandler()}
              />
              <p className={style.errorMsg}>{cpassErrorMsg}</p>
            </div>
            <div className={style.allBtn}>
              <button className={style.signin2} type="submit">
                Change Password
              </button>
            </div>
          </form>
        </div>
        {props.showAlert && (
          <div className={style.changeDiv}>
            {
              <ResponseAlert
                redirect={"/"}
                message={
                  props.resetPasswordStatus
                    ? "Your password has been successfully changed. Sign In now!"
                    : props.errorMsg
                }
              />
            }
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    resetPasswordStatus: state.authReducer.resetPasswordStatus,
    errorMsg: state.authReducer.errorMsg,
    loader: state.authReducer.loader,
    showAlert: state.authReducer.showAlert,
    initial: state.authReducer.initial,
    ErrorCode: state.authReducer.ErrorCode,
    showBlur: state.authReducer.showBlur,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetPasswordEmailOtp: (otp, email, password) =>
      dispatch(actionTypes.ResetPasswordEmailOtp(otp, email, password)),
    ResetPasswordModal: (data) =>
      dispatch(actionTypes.ResetPasswordModal(data)),
    SignIn: () => dispatch(actionTypes.handleSignInModal()),
    hideModal: () => dispatch(actionTypes.hideAuthModal()),

    forgotPassword: (email) => dispatch(actionTypes.forgotPassword(email)),
    ValidateOtpResetPassword: (formData) =>
      dispatch(actionTypes.ValidateOtpResetPassword(formData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
