import React, { useState, useEffect } from "react";
import style from "./sendrequest.module.css";
import moment from "moment";
import Pagination from "../../../GenericComponent/Pagination/Pagination";
import {
  BlueButton,
  WhiteButton,
} from "../../../GenericComponent/Buttons/button";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import closeCircle from "../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";

function Modal(props) {
  const [oppId, setOppId] = useState("");
  const [requestTime, setRequestTime] = useState("");
  const [pageno, setPageno] = useState(1);

  const cancleModal = () => {
    props.handleModal(false);
  };

  const handleoppId = (e, item) => {
    setOppId(e.target.value);
    setRequestTime(moment().format("YYYY-MM-DD HH:mm:ss"));
  };

  const toShowPagination = () => {
    if (
      props.page_no_rec !== undefined &&
      props.page_no_rec > 1 &&
      props.opp_rec_list
    ) {
      return (
        <Pagination
          activePage={pageno}
          pageRangeDisplayed={props.page_no_rec}
          onLeftChange={() => onLeftChange()}
          onRightChange={() => onRightChange()}
        />
      );
    }
  };

  const onLeftChange = () => {
    if (pageno !== 1) {
      setPageno(pageno - 1);
    }
  };

  const onRightChange = () => {
    if (pageno !== props.page_no_rec) {
      setPageno(pageno + 1);
    }
  };
  const getDetails = () => {
    const payload = {
      categoryId: null,
      createdFrom: null,
      createdTo: null,
      subCategoryId: null,
      location: null,
    };

    props.getListByRecruiter("ALL", pageno, payload);
  };

  useEffect(() => {
    getDetails();
  }, [pageno]);

  return (
    <div className={style.centered}>
      <div className={style.header}>
        <div className={style.upper}>
          <img
            src={closeCircle}
            alt="img"
            style={{ cursor: "pointer" }}
            onClick={cancleModal}
          />
        </div>
        <div className={style.lower}>
          <p>Send Request</p>
        </div>
      </div>

      <form className={style.form} onChange={handleoppId}>
        {props.opp_rec_list && props.opp_rec_list.length > 0
          ? props.opp_rec_list.map((item, index) => (
              <div className={style.radiodiv1} key={item.opportunityId}>
                <p>{item.title}</p>
                <input
                  className={style.radioinput}
                  type="radio"
                  name="input"
                  value={item.opportunityId}
                />
              </div>
            ))
          : null}
      </form>
      <div className={style.footer}>{toShowPagination()}</div>
      <div className={style.btn}>
        <div onClick={cancleModal}>
          <WhiteButton children="Cancel" />
        </div>

        <div
          onClick={() => {
            oppId && props.cardData(oppId, requestTime, props.studentProfileId);
          }}
        >
          {" "}
          <button className={oppId ? style.blueButton : style.blueButton1}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    opp_rec_list: state.recruiterReducer.opp_rec_list,

    page_no_rec: state.recruiterReducer.page_no_rec,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListByRecruiter: (filter, pageno, payload) =>
      dispatch(actionTypes.getListByRecruiter(filter, pageno, payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
