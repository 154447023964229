import React, { useState } from "react";
import style from "./cards.module.css";
import { useHistory } from "react-router-dom";
import profileImg from "../../../assets/profile/Rectangle 5304.png";
import defaultImg from "../../../assets/defaultimg/Rectangle 5337@2x.png";

function Cards(props) {
  const history = useHistory();
  const showRequestModal = (studentProfileId) => {
    props.handleModal(studentProfileId);
    props.setStudentProfileId(studentProfileId);
    let oppid = [];
    if (oppid == []) {
      props.data1.map((item1, index) => {
        oppid.push(item1.opportunityId);
      });
      props.setoppId(oppid);
    }
  };

  const handleClick = (studentProfileId, sentRequest) => {
    localStorage.setItem("studentId", studentProfileId);
    props.setStudentProfileId(studentProfileId);

    history.push({
      pathname: "/Recruiter/StudentProfile",
      state: {
        studentProfileId: studentProfileId,
        sentRequest: sentRequest,
      },
    });
  };

  function showSkillData(condition, val1, val2) {
    if (condition) {
      return val1.join(" , ");
    } else {
      return val1;
    }
  }

  return (
    <>
      {props.data && props.data.length > 0
        ? props.data.map((item, index) => (
            <div className={style.container}>
              <div
                className={style.card}
                onClick={() =>
                  handleClick(item.studentProfileId, item.sentRequest)
                }
              >
                <div className={style.left}>
                  <img
                    src={
                      item.profileImgUrl && item.profileImgUrl !== null
                        ? item.profileImgUrl
                        : defaultImg
                    }
                    alt="img"
                  />
                </div>
                <div className={style.middle}>
                  <p>{item.name}</p>
                  <table className={style.table1}>
                    <tbody>
                      <div className={style.mainDiv}>
                        <div className={style.category}>Age</div>
                        <div className={style.value}>
                          {item && item.age !== null
                            ? item.age
                            : "No Information Provided"}
                        </div>
                      </div>
                      <div className={style.mainDiv}>
                        <div className={style.category}>Category</div>
                        <div className={style.value}>
                          {showSkillData(
                            Object.entries(item).length > 0 &&
                              item.category !== null,
                            item.category,
                            ""
                          ) || "No Information Provided"}
                        </div>
                      </div>

                      <div className={style.mainDiv}>
                        <div className={style.category}>Location</div>
                        <div className={style.value}>
                          {item && item.country !== null
                            ? item.country
                            : "No Information Provided"}
                        </div>
                      </div>
                    </tbody>
                  </table>
                </div>
                <div className={style.right}>
                  <table className={style.right_table1}>
                    <tbody>
                      <div className={style.mainDiv}>
                        <div className={style.category}>Gender</div>
                        <div className={style.value}>
                          {item && item.gender !== null
                            ? item.gender
                            : "No Information Provided"}
                        </div>
                      </div>
                      <div className={style.mainDiv}>
                        <div className={style.category}>Skills</div>
                        <div className={style.value}>
                          {showSkillData(
                            Object.entries(item).length > 0 &&
                              item.skills !== null,
                            item.skills,
                            ""
                          ) || "No Information Provided"}
                        </div>
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className={style.request}
                onClick={() => showRequestModal(item.studentProfileId)}
              >
                <p>Send Request</p>
              </div>
            </div>
          ))
        : null}
    </>
  );
}

export default Cards;
