import * as actionTypes from "../Action/Actiontypes";

const initialState = {
  loader: false,
  showConfirmModal: false,
  showRejectModal: false,
  showsentRequestModal: false,
  // showResetPassModal: false,
  showOTPModal: false,
  showSignupOTPModal: false,
  showForgotPassModal: false,
  showSignUpModal: false,
  showSignInModal: false,
  confirm: false,
  showBlur: false,
  showResetPasswordModal: false,
  type: 0,
  loaderList: true,
  errorMsg: "",
  loginStatus: false,
  initial: true,
  ErrorCode: "",
  showAlert: false,
  resetPasswordSuccMsg: "",
  emailverifySuccMsg: "",
  userDetails: {},

  signUpSuccess: false,
  verifyEmailStatus: null,
  errorMsgVerifyEmail: "",
  VerifyEmailNetworkStatus: false,
  showBlur1: false,
  showAlert1: false,
  notificationCount: 0,
  updateStatus: true,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AUTH_HIDE_MODAL:
      return {
        ...state,
        showConfirmModal: false,
        showResetPasswordModal: false,
        showOTPModal: false,
        showForgotPassModal: false,
        showSignUpModal: false,
        showSignInModal: false,
        confirm: false,
        showBlur: false,
        showSignupOTPModal: false,
        newuserEmailid: "",
      };
    case actionTypes.UPDATE_AUTH_SIGNIN_MODAL:
      return {
        ...state,
        showSignInModal: true,
        showSignUpModal: false,
        showBlur: true,
      };
    case actionTypes.UPDATE_AUTH_SIGNUP_MODAL:
      return {
        ...state,
        showSignInModal: false,
        showSignUpModal: true,
        showBlur: true,
      };
    case actionTypes.UPDATE_AUTH_FORGOT_MODAL:
      return {
        ...state,
        showSignInModal: false,
        showForgotPassModal: true,
        showBlur: true,
      };
    // case actionTypes.UPDATE_AUTH_RESET_MODAL:
    //   return {
    //     ...state,
    //     showResetPasswordModal: true,
    //     z: true,
    //   };
    case actionTypes.UPDATE_AUTH_OTP_MODAL:
      return {
        ...state,
        showOTPModal: true,
        showBlur: true,
        showConfirmModal: false,
      };

    case actionTypes.UPDATE_AUTH_SIGNUP_OTP_MODAL:
      return {
        ...state,
        showSignupOTPModal: true,
        showBlur: true,
        showConfirmModal: false,
      };

    case actionTypes.UPDATE_AUTH_CONFIRM_MODAL:
      return {
        ...state,
        showConfirmModal: true,
        showBlur: true,
      };

    case actionTypes.UPDATE_AUTH_RESET_PASSWORD_MODAL:
      return {
        ...state,
        showResetPasswordModal: true,
        showBlur: true,
        showConfirmModal: false,
        showOTPModal: false,
      };
    case actionTypes.LOGIN_INITIAL:
      return {
        ...state,
        errorMsg: "",
        loader: true,
        loginStatus: false,
        initial: true,
        ErrorCode: "",
        showAlert: false,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loginStatus: action.payload,
        updateStatus: action.payload,
        errorMsg: "",
        loader: false,
        initial: true,
        ErrorCode: "",
        showAlert: false,
      };
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        loginStatus: false,
        errorMsg: action.payload,
        loader: false,
        initial: false,
        showBlur: true,
        ErrorCode: action.ErrorCode,
        showAlert: true,
      };
    case actionTypes.CLEAR_LOGIN_STATE:
      return {
        ...state,
        loader: false,
        loginStatus: false,
        ErrorCode: "",
        errorMsg: "",
        showAlert: true,
      };
    case actionTypes.REGISTER_FCMTOKEN_INITIAL:
      return {
        ...state,
        errorMsg: "",
        loader: true,
        FCMStatus: false,
        initial: true,
        ErrorCode: "",
        showAlert: false,
      };
    case actionTypes.REGISTER_FCMTOKEN_SUCCESS:
      return {
        ...state,
        FCMStatus: action.payload,
        errorMsg: "",
        loader: false,
        initial: true,
        // role: action.role,
        ErrorCode: "",
        showAlert: false,
      };
    case actionTypes.REGISTER_FCMTOKEN_FAIL:
      return {
        ...state,
        FCMStatus: false,
        errorMsg: action.payload,
        loader: false,
        initial: false,
        showBlur: true,
        ErrorCode: action.ErrorCode,
        showAlert: true,
      };

    case actionTypes.GET_DETAILS_INITIAL:
      return {
        ...state,
        loginStatus: true,
        errorMsg: "",
        loader: true,
        initial: true,
        ErrorCode: "",
        showAlert1: false,
        showBlur1: false,
      };
    case actionTypes.GET_DETAILS_SUCCESS:
      return {
        ...state,
        loginStatus: true,
        errorMsg: "",
        loader: false,
        initial: true,
        ErrorCode: "",
        showAlert1: false,
        showBlur1: false,
        userDetails: action.payload,
      };
    case actionTypes.GET_DETAILS_FAIL:
      return {
        ...state,
        loginStatus: false,
        errorMsg: action.payload,
        loader: false,
        initial: false,
        showBlur1: true,
        ErrorCode: action.ErrorCode,
        showAlert1: true,
      };
    case actionTypes.CLOSE_RESPONSE_ALERT:
      return {
        ...state,
        loader: false,
        ErrorCode: "",
        errorMsg: "",
        showBlur: false,
        showAlert: false,
      };
    case actionTypes.STUDENT_SIGNUP_INITIAL:
      return {
        ...state,
        errorMsg: "",
        loader: true,
        signUpStatus: true,
        initial: true,
        ErrorCode: "",
        showAlert: false,
        showConfirmModal: false,
        showSignUpModal: false,
        newuserEmailid: action.emailid,
      };
    case actionTypes.STUDENT_SIGNUP_SUCCESS:
      return {
        ...state,
        errorMsg: "",
        loader: false,
        signUpStatus: true,
        initial: true,
        ErrorCode: "",
        showAlert: false,
        showBlur: true,
        showConfirmModal: true,
        showSignUpModal: false,
        newuserEmailid: action.emailid,
        type: 0,
      };
    case actionTypes.STUDENT_SIGNUP_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
        loader: false,
        signUpStatus: false,
        initial: false,
        ErrorCode: action.ErrorCode,
        showAlert: true,
        showConfirmModal: false,
        showSignUpModal: false,
        newuserEmailid: "",
      };
    case actionTypes.ORG_SIGNUP_INITIAL:
      return {
        ...state,
        errorMsg: "",
        loader: true,
        signUpStatus: true,
        initial: true,
        ErrorCode: "",
        showAlert: false,
        showConfirmModal: true,
        showSignUpModal: false,
        newuserEmailid: action.emailid,
      };
    case actionTypes.ORG_SIGNUP_SUCCESS:
      return {
        ...state,
        errorMsg: "",
        loader: false,
        signUpStatus: true,
        initial: true,
        ErrorCode: "",
        showAlert: false,
        showBlur: true,
        showConfirmModal: true,
        showSignUpModal: false,
        newuserEmailid: action.emailid,
        type: 0,
      };
    case actionTypes.ORG_SIGNUP_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
        loader: false,
        signUpStatus: false,
        initial: false,
        ErrorCode: action.ErrorCode,
        showAlert: true,
        showConfirmModal: false,
        showSignUpModal: false,
        newuserEmailid: "",
      };
    case actionTypes.LOGOUT_INITIAL:
      return {
        ...state,
        loader: true,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        loader: false,
        // role: null,
      };

    case actionTypes.VERIFY_EMAIL_INITIAL:
      return {
        ...state,
        loader: true,
        // showBlur:false,
        // showAlert:false,
        verifyEmailStatus: false,
      };
    case actionTypes.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loader: false,
        // showBlur:false,
        // showAlert:false,
        verifyEmailStatus: action.payload,
      };
    case actionTypes.VERIFY_EMAIL_FAIL:
      return {
        ...state,
        loader: false,
        // showBlur:true,
        // showAlert:true,
        verifyEmailStatus: false,
        errorMsgVerifyEmail: action.payload,
      };

    case actionTypes.VERIFY_EMAIL_NETWORK_ERROR:
      return {
        ...state,
        loader: false,
        //     showBlur:true,
        // showAlert:true,
        VerifyEmailNetworkStatus: true,
      };
    case actionTypes.GENERATE_PASSWORD_RESET_TOKEN_INITIAL:
      return {
        ...state,
        errorMsg: "",
        loader: true,
        GeneratePasswordResetTokenStatus: false,
        initial: true,
        ErrorCode: "",
        showAlert: false,
        type: 0,
      };
    case actionTypes.GENERATE_PASSWORD_RESET_TOKEN_SUCCESS:
      return {
        ...state,
        GeneratePasswordResetTokenStatus: action.payload,
        errorMsg: "",
        loader: false,
        initial: true,
        ErrorCode: "",
        showAlert: false,
        showBlur: true,
        showConfirmModal: true,
        showSignUpModal: false,
        type: 2,
      };
    case actionTypes.GENERATE_PASSWORD_RESET_TOKEN_FAIL:
      return {
        ...state,
        GeneratePasswordResetTokenStatus: false,
        errorMsg: action.payload,
        loader: false,
        initial: false,
        showBlur: true,
        ErrorCode: action.ErrorCode,
        showAlert: true,
      };
    case actionTypes.CLEAR_GENERATE_PASSWORD_RESET_TOKEN_STATE:
      return {
        ...state,
        loader: false,
        GeneratePasswordResetTokenStatus: false,
        ErrorCode: "",
        errorMsg: "",
        showAlert: true,
      };

    case actionTypes.FORGOT_PASSWORD_INITIAL:
      return {
        ...state,
        errorMsg: "",
        loader: true,
        GenerateOTP: false,
        initial: true,
        ErrorCode: "",
        showAlert: false,
        type: 0,
      };
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        GenerateOTP: action.payload,
        errorMsg: "",
        loader: false,
        initial: true,
        ErrorCode: "",
        showAlert: false,
        showBlur: true,
        showConfirmModal: true,
        showOTPModal: false,
        type: 2,
      };
    case actionTypes.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        GenerateOTP: false,
        errorMsg: action.payload,
        loader: false,
        initial: false,
        showBlur: true,
        ErrorCode: action.ErrorCode,
        showAlert: true,
      };
    case actionTypes.CLEAR_FORGOT_PASSWORD_STATE:
      return {
        ...state,
        loader: false,
        GenerateOTP: false,
        ErrorCode: "",
        errorMsg: "",
        showAlert: true,
      };

    case actionTypes.VALIDATE_OTP_RESET_PASSWORD_INITIAL:
      return {
        ...state,
        otpErrorMsg: "",
        loader: true,
        otpValidationStatus: false,
        initial: true,
        ErrorCode: action.payload,
        showAlert: false,
      };
    case actionTypes.VALIDATE_OTP_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        otpValidationStatus: action.payload,
        otpErrorMsg: "",
        loader: false,
        initial: true,
        ErrorCode: action.payload,
        showAlert: false,
        showBlur: true,
        showResetPasswordModal: true,
      };
    case actionTypes.VALIDATE_OTP_RESET_PASSWORD_FAIL:
      return {
        ...state,
        otpValidationStatus: false,
        otpErrorMsg: action.payload,
        loader: false,
        initial: false,
        showBlur: true,
        ErrorCode: action.ErrorCode,
        showAlert: true,
        showResetPasswordModal: false,
        errorMsg: action.payload,
        // errorMsg:"INVALID OTP",
      };

    case actionTypes.RESET_PASSWORD_EMAIL_OTP_INITIAL:
      return {
        ...state,
        errorMsg: "",
        loader: false,
        resetPasswordStatus: false,
        initial: true,
        ErrorCode: "",
        showAlert: false,
        showBlur: false,
      };
    case actionTypes.RESET_PASSWORD_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        resetPasswordStatus: action.payload,
        errorMsg: "Your password has been successfully changed. Sign In now!",
        loader: false,
        initial: true,
        ErrorCode: "",
        showAlert: true,
        showBlur: true,
        resetPasswordSuccMsg:
          "Your password has been successfully changed. Sign In now!",
      };
    case actionTypes.RESET_PASSWORD_EMAIL_OTP_FAIL:
      return {
        ...state,
        resetPasswordStatus: false,
        errorMsg: action.payload,
        loader: false,
        initial: false,
        showBlur: true,
        ErrorCode: action.ErrorCode,
        showAlert: true,
      };

    case actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_INITIAL:
      return {
        ...state,
        emailverificationotpErrorMsg: "",
        loader: true,
        emailverificationotpStatus: false,
        initial: true,
        ErrorCode: action.payload,
        showAlert: false,
      };
    case actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailverificationotpStatus: action.payload,
        emailverificationotpErrorMsg: "",
        loader: false,
        initial: true,
        ErrorCode: action.payload,
        showAlert: true,
        emailverifySuccMsg:
          "Your email has been verified.Please Login your account!",
        errorMsg: "Your email has been verified.Please Login your account!",
        showBlur: true,
        // showResetPasswordModal: true,
      };
    case actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        emailverificationotpStatus: false,
        emailverificationotpErrorMsg: action.payload,
        loader: false,
        initial: false,
        showBlur: true,
        ErrorCode: action.ErrorCode,
        showAlert: true,
        showResetPasswordModal: false,
        errorMsg: action.payload,
        // errorMsg:"INVALID OTP",
      };
    //   case actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_INITIAL:
    //   return {
    //     ...state,
    //     emailverificationotpErrorMsg: "",
    //     loader: true,
    //     emailverificationotpStatus: false,
    //     initial: true,
    //     ErrorCode: action.payload,
    //     showAlert: false,
    //   };
    // case actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_SUCCESS:
    //   return {
    //     ...state,
    //     emailverificationotpStatus: action.payload,
    //     emailverificationotpErrorMsg: "",
    //     loader: false,
    //     initial: true,
    //     ErrorCode: action.payload,
    //     showAlert: true,
    //     emailverifySuccMsg:"Your Email Verified Successfully",
    //     showBlur: true,
    //     // showResetPasswordModal: true,
    //   };
    // case actionTypes.VALIDATE_OTP_EMAIL_VERIFICATION_FAIL:
    //   return {
    //     ...state,
    //     emailverificationotpStatus: false,
    //     emailverificationotpErrorMsg: action.payload,
    //     loader: false,
    //     initial: false,
    //     showBlur: true,
    //     ErrorCode: action.ErrorCode,
    //     showAlert: true,
    //     showResetPasswordModal: false,
    //     errorMsg: action.payload,
    //     // errorMsg:"INVALID OTP",
    //   };

    case actionTypes.USER_LIST_INITIAL:
      return {
        ...state,
        loader: true,
        loaderList: true,
        verifyUserListStatus: false,
      };
    case actionTypes.USER_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        loaderList: false,
        verifyUserListStatus: true,
        userList: action.payload,
      };
    case actionTypes.USER_LIST_FAIL:
      return {
        ...state,
        loader: false,
        loaderList: false,
        verifyUserListStatus: false,
        errorMsg: action.payload,
      };
    case actionTypes.GET_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        notificationCount: action.payload,
      };

    default:
      return state;
  }
};
