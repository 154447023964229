import React from "react";
import style from "./cards.module.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import defaultImg from "../../../../assets/defaultimg/Rectangle 5337@2x.png";

function Cards(props) {
  const history = useHistory();

  const handleRedirect = (path, oppid) => {
    history.push({
      pathname: path,
      state: {
        opp_id: oppid,
        applied: true,
        backPath: "/AppliedOpportunities",
      },
    });
  };

  return (
    <>
      {props.data && props.data.length > 0
        ? props.data.map((item, index) => (
            <div
              className={style.container}
              onClick={() =>
                handleRedirect("/Opportunity_Details", item.opportunityId)
              }
            >
              <div className={style.card}>
                <div className={style.left}>
                  <p className={style.oppdes}>{item.opportunitydescription}</p>
                  <p className={style.orgname}>{item.orgName}</p>
                  <p className={style.time}>
                    {moment(item.createdTime).fromNow()}
                  </p>
                </div>

                <div className={style.right}>
                  <img
                    src={
                      item.opportunityimageUrl &&
                      item.opportunityimageUrl !== null
                        ? item.opportunityimageUrl
                        : defaultImg
                    }
                    alt="img"
                  />
                </div>
              </div>
            </div>
          ))
        : null}
    </>
  );
}

export default Cards;
