import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import LogoutIcon2 from "../../../../assets/upload_icon/Icon feather-log-out.png";
import ProfileIcon2 from "../../../../assets/upload_icon/Path 30309.png";
import LogoutIcon from "../../../../assets/upload_icon/Icon feather-log-out (2).png";
import ProfileIcon from "../../../../assets/regicons/Icon ionic-ios-person.png";
import style from "./profileDropdown.module.css";
import ResponseAlert from "../../../Modal/ResAlert/ResponseAlert";
import { connect } from "react-redux";
import * as actionTypes from "../../../../Store/Action/index";

function ProfileDropdown(props) {
  const [img1, setImg1] = useState(ProfileIcon);
  const [img2, setImg2] = useState(LogoutIcon);

  const history = useHistory();

  const [ip, setIp] = useState(props?.data?.accountId);

  function handleClick() {
    if (localStorage.getItem("userRole") === "ORGANISATION") {
      history.push("/Profile");
    } else if (localStorage.getItem("userRole") === "CANDIDATE") {
      history.push("/Student/Profile");
    } else if (localStorage.getItem("userRole") === "RECRUITER") {
      history.push("/Recruiter/Profile");
    }
  }

  useEffect(() => {
    props.getUserDetails();
  }, []);

  return (
    <div className={style.container}>
      <div
        className={style.subContainer1}
        onMouseOver={() => setImg1(ProfileIcon2)}
        onMouseOut={() => setImg1(ProfileIcon)}
        onClick={handleClick}
      >
        <label className={style.label} onClick={handleClick}>
          Profile
        </label>
        <img className={style.profile_img} src={img1} alt="pro" />
      </div>

      {props.showAlert1 ? (
        <div className={style.changeDiv}>{<ResponseAlert />}</div>
      ) : (
        <Link to={{ pathname: "/Logout", state: { ip: ip } }}>
          <div
            className={style.subContainer2}
            onMouseOver={() => setImg2(LogoutIcon2)}
            onMouseOut={() => setImg2(LogoutIcon)}
          >
            <label className={style.label}>Logout</label>
            <img className={style.profile_img} src={img2} alt="out" />
          </div>
        </Link>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    showBlur1: state.authReducer.showBlur1,
    showAlert1: state.authReducer.showAlert1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: () => dispatch(actionTypes.getUserDetails()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
