import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import Delete from "../../../GenericComponent/Modal/Delete/Delete";
import style from "./SkillDetails.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import Backdroplog from "../../../GenericComponent/Backdrop/Backdrop";
import EditSkill from "./EditAddSkills";
import deletebin from "../../../assets/delete/bin@2x.png";
import activityImg from "../../../assets/activity/Group 18035.png";
import NoData from "../../../assets/globe.jpg";
import Loader from "../../../assets/Loader/spinner.gif";
import { WhiteButton } from "../../../GenericComponent/Buttons/button";
import Pagination from "../../../GenericComponent/Pagination/Pagination";
import ResponseAlert from "../../../GenericComponent/Modal/ResponseAlert/ResponseAlert";

const SkillDetails = (props) => {
  const [sidebar, setSidebar] = useState(false);

  const [editSkillData, setEditSkillData] = useState(null);
  const [type, setType] = useState("Add");
  const [skillIdToDelete, setSkillIdToDelete] = useState(null);
  const [removeMediaArray, setRemoveMediaArray] = useState([]);
  const [pageNo, setPageNo] = useState(1);

  const toShowPagination = () => {
    if (props.page_no_rec !== undefined && props.page_no_rec > 1) {
      return (
        <Pagination
          activePage={pageNo}
          pageRangeDisplayed={props.page_no_rec}
          onLeftChange={() => onLeftChange()}
          onRightChange={() => onRightChange()}
        />
      );
    }
  };

  const onLeftChange = () => {
    if (pageNo !== 1) {
      setPageNo(pageNo - 1);
    }
  };

  const onRightChange = () => {
    if (pageNo !== props.page_no_rec) {
      setPageNo(pageNo + 1);
    }
  };

  function openModal(pageType, data) {
    if (pageType == "Edit") {
      props.getSubCategoryList(data.mainCategoryId);
    }
    setTimeout(function () {
      setType(pageType);
      setEditSkillData(data);
      props.openSkillModal();
    }, 100);
  }

  const hideModal = () => {
    props.closeSkillModal();
  };

  const addUpdateSkillData = (types, data, media) => {
    props.closeSkillModal();
    props.addUpdateSkill(types, data, media, 5, pageNo);
  };

  const handleDelete = (id, val) => {
    setSkillIdToDelete(id);
    props.handleCandidateDeleteModal(val);
  };

  const handleCancelDelete = () => {
    if (skillIdToDelete !== null) {
      setSkillIdToDelete(null);
      props.handleCandidateDeleteModal(false);
    } else {
      setRemoveMediaArray([]);
      props.handleCandidateDeleteModal(false);
      setTimeout(function () {
        props.openSkillModal();
      }, 100);
    }
  };

  const submitDelete = () => {
    if (skillIdToDelete == null) {
      props.deleteSkillMedia(removeMediaArray, 5, pageNo);
    } else {
      props.deleteSkill(skillIdToDelete, 5, pageNo);
    }
    props.handleCandidateDeleteModal(false);
  };

  const handleDeleteSkillMedia = async (arr, val) => {
    setRemoveMediaArray(arr);
    await props.closeSkillModal();
    await props.handleCandidateDeleteModal(val);
  };

  const handleRedirect = () => {
    props.getCandidateSkills(5, pageNo);
  };

  function showDetailsData() {
    if (props.skillDatas.length > 0) {
      return props.skillDatas.map((userSkill, index) => (
        <div key={index} className={style.card_style}>
          <div className={style.div2}>
            <hr />
            <br />
          </div>
          <div className={style.div3}>
            <div className={style.left}>
              <label className={style.label1}>Category</label>
              <label className={style.display_data}>
                {userSkill.mainCategory ? userSkill.mainCategory : ""}
              </label>
            </div>
            <div className={style.right}>
              <label className={style.label1}>Skills</label>
              <label className={style.display_data}>
                {userSkill.subCategory ? userSkill.subCategory : ""}
              </label>
            </div>
            <div className={style.right1}>
              <div>
                <img
                  className={style.edit}
                  src={activityImg}
                  alt="edit"
                  onClick={() => openModal("Edit", userSkill)}
                />
              </div>
              <div>
                <img
                  className={style.delete}
                  src={deletebin}
                  alt="delete"
                  onClick={() => handleDelete(userSkill.skillId, true)}
                />
              </div>
            </div>
          </div>
          <div className={style.left}>
            <label className={style.label1}>Description</label>
            {userSkill.description !== null && userSkill.description !== "" ? (
              <label className={style.display_info}>
                {userSkill.description}
              </label>
            ) : (
              <label className={style.display_info} style={{ opacity: "0.5" }}>
                No Information Provided
              </label>
            )}
          </div>
          <div className={style.div5}>
            <label className={style.label2}>Media</label>
            {userSkill.mediaList && userSkill.mediaList.length > 0 ? (
              <div className={style.picdiv}>
                <div className={style.gridContainer}>
                  {userSkill.mediaList &&
                    userSkill.mediaList.length > 0 &&
                    userSkill.mediaList.map((item, index3) => (
                      <div className={style.item} key={index3}>
                        {item.fileType === "image" && (
                          <img
                            src={item.fileUri}
                            alt="medias"
                            style={{
                              width: "280px",
                              height: "200px",
                              margin: "3px",
                            }}
                          />
                        )}
                        {item.fileType === "video" && (
                          // <iframe
                          //   src={item.fileUri}
                          //   height="200px"
                          //   controls
                          //   width="280px"
                          //   alt="medias"
                          // ></iframe>
                          <video
                            width="210px"
                            height="200px"
                            controls
                            preload="metadata"
                          >
                            <source
                              src={`${item.fileUri}#t=0.5`}
                              type="video/mp4"
                            />
                          </video>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <label
                className={style.display_info}
                style={{ opacity: "0.5", marginLeft: "1.8rem" }}
              >
                No Media Found
              </label>
            )}
          </div>
        </div>
      ));
    } else {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <p>
              <b>No Data Found</b>
            </p>
            <img
              className={style.nodata}
              style={{ width: "50%", height: "60%" }}
              src={NoData}
              alt="nodata"
            />
          </div>
        </div>
      );
    }
  }

  useEffect(() => {
    props.getCandidateSkills(5, pageNo);
    props.getMainCategoryList();
  }, [pageNo]);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <Backdroplog show={props.showBlur} />
        <div className={style.firstHeading}>Skill Details</div>

        {props.loader ? (
          <div className="loader_styles">
            <img src={Loader} alt="loader" />
          </div>
        ) : (
          <div>
            <div className={style.mainbox}>
              <div className={style.div}>
                <div className={style.button}>
                  <button
                    children="+ Additional Skills"
                    className={style.whiteButton}
                    onClick={() => openModal("Add", null)}
                  ></button>
                </div>
                {showDetailsData()}
              </div>

              {props.showDeleteModal && (
                <div className={style.changeDeleteDiv}>
                  <Delete
                    cancelDelete={handleCancelDelete}
                    confirmDelete={submitDelete}
                  />
                </div>
              )}
            </div>
            {props.showSkillModal && (
              <div className={style.changeDiv}>
                <EditSkill
                  toClose={hideModal}
                  handleDeleteSkillMedia={handleDeleteSkillMedia}
                  type={type}
                  editSkillData={editSkillData}
                  category_list={props.category_list}
                  toSubmit={addUpdateSkillData}
                />
              </div>
            )}
            <div className={style.footer}>{toShowPagination()}</div>
          </div>
        )}

        {props.showAlert && (
          <div className={style.changeResponseDiv}>
            <ResponseAlert
              redirect={
                props.addCandidateSkillStatus ? "/Student/SkillDetails" : null
              }
              fetchUpdate={handleRedirect}
              message={
                props.addCandidateSkillStatus
                  ? props.addCandidateSkillMsg
                  : props.candidateErrorMsg
              }
              type="CANDIDATE"
            />
          </div>
        )}
      </section>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.candidateReducer.loader,
    skillDatas: state.candidateReducer.skillDatas,
    candidateErrorMsg: state.candidateReducer.candidateErrorMsg,
    candidateErrorCode: state.candidateReducer.candidateErrorCode,
    category_list: state.recruiterReducer.category_list,
    categoryErrorMsg: state.recruiterReducer.categoryErrorMsg,
    categoryErrorCode: state.recruiterReducer.categoryErrorCode,
    categoryListErrMsg: state.recruiterReducer.categoryListErrMsg,
    categoryListStatus: state.recruiterReducer.categoryListStatus,
    sub_category_list: state.recruiterReducer.sub_category_list,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
    showSkillModal: state.candidateReducer.showSkillModal,
    showDeleteModal: state.candidateReducer.showDeleteModal,
    page_no_rec: state.candidateReducer.page_no_rec,
    addCandidateSkillStatus: state.candidateReducer.addCandidateSkillStatus,
    addCandidateSkillMsg: state.candidateReducer.addCandidateSkillMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidateSkills: (limit, pageNo) =>
      dispatch(actionTypes.getCandidateSkills(limit, pageNo)),
    getMainCategoryList: () => dispatch(actionTypes.getMainCategoryList()),
    getSubCategoryList: (id) => dispatch(actionTypes.getSubCategoryList(id)),
    openSkillModal: () => dispatch(actionTypes.openSkillModal()),
    closeSkillModal: () => dispatch(actionTypes.closeSkillModal()),
    addUpdateSkill: (type, FormData, mediaList, limit, pageNo) =>
      dispatch(
        actionTypes.addUpdateSkill(type, FormData, mediaList, limit, pageNo)
      ),
    deleteSkill: (id, limit, pageNo) =>
      dispatch(actionTypes.deleteSkill(id, limit, pageNo, limit, pageNo)),
    handleCandidateDeleteModal: (val) =>
      dispatch(actionTypes.handleCandidateDeleteModal(val)),
    deleteSkillMedia: (arr, limit, pageNo) =>
      dispatch(actionTypes.deleteSkillMedia(arr, limit, pageNo)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SkillDetails);
