import * as actionTypes from "../Action/Actiontypes";

const initialState = {
  loader: false,
  orgProfileData: {},
  orgDashboardData: {},
  showBlur: false,
  showAlert: false,
  orgErrorMsg: "",
  orgErrorCode: "",
  orgProfileDataStatus: false,

  recruiterListErrMsg: "",
  recruiterlistStatus: false,
  recruiterDeleteErrMsg: "",
  recruiterDeleteStatus: false,
  recruiterStatusErrMsg: "",
  recruiterStatusStatus: false,
  showDropDownModal: false,
  showDropDownid: 0,
  showDeleteModal: false,
  rec_filter: "ALL",
  recsignUpStatus: false,
  showCreationModal: false,
};

export const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ORG_PROFILE_INITIAL:
      return {
        ...state,
        loader: true,
        showAlert: false,
        orgErrorMsg: "",
        orgErrorCode: "",
        showBlur: false,
      };
    case actionTypes.ORG_PROFILE_SUCCESS:
      return {
        ...state,
        loader: false,
        showAlert: false,
        orgErrorMsg: "",
        orgErrorCode: "",
        showBlur: false,
        orgProfileData: action.payload,
      };
    case actionTypes.ORG_PROFILE_FAIL:
      return {
        ...state,
        loader: false,
        orgErrorMsg: action.payload,
        orgErrorCode: action.ErrorCode,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.UPDATE_ORG_PROFILE_INITIAL:
      return {
        ...state,
        loader: true,
        showAlert: false,
        orgErrorMsg: "",
        orgErrorCode: "",
        showBlur: false,
        orgProfileDataStatus: false,
      };
    case actionTypes.UPDATE_ORG_PROFILE_SUCCESS:
      return {
        ...state,
        loader: false,
        showAlert: true,
        orgErrorMsg: "",
        orgErrorCode: "",
        showBlur: true,
        orgProfileDataStatus: true,
        orgProfileData: action.oldData,
      };
    case actionTypes.UPDATE_ORG_PROFILE_FAIL:
      return {
        ...state,
        loader: false,
        orgErrorMsg: action.payload,
        orgErrorCode: action.ErrorCode,
        orgProfileDataStatus: false,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.ORG_DASHBOARD_INITIAL:
      return {
        ...state,
        loader: true,
        showAlert: false,
        orgErrorMsg: "",
        orgErrorCode: "",
        showBlur: false
      };
    case actionTypes.ORG_DASHBOARD_SUCCESS:
      return {
        ...state,
        loader: false,
        showAlert: false,
        orgErrorMsg: "",
        orgErrorCode: "",
        showBlur: false,
        orgDashboardData: action.payload,
      };
    case actionTypes.ORG_DASHBOARD_FAIL:
      return {
        ...state,
        loader: false,
        orgErrorMsg: action.payload,
        orgErrorCode: action.ErrorCode,
        showAlert: true,
        showBlur: true
      };
    case actionTypes.CLOSE_ORG_RESPONSE_ALERT:
      return {
        ...state,
        loader: false,
        orgErrorCode: "",
        orgErrorMsg: "",
        showBlur: false,
        showAlert: false,
      };
    case actionTypes.DELETE_RECRUITER_PROFILE_INITIAL:
      return {
        ...state,
        recruiterDeleteErrMsg: "",
        recruiterDeleteStatus: false,
        orgErrorMsg: "",
        orgErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
        showBlur: false,
      };
    case actionTypes.DELETE_RECRUITER_PROFILE_SUCCESS:
      return {
        ...state,
        recruiterDeleteErrMsg: "",
        recruiterDeleteStatus: true,
        orgErrorMsg: "",
        orgErrorCode: "",
        loader: false,
        initial: true,
        recSuccessMsg: "Account deleted successfully!",
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.DELETE_RECRUITER_PROFILE_FAIL:
      return {
        ...state,
        recruiterDeleteErrMsg: "",
        recruiterDeleteStatus: false,
        orgErrorMsg: action.payload,
        orgErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };

    case actionTypes.UPDATE_RECRUITER_STATUS_INITIAL:
      return {
        ...state,
        recruiterStatusErrMsg: "",
        recruiterStatusStatus: false,
        orgErrorMsg: "",
        orgErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
      };
    case actionTypes.UPDATE_RECRUITER_STATUS_SUCCESS:
      return {
        ...state,
        recruiterStatusErrMsg: "",
        recruiterStatusStatus: true,
        orgErrorMsg: "",
        orgErrorCode: "",
        loader: false,
        initial: true,
        showAlert: false,
      };
    case actionTypes.UPDATE_RECRUITER_STATUS_FAIL:
      return {
        ...state,
        recruiterStatusErrMsg: "",
        recruiterStatusStatus: false,
        orgErrorMsg: action.payload,
        orgErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
      };
    case actionTypes.RECRUITER_LIST_INITIAL:
      return {
        ...state,
        recruiterListErrMsg: "",
        recruiterlistStatus: false,
        orgErrorMsg: "",
        orgErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
        // rec_list:[],
      };
    case actionTypes.RECRUITER_LIST_SUCCESS:
      return {
        ...state,
        recruiterListErrMsg: "",
        recruiterlistStatus: true,
        orgErrorMsg: "",
        orgcErrorCode: "",
        loader: false,
        initial: true,
        showBlur: action.showAlert,
        showAlert: action.showAlert,
        rec_list: action.payload.recruiterDetails,
        page_no_rec: action.payload.noOfPagesAsPerPageLimit,
      };
    case actionTypes.RECRUITER_LIST_FAIL:
      return {
        ...state,
        recruiterListErrMsg: "",
        recruiterlistStatus: true,
        orgErrorMsg: action.payload,
        orgErrorCode: action.ErrorCode,
        loader: false,
        showBlur: false,
        initial: false,
        showAlert: false,
      };
    case actionTypes.HANDLE_DROPDOWN_MODAL:
      return {
        ...state,
        showDropDownModal: true,
      };
    case actionTypes.CLOSE_DROPDOWN_MODAL:
      return {
        ...state,
        showDropDownModal: false,
      };
    case actionTypes.UPDATE_REC_CREATION_MODAL:
      return {
        ...state,
        showBlur: action.payload,
        showCreationModal: action.payload,
      };
    case actionTypes.UPDATE_DELETE_MODAL:
      return {
        ...state,
        showBlur: action.payload,
        showDeleteModal: action.payload,
      };
    case actionTypes.RECRUITER_SIGNUP_INITIAL:
      return {
        ...state,
        orgErrorMsg: "",
        orgErrorCode: "",
        loader: true,
        initial: true,
        showAlert: false,
        showBlur: false,
        recsignUpStatus: false,
      };
    case actionTypes.RECRUITER_SIGNUP_SUCCESS:
      return {
        ...state,
        showAlert: true,
        showBlur: true,
        recsignUpStatus: true,
        recSuccessMsg: "Account created successfully!",
      };
    case actionTypes.RECRUITER_SIGNUP_FAIL:
      return {
        ...state,
        orgErrorMsg: action.payload,
        orgErrorCode: action.ErrorCode,
        loader: false,
        initial: false,
        showAlert: true,
        showBlur: true,
        recsignUpStatus: false,
      };
    case actionTypes.GET_OPPORTUNITY_DETAILS_INITIAL:
      return {
        ...state,
        loader: true,
        orgErrorMsg: "",
        orgErrorCode: "",
        showBlur: false,
        showAlert: false,
      };
    case actionTypes.GET_OPPORTUNITY_DETAILS_SUCCESS:
      return {
        ...state,
        loader: false,
        showAlert: false,
        orgErrorMsg: "",
        orgErrorCode: "",
        showBlur: false,
        orgOpportunityData: action.payload,
      };
    case actionTypes.GET_OPPORTUNITY_DETAILS_FAIL:
      return {
        ...state,
        loader: false,
        orgErrorMsg: action.payload,
        orgErrorCode: action.ErrorCode,
        showBlur: true,
        showAlert: true,
      };
      case actionTypes.OPPO_ORGANISATION_LIST_INITIAL:
        return {
          ...state,
          loader: true,
          orgErrorMsg: "",
          orgErrorCode: "",
          showBlur: false,
          showAlert: false
        };
      case actionTypes.OPPO_ORGANISATION_LIST_SUCCESS:
        return {
          ...state,
          loader: false,
          showAlert: false,
          orgErrorMsg: "",
          orgErrorCode: "",
          showBlur: false,
          opp_org_list: action.payload.createdOpportinity,
          page_no_rec: action.payload.noOfPagesAsPerPageLimit,
        };
      case actionTypes.OPPO_ORGANISATION_LIST_FAIL:
        return {
          ...state,
          orgErrorMsg: action.payload,
          orgErrorCode: action.ErrorCode,
          loader: false,
          initial: false,
          showAlert: false,
          showBlur: false,
        };
        case actionTypes.UPDATE_ORG_OPPORTUNITY_STATUS_INITIAL:
          return {
            ...state,
            loader: true,
            orgErrorMsg: "",
            orgErrorCode: "",
            showAlert: false,
            showBlur: false
          };
        case actionTypes.UPDATE_ORG_OPPORTUNITY_STATUS_FAIL:
          return {
            ...state,
            orgErrorMsg: action.payload,
            orgErrorCode: action.ErrorCode,
            loader: false,
            initial: false,
            showAlert: true,
            showBlur: true
          };

          case actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_INITIAL:
            return {
              ...state,
              acceptedopportunityErrMsg: "",
              acceptedopportunityStatus: false,
              acceptedopportunityErrorMsg: "",
              acceptedopportunityErrorCode: "",
              loader: true,
              initial: true,
              showAlert: false,
            };
          case actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_SUCCESS:
            return {
              ...state,
              acceptedopportunityErrMsg: "",
              acceptedopportunityStatus: true,
              acceptedopportunityErrorMsg: "",
              acceptedopportunityErrorCode: "",
              loader: false,
              initial: true,
              acceptedopportunityData: action.payload,
              skillDatas: action.payload.acceptOpportunityCategory,
              page_no_accepted: action.payload.noOfPagesAsPerPageLimit,
              showAlert: false,
            };
          case actionTypes.GET_ACCEPT_OPP_CATEGORY_ORG_FAIL:
            return {
              ...state,
              acceptedopportunityErrMsg: "",
              acceptedopportunityStatus: false,
              acceptedopportunityErrorMsg: action.payload,
              acceptedopportunityErrorCode: action.ErrorCode,
              loader: false,
              initial: false,
              showAlert: true,
              // showBlur: true,
            };
         
    default:
      return state;
  }
};
