import React, { useState, useRef, useEffect } from "react";
import style from "./EditProfile.module.css";
import { useHistory } from "react-router-dom";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import Backdroplog from "../../GenericComponent/Backdrop/Backdrop";
import Uploadimg from "../../assets/regicons/upload.svg";
import { BlueButton, WhiteButton } from "../../GenericComponent/Buttons/button";
import { connect } from "react-redux";
import Loader from "../../assets/Loader/spinner.gif";
import defaultImg from "../../assets/defaultimg/Rectangle.png";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import * as actionTypes from "../../Store/Action/index";
import profile from "./profile";
import { useDropzone } from "react-dropzone";

function EditProfile(props) {
  const [sidebar, setSidebar] = useState(false);
  const inputFile = useRef(null);
  const contactNoRef = useRef(null);
  const orgNameRef = useRef(null);

  const [profileData, setprofileData] = useState(
    props.history.location.state.profileData
  );
  const [country, setCountry] = useState(profileData.country);
  const [state, setState] = useState(profileData.state);
  const [city, setCity] = useState(profileData.city);
  const [isRemoveIcon, setIsRemoveIcon] = useState(
    props.history.location.state.profileData.profileUrl ? true : false
  );
  const [operation, setOperation] = useState("NONE");
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(
    profileData && profileData.profileUrl ? profileData.profileUrl : defaultImg
  );
  const [errorMsg, setErrorMsg] = useState(null);
  const history = useHistory();

  const handleCountry = (value) => {
    const index = value.target.selectedIndex;
    const el = value.target.childNodes[index];
    const option = el.getAttribute("id");
    setCountry(option);
    props.getStateListId(option);
    setCountry(value.target.value);
    setState(null);
    setCity(null);
  };

  const handleState = (value) => {
    const index = value.target.selectedIndex;
    const el = value.target.childNodes[index];
    const option = el.getAttribute("id");
    setState(option);
    props.getCityListId(option);
    setState(value.target.value);
    setCity(null);
  };
  const handleCity = (value) => {
    setCity(value.target.value);
  };

  useEffect(() => {
    props.getCountryList();
    props.getStateListId(profileData.countryId);
    props.getCityListId(profileData.stateId);
  }, []);

  function updateProfileData(e) {
    let name = e.target.name;
    let value = e.target.value;
    setprofileData({ ...profileData, [name]: value });
    setErrorMsg(null);
  }

  function removeImage() {
    setPreviewImage(defaultImg);
    setProfileImage("");
    setprofileData({ ...profileData, profileUrl: null });
    setIsRemoveIcon(false);

    setOperation(profileData.profileUrl === null ? "NONE" : "DELETE");
  }

  function handleSubmitData(e) {
    e.preventDefault();
    if (
      profileData.organisationName === null ||
      profileData.organisationName === ""
    ) {
      setErrorMsg({ orgNameErr: "*Organization name is required." });
      orgNameRef.current.focus();
    } else if (profileData.contactNo === null || profileData.contactNo === "") {
      setErrorMsg({ contactNoErr: "*Contact no. is required." });
      contactNoRef.current.focus();
    } else {
      if (errorMsg === null) {
        let payload = {
          about: profileData.about,
          addressLine1: profileData.addressLine1,
          city: city,
          contactNo: profileData.contactNo,
          country: country,
          organizationName: profileData.organisationName,
          pinCode: profileData.pinCode,
          state: state,
          website: profileData.website,
          operation: operation,
          countrycode: profileData.countrycode,
        };
        let formData = new FormData();
        formData.append(
          "organisationImage",
          previewImage[0] !== null ? previewImage[0] : ""
        );
        formData.append("jsonRequest", JSON.stringify(payload));
        props.updateOrgProfile(formData, props.profileData);
      }
    }
  }

  // const imageHandler = (e) => {
  //   if (e.target.files.length > 0) {
  //     const myfile = e.target.files[0].name;
  //     var ext = myfile.split(".").pop().toLowerCase();
  //     if (ext === "png" || ext === "jpg" || ext === "svg" || ext === "jpeg") {
  //       setProfileImage(e.target.files[0]);
  //       setPreviewImage(URL.createObjectURL(e.target.files[0]));
  //       e.target.value = null;
  //       setIsRemoveIcon(true);
  //       setErrorMsg(null);
  //       setOperation("UPDATE");
  //     } else {
  //       setErrorMsg({
  //         ProfileImageErr:
  //           "*Invalid File!- Allowed Format: .png, .jpg, .jpeg, .svg.",
  //       });
  //       setProfileImage("");
  //       setPreviewImage(defaultImg);
  //       e.target.value = null;
  //       setIsRemoveIcon(false);
  //       setOperation("NONE");
  //     }
  //   }
  // };

  function handleTextarea(e) {
    if (profileData.about === "" && e.keyCode === 32) {
      e.preventDefault();
    } else {
      let name = e.target.name;
      let value = e.target.value;
      setprofileData({ ...profileData, [name]: value });
    }
  }

  function handleNumberdata(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.keyCode === 190 || e.keyCode === 187) {
        e.preventDefault();
      } else {
        setprofileData({ ...profileData, [e.target.name]: e.target.value });
      }
    }
  }

  function handleClick() {
    history.push("/Profile");
  }

  const onButtonClick = () => {
    inputFile.current.click();
    setErrorMsg(null);
  };

  function showProfileImageErr() {
    if (errorMsg !== null && errorMsg.ProfileImageErr) {
      return errorMsg.ProfileImageErr;
    } else {
      return "";
    }
  }

  function showOrgNameErr() {
    if (errorMsg !== null && errorMsg.orgNameErr) {
      return <span className={style.errorMsg}>{errorMsg.orgNameErr}</span>;
    } else {
      return "";
    }
  }

  function showContactNoErr() {
    if (errorMsg !== null && errorMsg.contactNoErr) {
      return errorMsg.contactNoErr;
    } else {
      return "";
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      setPreviewImage(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setOperation("UPDATE");
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    if (previewImage == []) {
      previewImage.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  }, [previewImage]);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <Backdroplog show={props.showBlur} />
        {props.loader ? (
          <div className="loader_styles">
            <img src={Loader} alt="loader" />
          </div>
        ) : (
          <div>
            <div className={style.topsomepagewrapper}>
              <div className={style.toprow}>
                <div className={style.topcolumn}>
                  <div>
                    <p className={style.para}>Profile</p>
                  </div>
                </div>
                <div className={style.topcolumn}>
                  <div className={style.editbtn}>
                    <WhiteButton
                      children="Cancel"
                      className={style.button}
                      onClick={() => handleClick()}
                    />
                    <BlueButton children="Save" onClick={handleSubmitData} />
                  </div>
                </div>
              </div>
              <div className={style.toprow}>
                <div className={style.centered}>
                  <div className={style.top}>
                    <p>Upload Organization Image</p>
                    <img
                      alt="img"
                      src={
                        previewImage[0]?.preview
                          ? previewImage[0]?.preview
                          : profileData?.profileUrl
                      }
                      className={style.orgimg}
                    />
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <img
                        src={Uploadimg}
                        className={style.img2}
                        style={{ cursor: "pointer" }}
                        id="img"
                        // onClick={onButtonClick}
                      />
                    </div>

                    {isRemoveIcon && (
                      <i
                        style={{
                          position: "absolute",
                          color: "green",
                          fontSize: "1.3rem",
                          cursor: "pointer",
                          top: "20%",
                          right: "1%",
                        }}
                        className="fa fa-times-circle"
                        aria-hidden="true"
                        onClick={() => removeImage()}
                      ></i>
                    )}
                    {/* <input
                      type="file"
                      name="profile"
                      onChange={imageHandler}
                      ref={inputFile}
                      accept="image/png, image/jpg, image/jpeg,image/svg"
                      style={{ display: "none", visibility: "none" }}
                      for="img"
                    /> */}
                    {/* <img
                      src={Uploadimg}
                      className={style.upimg}
                      style={{ cursor: "pointer" }}
                      id="img"
                      alt="img"
                      onClick={onButtonClick}
                    /> */}
                  </div>
                  <div className={style.somepagewrapper}>
                    <span className={style.errorMsg}>
                      {showProfileImageErr()}
                    </span>
                    <hr className={style.line} />
                    <div className={style.row}>
                      <div className={style.column}>
                        <label className={style.label1}>
                          Organization Name
                        </label>
                        <input
                          className={style.input}
                          ref={orgNameRef}
                          defaultValue={
                            profileData && profileData.organisationName
                          }
                          disabled={true}
                          name="organisationName"
                          onChange={(e) => updateProfileData(e)}
                        ></input>

                        {showOrgNameErr()}

                        <label className={style.label1}>Email ID</label>
                        <input
                          className={style.input}
                          defaultValue={profileData && profileData.emailId}
                          name="emailId"
                          onChange={(e) => updateProfileData(e)}
                          disabled
                        ></input>

                        <label className={style.label1}>Country</label>
                        <select
                          className={style.input}
                          onChange={(e) => {
                            handleCountry(e);
                          }}
                          name="country"
                        >
                          <option value="">
                            {country ? country : "--Select--"}
                          </option>

                          {props.country_list && props.country_list.length > 0
                            ? props.country_list.map((item, index) => (
                                <option value={item.name} id={item.id}>
                                  {item.name}
                                </option>
                              ))
                            : null}
                        </select>

                        <label className={style.label1}>State</label>
                        <select
                          className={style.input}
                          onChange={(e) => {
                            handleState(e);
                          }}
                          name="state"
                        >
                          <option value="">
                            {state ? state : "--Select--"}
                          </option>
                          {props.state_list && props.state_list.length > 0
                            ? props.state_list.map((item, index) => (
                                <option value={item.name} id={item.id}>
                                  {item.name}
                                </option>
                              ))
                            : null}
                        </select>

                        <label className={style.label1}>City</label>
                        <select
                          className={style.input}
                          onChange={(e) => handleCity(e)}
                          name="city"
                        >
                          <option value="">{city ? city : "--Select--"}</option>
                          {props.city_list && props.city_list.length > 0
                            ? props.city_list.map((item, index) => (
                                <option value={item.name} id={item.id}>
                                  {item.name}
                                </option>
                              ))
                            : null}
                        </select>
                        <label className={style.label1}>Postal Code</label>
                        <input
                          className={style.input}
                          type="number"
                          defaultValue={profileData && profileData.pinCode}
                          name="pinCode"
                          onChange={(e) => updateProfileData(e)}
                          autoComplete="off"
                        />

                        <label className={style.label1}>
                          Organization Highlights
                        </label>
                        <textarea
                          className={style.input2}
                          name="about"
                          maxLength="500"
                          onKeyDown={(e) => handleTextarea(e)}
                          onChange={(e) => updateProfileData(e)}
                          defaultValue={profileData && profileData.about}
                          placeholder="Describe awards, recognition, accomplishments that would attract students/talent to your organization."
                        ></textarea>
                      </div>
                      <div style={{ width: "5%" }}></div>
                      <div className={style.column}>
                        <label className={style.label1}>Website</label>
                        <input
                          className={style.input}
                          name="website"
                          onChange={(e) => updateProfileData(e)}
                          defaultValue={profileData && profileData.website}
                        ></input>
                        <label className={style.label1}>Phone No.</label>
                        <div className={style.phonediv}>
                          <div className={style.phonedivleft}>
                            <input
                              className={style.input}
                              type="number"
                              onKeyDown={(e) => handleNumberdata(e)}
                              defaultValue={
                                profileData && profileData.countrycode
                              }
                              name="countrycode"
                              onChange={(e) => updateProfileData(e)}
                              autoComplete="off"
                            />
                          </div>
                          <div className={style.phonedivright}>
                            <input
                              className={style.input}
                              name="contactNo"
                              ref={contactNoRef}
                              type="number"
                              onKeyDown={(e) => handleNumberdata(e)}
                              onChange={(e) => updateProfileData(e)}
                              defaultValue={
                                profileData && profileData.contactNo
                              }
                            />
                          </div>
                        </div>
                        <span className={style.errorMsg}>
                          {showContactNoErr()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.showAlert && (
          <div className={style.changeDiv}>
            <ResponseAlert
              redirect={props.orgProfileDataStatus === true ? "/Profile" : null}
              message={
                props.orgProfileDataStatus === true
                  ? "Profile updated successfully!"
                  : props.orgErrorMsg
              }
              type="ORGANISATION"
            />
          </div>
        )}
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.orgReducer.loader,
    orgErrorMsg: state.orgReducer.orgErrorMsg,
    orgErrorCode: state.orgReducer.orgErrorCode,
    showAlert: state.orgReducer.showAlert,
    showBlur: state.orgReducer.showBlur,
    orgProfileDataStatus: state.orgReducer.orgProfileDataStatus,
    country_list: state.recruiterReducer.country_list,
    state_list: state.recruiterReducer.state_list,
    city_list: state.recruiterReducer.city_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrgProfile: (data, oldData) =>
      dispatch(actionTypes.updateOrgProfile(data, oldData)),
    getCountryList: () => dispatch(actionTypes.getCountryList()),
    getStateListId: (id) => dispatch(actionTypes.getStateListId(id)),
    getCityListId: (id) => dispatch(actionTypes.getCityListId(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
