import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cards from "./Cards/cards";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import style from "./student.module.css";
import Pagination from "../../GenericComponent/Pagination/Pagination";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import NoData from "../../assets/globe.jpg";
import SentRequest from "../../GenericComponent/Modal/SendRequest/sendrequest";
import Backdroplog from "../../GenericComponent/Backdrop/Backdrop";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import optionsImg from "../../assets/options/Group 18213.png";
import searchImg from "../../assets/search/Icon ionic-ios-search.png";
import Modal from "../../GenericComponent/RsearchFilter/AdvanceFilter";
import loader from "../../assets/Loader/spinner.gif";
function Search(props) {
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  const [showResponseAlert, setshowResponseAlert] = useState(false);
  const [showsentRequestModal, setshowsentRequestModal] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [oppId, setoppId] = useState([]);
  const [studentProfileId, setStudentProfileId] = useState("");
  const [title, setTitle] = useState();
  const [categoryId, setCategoryID] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [locationId, setLocationId] = useState();
  const [ageFromId, setAgeFromId] = useState(null);
  const [ageToId, setAgeToId] = useState(null);
  const [data1Card, setData1Card] = useState(null);
  const [noDataVal, setNoDataVal] = useState(false);

  const toShowPagination = () => {
    if (
      props.page_no_stu !== undefined &&
      props.page_no_stu > 1 &&
      props.search_stu_list
    ) {
      return (
        <Pagination
          activePage={pageNo}
          pageRangeDisplayed={props.page_no_stu}
          onLeftChange={() => onLeftChange()}
          onRightChange={() => onRightChange()}
        />
      );
    }
  };

  const onLeftChange = () => {
    if (pageNo !== 1) {
      setPageNo(pageNo - 1);
    }
  };

  const onRightChange = () => {
    if (pageNo !== props.page_no_stu) {
      setPageNo(pageNo + 1);
    }
  };

  const cardData = async (val1, val2, val3) => {
    props.handleSentRequestModal(false);
    await props.sentRequest(val1, val2, val3);
    setshowsentRequestModal(false);
    props.handleshowAlert(true);
    setshowAlert(true);
    getSearch();
  };
  const toShowblur = (val) => {
    props.handleshowBlur(val);
  };

  const handleModal = (val) => {
    props.handleSentRequestModal(val);
  };
  const handleFilterModal = (val1, val2) => {
    props.handleRecAdvanceFilter(val1);
  };

  const toShowCards = () => {
    if (props.search_stu_list && props.search_stu_list.length > 0) {
      return (
        <Cards
          oppId={oppId}
          studentProfileId={studentProfileId}
          data={props.search_stu_list ? props.search_stu_list : []}
          data1={props.opp_rec_list}
          setshowResponseAlert={setshowResponseAlert}
          setshowAlert={setshowAlert}
          setshowsentRequestModal={setshowsentRequestModal}
          setStudentProfileId={setStudentProfileId}
          setoppId={setoppId}
          handleModal={handleModal}
          toShowblur={toShowblur}
        />
      );
    } else {
      if (props.loader) {
        return (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <p>
                <b>No Data Found</b>
              </p>
              <img
                className={style.nodata}
                style={{ width: "50%", height: "60%" }}
                src={NoData}
                alt="nodata"
              />
            </div>
          </div>
        );
      } else {
        if (noDataVal) {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <p>No Data Found</p>
                <img
                  className={style.nodata}
                  style={{ width: "50%", height: "60%" }}
                  src={NoData}
                  alt="nodata"
                />
              </div>
            </div>
          );
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setNoDataVal(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const handlefilter = (val1, val2, val3, val4, val5) => {
    // setAgeFromId(val1);
    // setAgeToId(val2);
    setCategoryID(val3);
    setLocationId(val4);
    setSubCategoryId(val5);
    setPageNo(1);

    setAgeFromId(val1);
    setAgeToId(val2);
    // getSearch();
  };

  const getDetails = () => {
    const payload = {
      categoryId: null,
      createdFrom: null,
      createdTo: null,
      subCategoryId: null,
      location: null,
    };

    props.getListByRecruiter("ALL", pageNo, payload);
  };
  const getSearch = (e) => {
    const payload = {
      ageFrom: ageFromId ? ageFromId : null,
      ageTo: ageToId ? ageToId : null,
      categoryId: categoryId ? categoryId : null,
      location: locationId ? locationId : null,
      subCategoryId: subCategoryId ? subCategoryId : null,
    };
    props.searchStudentListByRecruiter(pageNo, payload, searchQuery);
  };
  useEffect(() => {
    getDetails();
  }, [pageNo]);

  useEffect(() => {
    props.getMainCategoryList();
  }, []);

  useEffect(
    (e) => {
      getSearch(e);
    },
    [
      pageNo,
      searchQuery,
      ageFromId,
      ageToId,
      categoryId,
      locationId,
      subCategoryId,
    ]
  );

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <Backdroplog show={props.showBlur} />
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <div className={style.topHeading}>
          <div className={style.header}>
            <div className={style.keyword}>
              <img src={searchImg} alt="img" />
              <input
                placeholder=" &nbsp;&nbsp;Keywords"
                className={style.input}
                id="seachQuery"
                onChange={(e) => {
                  setPageNo(1);
                  setSearchQuery(e.target.value);
                }}
              ></input>
            </div>
            <div className={style.options}>
              <img
                src={optionsImg}
                alt="img"
                style={{ cursor: "pointer" }}
                onClick={() => handleFilterModal(true, true)}
              />
            </div>
          </div>
        </div>

        <div className={style.heading}></div>
        <div className={style.centered}>{toShowCards()}</div>
      </section>
      <div className={style.footer}>{toShowPagination()}</div>
      {props.showsentRequestModal && (
        <div className={style.changeDiv}>
          <SentRequest
            oppId={oppId}
            studentProfileId={studentProfileId}
            setTitle={setTitle}
            setshowsentRequestModal={setshowsentRequestModal}
            setshowAlert={setshowAlert}
            handleModal={handleModal}
            setshowResponseAlert={setshowResponseAlert}
            toShowblur={toShowblur}
            cardData={cardData}
            getSearch={getSearch}
          />
        </div>
      )}
      {props.showRecAdvanceFilter && (
        <div className={style.changeDiv3}>
          <Modal
            handleFilterModal={handleFilterModal}
            handlefilter={handlefilter}
            category_list={props.category_list}
          />
        </div>
      )}

      {props.showAlert && (
        <div className={style.changeDiv1}>
          <ResponseAlert
            message={
              props.searchstudentErrorMsg == "Server Error!"
                ? "Please Login Again"
                : props.sentRequestErrorMsg == "Server Error!"
                ? "Please Login Again"
                : props.sentRequestErrorCode == "175"
                ? "This opportunity has already applied by student"
                : "Your Request has been sent successfully !"
            }
            type={"RECRUITER"}
            redirect={
              props.searchstudentErrorMsg ? "/" : "/Recruiter/R_SearchList"
            }
            sentRequestErrorCode={props.sentRequestErrorCode}
            handleModal={handleModal}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    searchstudentListErrMsg: state.recruiterReducer.searchstudentListErrMsg,
    searchstudentlistStatus: state.recruiterReducer.searchstudentlistStatus,
    searchstudentErrorMsg: state.recruiterReducer.searchstudentErrorMsg,
    sentRequestErrorMsg: state.recruiterReducer.sentRequestErrorMsg,
    oppErrorCode: state.recruiterReducer.oppErrorCode,
    sentRequestErrorCode: state.recruiterReducer.sentRequestErrorCode,
    loader: state.recruiterReducer.loader,
    initial: state.recruiterReducer.initial,
    opp_rec_list: state.recruiterReducer.opp_rec_list,
    search_stu_list: state.recruiterReducer.search_stu_list,
    page_no_stu: state.recruiterReducer.page_no_stu,
    showAlert: state.recruiterReducer.showAlert,
    showBlur: state.recruiterReducer.showBlur,
    showsentRequestModal: state.recruiterReducer.showsentRequestModal,
    showDeleteModal: state.orgReducer.showDeleteModal,
    sub_category_list: state.recruiterReducer.sub_category_list,
    showRecAdvanceFilter: state.recruiterReducer.showRecAdvanceFilter,
    page_no_rec: state.recruiterReducer.page_no_rec,
    category_list: state.recruiterReducer.category_list,
    sentRequestStatus: state.recruiterReducer.sentRequestStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleshowAlert: (val) => dispatch(actionTypes.handleshowAlert(val)),
    handleshowBlur: (val) => dispatch(actionTypes.handleshowBlur(val)),
    searchStudentListByRecruiter: (pageNo, payload, searchQuery) =>
      dispatch(
        actionTypes.searchStudentListByRecruiter(pageNo, payload, searchQuery)
      ),
    sentRequest: (opportunityId, timeRequest, studentProfileId) =>
      dispatch(
        actionTypes.sentRequest(opportunityId, timeRequest, studentProfileId)
      ),
    getListByRecruiter: (filter, pageno, payload) =>
      dispatch(actionTypes.getListByRecruiter(filter, pageno, payload)),
    handleSentRequestModal: (data) =>
      dispatch(actionTypes.handleSentRequestModal(data)),

    handleRecAdvanceFilter: (val) =>
      dispatch(actionTypes.handleRecAdvanceFilter(val)),
    getMainCategoryList: () => dispatch(actionTypes.getMainCategoryList()),
    getSubCategoryList: (id) => dispatch(actionTypes.getSubCategoryList(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
