import React from "react";
import "../Pagination/Pagination.css";

const Pagination = (props) => {

  const toShow = () => {
    if(props.activePage === undefined || props.pageRangeDisplayed === null) {
      return "";
    } else {
      return (
        <div>
          <div
            style={{ display: "inline-block" }}
            onClick={() => props.onLeftChange()}
          >
            <i
              className="fas fa-chevron-left arrow"
              style={
                props.activePage === 1
                  ? {
                      fontSize: "20px",
                      color: "#9c9cbb",
                      marginRight: "11px",
                    }
                  : {
                      fontSize: "20px",
                      color: "#1E118D",
                      marginRight: "11px",
                      cursor: "pointer",
                    }
              }
            ></i>
          </div>
          <div className="active1">{props.activePage}</div>
          <div
            style={{ display: "inline-block" }}
            onClick={() => props.onRightChange()}
          >
            <i
              className="fas fa-chevron-right arrow"
              style={
                props.activePage === props.pageRangeDisplayed
                  ? {
                      fontSize: "20px",
                      color: "#9c9cbb",
                      marginLeft: "11px",
                    }
                  : {
                      fontSize: "20px",
                      color: "#1E118D",
                      marginLeft: "11px",
                      cursor: "pointer",
                    }
              }
            ></i>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      {props.pageRangeDisplayed ? (
        <div className="pagination" style={props.style}>
          {toShow()}
        </div>
      ) : null}
    </>
  );
};

export default Pagination;
