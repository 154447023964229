import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as actionTypes from "../../../Store/Action/index";
import { connect } from "react-redux";
import moment from "moment";
import style from "./SViewProfile.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import defaultImage from "../../../assets/defaultimg/Rectangle 5337@2x.png";
import RejectModal from "../../../GenericComponent/Modal/RejectModal/RejectModal";
import ResponseAlert from "../../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import media from "../../../assets/ellipse/Rectangle -1.png";
import Backdroplog from "../../../GenericComponent/Backdrop/Backdrop";
import backarrowImg from "../../../assets/back arrow/Icon ionic-ios-arrow-round-back.png";
function SProfile(props) {
  const [sidebar, setSidebar] = useState(false);
  const [status, setStatus] = useState(true);
  const [status1, setStatus1] = useState(false);
  const [dob] = useState(
    props?.profileData?.dateOfBirth
      ? moment(props?.profileData?.dateOfBirth).format("DD-MM-YYYY")
      : "No Information Provided"
  );
  const [showBlueButton, setshowBlueButton] = useState();
  const [showWhiteButton, setshowWhiteButton] = useState();
  const [child, setchild] = useState("Accept");
  const [requestDate, setRequestDate] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [showRejectModal, setshowRejectModal] = useState(false);

  const history = useHistory();

  const handleModal = (val) => {
    props.handleRejectModal(val);
  };

  useEffect(() => {
    props.getOpportunityDetails(props?.history?.location?.state?.OpportunityId);
  }, []);

  const submitReject = (comment) => {
    props.acceptedRejectedAppliedOpportunity(
      false,
      comment,
      props?.history?.location?.state?.OpportunityId,
      requestDate,
      props?.profileData?.userAccountId
    );
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  function showData(data) {
    if (
      props.profileData !== undefined &&
      props.profileData !== null &&
      data !== null &&
      data !== ""
    ) {
      return <p>{data}</p>;
    } else {
      return <p style={{ opacity: "0.5" }}>No Information Provided.</p>;
    }
  }

  function showTopData(data) {
    if (
      props.profileData !== undefined &&
      props.profileData !== null &&
      data !== null &&
      data !== ""
    ) {
      return data;
    } else {
      return "No Information Provided";
    }
  }

  function showSkillData(condition, val1, val2) {
    if (condition) {
    } else {
      return val1;
    }
  }

  function showskilldatas() {
    if (
      props.skillDatas !== undefined &&
      props.skillDatas !== null &&
      props.skillDatas.length > 0
    ) {
      const newarray = props.skillDatas;
      return newarray.map((userSkill, index) => (
        <div className={style.row3} key={index}>
          <p>Skill: {showTopData(userSkill.subCategory)}</p>
          <div className={style.upper}>
            <div className={style.upperleft}>
              <p>Description:</p>
            </div>
            <div className={style.upperright}>
              <p>{showTopData(userSkill.description)}</p>
            </div>
          </div>

          <div className={style.lower}>
            <div className={style.lowerleft}>
              <p>Media:</p>
            </div>
            <div className={style.lowerright}>
              {userSkill.mediaList &&
                userSkill.mediaList.length > 0 &&
                userSkill.mediaList.map((item, index3) => (
                  <div className={style.item} key={index3}>
                    {item.fileType === "image" && (
                      <img
                        src={item.fileUri}
                        alt="medias"
                        style={{
                          width: "210px",
                          height: "200px",
                          margin: "3px",
                        }}
                      />
                    )}
                    {item.fileType === "video" && (
                      // <iframe
                      //   src={item.fileUri}
                      //   height="200px"
                      //   width="210px"
                      //   alt="medias"
                      // ></iframe>
                      <video
                        width="210px"
                        height="200px"
                        controls
                        preload="metadata"
                      >
                        <source
                          src={`${item.fileUri}#t=0.5`}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      ));
    }
  }

  function showImgData(data) {
    if (
      props.profileData !== undefined &&
      props.profileData !== null &&
      data !== null
    ) {
      return data;
    } else {
      return defaultImage;
    }
  }

  useEffect(() => {
    props.getStudentProfileByProfileId(
      props.history.location.state.studentProfileId
    );
  }, [showRejectModal]);

  const acceptButton = () => {
    if (props.history.location.state.requestAccepted) {
      return (
        <button
          // style={{ marginTop: "-2vh", marginLeft: "58vw" }}
          className={style.accepted}
        >
          Accepted
        </button>
      );
    } else {
      return (
        <div className={style.abutton}>
          <button
            children="Accept"
            className={child == "Accept" ? style.accept : style.accepted}
            onClick={() => {
              props.acceptedRejectedAppliedOpportunity(
                true,
                status,
                props.history.location.state.OpportunityId,
                requestDate,
                props.profileData.userAccountId
              );
              history.push("/");
              setchild("Accepted");
            }}
          >
            {child}
          </button>

          <button
            // className={style.reject}
            className={child === "Accepted" ? style.rejected : style.reject}
            onClick={() => handleModal(true)}
          >
            Reject
          </button>
        </div>
      );
    }
  };
  const handleClick = (OpportunityId) => {
    history.push({
      pathname: "/AppliedStudents",
      state: {
        OpportunityId: props.history.location.state.OpportunityId,
      },
    });
  };

  const toshowLocation = (data1, data2, data3) => {
    if (data1 === null && data2 !== null) {
      return data2 + "," + data3;
    } else if (data1 === null && data2 === null && data3 === null) {
      return "No Information Provided";
    } else if (data1 === null && data2 === null) {
      return data3;
    } else {
      return data1 + "," + data2 + "," + data3;
    }
  };

  return (
    <div className={style.maincontainer}>
      <div className={style.container}>
        <header className={style.headerStyle}>
          <Header sidebar={setSidebar} side={sidebar} />
        </header>
        <Backdroplog show={props.showBlur} />

        <section className={style.sectionStyle}>
          <div>
            <Sidebar side={sidebar} />
          </div>
          <div className={style.topsomepagewrapper}>
            <div className={style.toprow}>
              <div className={style.topcolumn}>
                <div className={style.backarrow}>
                  <img
                    src={backarrowImg}
                    alt="img"
                    onClick={() =>
                      handleClick(props.history.location.state.OpportunityId)
                    }
                  />
                  <p className={style.para}>Profile</p>
                </div>

                {localStorage.getItem("userRole") === "RECRUITER"
                  ? acceptButton()
                  : null}
              </div>
            </div>
            <div className={style.toprow}>
              <div className={style.centered}>
                <div className={style.borderbox}>
                  <div className={style.row1}>
                    <div className={style.row1box}>
                      <div className={style.left}>
                        <img
                          src={showImgData(props?.profileData?.profileUrl)}
                          alt="img"
                        />
                      </div>
                      <div className={style.middle}>
                        <span style={{ fontSize: "15pt" }}>
                          {showTopData(props?.profileData?.firstName)}{" "}
                          {showTopData(props?.profileData?.lastName)}
                        </span>
                        <div className={style.table1}>
                          <div>
                            <div className={style.display}>
                              <div className={style.category}>
                                Date Of Birth
                              </div>
                              <div className={style.value}>
                                {showTopData(
                                  props?.profileData?.dateOfBirth
                                    ? moment(
                                        props?.profileData?.dateOfBirth
                                      ).format("MM/DD/YYYY")
                                    : "No Information Provided"
                                )}
                              </div>
                            </div>
                            <div className={style.display}>
                              <div className={style.category}>Category</div>
                              <div className={style.value}>
                                {props.skillDatas && props.skillDatas.length > 0
                                  ? props.skillDatas
                                      .map(function (elem) {
                                        return elem.mainCategory;
                                      })
                                      .join(", ")
                                  : "No Information Provided"}
                              </div>
                            </div>

                            <div className={style.display}>
                              <div className={style.category}>Email ID</div>
                              <div className={style.value}>
                                {showTopData(props?.profileData?.email)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={style.right}>
                        <div className={style.right_table1}>
                          <div>
                            <div className={style.display}>
                              <div className={style.category}>Gender</div>
                              <div className={style.value}>
                                {showTopData(props?.profileData?.gender)}
                              </div>
                            </div>
                            <div className={style.display}>
                              <div className={style.category}>Skills</div>
                              <div className={style.value}>
                                {props.skillDatas &&
                                props?.skillDatas?.length > 0
                                  ? props.skillDatas
                                      .map(function (elem) {
                                        return elem.subCategory;
                                      })
                                      .join(", ")
                                  : "No Information Provided"}
                              </div>
                            </div>
                            <div className={style.display}>
                              <div className={style.category}>Location</div>
                              <div className={style.value}>
                                {toshowLocation(
                                  props?.profileData?.city,
                                  props?.profileData?.state,
                                  props?.profileData?.country
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={style.row2}>
                    <div className={style.row2left}>
                      <p>I Am</p>
                    </div>
                    <div className={style.row2right}>
                      {showData(props?.profileData?.about)}
                    </div>
                  </div>
                  <div className={style.row2}>
                    <div className={style.row2left}>
                      <p>Achievements</p>
                    </div>
                    <div className={style.row2right}>
                      {showData(props?.profileData?.candidateAchievement)}
                    </div>
                  </div>
                  {showskilldatas()}
                </div>
              </div>
            </div>
          </div>
        </section>

        {props.showRejectModal && (
          <div className={style.changeDiv}>
            <RejectModal
              OpportunityId={props.history.location.state.OpportunityId}
              userAccountId={props.profileData.userAccountId}
              setStatus={setStatus}
              profileData={props?.orgOpportunityData}
              Name={showRejectModal}
              setshowRejectModal={setshowRejectModal}
              handleModal={handleModal}
              submitReject={submitReject}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.candidateReducer.loader,
    profileData: state.recruiterReducer.candidateProfileData,
    studentProfilebyProfileIdErrorMsg:
      state.recruiterReducer.studentProfilebyProfileIdErrorMsg,
    studentProfilebyProfileIdErrorCode:
      state.recruiterReducer.studentProfilebyProfileIdErrorCode,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.recruiterReducer.showBlur,
    showRejectModal: state.recruiterReducer.showRejectModal,
    orgOpportunityData: state.orgReducer.orgOpportunityData,
    skillDatas: state.recruiterReducer.skillDatas,
    studentProfilebyProfileIdErrMsg:
      state.recruiterReducer.studentProfilebyProfileIdErrMsg,
    studentProfilebyProfileIdStatus:
      state.recruiterReducer.studentProfilebyProfileIdStatus,

    hideModal: state.authReducer.hideauthModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    acceptedRejectedAppliedOpportunity: (
      status,
      comment,
      opportunityId,
      requestDate,
      studentProfileId
    ) =>
      dispatch(
        actionTypes.acceptedRejectedAppliedOpportunity(
          status,
          comment,
          opportunityId,
          requestDate,
          studentProfileId
        )
      ),
    getStudentProfileByProfileId: (studentProfileId) =>
      dispatch(actionTypes.getStudentProfileByProfileId(studentProfileId)),
    handleRejectModal: (data) => dispatch(actionTypes.handleRejectModal(data)),
    hideModal: () => dispatch(actionTypes.hideAuthModal()),
    getOpportunityDetails: (id) =>
      dispatch(actionTypes.getOpportunityDetails(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SProfile);
