import React from "react";
import style from "./cards.module.css";
import moment from "moment";
import * as actionTypes from "../../../Store/Action/index";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import defaultImg from "../../../assets/defaultimg/Rectangle 5337@2x.png";

function Cards(props) {
  const history = useHistory();

  const initChat = (user) => {
    props.getRealtimeConversations({ uid_1: user.userId, uid_2: props.userId });
    history.push({
      pathname: "/recruiter/Chat",
      state: {
        userid: user.userId,
        senderid: props.userId,
        chatUser: user.userName,
        uimg: user.profileUrl,
      },
    });
  };

  return (
    <>
      {props.data && props.data.length > 0
        ? props.data.map((item, index) => (
            <div className={style.container} onClick={() => initChat(item)}>
              <div className={style.card}>
                <div className={style.left}>
                  <img
                    src={
                      item.profileUrl && item.profileUrl !== null
                        ? item.profileUrl
                        : defaultImg
                    }
                    alt="img"
                  />
                  <p className={style.orgname}>{item.userName}</p>
                  {/* <p className={style.orgname}>{item.orgName}</p> */}
                </div>

                <div className={style.right}></div>
              </div>
            </div>
          ))
        : null}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    conversations: state.fReducer.conversations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRealtimeConversations: (user) =>
      dispatch(actionTypes.getRealtimeConversations(user)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Cards);
