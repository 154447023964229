import React, { Component, useState, useEffect, useCallback } from "react";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router";
import ResponseAlert from "../../../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import { connect, useDispatch, useSelector } from "react-redux";
import style from "./SignupOTPScreen.module.css";
import * as actionTypes from "../../../../Store/Action/index";
import closeCircle from "../../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import SignupImg from "../../../../assets/Basketball.jpg";
import logo from "../../../../assets/logo/rev13-03.png";

function OTPScreen(props) {
  const [otp, setOtp] = useState();
  const [otpErrorMsg, setOtpErrorMsg] = useState(" ");
  const [resentConfirm, setResentConfirm] = useState(false);

  const [minute, setMinute] = useState(3);
  const [second, setSecond] = useState(0);
  const [intev, setSetintev] = useState("");
  const [disable, setDisable] = React.useState(false);
  const start = () => {
    run();
    setSetintev(setInterval(run, 1000));
  };
  var updatedS = second,
    updatedM = minute;
  const run = () => {
    if (updatedM > 0 && updatedS === 0) {
      updatedS = 60;
      updatedM--;
      setMinute(updatedM);
    }
    if (updatedS > 0) {
      updatedS--;
      setSecond(updatedS);
    }
  };

  const resendOtp = () => {
    setMinute(3);
    setResentConfirm(true);
    props.ResendOtpForEmailVerification(props.SsignupEmail);
  };

  useEffect(() => {
    start();
  }, [resentConfirm]);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const verifyOtp = () => {
    if (otp.length === 6) {
      props.ValidateOtpEmailVerification(
        otp,
        props.SsignupEmail,
        props.SsignupPassword
      );
      props.hideModal();
    } else {
      setOtpErrorMsg("All fields are required.");
    }
  };

  return (
    <div className={style.container}>
      <div className={style.leftContainer}>
        <div className={style.leftLogo}>
          <img className={style.logo} src={logo} alt="logo" />
        </div>

        <div className={style.leftPara}>
          <p>
            I Got Game 2 is the #1 portal for connecting students, athletes and
            talent to available scholarship opportunities with universities and
            colleges worldwide.
          </p>
        </div>
        <div className={style.leftImg}>
          <img src={SignupImg} alt="img" />
        </div>
      </div>
      <div className={style.rightContainer}>
        <img
          src={closeCircle}
          className={style.closeModal}
          alt="img"
          onClick={() => props.hideModal()}
        />

        <div className={style.flex}>
          <div>
            <h1 className={style.heading}> One Time Password Verify</h1>
          </div>
          <div>
            <p className={style.para}> Enter the 6 digit code below.</p>
          </div>
          <div className={style.text}>
            <OtpInput
              value={otp}
              onChange={(otp) => handleChange(otp)}
              numInputs={6}
              isInputNum={true}
              separator={<span>-</span>}
            />
          </div>
          <span className={style.errorMsg}>{otpErrorMsg}</span>
          <div>
            <button className={style.button1} onClick={verifyOtp}>
              verifySign
            </button>
          </div>
          <div className={style.para}>
            Resend One Time Password in{" "}
            <span>{minute >= 10 ? minute : "0" + minute}</span>:
            <span>{second >= 10 ? second : "0" + second} </span>
            {/* <span>{minute}</span><span>:</span> <span>{second}</span> */}
          </div>
          <div>
            <button
              className={style.button2}
              disabled={minute !== 0 || second !== 0}
              onClick={resendOtp}
            >
              Resend One Time Password
            </button>
          </div>
        </div>
        {props.showAlert && (
          <div className={style.changeDiv}>
            {
              <ResponseAlert
                redirect={"/"}
                // message={"Your password has been successfully changed. Sign In now!"}
                message={
                  // props.emailverificationotpStatus ?
                  props.emailverifySuccMsg
                  //  "Your email has been verified.Please Login your account!"
                  //  : props.errorMsg
                }
                // type="CANDIDATE"
              />
            }
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    showAlert: state.authReducer.showAlert,
    errorMsg: state.authReducer.errorMsg,
    emailverifySuccMsg: state.authReducer.emailverifySuccMsg,
    emailverificationotpErrorMsg:
      state.authReducer.emailverificationotpErrorMsg,
    emailverificationotpStatus: state.authReducer.emailverificationotpStatus,
    loader: state.authReducer.loader,
    initial: state.authReducer.initial,
    ErrorCode: state.authReducer.ErrorCode,
    showSignupOTPModal: state.authReducer.showSignupOTPModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => dispatch(actionTypes.hideAuthModal()),
    handleSignupOTPModal: () => dispatch(actionTypes.handleSignupOTPModal()),
    ValidateOtpEmailVerification: (otp, email, password) =>
      dispatch(actionTypes.ValidateOtpEmailVerification(otp, email, password)),
    ResendOtpForEmailVerification: (email) =>
      dispatch(actionTypes.ResendOtpForEmailVerification(email)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OTPScreen);
