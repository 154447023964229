import React, { useEffect } from "react";
import style from "./profileUpdate.module.css";
import { BlueButton } from "../../Buttons/button";
import { useHistory } from "react-router-dom";

import * as actionTypes from "../../../Store/Action/index";

function ProfileUpdate(props) {
  const history = useHistory();

  const submit = () => {
    history.push("/Recruiter/Profile");
  };

  return (
    <>
      <div className={style.container}>
        <p>I Got Game 2</p>
        <p style={{ fontSize: "16px" }}>Please Update Your Profile.</p>
        <BlueButton children="Go To Profile" onClick={submit} />
      </div>
    </>
  );
}

export default ProfileUpdate;
