import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as actionTypes from "../../Store/Action/index";
import { connect } from "react-redux";
import style from "./SViewProfile.module.css";
import SentRequest from "../../GenericComponent/Modal/SendRequest/sendrequest";
import Header from "../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../GenericComponent/Sidebar/sidebar";
import defaultImage from "../../assets/defaultimg/Rectangle 5337@2x.png";
import ResponseAlert from "../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import moment from "moment";
import Backdroplog from "../../GenericComponent/Backdrop/Backdrop";
import backarrowImg from "../../assets/back arrow/Icon ionic-ios-arrow-round-back.png";

function SProfile(props) {
  const [sidebar, setSidebar] = useState(false);
  const [showResponseAlert, setshowResponseAlert] = useState(false);
  const [showsentRequestModal, setshowsentRequestModal] = useState(false);
  const [title, setTitle] = useState();
  const [child, setchild] = useState("Send Request");
  const [showAlert, setshowAlert] = useState(false);
  const [oppId, setOppId] = useState(
    props?.history?.location?.state?.opportunityId || ""
  );
  const [sId, setId] = useState(
    props?.history?.location?.state?.studentProfileId
  );

  const history = useHistory();
  const studentId = localStorage.getItem("studentId");
  function showData(data) {
    if (
      props.profileData !== undefined &&
      props.profileData !== null &&
      data !== null &&
      data !== ""
    ) {
      return <p>{data}</p>;
    } else {
      return <p style={{ opacity: "0.5" }}>No Information Provided.</p>;
    }
  }

  const cardData = async (val1, val2, val3) => {
    await props.sentRequest(val1, val2, val3);
    props.handleSentRequestModal(false);
    setshowsentRequestModal(false);
    props.handleshowAlert(true);
    setshowAlert(true);
    props.getStudentProfileByProfile(studentId);
  };
  const toShowblur = (val) => {
    props.handleshowBlur(val);
  };
  const handleModal = (val) => {
    props.handleSentRequestModal(val);
  };

  function showTopData(data) {
    if (
      props.profileData !== undefined &&
      props.profileData !== null &&
      data !== null &&
      data !== ""
    ) {
      return data;
    } else {
      return "No Information provided";
    }
  }

  function showskilldatas() {
    if (
      props.skillDatas !== undefined &&
      props.skillDatas !== null &&
      props.skillDatas.length > 0
    ) {
      const newarray = props.skillDatas;
      return newarray.map((userSkill, index) => (
        <div className={style.row3} key={index}>
          <p>Skill: {showTopData(userSkill.subCategory)}</p>
          <div className={style.upper}>
            <div className={style.upperleft}>
              <p>Description:</p>
            </div>
            <div className={style.upperright}>
              <p>{showTopData(userSkill.description)}</p>
            </div>
          </div>

          <div className={style.lower}>
            <div className={style.lowerleft}>
              <p>Media:</p>
            </div>
            <div className={style.lowerright}>
              {userSkill.mediaList &&
                userSkill.mediaList.length > 0 &&
                userSkill.mediaList.map((item, index3) => (
                  <div className={style.item} key={index3}>
                    {item.fileType === "image" && (
                      <img
                        src={item.fileUri}
                        alt="medias"
                        style={{
                          width: "210px",
                          height: "200px",
                          margin: "3px",
                        }}
                      />
                    )}
                    {item.fileType === "video" && (
                      // <iframe
                      //   src={item.fileUri}
                      //   height="200px"
                      //   width="210px"
                      //   alt="medias"
                      // ></iframe>
                      <video
                        width="210px"
                        height="200px"
                        controls
                        preload="metadata"
                      >
                        <source
                          src={`${item.fileUri}#t=0.5`}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      ));
    }
  }

  function showImgData(data) {
    if (
      props.profileData !== undefined &&
      props.profileData !== null &&
      data !== null
    ) {
      return data;
    } else {
      return defaultImage;
    }
  }

  const handleRedirect = (path) => {
    history.push(path);
  };

  const getProfile = () => {
    if (history.location.state) {
      localStorage.setItem(
        "studentId",
        history.location.state.studentProfileId
      );
    }
    props.getStudentProfileByProfile(studentId);
  };

  useEffect(() => {
    getProfile();
  }, [studentId]);

  const sentRequestbutton = () => {
    if (props?.history?.location?.state?.sentRequest) {
      return (
        <button
          style={{ marginTop: "-2vh", marginLeft: "58vw" }}
          className={style.accepted}
        >
          Request Sent
        </button>
      );
    } else {
      return (
        <div style={{ margin: "3% 0% 1% -5%" }}>
          <button
            children="Send Request"
            className={child == "Send Request" ? style.button : style.accepted}
            onClick={() => handleModal(true)}
          >
            {child}
          </button>
        </div>
      );
    }
  };

  const toshowLocation = (data1, data2, data3) => {
    if (data1 === null && data2 !== null) {
      return data2 + "," + data3;
    } else if (data1 === null && data2 === null) {
      return data3;
    } else if (data1 === null && data2 === null && data3 === null) {
      return "No Information Provided";
    } else {
      return data1 + "," + data2 + "," + data3;
    }
  };

  return (
    <div className={style.maincontainer}>
      <div className={style.container}>
        <header className={style.headerStyle}>
          <Header sidebar={setSidebar} side={sidebar} />
        </header>
        <Backdroplog show={props.showBlur} />

        <section className={style.sectionStyle}>
          <div>
            <Sidebar side={sidebar} />
          </div>
          <div className={style.topsomepagewrapper}>
            <div className={style.toprow}>
              <div className={style.topcolumn}>
                <div>
                  <p className={style.para}>Profile</p>
                </div>
                <div className={style.backarrow}>
                  <img
                    src={backarrowImg}
                    alt="img"
                    onClick={() => handleRedirect("/Recruiter/R_SearchList")}
                    style={{ cursor: "pointer", width: "80%" }}
                  ></img>
                </div>
              </div>
              <div className={style.topcolumn1}>
                <div style={{ margin: "-3% 0% 1% 0%" }}>
                  {sentRequestbutton()}
                </div>
              </div>
            </div>
            <div className={style.toprow}>
              <div className={style.centered}>
                <div className={style.borderbox}>
                  <div className={style.row1}>
                    <div className={style.row1box}>
                      <div className={style.left}>
                        <img
                          src={showImgData(props?.profileData?.profileUrl)}
                          alt="img"
                        />
                      </div>
                      <div className={style.middle}>
                        <span style={{ fontSize: "15pt" }}>
                          {showTopData(props?.profileData?.firstName)}{" "}
                          {showTopData(props?.profileData?.lastName)}
                        </span>
                        <table className={style.table1}>
                          <tbody>
                            <div className={style.display}>
                              <div className={style.category}>
                                Date Of Birth
                              </div>
                              <div className={style.value}>
                                {props?.profileData?.dateOfBirth
                                  ? showTopData(
                                      moment(
                                        props?.profileData?.dateOfBirth
                                      ).format("MM/DD/YYYY")
                                    )
                                  : "No Infromaion Provided"}
                              </div>
                            </div>
                            <div className={style.display}>
                              <div className={style.category}>Category</div>
                              <div className={style.value}>
                                {props.skillDatas && props.skillDatas.length > 0
                                  ? props.skillDatas
                                      .map(function (elem) {
                                        return elem.mainCategory;
                                      })
                                      .join(", ")
                                  : "No Information Provided"}
                              </div>
                            </div>

                            <div className={style.display}>
                              <div className={style.category}>Email ID</div>
                              <div className={style.value}>
                                {showTopData(props?.profileData?.email)}
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </div>
                      <div className={style.right}>
                        <table className={style.right_table1}>
                          <tbody>
                            <div className={style.display}>
                              <div className={style.category}>Gender</div>
                              <div className={style.value}>
                                {showTopData(props?.profileData?.gender)}
                              </div>
                            </div>
                            <div className={style.display}>
                              <div className={style.category}>Skills</div>
                              <div className={style.value}>
                                {props.skillDatas && props.skillDatas.length > 0
                                  ? props.skillDatas
                                      .map(function (elem) {
                                        return elem.subCategory;
                                      })
                                      .join(", ")
                                  : "No Information Provided"}
                              </div>
                            </div>
                            <div className={style.display}>
                              <div className={style.category}>Location</div>
                              <div className={style.value}>
                                {toshowLocation(
                                  props?.profileData?.city,
                                  props?.profileData?.state,
                                  props?.profileData?.country
                                )}
                              </div>
                            </div>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className={style.row2}>
                    <div className={style.row2left}>
                      <p>I Am</p>
                    </div>
                    <div className={style.row2right}>
                      {showData(props?.profileData?.about)}
                    </div>
                  </div>
                  <div className={style.row2}>
                    <div className={style.row2left}>
                      <p>Achievements</p>
                    </div>
                    <div className={style.row2right}>
                      {showData(props?.profileData?.candidateAchievement)}
                    </div>
                  </div>
                  {showskilldatas()}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {props.showsentRequestModal && (
        <div className={style.changeDiv}>
          <SentRequest
            handleModal={handleModal}
            oppId={oppId}
            studentProfileId={studentId}
            setshowsentRequestModal={setshowsentRequestModal}
            setTitle={setTitle}
            setshowAlert={setshowAlert}
            cardData={cardData}
            setshowResponseAlert={setshowResponseAlert}
            toShowblur={toShowblur}
            getSearch={getProfile}
          />
        </div>
      )}

      {props.showAlert && (
        <div className={style.changeDiv1}>
          <ResponseAlert
            message={
              props.searchstudentErrorMsg
                ? "Please Login Again"
                : props.sentRequestErrorMsg
                ? "Please Login Again"
                : props.sentRequestErrorCode
                ? "This opportunity has already applied by student"
                : "Your Request has been sent successfully !"
            }
            type={"RECRUITER"}
            redirect={
              props.searchstudentErrorMsg ? "/" : "/Recruiter/StudentProfile"
            }
            sentRequestErrorCode={props.sentRequestErrorCode}
            handleModal={handleModal}
            getProfile={getProfile}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.authReducer.loginStatus,
    loader: state.candidateReducer.loader,
    profileData: state.recruiterReducer.candidateProfileData,
    sentRequestErrorCode: state.recruiterReducer.sentRequestErrorCode,
    sentRequestErrorMsg: state.recruiterReducer.sentRequestErrorMsg,
    studentProfilebyProfileIdErrorMsg:
      state.recruiterReducer.studentProfilebyProfileIdErrorMsg,
    studentProfilebyProfileIdErrorCode:
      state.recruiterReducer.studentProfilebyProfileIdErrorCode,
    showAlert: state.recruiterReducer.showAlert,
    showBlur: state.recruiterReducer.showBlur,
    showRejectModal: state.recruiterReducer.showRejectModal,
    opp_rec_list: state.recruiterReducer.opp_rec_list,
    skillDatas: state.recruiterReducer.skillDatas,
    studentProfilebyProfileIdErrMsg:
      state.recruiterReducer.studentProfilebyProfileIdErrMsg,
    studentProfilebyProfileIdStatus:
      state.recruiterReducer.studentProfilebyProfileIdStatus,
    showsentRequestModal: state.recruiterReducer.showsentRequestModal,
    hideModal: state.authReducer.hideauthModal,
    sentRequestStatus: state.recruiterReducer.sentRequestStatus,
    studentId: state.recruiterReducer.studentId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sentRequest: (opportunityId, timeRequest, studentProfileId) =>
      dispatch(
        actionTypes.sentRequest(opportunityId, timeRequest, studentProfileId)
      ),
    handleshowAlert: (val) => dispatch(actionTypes.handleshowAlert(val)),
    handleshowBlur: (val) => dispatch(actionTypes.handleshowBlur(val)),

    getStudentProfileByProfile: (studentProfileId) =>
      dispatch(actionTypes.getStudentProfileByProfile(studentProfileId)),

    hideModal: () => dispatch(actionTypes.hideAuthModal()),

    handleSentRequestModal: (data) =>
      dispatch(actionTypes.handleSentRequestModal(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SProfile);
