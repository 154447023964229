import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import * as actionTypes from "../../../../Store/Action/index";

import style from "./signup.module.css";

import SignupImg from "../../../../assets/globe.jpg";
import logo from "../../../../assets/logo/rev13-03.png";
import mailIcon from "../../../../assets/email/Icon material-email.png";
import openEye from "../../../../assets/eyeIcon/Icon awesome eye.png";
import closeEye from "../../../../assets/eyeIcon/Icon ionic-ios-eye-off.png";
import orgIcon from "../../../../assets/Input icons/Icon ionic-ios-business@3x.png";
import closeIcon from "../../../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";

function SignUp(props) {
  const [showLeft, setShowLeft] = useState(true);
  const [showRight, setShowRight] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [fnameErrorMsg, setFnameErrorMsg] = useState("");
  const [lnameErrorMsg, setLnameErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passErrorMsg, setPassErrorMsg] = useState("");
  const [orgErrorMsg, setOrgErrorMsg] = useState("");

  const openCity = (cityName) => {
    setFnameErrorMsg("");
    setLnameErrorMsg("");
    setEmailErrorMsg("");
    setPassErrorMsg("");
    setOrgErrorMsg("");

    if (cityName === "Left") {
      setShowLeft(true);
      setShowRight(false);
    } else {
      setShowLeft(false);
      setShowRight(true);
    }
  };

  const handleSignUp1 = (e) => {
    var regName = /^[a-zA-Z]+$/;

    e.preventDefault();

    if (!regName.test(e.target.firstname.value)) {
      setFnameErrorMsg("First name only contains letters.");
    } else if (e.target.firstname.value === "") {
      setFnameErrorMsg("Enter your First name.");
    } else if (!regName.test(e.target.lastname.value)) {
      setLnameErrorMsg("Last name only contains letters.");
    } else if (e.target.lastname.value === "") {
      setLnameErrorMsg("Enter your last name.");
    } else if (e.target.email.value === "") {
      setEmailErrorMsg("Enter your Email ID");
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        e.target.email.value.trim()
      )
    ) {
      setEmailErrorMsg("Please enter a valid Email ID");
    } else if (e.target.password.value === "") {
      setPassErrorMsg("Enter your Password");
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        e.target.password.value
      )
    ) {
      setPassErrorMsg(
        "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
      );
    } else {
      const form1Data = {
        email: e.target.email.value.trim(),
        firstName: e.target.firstname.value,
        lastName: e.target.lastname.value,
        password: e.target.password.value,
      };

      props.setSSignupEmail(e.target.email.value.trim());
      props.setSSignupPassword(e.target.password.value);
      props.studentSignUp(form1Data);
    }
  };

  const handleSignUp2 = (e) => {
    e.preventDefault();
    if (e.target.org.value === "") {
      setOrgErrorMsg("Enter Organization name");
    } else if (e.target.email1.value === "") {
      setEmailErrorMsg("Enter your Email ID");
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        e.target.email1.value.trim()
      )
    ) {
      setEmailErrorMsg("Please enter a valid Email ID");
    } else if (e.target.password2.value === "") {
      setPassErrorMsg("Enter your Password");
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        e.target.password2.value
      )
    ) {
      setPassErrorMsg(
        "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
      );
    } else {
      const form2Data = {
        email: e.target.email1.value,
        organisationName: e.target.org.value,
        password: e.target.password2.value,
      };
      props.orgSignUp(form2Data);
      props.hideModal();
      props.setSSignupEmail(e.target.email1.value);
      props.setSSignupPassword(e.target.password2.value);
    }
  };

  const passwordFormatChangedHandler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  useEffect(() => {}, [passwordType]);

  return (
    <div className={style.container}>
      <div className={style.leftContainer}>
        <div className={style.leftLogo}>
          <a href="#">
            <img className={style.logo} src={logo} alt="logo" />
          </a>
        </div>
        <div className={style.leftPara}>
          {" "}
          <p>
            I Got Game 2 is the #1 portal for connecting students, athletes and
            talent to available scholarship opportunities with universities and
            colleges worldwide.
          </p>
        </div>
        <div className={style.leftImg}>
          <img src={SignupImg} alt="img" />
        </div>
      </div>
      <div className={style.rightContainer}>
        <img
          src={closeIcon}
          alt="img"
          className={style.closeModal}
          onClick={() => props.hideModal()}
        />

        <p className={style.title}>Sign Up</p>
        <p className={style.subTitle}>Already have an account? </p>
        <a onClick={() => props.SignIn()} className={style.anchorStyle}>
          Sign In
        </a>
        <p />
        <div className={style.tab}>
          <label
            className={
              showLeft ? (style.tabLinks, style.activeTab) : style.tabLinks
            }
            onClick={() => openCity("Left")}
          >
            As a student/talent
          </label>
          <label
            style={{ margin: "3%" }}
            className={
              showRight === true
                ? (style.tabLinks, style.activeTab)
                : style.tabLinks
            }
            onClick={() => openCity("Right")}
          >
            As an Organization
          </label>
          <hr className={style.lineStyle} />
        </div>

        {showLeft && (
          <div className={style.formStyle}>
            <form className={style.formStyle} onSubmit={handleSignUp1}>
              <div className={style.emailName}>
                <label className={style.mail2}>First Name</label>
                <input
                  type="text"
                  name="firstname"
                  autoComplete="off"
                  onChange={() => setFnameErrorMsg("")}
                />
                <span className={style.errorMsg}>{fnameErrorMsg}</span>
              </div>
              <div className={style.emailName}>
                <label className={style.mail2}>Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  autoComplete="off"
                  onChange={() => setLnameErrorMsg("")}
                />
                <span className={style.errorMsg}>{lnameErrorMsg}</span>
              </div>
              <div className={style.emailName}>
                <label className={style.mail2}>Email Id</label>
                <input
                  type="text"
                  name="email"
                  autoComplete="off"
                  onChange={() => setEmailErrorMsg("")}
                />
                <img className={style.icon2} src={mailIcon} alt="email" />
                <span className={style.errorMsg}>{emailErrorMsg}</span>
              </div>
              <div className={style.pass}>
                <label className={style.mail2}>Password</label>
                <input
                  type={passwordType}
                  name="password"
                  autoComplete="off"
                  onChange={() => setPassErrorMsg("")}
                />
                <img
                  className={
                    passwordType === "password" ? style.icon3 : style.icon33
                  }
                  src={passwordType === "password" ? closeEye : openEye}
                  alt="eye"
                  onClick={() => passwordFormatChangedHandler()}
                />
                <span className={style.perrorMsg}>{passErrorMsg}</span>
              </div>
              <div>
                <button className={style.signin1} type="submit">
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        )}
        {showRight && (
          <div className={style.formStyle}>
            <form
              className={style.formStyle}
              onSubmit={handleSignUp2}
              autoComplete="off"
            >
              <div className={style.orgname2}>
                <label className={style.mail2}>Organization Name</label>
                <input
                  type="text"
                  id="org"
                  name="org"
                  autoComplete="off"
                  onChange={() => setOrgErrorMsg("")}
                />
                <img className={style.iconOrg} src={orgIcon} alt="org" />
                <span className={style.errorMsg}>{orgErrorMsg}</span>
              </div>
              <div className={style.emailName2}>
                <label className={style.mail2}>Email Id</label>
                <input
                  type="text"
                  id="email1"
                  name="email1"
                  autoComplete="off"
                  onChange={() => setEmailErrorMsg("")}
                />
                <img className={style.icon2} src={mailIcon} alt="email" />
                <span className={style.errorMsg}>{emailErrorMsg}</span>
              </div>
              <div className={style.pass2}>
                <label className={style.mail2}>Password</label>
                <input
                  type={passwordType}
                  id="pass1"
                  name="password2"
                  autoComplete="off"
                  onChange={() => setPassErrorMsg("")}
                />
                <img
                  alt="img"
                  className={
                    passwordType === "password" ? style.icon3 : style.icon33
                  }
                  src={passwordType === "password" ? closeEye : openEye}
                  alt="pass"
                  onClick={() => passwordFormatChangedHandler()}
                />
                <span className={style.perrorMsg}>{passErrorMsg}</span>
              </div>
              <div className={style.allBtn2}>
                <button className={style.signin2} type="submit">
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    signUpStatus: state.authReducer.signUpStatus,
    errorMsg: state.authReducer.errorMsg,
    loader: state.authReducer.loader,
    initial: state.authReducer.initial,
    ErrorCode: state.authReducer.ErrorCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    studentSignUp: (formData) =>
      dispatch(actionTypes.getStudentSignUp(formData)),
    orgSignUp: (formData) => dispatch(actionTypes.getOrgSignUp(formData)),
    hideModal: () => dispatch(actionTypes.hideAuthModal()),
    SignIn: () => dispatch(actionTypes.handleSignInModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
