import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function ProtectedRoute({ component: Component, ...rest }) {
  const userToken = localStorage.getItem("userToken");
  return (
    <Route
      render={(props) => userToken ? (<Component {...rest} {...props} />) : (
        <Redirect to="/" />
      )}

    />
  );
}
