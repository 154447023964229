import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Action/index";
import style from "./CreateOpportunity.module.css";
import Header from "../../../GenericComponent/OrgHeader/orgHeader";
import Sidebar from "../../../GenericComponent/Sidebar/sidebar";
import Uploadimg from "../../../assets/regicons/upload.svg";
import ResponseAlert from "../../../GenericComponent/Modal/ResponseAlert/ResponseAlert";
import Backdroplog from "../../../GenericComponent/Backdrop/Backdrop";
import defaultImg from "../../../assets/defaultimg/Rectangle 5337@2x.png";
import Loader from "../../../assets/Loader/spinner.gif";
import {
  WhiteButton,
  BlueButton,
} from "../../../GenericComponent/Buttons/button";

function CreateOpportunity(props) {
  const inputFile = useRef(null);
  const titleRef = useRef();
  const aboutRef = useRef();
  const catRef = useRef();
  const skillRef = useRef();

  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  const [profileImage, setProfileImage] = useState({});
  const [previewImage, setPreviewImage] = useState("");
  const [location, setLocation] = useState("");
  const [about, setAbout] = useState("");
  const [ourWins, setOurWins] = useState("");
  const [title, setTitle] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [skillID, setSkillID] = useState("");
  const [isRemoveIcon, setIsRemoveIcon] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const handleSubmitData = (e) => {
    e.preventDefault();
    if (title === null || title === "") {
      setErrorMsg({ titleErr: "*Title is required." });
      titleRef.current.focus();
    } else if (categoryID === null || categoryID === "") {
      setErrorMsg({ categoryErr: "*Category is required." });
      catRef.current.focus();
    } else if (skillID === null || skillID === "") {
      setErrorMsg({ skillErr: "*Skill is required." });
      skillRef.current.focus();
    } else if (about === null || about === "") {
      setErrorMsg({ aboutErr: "*About is required." });
      aboutRef.current.focus();
    } else {
      if (errorMsg === null) {
        let payload = {
          about: about.trim(),
          addressLine1: location.trim(),
          city: "",
          country: "",
          mainSkillId: categoryID,
          ourWins: ourWins.trim(),
          pinCode: "",
          state: "",
          subSkillId: skillID,
          title: title.trim(),
        };
        let formData = new FormData();
        formData.append(
          "opportunityImage",
          profileImage !== null ? profileImage : ""
        );
        formData.append("jsonRequest", JSON.stringify(payload));
        props.createRecruiterOpportunity(formData, profileImage);
      }
    }
  };
  const imageHandler = (e) => {
    if (e.target.files.length > 0) {
      const myfile = e.target.files[0].name;
      var ext = myfile.split(".").pop().toLowerCase();
      if (ext === "png" || ext === "jpg" || ext === "svg" || ext === "jpeg") {
        setProfileImage(e.target.files[0]);
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
        e.target.value = null;
        setIsRemoveIcon(true);
        setErrorMsg(null);
      } else {
        setErrorMsg({
          ProfileImageErr:
            "*Invalid File!- Allowed Format: .png, .jpg, .jpeg, .svg.",
        });
        setProfileImage("");
        setPreviewImage(defaultImg);
        e.target.value = null;
        setIsRemoveIcon(false);
      }
    }
  };

  function removeImage() {
    setPreviewImage(defaultImg);
    setProfileImage(null);
    setIsRemoveIcon(false);
    setErrorMsg(null);
  }

  const onButtonClick = () => {
    inputFile.current.click();
    setErrorMsg(null);
  };

  const handleCategory = (value) => {
    setErrorMsg(null);
    setCategoryID(value);
    props.getSubCategoryList(value);
  };
  const handleSubCategory = (value) => {
    setErrorMsg(null);
    setSkillID(value);
  };
  const handleClick = () => {
    history.push("/Recruiter/Dashboard");
  };

  function updateData(e) {
    if (e.target.name === "title") {
      setTitle(e.target.value);
    } else {
      setAbout(e.target.value);
    }
    setErrorMsg(null);
  }

  function showProfileImageErr() {
    if (errorMsg !== null && errorMsg.ProfileImageErr) {
      return <span className={style.errorMsg}>{errorMsg.ProfileImageErr}</span>;
    } else {
      return false;
    }
  }

  function handleTextareaContent(e) {
    if (e.target.name === "about") {
      if (about === "" && e.keyCode === 32) {
        e.preventDefault();
      } else {
        setAbout(e.target.value);
      }
    } else {
      if (ourWins === "" && e.keyCode === 32) {
        e.preventDefault();
      } else {
        setOurWins(e.target.value);
      }
    }
  }

  function showTitleErr() {
    if (errorMsg !== null && errorMsg.titleErr) {
      return errorMsg.titleErr;
    } else {
      return "";
    }
  }
  function showCategoryErr() {
    if (errorMsg !== null && errorMsg.categoryErr) {
      return errorMsg.categoryErr;
    } else {
      return "";
    }
  }
  function showSkillErr() {
    if (errorMsg !== null && errorMsg.skillErr) {
      return errorMsg.skillErr;
    } else {
      return "";
    }
  }
  function showAboutErr() {
    if (errorMsg !== null && errorMsg.aboutErr) {
      return errorMsg.aboutErr;
    } else {
      return "";
    }
  }

  useEffect(() => {
    props.getMainCategoryList();
  }, []);

  return (
    <div className={style.container}>
      <header className={style.headerStyle}>
        <Header sidebar={setSidebar} side={sidebar} />
      </header>
      <section className={style.sectionStyle}>
        <div>
          <Sidebar side={sidebar} />
        </div>
        <Backdroplog show={props.showBlur} />
        {props.loader && (
          <div className={style.loader}>
            <img src={Loader} alt="loader" />
          </div>
        )}
        <div className={style.topHeading}>
          <h1 className={style.para}>Create an Opportunity/Scholarship</h1>
        </div>
      </section>

      <div className={style.centered}>
        <div className={style.baseone}>
          <p className={style.heading}>Thumbnail of Opportunity/Scholarship</p>
          <img
            src={previewImage ? previewImage : defaultImg}
            className={style.img}
            alt="img"
          />
          {isRemoveIcon && (
            <i
              style={{
                position: "absolute",
                color: "green",
                fontSize: "1.3rem",
                cursor: "pointer",
                top: "10%",
                left: "31%",
              }}
              className="fa fa-times-circle"
              aria-hidden="true"
              onClick={() => removeImage()}
            ></i>
          )}
        </div>
        <div className={style.basetwo}>
          <input
            type="file"
            name="profile"
            onChange={imageHandler}
            ref={inputFile}
            style={{ display: "none", visibility: "none" }}
            for="img"
          />
          <img
            src={Uploadimg}
            className={style.img1}
            style={{ cursor: "pointer" }}
            id="img"
            alt="img"
            onClick={onButtonClick}
          />
        </div>
        <br />
        {showProfileImageErr()}
        <div className={style.divFirst}>
          <label className={style.label1}> Opportunity/Scholarship Title</label>
          <input
            className={style.input1}
            name="title"
            ref={titleRef}
            onChange={(e) => updateData(e)}
            autoComplete="off"
          ></input>
          <span className={style.errorMsg}>{showTitleErr()}</span>
        </div>
        <hr className={style.line} />
        <form className={style.div2}>
          <div className={style.left}>
            <div className={style.form1}>
              <label className={style.label2}> Category</label>
              <select
                className={style.input2}
                onChange={(e) => handleCategory(e.target.value)}
                ref={catRef}
              >
                <option value="" disabled selected>
                  Select a category
                </option>
                {props.category_list && props.category_list.length > 0
                  ? props.category_list.map((item, index) => (
                      <option value={item.categoryId}>
                        {item.categoryName}
                      </option>
                    ))
                  : null}
              </select>
              <span className={style.errorMsgbelow}>{showCategoryErr()}</span>
              <label className={style.label2}>Location</label>
              <input
                className={style.location}
                onChange={(e) => setLocation(e.target.value)}
                autoComplete="off"
              ></input>
              <label
                className={style.label2}
                style={{
                  marginTop: "8%",
                  color: "#24242E",
                  fontSize: "14px",
                }}
              >
                About Opportunity/Scholarship
              </label>
              <textarea
                className={style.textarea1}
                maxLength="500"
                style={{
                  width: "152%",
                  height: "150%",
                  opacity: "1",
                  padding: "10px",
                }}
                ref={aboutRef}
                placeholder="Provide description, requirements and other key information for the applicant."
                defaultValue={
                  props.recprofileData ? props.recprofileData.about : ""
                }
                name="about"
                onKeyDown={(e) => handleTextareaContent(e)}
                onChange={(e) => updateData(e)}
              ></textarea>
              <span className={style.errorMsgta}>{showAboutErr()}</span>
              <label
                className={style.label2}
                style={{
                  color: "#24242E",
                  fontSize: "14px",
                }}
              >
                Wins
              </label>
              <textarea
                className={style.textarea1}
                maxLength="500"
                style={{
                  width: "152%",
                  height: "150%",
                  opacity: "1",
                  padding: "10px",
                }}
                placeholder="Describe awards, recognition, accomplishments that would attract talent to this opportunity/scholarship."
                defaultValue={
                  props.recprofileData ? props.recprofileData.about : ""
                }
                name="ourWins"
                onKeyDown={(e) => handleTextareaContent(e)}
                onChange={(e) => setOurWins(e.target.value)}
              ></textarea>
              <br />
            </div>
          </div>
          <div className={style.right}>
            <div className={style.form2}>
              <label className={style.label3}> Skill</label>
              <select
                className={style.input3}
                onChange={(e) => handleSubCategory(e.target.value)}
                ref={skillRef}
                disabled={categoryID !== "" ? false : true}
              >
                <option value="" disabled selected>
                  Select a skill
                </option>

                {props.sub_category_list && props.sub_category_list.length > 0
                  ? props.sub_category_list.map((item, index) => (
                      <option
                        value={item.subCategoryId}
                        style={{ cursor: "pointer" }}
                      >
                        {item.subCategoryName}
                      </option>
                    ))
                  : null}
              </select>
              <span className={style.errorMsgbelow}>{showSkillErr()}</span>
            </div>
          </div>
        </form>

        <div className={style.buttonDiv}>
          <div>
            <WhiteButton children="Cancel" onClick={() => handleClick()} />
          </div>
          <div>
            <BlueButton children="Create" onClick={handleSubmitData} />
          </div>
        </div>
        <br />
      </div>

      {props.showAlert && (
        <div className={style.changeDiv}>
          <ResponseAlert
            redirect={
              props.recSuccessStatus ? "/Recruiter/Opportunities" : null
            }
            message={
              props.recSuccessStatus
                ? "Opportunity created successfully!"
                : props.recErrorMsg
            }
            type="RECRUITER"
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.recruiterReducer.loader,
    createErrorMsg: state.recruiterReducer.createErrorMsg,
    createErrorCode: state.recruiterReducer.createErrorCode,
    createRecruiterStatus: state.recruiterReducer.createrecruiterStatus,
    loginStatus: state.authReducer.loginStatus,
    category_list: state.recruiterReducer.category_list,
    sub_category_list: state.recruiterReducer.sub_category_list,
    categoryErrorMsg: state.recruiterReducer.categoryErrorMsg,
    categoryErrorCode: state.recruiterReducer.categoryErrorCode,
    categoryListErrMsg: state.recruiterReducer.categoryListErrMsg,
    categoryListStatus: state.recruiterReducer.categoryListStatus,
    showBlur: state.recruiterReducer.showBlur,
    showAlert: state.recruiterReducer.showAlert,
    recErrorStatus: state.recruiterReducer.recErrorStatus,
    recErrorMsg: state.recruiterReducer.recErrorMsg,
    recSuccessStatus: state.recruiterReducer.recSuccessStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRecruiterOpportunity: (formData, imageData) =>
      dispatch(actionTypes.createRecruiterOpportunity(formData, imageData)),
    getMainCategoryList: () => dispatch(actionTypes.getMainCategoryList()),
    getSubCategoryList: (id) => dispatch(actionTypes.getSubCategoryList(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateOpportunity);
