import { combineReducers } from "redux";
import { authReducer } from "./AuthReducer";
import { candidateReducer } from "./CandidateReducer";
import { orgReducer } from "./OrgReducer";
import { recruiterReducer } from "./RecruiterReducer"

import {fReducer} from "./firebasereducer";
export const rootReducer = combineReducers({
  authReducer,
  candidateReducer,
  orgReducer,
  recruiterReducer,
  fReducer
});
