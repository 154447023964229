import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/index";
import style from "./AdvanceFilter.module.css";
import { BlueButton } from "../Buttons/button";
import Cross from "../../assets/closeCircle/Icon ionic-ios-close-circle-outline.png";
import Calendar from "react-calendar";
import Moment from "moment";
import CalendarImg from "../../assets/svg files/Icon feather_calendar.svg";

function AdvanceFilterc(props) {
  const catRef = useRef();
  const skillRef = useRef();
  const [categoryID, setCategoryID] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showerrormsg, setShowerrormsg] = useState(false);
  const [showerrormsgg, setShowerrormsgg] = useState(false);
  const [skillID, setSkillID] = useState("");
  const [location, setLocation] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [skill1, setskill1] = useState([]);

  const handleCategory = (value) => {
    setErrorMsg(null);
    setCategoryID(value);
    setSkillID("");
    props.getSubCategoryList(value);
  };

  const handleSubCategory = (value) => {
    setErrorMsg(null);
    setSkillID(value);
  };

  const handlelocation = (value) => {
    setErrorMsg(null);
    setLocation(value);
  };

  const handleFrom = (value) => {
    const re = /^[0-9\b]+$/;
    if (value.target.value === "" || re.test(value.target.value)) {
      setFrom(value.target.value);
    }
    setErrorMsg(null);
  };

  const handleTo = (value) => {
    const re = /^[0-9\b]+$/;

    if (value.target.value === "" || re.test(value.target.value)) {
      from && setTo(value.target.value);
    }
    setErrorMsg(null);
  };

  const ApplyFilter = () => {
    if ((from !== "" && to === "") || (from !== null && to === null)) {
      setShowerrormsgg(true);
      setShowerrormsg(false);
    } else if (parseInt(from) > parseInt(to)) {
      setShowerrormsg(true);
      setShowerrormsgg(false);
    } else {
      props.handlefilter(from, to, categoryID, location, skillID);
      props.handleFilterModal(false);
    }
  };

  const resetFilter = () => {
    setSkillID(null);
    setLocation(null);
    setCategoryID(null);
    setFrom(null);
    setTo(null);
    setskill1([]);
    setShowerrormsg(false);
    setShowerrormsgg(false);
  };

  useEffect(() => {
    setskill1(props.sub_category_list);
  }, [props.sub_category_list]);

  return (
    <div className={style.container}>
      <img
        src={Cross}
        alt="img"
        className={style.closeModal}
        onClick={() => props.handleFilterModal(false)}
      />
      <h1 className={style.para}> Advance Filter</h1>
      <div>
        <div className={style.div}>
          <form className={style.form1}>
            <div className={style.first}>
              <label className={style.label}> Category</label>
              <select
                ref={catRef}
                className={style.input}
                name="categoryId"
                onChange={(e) => handleCategory(e.target.value)}
              >
                <option value="">Select a category</option>
                {props.category_list && props.category_list.length > 0
                  ? props.category_list.map((item, index) => (
                      <option value={item.categoryId}>
                        {item.categoryName}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className={style.second}>
              <label className={style.label}> Skill</label>
              <select
                className={style.input}
                ref={skillRef}
                onChange={(e) => handleSubCategory(e.target.value)}
                value={skillID}
              >
                <option value="">Select a skill</option>

                {skill1 && skill1.length > 0
                  ? skill1.map((item, index) => (
                      <option
                        value={item.subCategoryId}
                        style={{ cursor: "pointer" }}
                      >
                        {item.subCategoryName}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className={style.fourth}>
              <label className={style.label}> Location</label>
              <input
                className={style.input}
                onChange={(e) => handlelocation(e.target.value)}
                vlaue={location}
              />
            </div>
            <div className={style.third}>
              <label className={style.subheading}> Select Age Range </label>
            </div>
            <div className={style.fourth}>
              <div className={style.fourth1}>
                <label className={style.label2}> From</label>
                <input
                  className={style.input1}
                  // type="number"
                  onChange={(e) => handleFrom(e)}
                  value={from}
                  name="from"
                  autoComplete="off"
                />
              </div>

              <div className={style.fourth2}>
                <label className={style.label2}> To</label>
                <input
                  className={style.input1}
                  onInput={(e) => handleTo(e)}
                  value={to}
                  name="to"
                  autoComplete="off"
                />
              </div>
              {showerrormsg && (
                <span className={style.errorMsgbelow}>
                  *Value of from should be less than to.
                </span>
              )}

              {showerrormsgg && (
                <span className={style.errorMsgbelow}>
                  {" "}
                  Please enter the full range.
                </span>
              )}
            </div>

            <div className={style.btn_css}>
              <button
                type="reset"
                className={style.whiteButton}
                onClick={() => resetFilter()}
              >
                Reset
              </button>
              <BlueButton children="Apply" onClick={() => ApplyFilter()} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.candidateReducer.loader,
    skillDatas: state.candidateReducer.skillDatas,
    candidateErrorMsg: state.candidateReducer.candidateErrorMsg,
    candidateErrorCode: state.candidateReducer.candidateErrorCode,
    sub_category_list: state.recruiterReducer.sub_category_list,
    showAlert: state.candidateReducer.showAlert,
    showBlur: state.candidateReducer.showBlur,
    category_list: state.recruiterReducer.category_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubCategoryList: (id) => dispatch(actionTypes.getSubCategoryList(id)),
    searchStudentListByRecruiter: (pageNo, payload, searchQuery) =>
      dispatch(
        actionTypes.searchStudentListByRecruiter(pageNo, payload, searchQuery)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceFilterc);
